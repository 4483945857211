import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer
} from 'components/SharedComponents/table';
import RowTable from '../RowTable'
import { ThemeContext } from 'styled-components'
import { useUserResume } from 'contexts/SharedContext'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },

});

function createData(
    diaSemana,
    data,
    regData,
    holidayType,
    holiday,
    holidayName,
    Entrada,
    saida,
    duracao,
    manual,
    inconsistencia,
    diaFechado,
    existeFile,
    itens
) {
    return {
        inconsistencia,
        holidayType,
        holiday,
        holidayName,
        diaFechado,
        existeFile,
        diaSemana,
        regData,
        Entrada,
        duracao,
        manual,
        saida,
        data,
        itens
    };
}

export default function TableFechamento({getInit, esconderEditar, handleUpload}) {
    const { colors, textTableColor } = useContext(ThemeContext)
    const { userResumeFechamento } = useUserResume()

    const classes = useStyles();
    
    return ( 
        <TableContainer>
            <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">DIA DA SEMANA</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">DATA</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">ENTRADA</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">SAÍDA</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">DURAÇÃO</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">REGISTRO MANUAL</TableCell>                 
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">INCONSISTÊNCIA</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">STATUS</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px'}} align="center">{esconderEditar ? 'VISUALIZAR' : 'EDITAR'}</TableCell>
                        <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px'}} align="center">UPLOAD</TableCell>
                    </TableRow>
                </TableHead>
                {
                    userResumeFechamento && userResumeFechamento.map((row, index) => {
                        const {
                            diaSemana,
                            data,
                            regData,
                            holidayType,
                            holiday,
                            holidayName,
                            Entrada,
                            saida,
                            duracao,
                            manual,
                            inconsistencia,
                            diaFechado,
                            existeFile,
                            itens } = row
                        const myRow = createData(
                            diaSemana,
                            data,
                            regData,
                            holidayType,
                            holiday,
                            holidayName,
                            Entrada,
                            saida,
                            duracao,
                            manual,
                            inconsistencia,
                            diaFechado,
                            existeFile,
                            itens)
                        return (
                            <TableBody style={{ width: '100%' }}>
                                {
                                   
                                }
                                <RowTable getInit={getInit} index={index} row={myRow} esconderEditar={esconderEditar} handleUpload={handleUpload}/>
                            </TableBody>
                        )
                    })
                }

            </Table>

        </TableContainer >
    );
}
