import React, { useState, useEffect, useContext } from 'react'
import { Container, Items, ColaboradoresContainer } from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro'
import { Wrap, WrapInput, WrapList } from "../../components/Wrap"
import { TitleStyle2 } from 'components/SharedComponents/styles'
import { SearchBox, SearchContent, SearchRow, IconeSearch, ItemsSearch } from "../../components/Search";
import MessageAlert from 'components/MessageAlert'
import { Button } from "../../components/Button";
import { INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { useSnackbar } from 'notistack';
import { api } from 'services/axios'
import CollabInfos from 'components/CollabInfos'
import { useColaboradoresResume } from 'contexts/SharedContext'
import { LabelInput, multSelect } from 'components/Input';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import "./styles.css";
import { ThemeContext } from 'styled-components'
import { ThemeProvider } from "@material-ui/styles";
import TabelaPainelAdm from 'components/TabelaPainelAdm';
import MultiSelect from "react-multi-select-component";
import { overrideStrings } from 'contexts/SharedContext'
import { Modal, CircularProgress, createMuiTheme } from "@material-ui/core";
import { createTrue } from 'typescript'
import { getMonthYear, GetSessionUser } from 'services/helpers'
import { MySelect} from "../../components/InputSelect";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


export default function PainelAdmin(props) {
  const { enqueueSnackbar } = useSnackbar()
  // const [companyId, setCompanyId] = useState(1)
  // const [services, setServices] = useState([])
  const [clients, setClients] = useState([]);
  const { setColaboradoresResume, setColabsInconsistentes } = useColaboradoresResume()

  const [tabelas, setTabelas] = useState([]);
  const [combo, setCombo] = useState([]);
  // const [showPessoas, setShowPessoas] = useState(false)
  // const [clientSelected, setClientSelected] = useState()
  const [values, setValues] = useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFim, setSelectedDateFim] = React.useState(null);
  const { bgHeading } = useContext(ThemeContext)
  const [clientSelected, setClientSelected] = useState([]);

  const [typeContract, setTypeContract] = useState([]);
  const [typeContractSelected, setTypeContractSelected] = useState([]);

  const [aggrement, setAggrement] = useState([]);
  const [aggrementSelected, setAggrementSelected] = useState([]);

  const [service, setService] = useState([]);
  const [serviceSelected, setServiceSelected] = useState([]);

  const [clientContract, setClientContract] = useState([]);
  const [loadDialog, setLoadDialog] = useState(false);

  const [monthYear] = useState(getMonthYear());
  const [selectedMesAno, setSelectedMesAno] = useState([]);


  const getInit = async () => {
    getClient();
    fetchColaboradores(1)
  }

  useEffect(() => {
    getInit()
  }, [])

  async function fetchColaboradores(init = 0) {
    setTabelas([]);
    let key = ''
    let query = '/register/getHourbyClient?'
    if (init === 1) {
      let dateNow = new Date().toLocaleDateString()
      query += `dataInicial=01/07/2020&dataFinal=${dateNow}`
    } else {
      key = enqueueSnackbar("Enviando Contrato", INFOMESSAGE);
      if (values.dataInicial) {
        query += `dataInicial=${values.dataInicial}&`
      }
      if (values.dataFinal) {
        query += `dataFinal=${values.dataFinal}&`
      }
    }
    if (values.clientId) {

      query += `clientId=${values.clientId.toString()}&`
    }
    if (values.typeContractId) {

      query += `aggrementTypeId=${values.typeContractId}&`
    }
    if (values.aggrementId) {

      query += `aggrementId=${values.aggrementId.toString()}&`
    }
    if (values.serviceId) {
      query += `serviceId=${values.serviceId.toString()}&`
    }
    try {
      setLoadDialog(true);
      const { data } = await api.get(query);
      if (data.success) {
        setTabelas(data.result)
        setCombo(data.result)
        // if (init === 1) {
        //   let clientes = data.result.map((item) => {
        //     return { value: item["Cliente ID"], label: item.Cliente };
        //   })
        //   setClients(clientes)
        // }
        userStatus(values)
        colabInconsistente(values)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  //Combo Tipo Contrato
  const getTypeContract = async (idClient) => {
    try {
      setLoadDialog(true);
      const { data } = await api.get('client/getAggrementType/' + idClient)
      if (data.success) {
        const { result } = data
        setTypeContract(result.map((item) => { return { label: item.AggrementTypeName, value: item.AggrementTypeId } }))
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  //Combo Cliente
  const getClient = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/client?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setClients(result.map((item) => { return { label: item.fantasyName, value: item.id } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Contrato
  const getAggrement = async (idType) => {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/aggrementType/getAggrement/' + idType + '/' + clientContract)

      if (data.success) {
        const { result } = data
        setAggrement(result.map((item) => { return { label: item.AggrementName, value: item.AggrementId } }))
        setLoadDialog(false);
      } else {
        enqueueSnackbar("Não há contratos para este cliente!", WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  //Combo Serviço
  const getService = async (idAgg) => {
    try {
      setLoadDialog(true);
      const { data } = await api.get('aggrement/getService/' + idAgg)
      if (data.success) {
        const { result } = data
        setService(result.map((item) => { return { label: item.ServiceName, value: item.ServiceId } }))
        setLoadDialog(false);
      } else {
        enqueueSnackbar('Não há serviços para este contrato!', WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  async function userStatus(valuesSearch) {
    let teste
 //   if (valuesSearch.length === 0) {
      teste = {
        "dataInicial": "01/01/2020",
        "dataFinal": new Date().toLocaleDateString(),
        "clientId": [],
        "serviceId": [],
        "aggrementId": [],
        "typeContractId": []
     }
    // } else {
    //   teste = {
    //     "dataInicial": "",
    //     "dataFinal": "",
    //     "clientId": [],
    //     "serviceId": [],
    //     "aggrementId": [],
    //     "typeContractId": []
    //   }
    //   teste.dataInicial = values.dataInicial
    //   teste.dataFinal = values.dataFinal
    //   teste.clientId = values.clientId ? values.clientId : []
    //   teste.serviceId = values.serviceId ? values.serviceId : []
    //   teste.aggrementId = values.aggrementId ? values.aggrementId : []
    //   teste.typeContractId = values.typeContractId ? values.typeContractId : []
    // }


    try {
      setLoadDialog(createTrue);
      const { data } = await api.put("/register/usersStatus", teste);
      if (data.success) {
        setColaboradoresResume(data.result)
        setLoadDialog(false);
      } else {
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }

  }


  async function colabInconsistente(valuesSearch) {
    let teste
//    if (valuesSearch.length === 0) {
      teste = {
        "dataInicial": "01/06/2020",
        "dataFinal": new Date().toLocaleDateString(),
        "clientId": [],
        "serviceId": [],
        "aggrementId": [],
        "typeContractId": []
      }
    // } else {
    //   teste = {
    //     "dataInicial": "",
    //     "dataFinal": "",
    //     "clientId": [],
    //     "serviceId": [],
    //     "aggrementId": [],
    //     "typeContractId": []
    //   }
    //   teste.dataInicial = values.dataInicial
    //   teste.dataFinal = values.dataFinal
    //   teste.clientId = values.clientId ? values.clientId : []
    //   teste.serviceId = values.serviceId ? values.serviceId : []
    //   teste.aggrementId = values.aggrementId ? values.aggrementId : []
    //   teste.typeContractId = values.typeContractId ? values.typeContractId : []
    // }
    try {
      setLoadDialog(false);
      const { data } = await api.put("/register/usersStatusDetails", teste);
      if (data.success) {
        setColabsInconsistentes(data.result)
        setLoadDialog(false);
      } else {
        setColabsInconsistentes([])
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setColabsInconsistentes([])
      setLoadDialog(false);
    }
  }

  async function handleClient(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        const idClient = obj.map(item => item.value);
        setValues({ ...values, clientId: idClient, typeContractId: [], aggrementId: [], serviceId: [] });
        setTypeContract([]);
        setTypeContractSelected([]);
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        const idClient = obj.map(item => item.value);
        setValues({ ...values, clientId: idClient });
        setTypeContract([]);
        setTypeContractSelected([]);
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
        getTypeContract(idClient);
        setClientContract(idClient)
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setClientSelected(obj)
      const idClient = obj.map(item => item.value);
      setValues({ ...values, clientId: idClient });
      getTypeContract([]);
      setTypeContract([]);
      setTypeContractSelected([]);
      setAggrementSelected([]);
      setAggrement([]);
      setService([]);
      setServiceSelected([]);
    }
  }


  async function handleTipoContrato(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTypeContractSelected(obj)
        const idType = obj.map(item => item.value);
        setValues({ ...values, typeContractId: idType, aggrementId: [], serviceId: [] });
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTypeContractSelected(obj)
        const idType = obj.map(item => item.value);
        setValues({ ...values, typeContractId: idType });
        getAggrement(idType);
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setTypeContractSelected(obj)
      const idType = obj.map(item => item.value);
      setValues({ ...values, typeContractId: idType });
      getAggrement([]);
      setAggrementSelected([]);
      setAggrement([]);
      setService([]);
      setServiceSelected([]);
    }
  }

  async function handleContrato(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setAggrementSelected(obj)
        const idAgg = obj.map(item => item.value);
        setValues({ ...values, aggrementId: idAgg, serviceId: [] });
        setServiceSelected([]);
        setService([]);

      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setAggrementSelected(obj)
        const idAgg = obj.map(item => item.value);
        setValues({ ...values, aggrementId: idAgg });
        setServiceSelected([]);
        setService([]);
        getService(idAgg);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setAggrementSelected(obj)
      const idAgg = obj.map(item => item.value);
      setValues({ ...values, aggrementId: idAgg });
      getService([]);
      setServiceSelected([]);
      setService([]);
    }
  }

  async function handleService(items) {

    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)
        const idService = obj.map(item => item.value);
        setValues({ ...values, serviceId: idService });

      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)
        const idService = obj.map(item => item.value);
        setValues({ ...values, serviceId: idService });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setServiceSelected(obj)
      const idService = obj.map(item => item.value);
      setValues({ ...values, serviceId: idService });

    }
  }

  useEffect(() => {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
  }, []);
  
  const handleMesAno = (item) => {
    setSelectedMesAno(item);
    if (item === null) {
      handleDateChange(null);
      handleDateChangeFim(null);
    } else {
      handleDateChange(monthYear[item.value].dtInicial);
      handleDateChangeFim(monthYear[item.value].dtFinal);
    }
  }

  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue["100"],
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });

  let handleDateChange = (date) => {
    setSelectedDate(date);
    if (date === null) {
      values.dataInicial = null

    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = day + '/' + mes + '/' + ano;
      values.dataInicial = data
      //setValues({ ...values, dataInicial: data })
    }
  }

  let handleDateChangeFim = (date) => {
    setSelectedDateFim(date);
    if (date === null) {
      values.dataFinal = null

    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = day + '/' + mes + '/' + ano;
      values.dataFinal = data
      //setValues({ ...values, dataFinal: data })
    }
  }

  function clearSearch() { 
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear });
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setClientSelected('');
    setTypeContractSelected('');
    setServiceSelected('');
    setAggrementSelected('');
    setTypeContract([]);
    setAggrement([]);
    setService([]); 
    setValues({...values,clientId:'',typeContractId:'',aggrementId:'',serviceId:''})
    setTabelas([]);
  }
  

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  return (
    <Container>
      <SearchContent>
        <SearchBox>
          <TitleStyle2>Filtros</TitleStyle2>
          <form>
            <Wrap  >
              <SearchRow>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <WrapInput >
                      <KeyboardDatePicker
                        minDateMessage="Não é possível buscar por datas futuras."
                        clearable
                        clearLabel="Limpar"
                        animateYearScrolling
                        cancelLabel="Cancelar"
                        margin="normal"
                        helperText={''}
                        id="dateInitial"
                        label="Data Início"
                        format="dd/MM/yyyy"
                        onChange={handleDateChange}
                        value={selectedDate}
                        minDate={new Date(new Date("01-01-2018"))}
                        maxDate={new Date(new Date())}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </WrapInput>

                    <WrapInput>
                      <KeyboardDatePicker
                        minDateMessage="Não é possível buscar por datas futuras."
                        clearable
                        clearLabel="Limpar"
                        animateYearScrolling
                        cancelLabel="Cancelar"
                        margin="normal"
                        helperText={''}
                        id="dateFinal"
                        label="Data Fim"
                        format="dd/MM/yyyy"
                        onChange={handleDateChangeFim}
                        value={selectedDateFim}
                        minDate={new Date(new Date("01-01-2018"))}
                        maxDate={new Date(new Date())}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </WrapInput>

                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                <WrapInput>
                  <LabelInput>Mês</LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder="Opções.."
                    onChange={handleMesAno}
                    value={selectedMesAno}
                    isClearable={true}
                    isSearchable={true}
                    options={monthYear.map((item) => {
                      return { value: item.id, label: item.monthYear };
                    })}
                  />
                </WrapInput>
                <WrapInput>
                  <LabelInput>Clientes </LabelInput>
                  <MultiSelect
                    overrideStrings={overrideStrings}
                    options={clients && clients.map((item) => { return { value: item.value, label: item.label }; })}
                    value={clientSelected}
                    onChange={(item) => handleClient(item)}
                    labelledBy={"Select"}
                    styles={multSelect}
                  />
                </WrapInput>
                
              </SearchRow>
              <SearchRow>
              <WrapInput>
                  <LabelInput>Tipo de Contrato/Serviço </LabelInput>
                  <MultiSelect
                    overrideStrings={overrideStrings}
                    options={typeContract}
                    value={typeContractSelected}
                    onChange={(item) => handleTipoContrato(item)}
                    labelledBy={"Select"}
                  />
                </WrapInput>
                <WrapInput>
                  <LabelInput>Contratos </LabelInput>
                  <MultiSelect
                    overrideStrings={overrideStrings}
                    options={aggrement}
                    value={aggrementSelected}
                    onChange={(item) => handleContrato(item)}
                    labelledBy={"Select"}
                  />
                </WrapInput>
                <WrapInput>
                  <LabelInput>Serviços </LabelInput>
                  <MultiSelect
                    overrideStrings={overrideStrings}
                    options={service}
                    value={serviceSelected}
                    onChange={(item) => handleService(item)}
                    labelledBy={"Select"}
                  />
                </WrapInput>
                <WrapInput style={{ marginTop: '10px' }}>
                  <ItemsSearch>
                    <IconeSearch onClick={() => fetchColaboradores()} size={25} title="Pesquisar"/>
                    <Button className='widthButton' onClick={()=>clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                  </ItemsSearch>
                </WrapInput>
              </SearchRow>

            </Wrap>
          </form>
        </SearchBox>
        <ColaboradoresContainer>
          <CollabInfos></CollabInfos>
        </ColaboradoresContainer>
      </SearchContent>
      <WrapList>
        <HeaderList>
          <span style={{ marginLeft: '20px' }}>TOTAL DE HORAS  </span>
        </HeaderList>
        {
          <BodyList>
            <TabelaPainelAdm
              tabelas={tabelas}
            />
          </BodyList>
        }
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}



