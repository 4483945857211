import React, { useState, useEffect, useContext } from 'react'
import {
  Title,
  WrapInput,
  LabelInput,
  LabelInput2,
  Input,
  InputText,
  WrapButtons,
  WrapHora,
  Button2,
  RegistroManual,
  BodyRegistroManual,
  Input2,
  WrapChecks,
  WrapInput2,
} from './styles.js'
import { shade } from 'polished'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { IoMdClock } from 'react-icons/io'
import { ThemeContext } from 'styled-components'
import { useUserResume, useShowModal } from 'contexts/SharedContext'
import { GetSessionUser } from 'services/helpers'
import { ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import MessageAlert from 'components/MessageAlert'

export default function RegistroHoras({ regData }) {
  const [hourEntradaSelected, setHourEntradaSelected] = useState(null)
  const [hourSaidaSelected, setHourSaidaSelected] = useState(null)
  const [hourEntradaToSend, setHourEntradaToSend] = useState('')
  const [hourSaidaToSend, setHourSaidaToSend] = useState('')
  const [locais, setLocais] = useState([])
  const [servicos, setServicos] = useState([])
  const [atividades, setAtividades] = useState([])
  const { userResumeFechamento, setUserResumeFechamento } = useUserResume()
  const { showModalIncluirRegistro, setShowModalIncluirRegistro } = useShowModal()
  const [locaisSelected, setLocaisSelected] = useState()
  const [servicoSelected, setServicoSelected] = useState()
  const [justificativas, setJustificativas] = useState([])
  const [observacaoText, setObservacaoText] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [atividadesSelected, setAtividadesSelected] = useState()
  const [justificativasSelected, setJustificativasSelected] = useState()
  const { colors, bgHeading } = useContext(ThemeContext)
  const [launchSend, setLaunchSend] = useState(null);

  const handleRegistroManual = (value) => {
    setShowModalIncluirRegistro(value)

  }

  const launch = [
    { value: "", label: "Outras Opções" },
    { value: "intervalo", label: "Intervalo" }
  ]

  useEffect(() => {
    setLaunchSend({ value: "", label: "Outras Opções" })
  }, [])


  const getAtividades = async () => {
    try {
      const { data } = await api.get('/job/getByService/' + servicoSelected.value)
      if (data.success) {
        const { result } = data
        let temp = []
        await result.map(item => {
          temp.push({
            id: item.Job.id,
            name: item.Job.name,
          })
        })
        setAtividades(temp)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }

  }

  useEffect(() => {
    if (servicoSelected) {
      getAtividades()
    }
  }, [servicoSelected])


  const getLocais = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/place/getByCompany/' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setLocais(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getJustificativas = async () => {
    try {
      const { data } = await api.get('/justification/0/?companyId=1')
      if (data.success) {
        const { result } = data
        setJustificativas(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getServicos = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/service/getByPerson/' + perfil.personId)
      if (data.success) {
        const { result } = data
        let temp = []
        await result.map(item => {
          temp.push({
            id: item.id,
            name: item.name,
            clientName: item.clientName
          })
        })
        setServicos(temp)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  useEffect(() => {
    getLocais()
    getServicos()
    getJustificativas()
  }, [])

  const handleServico = (item) => {
    setServicoSelected(item)
  }

  const handleAtividades = (item) => {
    setAtividadesSelected(item)
  }

  const handleJustificativas = (item) => {
    setJustificativasSelected(item)
  }

  const handleLocais = (item) => {
    setLocaisSelected(item)
  }



  const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 35
  });



  const handleHourEntradaSelected = (e) => {
    setHourEntradaSelected(e)
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setHourEntradaToSend(hora + ':' + minutos)
  }


  const handleHourSaidaSelected = (e) => {
    setHourSaidaSelected(e)
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setHourSaidaToSend(hora + ':' + minutos)
  }

  let handleLaunchSend = (item) => {
    setLaunchSend(item);
  }


  const insertNewRegister = () => {
    if (!hourSaidaSelected) {
      enqueueSnackbar(<MessageAlert message={'Campo Hora Saída obrigatorio.'} />, WARNINGMESSAGE)
    }
    else if (hourSaidaSelected + '' === 'Invalid Date') {
      enqueueSnackbar(<MessageAlert message={'Hora com formato inválido.'} />, WARNINGMESSAGE)
    }
    else if (!hourEntradaSelected) {
      enqueueSnackbar(<MessageAlert message={'Campo Hora Entrada obrigatorio.'} />, WARNINGMESSAGE)
    }
    else if (hourEntradaSelected + '' === 'Invalid Date') {
      enqueueSnackbar(<MessageAlert message={'Hora com formato inválido.'} />, WARNINGMESSAGE)
    }
    else if (launchSend.value != 'intervalo' && !servicoSelected) {
      enqueueSnackbar(<MessageAlert message={'Serviço obrigatório.'} />, WARNINGMESSAGE)
    }
    else if (launchSend.value != 'intervalo' && !atividadesSelected) {
      enqueueSnackbar(<MessageAlert message={'Atividade obrigatório.'} />, WARNINGMESSAGE)
    }
    else if (launchSend.value != 'intervalo' && !locaisSelected) {
      enqueueSnackbar(<MessageAlert message={'Local obrigatório.'} />, WARNINGMESSAGE)
    }
    else if (!justificativasSelected) {
      enqueueSnackbar(<MessageAlert message={'Campo Justificativa obrigatorio.'} />, WARNINGMESSAGE)
    } else {

      try {
        let temp = showModalIncluirRegistro.lineId + 1//hourEntradaToSend.split(':')[0]
        //temp = parseInt(temp) + parseInt(hourEntradaToSend.split(':')[1])
        const obj = {
          id: temp,
          entrada: hourEntradaToSend,
          saida: hourSaidaToSend,
          placeId: launchSend.value == "" ? locaisSelected.value : "",
          placeName: launchSend.value == "" ? locaisSelected.label : "",
          serviceId: launchSend.value == "" ? servicoSelected.value : "",
          serviceName: launchSend.value == "" ? servicoSelected.name : "",
          clientName: launchSend.value == "" ? servicoSelected.clientName : "",
          jobId: launchSend.value == "" ? atividadesSelected.value : "",
          jobName: launchSend.value == "" ? atividadesSelected.label : "Intervalo",
          justificationId: justificativasSelected.value,
          justificationName: justificativasSelected.label,
          observacao: observacaoText,
          manual: true,
          operacao: launchSend.value,
          status: 1,
          novo: true
        }

        /*   const temp = {
 "id": 132,
 "entrada": "17:41",
 "saida": "17:42",
 "placeId": 1,
 "placeName": "Interno",
 "serviceId": 5,
 "serviceName": "New Timesheet",
 "jobId": 1,
 "jobName": "Treinamento",
 "justificationId": 2,
 "justificationName": "Esqueci",
 "observacao": "sds",
 "manual": true,
 "status": 1
}*/
        const newArray = userResumeFechamento
        const tempArray = newArray.map(item => {
          if (item.regData === regData)
            item.itens.push(obj)
          return item
        })
        setUserResumeFechamento(tempArray)
        // enqueueSnackbar('Registro efetuado com sucesso.', SUCCESSMESSAGE)
        // getInit('Atualizando registros...')
        handleRegistroManual(false)
      } catch (err) {
        console.log(err)
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      }
    }
  }


  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue["100"],
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });

  return (
    <RegistroManual>
      <Title> &nbsp;&nbsp;&nbsp;&nbsp;INCLUIR NOVO REGISTRO</Title>
      <BodyRegistroManual>
        <WrapChecks>
          <WrapInput2>
            <WrapHora>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    ampm={false}
                    autoFocus={true}
                    margin="normal"
                    id="time-picker"
                    helperText={''}
                    label={'Hora Entrada'}
                    value={hourEntradaSelected}
                    onChange={e => handleHourEntradaSelected(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </WrapHora>
          </WrapInput2>
          <WrapInput2>
            <WrapHora>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    helperText={''}
                    ampm={false}
                    margin="normal"
                    id="time-picker"
                    label={'Hora Saída'}
                    value={hourSaidaSelected}
                    onChange={e => handleHourSaidaSelected(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </WrapHora>
          </WrapInput2>
        </WrapChecks>
        <WrapInput>
          <LabelInput2> Operação </LabelInput2>
          <Input2
            styles={{ control: customControlStyles }}
            placeholder="Operação"
            value={launchSend}
            onChange={handleLaunchSend}
            options={launch}
            border={`0.5px ${shade(0.2, colors.surface)} solid`} />
        </WrapInput>
        {
          launchSend && launchSend.value != 'intervalo' && <>
            <WrapInput>
              <LabelInput2> Serviço </LabelInput2>
              <Input2
                styles={{ control: customControlStyles }}
                placeholder="Serviços"
                isClearable
                value={servicoSelected}
                onChange={handleServico}
                options={servicos.map(item => { return { value: item.id, name: item.name, clientName: item.clientName, label: item.clientName + " - " + item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
            <WrapInput>
              <LabelInput2> Atividade </LabelInput2>
              <Input2
                styles={{ control: customControlStyles }}
                placeholder="Atividades"
                isClearable
                value={atividadesSelected}
                onChange={handleAtividades}
                options={atividades.map(item => { return { value: item.id, label: item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
            <WrapInput>
              <LabelInput> Local </LabelInput>
              <Input
                styles={{ control: customControlStyles }}
                placeholder="Locais"
                isClearable
                value={locaisSelected}
                onChange={handleLocais}
                options={locais.map(item => { return { value: item.id, label: item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
          </>
        }
        {
          launchSend &&
          <>
          </>
        }
        <WrapInput>
          <LabelInput2> Justificativa </LabelInput2>
          <Input2
            styles={{ control: customControlStyles }}
            placeholder="Justificativas"
            isClearable
            value={justificativasSelected}
            onChange={handleJustificativas}
            options={justificativas.map(item => { return { value: item.id, label: item.name } })}
            border={`0.5px ${shade(0.2, colors.surface)} solid`} />
        </WrapInput>
        <WrapInput>
          <LabelInput> Observação </LabelInput>
          <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
        </WrapInput>
        <WrapButtons>
          <Button2 onClick={() => handleRegistroManual(false)} bg="#D12020" >Cancelar</Button2>
          <Button2 onClick={() => insertNewRegister()} bg="#107CB8" >Confirmar</Button2>
        </WrapButtons>
      </BodyRegistroManual>
    </RegistroManual>
  )
}
