import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { modalStyle, ModalBox } from '../../components/Modal'
import { ThemeContext } from 'styled-components'
import { TitleStyle2, Items } from "../../pages/CadastroPessoas/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal';
import { TableContainer } from 'components/SharedComponents/table';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function Historico({ historico, name, handleCloseHist }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { colors, textTableColor } = useContext(ThemeContext)

  return (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Histórico de Atualização - {name}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleCloseHist()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'auto', height: '400px' }}>
        <div className={classes.root}>
          {
            historico && historico.map((row, index) => {
              return (
                <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {row.transactionType === 1 && <b>Inclusão - </b>}
                      {row.transactionType === 2 && <b>Alteração - </b>}
                      {row.transactionType === 3 && <b>Exclusão - </b>}
                      {row.dtAlter}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>{row.changePerson}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: "#DCDCDC" }}>
                    <Typography>
                      <p><b>Antes : </b>{row.beforeFields}</p>
                      <p><b>Depois: </b>{row.afterFields}</p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </div>
      </ModalBox>
    </div>
  );
}