import React, { useState, useEffect, useContext } from 'react'
import { Container, Items,ModalBox } from './styles.js'
import { Button, ConfirmarBtn, CancelarBtn } from "../../components/Button"
import { api } from 'services/axios'
import TableCadastroCliente from 'components/TableCadastroCliente'
import { useSnackbar } from 'notistack';
import { GetSessionUser } from 'services/helpers'
import "./style.css";
import { useShowModal } from 'contexts/SharedContext'
import { SearchBox, IconeSearch, ItemsSearch} from "../../components/Search";
import { Wrap, WrapInput, WrapList } from "../../components/Wrap";
import { modalStyle } from '../../components/Modal'
import { HeaderList, BodyList } from '../../components/TabelaCadastro'
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { Input, LabelInput, inputStyle } from "components/Input";
import { Modal, CircularProgress } from "@material-ui/core";
import MessageAlert from 'components/MessageAlert'
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal'
import { ThemeContext } from 'styled-components'
import { TitleStyle2 } from "../../pages/CadastroPessoas/styles";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';

export default function CadastroCliente() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { colors, textTableColor } = useContext(ThemeContext)
  const [client, setOptions] = useState([]);
  const [compId, setCompId] = useState(1);
  const [values, setValues] = useState({ externalCode: "", cnpj: "", fantasyName: "", clientName: "", companyId: 1 });
  const [valuesAlt, setValuesAlt] = useState([]);

  const [company, setCompany] = useState();
  const [pagina, setPagina] = useState(1);
  const [totalPagina, setTotalPaginas] = useState();
  const [qntPorPagina, setQuantidadePorPagina] = useState();
  const { setShowModalCliente, showModalCliente } = useShowModal();
  const [loadDialog, setLoadDialog] = useState(false);

  //Iniciar 
  const getInit = async () => { 
    setLoadDialog(true);
    const { perfil } = GetSessionUser()
    setCompId(1)
    fetchClient(1);
    getCompany(1);
    try {
      const { data } = await api.get('/client?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setOptions(result)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }

  }
  useEffect(() => {
    getInit();
  }, []);
  
  useEffect(() => {
    //getInit()
    if (showModalCliente.action === 'editar') {
      setValuesAlt({
        "companyId": compId,
        "externalCode": showModalCliente.item.externalCode,
        "cnpj": showModalCliente.item.cnpj,
        "fantasyName": showModalCliente.item.fantasyName,
        "clientName": showModalCliente.item.clientName,
        "logChange": showModalCliente.item.logChange
      })
    }
    if (showModalCliente.action === 'inserir') {
      setValuesAlt({ "companyId": compId })
    }
  }, [showModalCliente.action])

  //Criar Client
  async function sendClient() {
    try {
      setLoadDialog(true);
      const { data } = await api.post("/client?companyId=" + compId, valuesAlt);
      // alert(JSON.stringify(values))
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalCliente({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    fetchCreate(1);
  }

  async function fetchCreate(paginaVal) {
    try {
      setLoadDialog(true);
      const { data } = await api.get("client" + '/0?companyId=' + 1 + `&page=${paginaVal}`);
      if (data.success) {
        const { result } = data;
        setOptions(result);
        setTotalPaginas(data.pages);
        setQuantidadePorPagina(data.result.length);
        if (paginaVal === 1) {
          setPagina(1)
        }
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar clientes", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  // Apagar Client
  async function deleteClient(id) {
    try {
      setLoadDialog(true);
      const { data } = await api.delete("/client/" + id);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalCliente({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    //setDeleteDialog(false);
    fetchCreate(1);
  }

  //Atualizar Client
  async function updateClient(id) {
    try {
      setLoadDialog(true);
      let comp_id = '&companyId=' + compId
      const { data } = await api.put("/client/" + id + comp_id, valuesAlt);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalCliente({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }

    fetchCreate(1);
  }

  //Pesquisar Client
  async function fetchClient(paginaVal) {
    const key = enqueueSnackbar('Pesquisando Clientes...', INFOMESSAGE)

    try {
      let cnpjSearch = values.cnpj.replace(/\D/g, "");
      let comp_id
      let query_filter = ''

      if (values.externalCode !== "") {
        query_filter = query_filter + `&externalCode=${values.externalCode}`
      }

      if (values.cnpj !== "") {
        query_filter = query_filter + `&cnpj=${cnpjSearch}`
      }

      if (values.fantasyName !== "") {
        query_filter = query_filter + `&fantasyName=${values.fantasyName}`
      }

      if (values.clientName !== "") {
        query_filter = query_filter + `&clientName=${values.clientName}`
      }

      query_filter += `&page=${paginaVal}`

      if (query_filter === "&externalCode= &cnpj= &fantasyName= &clientName= &page=" + paginaVal) {
        comp_id = '/0?companyId=' + 1 + `&page=${paginaVal}`
      } else {
        comp_id = '?companyId=' + 1 + query_filter
      }
      setLoadDialog(true);
      const { data } = await api.get("client" + comp_id);
      if (data.success) {
        const { result } = data;
        setOptions(result);
        setTotalPaginas(data.pages);
        setQuantidadePorPagina(data.result.length);
        if (paginaVal === 1) {
          setPagina(1)
        }
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setOptions([]);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar clientes", ERRORMESSAGE);
      setLoadDialog(false);
    }
    closeSnackbar(key)
  }


  //Get Company Input Empresa

  async function getCompany(idVal) {
    try {
      setLoadDialog(true);
      let { data } = await api.get("/client?companyId=" + compId + "&page=1&id=" + idVal);
      if (data.success) {
        setCompany(data.result[0].CompanyName);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    }
    catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  //Handles
  let handleChange = (item) => {
    if (item.label) {
      let name = "clientId"
      let value = item.value
      setValues({ ...values, [name]: value });
    } else {
      let { name, value } = item.target;
      setValues({ ...values, [name]: value });
    }

  }

  let handleChangeAlt = (itemAlt) => {

    if (itemAlt.label) {
      let { label, value, handleName } = itemAlt
      if (handleName === "clientID") {
        setValuesAlt(
          { ...valuesAlt, clientId: value, ClienteName: label }
        );
      } else {
        setValuesAlt(
          { ...valuesAlt, aggrementTypeId: value, AggrementType: label }
        );
      }
    } else {
      let { name, value } = itemAlt.target;
      setValuesAlt({ ...valuesAlt, [name]: value });
    }
  }


  const handleClose = () => {
    setShowModalCliente({ show: false });
  };

  function clearSearch() {
    setValues({ externalCode: "", cnpj: "", fantasyName: "", clientName: "", companyId: 1, logChange: 0 });
    setOptions([]);
  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const modalContent = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        {showModalCliente.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      {showModalCliente.action === 'editar' || showModalCliente.action === 'inserir' ?
        <ModalBox>
          <Wrap>
            <WrapInput classname="Wrap">
              <LabelInput>Código Externo </LabelInput>
              <Input
                style={inputStyle}
                type="text"
                name="externalCode"
                onChange={handleChangeAlt}
                value={valuesAlt.externalCode}
              />
            </WrapInput>
            <WrapInput classname="Wrap">
              <LabelInput>Nome Fantasia <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <Input
                style={inputStyle}
                maxLength="40"
                type="text"
                name="fantasyName"
                onChange={handleChangeAlt}
                value={valuesAlt.fantasyName}
              />
            </WrapInput>
            <WrapInput classname="Wrap">
              <LabelInput>Nome do Cliente <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <Input
                style={inputStyle}
                maxLength="50"
                type="text"
                name="clientName"
                onChange={handleChangeAlt}
                value={valuesAlt.clientName}
              />
            </WrapInput>
          </Wrap>
          <Wrap>
            <WrapInput className="wrapInputModal">
              <Items>
                <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                <Button type="button" onClick={() => showModalCliente.action === 'editar' ? updateClient(showModalCliente.item.id) : sendClient()}> Salvar </Button>
              </Items>
            </WrapInput>
          </Wrap>
        </ModalBox>
        : ''}

      {showModalCliente && showModalCliente.action === 'remover' ?
        <div>
          <WrapInput className="alertText">
            <h3>Você tem certeza que deseja remover {showModalCliente ? showModalCliente.item.clientName : ''}? Essa operação é permanente! </h3>
          </WrapInput>
          <WrapInput className="alertBtn">
            <Items>
              <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
              <ConfirmarBtn type="button" onClick={() => deleteClient(showModalCliente.item.id)}> Confirmar </ConfirmarBtn>
            </Items>
          </WrapInput>
        </div>

        : ''
      }
    </div>
  );



  return (
    <Container>
      <Modal open={showModalCliente.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalContent}
      </Modal>
      <SearchBox>
        <TitleStyle2>Cadastro de Clientes</TitleStyle2>
        <Wrap className="Wrap" >
          <WrapInput>
            <LabelInput>Código Externo </LabelInput>
            <Input
              style={inputStyle}
              type="text"
              name="externalCode"
              onChange={handleChange}
              value={values.externalCode}

            />
          </WrapInput>
          <WrapInput>
            <LabelInput>Nome Fantasia</LabelInput>
            <Input
              style={inputStyle}
              maxLength="40"
              type="text"
              name="fantasyName"
              onChange={handleChange}
              value={values.fantasyName}
            />
          </WrapInput>
          <WrapInput>
            <LabelInput>Nome do Cliente</LabelInput>
            <Input
              style={inputStyle}
              maxLength="50"
              type="text"
              name="clientName"
              onChange={handleChange}
              value={values.clientName}
            //inserir dados da pesquisa
            />
          </WrapInput>
          <WrapInput style={{ marginTop: '10px' }}>
            <ItemsSearch>
              <IconeSearch size={25} title="Pesquisar" onClick={() => fetchClient(1)} />
              <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => setShowModalCliente({ show: true, title: 'CADASTRAR CLIENTE', action: 'inserir' })}>&nbsp;Novo<AddIcon></AddIcon></Button>
              <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
            </ItemsSearch>
          </WrapInput>
        </Wrap>
      </SearchBox>
      <WrapList>
        <HeaderList className="HeaderList">
          <span> Clientes </span>
        </HeaderList>

        <BodyList>
          <TableCadastroCliente clientes={client} />
        </BodyList>
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}