import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/index';
import { LoadingProvider, MenuProvider, SharedProvider, ThemeProvider } from './contexts/index'
import { AiOutlineCopyright } from "react-icons/ai";

ReactDOM.render(
    <ThemeProvider>
        <SharedProvider>
            <LoadingProvider>
                <MenuProvider>
                    <App />
                    <div style={{ color: "#FFFFFF", textAlign: "center" }}>
                        <AiOutlineCopyright></AiOutlineCopyright> 2020 - Confluence Soluções em Informática | Todos os direitos reservados - Versão {process.env.REACT_APP_VERSAO + ' - ' + process.env.REACT_APP_AMBIENTE}
                    </div>
                </MenuProvider>
            </LoadingProvider>
        </SharedProvider>
    </ThemeProvider>
    , document.getElementById('root'));

