import React, { useState, useEffect } from 'react'
import { Container } from './styles.js'
import { Button } from "../../components/Button";
import { api } from 'services/axios'
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, LOADMESSAGE } from 'utils/types-messages'
import { useSnackbar } from 'notistack';
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import MultiSelect from "react-multi-select-component"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  overrideStrings,
  TitleStyle2,
  MySelect
} from '../Consolidado/styles.js';
import { Wrap, WrapInput, WrapList } from "../../components/Wrap"
import { HeaderList, BodyList } from '../../components/TabelaCadastro'
import { SearchBox, IconeSearch, ItemsSearch, IconExcel } from "../../components/Search";
import TabelaConsolidado from 'components/TabelaConsolidado';
import { LabelInput, multSelect } from "components/Input";
import MessageAlert from 'components/MessageAlert'
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { getMonthYear } from 'services/helpers'
export default function Consolidado() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [compId, setCompId] = useState(1);
  const [table, setTable] = useState([]);
  const [tipoContratacao, setTipoContratacao] = useState([]);
  const [tipoContratacaoSelected, setTipoContratacaoSelected] = useState([]);

  const [team, setTeam] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);

  const [valuesAlt, setValuesAlt] = useState({ 'typeContractId': '', 'dataInicial': "", 'dataFinal': "", 'personId': '', 'teamId': '' });
  const [excel, setExcel] = useState({ typeContractId: [], dataInicial: "", dataFinal: "", personId: [], serviceId: [], statusId: [], teamId: [], clientId: [], aggrementId: [], dec: 0, xls: true });
  const [buttonDisable, setButtonDisable] = useState(true);

  const [person, setOptions] = useState([]);
  const [colaboradoresSelected, setColaboradoresSelected] = useState([]);

  const [client, setClient] = useState([]);
  const [clientSelected, setClientSelected] = useState([]);

  const [aggrement, setAggrement] = useState([]);
  const [aggrementSelected, setAggrementSelected] = useState([]);

  const [service, setService] = useState([]);
  const [serviceSelected, setServiceSelected] = useState([]);

  const [statusSend, setStatusSend] = useState(null);

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFim, setSelectedDateFim] = React.useState(null);
  const [tituloLoad, setTituloLoad] = React.useState('');

  const [decSelected, setDecSelected] = useState();
  const [loadDialog, setLoadDialog] = useState(false);
  const [selectedMesAno, setSelectedMesAno] = useState([]);
  const [monthYear] = useState(getMonthYear());
  const status = [
    { value: 1, label: "Aberto" },
    { value: 0, label: "Fechado" }
  ]


  //Combo Equipe
  const getTeam = async () => {
    try {
      const { data } = await api.get('/team?companyId=' + compId)
      if (data.success) {
        const { result } = data
        setTeam(result.map((item) => { return { label: item.name, value: item.id } }));
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  useEffect(() => {
    getTeam()
    getTypeAggrement()
    getClient();
  }, []);

  //Combo Pessoas
  const getPerson = async (idTeam, idTypeAggrement) => {
    try {
      const { data } = await api.get(`team/getPerson/${idTeam}/${idTypeAggrement}`)
      if (data.success) {
        const { result } = data

        setOptions(result.map((item) => { return { label: item.personName, value: item.PersonId } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Cliente
  const getClient = async () => {
    try {
      const { data } = await api.get('/client?companyId=' + compId)
      if (data.success) {
        const { result } = data
        setClient(result.map((item) => { return { label: item.fantasyName, value: item.id } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Tipo Contrato
  const getTypeAggrement = async () => {
    try {
      const { data } = await api.get('/typeContract/0?companyId=1')
      if (data.success) {
        const { result } = data
        setTipoContratacao(result.map((item) => { return { label: item.name, value: item.id } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Contrato
  const getAggrement = async (idClient) => {
    try {
      const { data } = await api.get('client/getAggrement/' + idClient)
      if (data.success) {
        const { result } = data
        setAggrement(result.map((item) => { return { label: item.AggrementName, value: item.AggrementId } }))
      } else {
        enqueueSnackbar("Não há contratos para este cliente!", WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Serviço
  const getService = async (idAgg) => {
    try {
      const { data } = await api.get('aggrement/getService/' + idAgg)
      if (data.success) {
        const { result } = data
        setService(result.map((item) => { return { label: item.ServiceName, value: item.ServiceId } }))
      } else {
        enqueueSnackbar('Não há serviços para este contrato!', WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  useEffect(() => {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setDecSelected({ value: 2, label: '2' })
    setExcel({ ...excel, dec: 2 });

  }, []);

  let handleDateChange = (date) => {
    setSelectedDate(date);
    if (date === null) {
      setValuesAlt({ ...valuesAlt, dataInicial: "" });
      setExcel({ ...excel, dataInicial: "" });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();

      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }

      let data = day + '/' + mes + '/' + ano;
      valuesAlt.dataInicial = data;
      excel.dataInicial = data;
      setValuesAlt({ ...valuesAlt });
      setExcel({ ...excel });


      // setValuesAlt({ ...valuesAlt, dataInicial: data });
      // setExcel({ ...excel, dataInicial: data });

    }
  }

  const handleDateChangeFim = (date) => {
    setSelectedDateFim(date);
    if (date === null) {
      setValuesAlt({ ...valuesAlt, dataFinal: "" });
      setExcel({ ...excel, dataFinal: "" });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();

      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }

      let data = day + '/' + mes + '/' + ano;
      valuesAlt.dataFinal = data;
      excel.dataFinal = data;
      setValuesAlt({ ...valuesAlt });
      setExcel({ ...excel });
      // setValuesAlt({ ...valuesAlt, dataFinal: data });
      // setExcel({ ...excel, dataFinal: data });

    }
  }

  async function handleTipoContratacao(items) {
    if (items) {
      if (items.length === 0) {
        clearSearch();
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTipoContratacaoSelected(obj)
        const typeContractId = obj.map(item => item.value);
        setExcel({ ...excel, typeContractId: typeContractId });
        setValuesAlt({ ...valuesAlt, typeContractId: typeContractId, teamId: [], personId: [] });
        setTeam([])
        setTeamSelected([]);
        setColaboradoresSelected([]);
        getTeam()
        getPerson(0, typeContractId);
      }
    } else {
      // alert('sem items')
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setTipoContratacaoSelected(obj)
      const idTeam = obj.map(item => item.value);
      setExcel({ ...excel, typeContractId: idTeam });
      setValuesAlt({ ...valuesAlt, typeContractId: idTeam, teamId: [], personId: [] });
      setOptions([]);
      setTeamSelected([]);
      setTeam([])
      setColaboradoresSelected([]);
      getTeam()
    }
  }

  async function handleEquipe(items) {
    if (items) {
      if (items.length === 0) {
        clearSearch();
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTeamSelected(obj)
        const idTeam = obj.map(item => item.value);
        setExcel({ ...excel, teamId: idTeam });
        setValuesAlt({ ...valuesAlt, teamId: idTeam, personId: [] });
        setOptions([]);
        setColaboradoresSelected([]);
        getPerson(idTeam, excel.typeContractId.length === 0 ? 0 : excel.typeContractId);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setTeamSelected(obj)
      const idTeam = obj.map(item => item.value);
      setExcel({ ...excel, teamId: idTeam });
      setValuesAlt({ ...valuesAlt, teamId: idTeam, personId: [] });
      setOptions([]);
      setColaboradoresSelected([]);
      getPerson(idTeam, 0);
    }
  }

  async function handleColaboradores(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setColaboradoresSelected(obj)
        const ids = obj.map(item => item.value);
        setExcel({ ...excel, personId: ids, });
        setValuesAlt({ ...valuesAlt, personId: ids, });

      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setColaboradoresSelected(obj)
        const ids = obj.map(item => item.value);
        setExcel({ ...excel, personId: ids });
        setValuesAlt({ ...valuesAlt, personId: ids });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setColaboradoresSelected(obj)
      const ids = obj.map(item => item.value);
      setExcel({ ...excel, personId: ids });
      setValuesAlt({ ...valuesAlt, personId: ids });
    }
  }

  async function handleDec(item) {
    setDecSelected(item);
    setExcel({ ...excel, dec: item.value });
  }

  function open(params) {
    var link = document.createElement('a');
    link.href = params;
    link.click();
  }

  async function sendForm() {
    const key = enqueueSnackbar('Pequisando Relatórios, Aguarde!', INFOMESSAGE);
    setTituloLoad('Pequisando Relatórios, Aguarde!');
    setLoadDialog(true);
    try {
      const { data } = await api.put("/register/relByUser", valuesAlt);
      if (data.success) {
        const { result } = data;
        setTable(result);
        setButtonDisable(false);
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setTable([]);
        setButtonDisable(true);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setTable([]);
      setButtonDisable(true);
    }

    closeSnackbar(key);
    setLoadDialog(false);
  }

  async function sendExcel() {
    setTituloLoad('Gerando a Planilha, Aguarde!');
    setButtonDisable(false);
    setLoadDialog(true);
    try {
      const temp = await api.put("/register/relByUser", excel);
      const { config } = temp
      const { data } = temp
      if (data.success) {
        const { path } = data;
        //setButtonDisable(true);
        var url = config.baseURL + path;
        open(url);
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setButtonDisable(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setButtonDisable(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setButtonDisable(false);
    }
    setLoadDialog(false);
  }

  const handleMesAno = (item) => {
    setSelectedMesAno(item);
    if (item === null) {
      handleDateChange(null);
      handleDateChangeFim(null);
    } else {
      handleDateChange(monthYear[item.value].dtInicial);
      handleDateChangeFim(monthYear[item.value].dtFinal);
    }
  }
  function clearSearch() {
    valuesAlt.personId = [];
    valuesAlt.typeContractId = [];
    valuesAlt.teamId = [];
    excel.personId = [];
    excel.typeContractId = [];
    excel.teamId = [];
    excel.dec = 2;
    excel.xls = true;
    //setValuesAlt({...valuesAlt, typeContractId: [], personId: [], teamId: []})
    //setExcel({ ...excel, typeContractId: [], personId: [], teamId: []})
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setDecSelected({ value: 2, label: '2' })
    //setExcel({ ...excel, dec: 2 });
    setTable([]);
    setButtonDisable(true);
    setTipoContratacaoSelected([]);
    setTeamSelected([]);
    setColaboradoresSelected([]);
    setOptions([]);

  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput style={{ alignItems: 'center' }} className="alertText">
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>{tituloLoad}</h3>
      </WrapInput>
    </div>
  );

  return (
    <Container>
      <SearchBox>
        <TitleStyle2>Relatório Consolidado</TitleStyle2>
        <form>
          <Wrap style={{ position: 'relative' }}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <WrapInput style={{ position: 'relative', width: '3%' }}>
                <KeyboardDatePicker
                  clearLabel="Limpar"

                  clearable
                  helperText={''}
                  minDateMessage="Não é possível buscar por datas futuras."
                  cancelLabel="Cancelar"
                  margin="normal"
                  id="dateInitial"
                  label="Data Início"
                  format="dd/MM/yyyy"
                  onChange={handleDateChange}
                  value={selectedDate}
                  minDate={new Date(new Date("01-01-2018"))}
                  maxDate={new Date(new Date())}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '3%' }}>
                <KeyboardDatePicker
                  helperText={''}
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  clearable
                  minDateMessage="Não é possível buscar por datas futuras."

                  margin="normal"
                  id="dateFinal"
                  label="Data Fim"
                  format="dd/MM/yyyy"
                  onChange={handleDateChangeFim}
                  value={selectedDateFim}
                  minDate={new Date(new Date("01-01-2018"))}
                  maxDate={new Date(new Date())}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </WrapInput>
            </MuiPickersUtilsProvider>
            <WrapInput style={{ width: '10%', paddingRight: '0px' }}>
              <LabelInput>Mês</LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Opções.."
                onChange={handleMesAno}
                value={selectedMesAno}
                isClearable={true}
                isSearchable={true}
                options={monthYear.map((item) => {
                  return { value: item.id, label: item.monthYear };
                })}
              />
            </WrapInput>


            <WrapInput style={{ width: '10%' }}>
              <LabelInput>Tipo de Contratação </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                isClearable={true}
                options={tipoContratacao}
                value={tipoContratacaoSelected}
                onChange={(item) => handleTipoContratacao(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ width: '12%' }}>
              <LabelInput>Equipes </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={team}
                value={teamSelected}
                onChange={(item) => handleEquipe(item)}
                labelledBy={"Select"}
              />
            </WrapInput>

          </Wrap>
          <Wrap style={{ position: 'relative', padding: '1px' }}>
            <WrapInput style={{ position: 'relative', width: '23%' }}>
              <LabelInput>Colaborador </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={person}
                value={colaboradoresSelected}
                onChange={(item) => handleColaboradores(item)}
                labelledBy={"Select"}
              />
            </WrapInput>

            <WrapInput style={{ position: 'relative', width: '15%', marginRight: '11px' }}>
              <LabelInput>Nº Decimais</LabelInput>
              <MySelect
                styles={multSelect}
                options={[
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                  { value: 5, label: '5' },
                  { value: 6, label: '6' }
                ]}
                value={decSelected}
                onChange={(item) => handleDec(item)}
                placeholder="Opções.."
                isClearable={true}
                isSearchable={true}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', marginTop: '10px' }}>
              <ItemsSearch><IconeSearch size={25} title="Pesquisar" onClick={() => sendForm()} />
                {!buttonDisable &&
                  <IconExcel size={25} title="Gerar Excel" onClick={() => sendExcel()} />
                }
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>
        </form>
      </SearchBox>
      <WrapList>
        <HeaderList>
          <span style={{ marginLeft: '20px' }}>TOTAL DE HORAS  </span>
        </HeaderList>
        {
          <BodyList>
            <TabelaConsolidado
              table={table}
              dataInicial={valuesAlt.dataInicial}
              dataFinal={valuesAlt.dataFinal}
              clientIds={clientSelected}
              aggrementIds={aggrementSelected}
              servicesId={serviceSelected}
            />
          </BodyList>
        }
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}
