import styled from 'styled-components'
import { FaExclamationTriangle } from 'react-icons/fa'
import { FaSyncAlt,FaPlay, FaStop } from 'react-icons/fa'
import Select from 'react-select';
import InputMask from 'react-input-mask';




export const InputHora = styled(InputMask)`
width: 39px;
`;

export const PlayIcon = styled(FaPlay)`
cursor: pointer;
`;

export const StopIcon = styled(FaStop)`
cursor: pointer;
`;

export const IconSync = styled(FaSyncAlt)`
margin-right: 10px;
transform: ${props => props.rotate};
transition: transform 0.1s;
`;

export const Header = styled.div`
width: 100%;
display:flex;
flex-direction: row;
justify-content: space-around;
align-items: flex-start;
padding-top: 20px;
@media (max-width: 800px){
    flex-direction: column;
    align-items: center;
}
`;

export const WrapMessages = styled.div`
width: 70%;
@media (max-width: 800px){
   width: 98%;
}

`;

export const Row = styled.div`
height: 100%;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
color: gray;
width: 100%;
background-color: ${props => props.bg}
`;


export const WrapAlmoco = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: column;
width: 95px;
padding-right: 10px;
height: 100%;
@media(max-width: 535px){
padding: 0;
height: 100%;
flex-direction: column;
align-items: flex-start;
justify-content: space-around;
}
`;
//width: 100px;


export const WrapTotalHoras = styled.div`
width: 25%;
@media (max-width: 800px){
   width: 98%;
   margin-top: 20px;
   margin-bottom: 20px;
}
`;

export const Messages = styled.div`
background-color: white;
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;

height: 100px;
`;
export const WrapInfos = styled.div`
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 98%;
background-color: ${props => props.theme.colors.surface};
display:flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-top: 20px;
height: 41px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
border-bottom: 7px ${props => props.theme.bgHeading} solid;

@media(max-width: 535px){
flex-direction: row;
height: 80px;
justify-content: space-between;
}
`;

export const WrapAlmocoMobile = styled.div`
display: flex;
align-items: center;
justify-content:space-between;
width: 65%;
@media(max-width: 535px){
padding: 0;
flex-direction: row;
align-items: center;
justify-content: space-around;
width: 100%;
height: 100%;
}
`;


export const Data = styled.div`
font-size: 13px;
margin-left: 20px;
display: flex;
justify-content: space-between;
align-items: center;    
width: 320px;
color: ${props => props.theme.textTable};
font-weight: 700;
@media(max-width: 1740px){
    width: 310px;
}
@media(max-width: 535px){
font-size: 12px;
margin: 0;
height: 100%;
flex-direction: column;
align-items: flex-start;
justify-content: space-around;
}

`;

export const Hour = styled.div`
color: ${props => props.theme.textTable};
font-weight: 700;
display: flex;
justify-content: scenter;
align-items: center;  
@media(max-width: 450px){
  
}
`;


export const WrapInput3 = styled.div`
width: 60%;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
@media( max-width: 565px){
    width: 100%;

}
`;


export const WrapInput = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
`;

export const AlmocoIcon= styled.div`
display:flex;
width: 33px;
height: 33px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
`;

export const Input2 = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;


export const Input = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;

export const Input3 = styled.select`
width: 100%;
height: 25px;
border-radius: 10px;
outline: 0;
background-color: ${props => props.theme.colors.background};
border: ${props => props.border};
`;

export const LabelInput3 = styled.div`
width: 100%;
color: gray;
margin-top: 5px;
margin-bottom: 5px;
`;



export const WrapButtons = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
`;
export const WrapHora = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
color: gray;
border-radius: 5px;
width: 100%;
height: 25px;
padding: 2px;
@media( max-width: 565px){
    margin-top: 10px; 
}
`;

export const WrapInput2 = styled.div`
margin-left: 10px;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 120px;
height: 100%;
`;

export const WrapChecks = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding-bottom: 15px;
height: 50px;
@media(max-height: 655px){
   
}
@media(max-width: 565px){
    flex-direction: column-reverse;
    align-items: flex-start;
}
`;

export const WrapCheckBox = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
height: 100px;
margin-left: 50px;


`;

export const LabelCheckBox = styled.div`
width: 100%;
color: gray;
`;
export const Button2 = styled.div`
font-size: 12px;
width: 100px;
height: 30px;
background-color: ${props => props.bg};
color: white;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
margin: 15px;
margin-top: 40px;
cursor: pointer;
@media(max-height: 655px){
    margin-top: 20px;
}
&:hover{
    opacity: 0.9;
}
`;

export const Button = styled.div`
font-size: 12px;
width: 100px;
height: 30px;
background-color: ${props => props.bg};
color: white;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
margin: 15px;
margin-top: 40px;
cursor: pointer;
&:hover{
    opacity: 0.9;
}
`;


export const InputText = styled.textarea`
border: ${props => props.border};
width: 86%;
height: 20px;
outline: 0;
border-radius: 20px;
padding: 25px;
max-width: 87%;
background-color: ${props => props.theme.colors.background};
@media(max-height: 577px){
    height: 5px;
    padding: 15px;
   
}
@media(max-width: 480px){
    width: 80%;
}
`;


export const Line2 = styled.div`
width: 100px;
height: 5px;
background-color: #FC2806;
margin-top: 8px;
`;




export const LabelInput2 = styled.div`
width: 95%;
color: gray;
margin-top: 5px;
margin-bottom: 5px;
`;


export const LabelInput = styled.div`
width: 90%;
color: gray;
margin-top: 5px;
margin-bottom: 5px;
`;

export const RegistroManual = styled.div`
padding-top: 10px;
width: 450px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
border-radius: 10px;
z-index: 600; 
position: fixed;
@media(max-height: 629px){
   top: 0;
}
@media(max-height: 541px){
}
@media(max-width: 500px){
    width: 90%;
}

`;

export const BodyRegistroManual = styled.div`
background-color: ${props => props.theme.colors.surface};
width: 100%;
height: 100%;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
@media(max-height: 655px){
}
`;


export const Backdrop = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 300;
opacity: ${props => props.op};
transition: opacity 0.5s;
background-color: rgba(0, 0, 0, 0.2);
`;
export const TrocarProjeto = styled.div`
padding-top: 10px;
width: 450px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border-radius: 10px;
z-index: 400; 
position: fixed;
@media(max-width: 500px){
    width: 90%;
}
`;

export const BodyTrocarProjeto = styled.div`
background-color: ${props => props.theme.colors.surface};
width: 100%;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);

`;



export const ListMessages = styled.div`
width: 80%;
color: gray;
`;

export const IconeMessages = styled.div`
display: flex;
width: 15%;
justify-content:center;
align-items: center;
height: 70px;
`;



export const ListBody = styled.div`
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
width: 100%;
display:flex;
flex-direction: column;
justify-content:flex-start;
align-items: center;
height: 100%;
background-color: ${props => props.theme.colors.surface};
overflow: auto;
max-height: 310px;
`;

export const Title = styled.span`
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: #F5F5F5;
font-weight: bold;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;


export const Title2 = styled.span`
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: #F5F5F5;
font-weight: bold;
display: flex;
justify-content: center;
align-items: center;
background-color: #003E5E;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;


export const ListDesktop = styled.div`
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 98%;
background-color: ${props => props.theme.colors.surface};
border-bottom-right-radius: 100px;
border-bottom-left-radius: 100px;
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
margin-top: 20px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 300px;
@media (max-height: 760px){
   height: 35%;
}
@media (max-height: 680px){
   height: 30%;
}
@media (max-height: 632px){
   height: 25%;
}
@media (max-width: 800px){
   display: none;
}
`;


export const ListMobile = styled.div`
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 98%;
background-color: ${props => props.theme.colors.surface};
border-bottom-right-radius: 100px;
border-bottom-left-radius: 100px;
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
margin-top: 20px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 300px;
@media (max-height: 760px){
   height: 35%;
}
@media (max-height: 680px){
   height: 30%;
}
@media (max-height: 632px){
   height: 25%;
}
@media (min-width: 800px){
   display: none;
}
`;


export const Icone = styled(FaExclamationTriangle)`

`;
export const Infos2 = styled.div`
width: 100%;
padding-right: 20px;
height: 100px;
display: flex;
flex-direction: column;
justify-content: space-around;
font-weight: 500;
`;

export const Infos = styled.div`
width: 30%;
padding-right: 20px;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
color: gray;
font-weight: 500;
@media(max-width: 1350px){
    width: 50%;
}
@media(max-width: 800px){
    display: none;
}
`;

export const WrapInfos2 = styled.div`
display: none;
width: 98%;
background-color: ${props => props.theme.bgHeading};
border-radius: 10px;
margin-top: 20px;
@media(max-width: 800px){
display: flex;
justify-content: center;
align-items: center;
}
`;

export const EnterIcon = styled.div`
width: 20px;
margin-right: 10px;
height: 17px;
background-image: url(${props => props.bg});
background-position: center;
background-size: cover;
background-repeat: no-repeat;`

    ;
export const ExitIcon = styled.div`
width: 20px;
margin-right: 10px;
height: 17px;
background-image: url(${props => props.bg});
background-position: center;
background-size: cover;
background-repeat: no-repeat;`
    ;

export const ButtonOptions = styled.div`
width: 160px;
font-size: 13px;
height: 47px;
background-color: ${props => props.bg};
color: ${props => props.color};
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
font-weight: bold;
box-shadow:  0px 0px 5px rgba(0, 0, 0, 0.2);
cursor: pointer;
margin-right: 10px;
margin-left: 10px;
opacity: ${props => props.disabled ? 0.3 : 1};
@media(max-width: 800px){
width: 80%;
height: 65px;
}
&:hover{
    background-color:${props => props.disabled ? '' : props.hover};
    transition: background-color 0.3s;
}
&:active {
 opacity:${props => props.disabled ? '' : 0.7};
 transition: opacity 0.3s;
}
`;

export const Options = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
@media(max-width: 1350px){
    align-items: flex-start;
    padding-bottom: 20px;
}
@media(max-width: 800px){
flex-direction: column;
justify-content: space-around;
align-items: center;
height: 100%;
padding: 0;
}

`;

export const WrapOptions = styled.div`
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 98%;
background-color: ${props => props.theme.colors.surface};
display:flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-top: 20px;
height: 100px;
border-radius: 10px;
@media(max-width: 800px){
flex-direction: column;
justify-content: center;
align-items: center;
height: 250px;
}
`;



export const ItemInfo = styled.div`
width: 100%;
text-align: end;
color: ${props => props.color};
`;

export const ItemInfo2 = styled.div`
width: 100%;
text-align: end;
color: ${props => props.color};

`;



export const Line = styled.div`
border: 1px gray solid;
height: 60px;
margin-top: 20px;
margin-bottom: 20px;
`;

export const Previstas = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
`;

export const Horas = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
`;


export const TotalHoras = styled.div`
background-color: white;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
display: flex;
justify-content: space-between;
align-items: center;
height: 100%;
`;

export const Hora = styled.span`

color: ${props => props.theme.colors.text};
font-weight: bold;
`;
export const Prevista = styled.span`
color: gray;
font-weight: bold;
`;
export const TitleHora = styled.span`
color: gray;
font-style: italic;
`;
export const TitlePrevista = styled.span`
color: gray;
font-style: italic;
`;