import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from 'components/SharedComponents/table';
import { ThemeContext } from 'styled-components'
import { shade } from 'polished'
import { useShowModal } from 'contexts/SharedContext'
import { FiEdit } from "react-icons/fi";
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { WrapInput, Wrap } from '../../components/Wrap'
import { modalStyle, ModalBox } from '../../components/Modal'
import { ConfirmarBtn, CancelarBtn } from "../../components/Button";
import { TitleStyle2, Items } from "../../pages/CadastroPessoas/styles";
import Modal from "@material-ui/core/Modal";
import Historico from '../Historico.js';
import { BiHistory } from "react-icons/bi";
import { Input2 } from './styles.js'
import { Button, } from "../../components/Button";
import MessageAlert from 'components/MessageAlert';
import { AiTwotoneDelete, AiFillProfile } from "react-icons/ai";
import { tint } from "polished";
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal'
let equipe = '';
let separa = '';


const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  head: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0
  },
});


const customControlStyles = base => ({
  ...base,
  height: 35,
  minHeight: 35
});


export default function TableCadastroIntervalo({ intervalo, getIntervalo }) {

  const { setShowModalInterval } = useShowModal();
  const { colors, textTableColor } = useContext(ThemeContext)
  const [nameEdit, setNameEdit] = useState('');
  const [familyNameEdit, setFamilyNameEdit] = useState('');
  const [idToEdit, setIdToEdit] = useState();
  const [servico, setServicos] = useState([]);
  const [openHist, setOpenHist] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [name, setName] = useState('');

  const useStyles = makeStyles({

    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    }
  });

  const [taskDialog, setTaskDialog] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();



  ///modal 
  const [setOpen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  function openModal(item, action) {
    if (action === 'editar') {
      setShowModalInterval({ show: true, title: 'EDITAR INTERVALO', action: 'editar', item: item });
    } else {
      if (action === 'inserir') {
        setShowModalInterval({ show: true, title: 'EDITAR INTERVALO', action: 'inserir', item: item });
      } else {
        setNameEdit(item.name);
        setIdToEdit(item.id);
        setDeleteDialog(true);
      }
    }
  }

  async function getHistorico(companyId, teamId, tableChangeId) {
    try {
      const { data } = await api.get("historico" + '?companyId=' + companyId + '&keyTable=' + teamId + '&tableChangeId=' + tableChangeId);;
      if (data.success) {
        const { result } = data;
        setHistorico(result)
        setOpenHist(true)
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  function openModalHist(item) {
    setHistorico([]);
    setName(item.name);
    getHistorico(item.companyId, item.id, 6);
  }

  const handleCloseHist = () => {
    setOpenHist(false);
    setHistorico([]);
  };

  const handleClose = () => {
    setDeleteDialog(false);
  };


  async function deleteInterval(id) {
    try {
      const { data } = await api.delete('/interval/' + id)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        getIntervalo();
        setDeleteDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  const modalDelete = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">Remover Intervalo</TitleStyle2>
      <WrapInput className="alertText">
        <h3>Você tem certeza que deseja remover {nameEdit ? nameEdit : ''}? Essa operação é permanente! </h3>
      </WrapInput>
      <WrapInput className="alertBtn">
        <Items>
          <ConfirmarBtn type="button" onClick={() => deleteInterval(idToEdit)}> Confirmar </ConfirmarBtn>
          <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
        </Items>
      </WrapInput>
    </div>
  );


  return (
    <TableContainer>
      <Modal open={deleteDialog}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalDelete}
      </Modal>
      <Modal open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Historico historico={historico} name={name} handleCloseHist={handleCloseHist} />
      </Modal>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">INICIO INTERVALO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">INICIO ALERTA</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">FIM INTERVALO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DURAÇÃO MINUTOS</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">PADRÃO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {
            intervalo && intervalo.map((row, index) => {

              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.name}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.initialLunch}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.startLunch}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.limitLunch}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.durationLunch}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.default === false ? 'Não' : 'Sim'}</TableCell>
                  <TableCell
                    style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                    align="center">
                    <FiEdit
                      onClick={() => openModal(row, 'editar')}
                      style={{ cursor: "pointer" }}
                      color={colors.primary}
                      size="20"
                      title="Editar"
                    />
                    {row.logChange > 0 ?
                      <BiHistory
                        onClick={() => openModalHist(row)}
                        style={{ cursor: "pointer" }}
                        color={colors.primary}
                        size="20"
                        title="Atualizações"
                      /> : <BiHistory
                        style={{ cursor: "pointer" }}
                        color={"#808080"}
                        size="20"
                        title="Atualizaçõeso"
                      />
                    }
                    {row.delete === 0 && <AiTwotoneDelete
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      color={tint(0.1, colors.error)}
                      size="20"
                      title="Remover"
                      onClick={() => openModal(row, 'remover')}
                    />}
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}