import styled from 'styled-components'
import Select from 'react-select'

export const Container = styled.div`
width: 100%;
height: 100vh;
margin: 0;
padding: 0%;
background-color: ${props => props.theme.colors.background};
display: flex;
justify-content: flex-start;
align-items: center;
background-image: url(${props => props.bg});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
flex-direction: column;
`;



export const MySelect = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;


export const Form = styled.div`
width: 85%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
@media(max-width: 900px){
    flex-direction: column;
    width: 100%;
}
`;

export const Button = styled.div`
font-weight: 500;
display: flex;
justify-content: center;
align-items: center;
width: 160px;
height: 45px;
background-color: ${props => props.btnColor};
border-radius: 10px;
margin-left: 20px;
color: white;
cursor: pointer;
&:hover{
    opacity: 0.7;
}
`;

export const Column = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
height: 200px;
@media(max-width: 900px){
   
}
`


export const WrapButtons = styled.div`
color: gray;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
width: 500px;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
@media(max-width: 800px){
    width: 100%;
}

`;

export const Logo = styled.div`
display:flex;
width: 240px;
height: 45px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;

`;

export const TextLogo = styled.div`
color: #ddd;
width: 100%;
display: flex;
justify-content: flex-end;
align-items: flex-start;
padding: 0;
margin: 0;
font-weight: 600;

`;

export const WrapShowSuccess = styled.div`
width: 100%;
background-color: rgba(0, 0, 0, 0.2);
height: 400px;
z-index: 300;
position: absolute;
`;

export const WrapLogo = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 20%;
padding-left: 15px;
flex-direction: column;
margin: 0;
cursor: pointer;
`;


export const Input = styled.input`
width: 100%;
height: 30px;
background-color: ${props => props.theme.colors.surface};
border: 1px ${props => props.theme.borderInputColor} solid;
border-radius: 10px;
box-shadow: none;
outline: 0;
padding-left: 15px;
color: ${props => props.theme.colors.textBg}
`;


export const WrapInput = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 500px;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 80%;
}
@media(max-width: 330px){
    width: 90%;
}
`;


export const LabelInput = styled.div`
text-align: center;
width: 100%;
color: gray;
padding-bottom: 10px;
padding-left: 10px;
@media(max-width: 330px){
   
}
`;



export const Body = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;


export const BodyHeader = styled.div`
font-weight: 500;
font-size: 15px;
color:  ${props => props.theme.colorTextAlterarSenha};
width: 50%;
height: 150px;
display: flex;
justify-content: flex-start;
align-items: flex-end;
border-bottom: 1px ${props => props.theme.bgHeading} solid;
padding-bottom: 10px;
@media(max-width: 800px){
    width: 90%;
}
`;

export const Header = styled.div`
width: 100%;
height: 10vh;
margin: 0;
padding: 0;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${props => props.theme.bgHeading};
`;

export const ButtonLogout = styled.div`
width: 40px;
cursor:pointer;
margin-right: 40px;
&:hover{
opacity: 0.8;
}
@media(max-width: 400px){
    margin-right: 20px;
}
`;