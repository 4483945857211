import React from "react";
import CopyrightIcon from '@material-ui/icons/Copyright';
import { FooterTag} from './styles.js'

export default function Footer() {

  return (
    <FooterTag>
        
    </FooterTag>
  )
}
