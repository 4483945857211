import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile, GridListTileBar, IconButton, CircularProgress } from "@material-ui/core";
import { TitleStyle2, Items, WrapButtons } from "../../pages/CadastroPessoas/styles";
import { ContainerMobile, Title, BodyUpload } from "../../pages/RegistroHoras/styles";
import { StyledBox, ModalBoxUpload, modalClose } from '../../components/Modal'
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ConfirmarBtn, Button } from "components/Button";
import { useSnackbar } from 'notistack';
import { GetSessionUser } from 'services/helpers';
import { api } from 'services/axios';
import MessageAlert from 'components/MessageAlert'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { Wrap, WrapInput } from "../../components/Wrap";
import Modal from "@material-ui/core/Modal";
import { SearchBox } from "../../components/Search";
import { LabelInput, multSelect } from 'components/Input';
import { MySelect } from "../../components/InputSelect";
import { FiUpload, FiMail } from 'react-icons/fi';
import { FcOk } from "react-icons/fc";

export default function Upload({ personId, dia, regDate, handleCloseUpload, device, btEnviar }) {
  const { enqueueSnackbar } = useSnackbar()
  const [tituloUpload, setTituloUpload] = useState('');
  const [regDateUpload, setRegDateUpload] = useState('');
  const { colors, textTableColor } = useContext(ThemeContext)
  const [tileData, setTileData] = useState();
  const [tipoArquivo, setTipoArquivo] = useState();
  const [loadDialog, setLoadDialog] = useState(false);
  const [mostraEnvia, setMostraEnvia] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: '200px',
      marginTop: '30px',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
    },
    title: {
      color: '#FFFFFF',
    },
    titleBar: {
      background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    head: {
      backgroundColor: "#fff",
      position: "sticky",
      top: 0
    },
    headCol: {
      backgroundColor: colors.background,
      position: "sticky",
      top: 0,
      color: textTableColor,
      fontWeight: 500,
      border: 'none',
      textTransform: 'uppercase',
      textAlign: 'center'
    }
  }));

  const classes = useStyles();

  async function handleUploadClick(event) {
    setLoadDialog(true);
    var file = event.target.files[0];
    if (file && (file.type.indexOf('image') !== -1 || file.type === 'application/pdf')) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          resolve(reader.result);
        }.bind(this);
        reader.onerror = error => reject(error);
      });

      const arquivo = {
        imageUploaded: 1,
        name: file.name,
        type: file.type,
        selectedFile: await toBase64(file)
      }
      await sendArquivo(arquivo);
    } else {
      enqueueSnackbar(<MessageAlert message='Só imagens e pdf são permitidos.' />, WARNINGMESSAGE)
    }
    setLoadDialog(false);
  }

  const getArquivo = async (regDateU) => {
    try {
      setLoadDialog(true);
      setMostraEnvia(false);
      const { data } = await api.get('/person/getImagens/' + personId + '/' + regDateU)
      if (data.success) {
        const { result } = data
        if (result.length === 0) {
          setTileData()
        } else {
          setTileData(result)
          if (btEnviar) {
            await result.map(reg => {
              if (!reg.fileSent) setMostraEnvia(true);
            })
          }
        }
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function sendArquivo(arquivo) {
    try {
      setLoadDialog(true);
      const obj = {
        "anoMesDia": regDateUpload,
        "personId": personId,
        "name": arquivo.name,
        "type": arquivo.type,
        "typeFile": tipoArquivo.value,
        "newfile": arquivo.selectedFile
      }
      const { data } = await api.post('/person/upload/', obj)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        getArquivo(regDateUpload)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const delArquivo = async (id) => {
    try {
      setLoadDialog(true);
      const { data } = await api.delete('/person/imagem/' + id)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        getArquivo(regDateUpload);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function enviarArq() {
    try {
      setLoadDialog(true);
      const obj = {
        "anoMesDia": regDateUpload,
        "personId": personId
      }
      const { data } = await api.post('/person/sendImagem/', obj)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        getArquivo(regDateUpload)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  useEffect(() => {
    setTipoArquivo({ value: 1, label: 'Atestado' });
    setRegDateUpload(regDate);
    setTituloUpload('UpLoad do dia: ' + dia);
    getArquivo(regDate);
  }, [])

  const handleTipo = (item) => {
    setTipoArquivo(item);
  };

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Aguarde...</h3>
      </WrapInput>
    </div>
  );

  return (
    <>
      {!device &&
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2 className="TitleStyle2">
            {tituloUpload}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleCloseUpload(false, '01/01/1900', '1900-01-01', true)}
            />
          </TitleStyle2>
          <ModalBoxUpload>
            <SearchBox style={{ width: '100%' }}>
              <Wrap>
                <WrapInput style={{ width: '5%', paddingRight: '0px' }}>
                  <LabelInput>Tipo do Arquivo *</LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder="Opções"
                    onChange={handleTipo}
                    options={[{ value: 1, label: 'Atestado' }, { value: 2, label: 'Cota' }, { value: 3, label: 'Reembolso' }]}
                    value={tipoArquivo}
                  />
                </WrapInput>
                <WrapInput>
                  <Items>
                    <input style={{ display: 'none', cursor: "pointer" }}
                      accept="image/*,application/pdf"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => handleUploadClick(e)}
                    />
                    <label htmlFor="contained-button-file">
                      <ConfirmarBtn type="button"
                        style={{ cursor: "pointer", top: '10px', backgroundColor: '#3FC248' }}
                        size="18"
                        title="Adicionar (Imagem e PDF)"
                      ><FiUpload size="18" />&nbsp;Upload</ConfirmarBtn>
                    </label>
                    {
                      mostraEnvia ?
                        <Button type="button"
                          color={colors.primary}
                          title="Enviar Arquivos"
                          style={{ cursor: "pointer", top: '10px' }}
                          size="18"
                          onClick={() => enviarArq()}
                        ><FiMail size="18" />&nbsp;Enviar </Button>
                        : ''
                    }
                  </Items>
                </WrapInput>
              </Wrap>
            </SearchBox>
            <div className={classes.root}>
              <GridList className={classes.gridList} cols={2.5}>
                {tileData && tileData.map((row) => (
                  <GridListTile key={row.name} style={{ width: '300px' }}>
                    {
                      row.type === 'application/pdf' && <embed type={row.type} src={row.url} alt={row.name} />
                    }
                    {
                      row.type !== 'application/pdf' && <img type={row.type} src={row.url} alt={row.name} />
                    }
                    <GridListTileBar
                      title={row.typeFile === 1 ? 'Atestado' : row.typeFile === 2 ? 'Cota' : 'Reembolso'}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title
                      }}
                      actionIcon={
                        <IconButton aria-label={`star ${row.title}`}>
                          {row.fileSent &&
                            <FcOk title="Enviado" size="18" />
                          }
                          {!row.fileSent &&
                            <DeleteOutlineOutlinedIcon onClick={() => delArquivo(row.id)} className={classes.title} />
                          }
                        </IconButton>
                      }
                    />
                  </GridListTile>
                ))}
                {!tileData && <GridListTile style={{ width: '300px' }}><p style={{ textAlign: 'center' }}>Nenhum arquivo encontrado.</p></GridListTile>}
              </GridList>
            </div>
          </ModalBoxUpload>
        </StyledBox>
      }
      {
        device &&
        <ContainerMobile>
          <Title> &nbsp;&nbsp;&nbsp;&nbsp; {tituloUpload}</Title>
          <SearchBox style={{ width: '100%', marginTop: '0px', marginBottom: '5px' }}>
            <Wrap className="wrapInputModal">
              <WrapInput style={{ width: '90%', paddingRight: '0px' }}>
                <LabelInput>Tipo do Arquivo *</LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder="Opções"
                  onChange={handleTipo}
                  options={[{ value: 1, label: 'Atestado' }, { value: 2, label: 'Cota' }, { value: 3, label: 'Reembolso' }]}
                  value={tipoArquivo}
                />
              </WrapInput>
              <WrapInput>
                <Items>
                  <input style={{ display: 'none', cursor: "pointer" }}
                    accept="image/*,application/pdf"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleUploadClick(e)}
                  />
                  <label htmlFor="contained-button-file">
                    <ConfirmarBtn type="button"
                      style={{ cursor: "pointer", top: '10px', backgroundColor: '#3FC248' }}
                      size="18"
                      title="Adicionar (Imagem e PDF)"
                    ><FiUpload size="18" />&nbsp;Upload</ConfirmarBtn>
                  </label>
                  {
                    mostraEnvia ?
                      <Button type="button"
                        color={colors.primary}
                        title="Enviar Arquivos"
                        style={{ cursor: "pointer", top: '10px' }}
                        size="18"
                        onClick={() => enviarArq()}
                      ><FiMail size="18" />&nbsp;Enviar </Button>
                      : ''
                  }
                </Items>
              </WrapInput>
            </Wrap>
          </SearchBox>
          <BodyUpload>
            <div className={classes.root}>
              <GridList className={classes.gridList} cols={2.5}>
                {tileData && tileData.map((row) => (
                  <GridListTile key={row.name} style={{ width: '300px' }}>
                    {
                      row.type === 'application/pdf' && <embed type={row.type} src={row.url} alt={row.name} />
                    }
                    {
                      row.type !== 'application/pdf' && <img type={row.type} src={row.url} alt={row.name} />
                    }
                    <GridListTileBar
                      title={row.typeFile === 1 ? 'Atestado' : row.typeFile === 2 ? 'Cota' : 'Reembolso'}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title
                      }}
                      actionIcon={
                        <IconButton aria-label={`star ${row.title}`}>
                          {row.fileSent &&
                            <FcOk title="Enviado" size="18" />
                          }
                          {!row.fileSent &&
                            <DeleteOutlineOutlinedIcon onClick={() => delArquivo(row.id)} className={classes.title} />
                          }
                        </IconButton>
                      }
                    />
                  </GridListTile>
                ))}
                {!tileData && <GridListTile style={{ width: '300px' }}><p style={{ textAlign: 'center' }}>Nenhum arquivo encontrado.</p></GridListTile>}
              </GridList>
            </div>
            <WrapInput className="wrapInputModal" style={{ alignItems: 'center' }}>
              <Items style={{ width: '300px' }}>
                <WrapButtons>
                  <Button onClick={() => handleCloseUpload(false)} style={{ backgroundColor: "#D12020", cursor: "pointer" }} >Cancelar</Button>
                  <Button onClick={() => handleCloseUpload(false)} style={{ cursor: "pointer" }} >Salvar</Button>
                </WrapButtons>
              </Items>
            </WrapInput>
          </BodyUpload>
        </ContainerMobile>
      }
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </>
  );

}