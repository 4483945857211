import styled from 'styled-components'
import { FaSearch, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Select from 'react-select'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
display:flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const Card = styled.div`
width: 200px;
background-color: ${props => props.theme.colors.surface} ;
height: 200px;
`;


export const MySelect = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;

export const MultiSelect = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;

export const SelectAll = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
@media (max-width: 800px){
    width: 60%;
}
`;

export const SearchBox = styled.div `
background-color: ${props => props.theme.colors.surface};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
`;

// export const SearchBox = styled.div `
// background-color: ${props => props.theme.colors.surface};
// margin:15px;
// padding:15px;
// border-radius:7px;
// box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
// width:91%;
// `;

// export const TitleStyle2 = styled.h2 `
//     margin: 0;
//     border-bottom: 1px solid ${props => props.theme.bgBorderButtonMenu};
//     color: ${props => props.theme.bgBorderButtonMenu};
//     font-weight: 100
// `;


export const TitleStyle2 = styled.h2 `
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;

`;

export const overrideStrings ={    
        "selectSomeItems": "Opções...",
        "allItemsAreSelected": "Todos itens Selecionados",
        "selectAll": "Selecionar Todos",
        "search": "Pesquisar"
    }

    export const overrideStringsC ={    
        "selectSomeItems": "Opções...",
        "allItemsAreSelected": "Todas Colunas Selecionadas",
        "selectAll": "Selecionar Todas",
        "search": "Pesquisar"
    }
export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 16,
        margin: 0,
        //backgroundColor: state.isSelected ? "#eee" : "",
        textAlign: "left",
        cursor: "pointer"
    }),
    container: base => ({
        ...base,
        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'white',
        borderColor: 'gray',
        height: 35,
        minHeight: 35,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};

export const disable= {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%", 
    height: "30px",
    //backgroundColor:"#107CB8",
    backgroundColor:"gray",
    borderRadius: "20px",
    marginLeft: "20px",
    color: "white",
    position:"relative",
    top:"-3px",
    cursor: "pointer",
    //hoveropacity: "0.7"
        
}

export const enable= {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%", 
    height: "30px",
    backgroundColor:"#107CB8",
    //backgroundColor:"gray",
    borderRadius: "20px",
    marginLeft: "20px",
    color: "white",
    position:"relative",
    top:"-3px",
    cursor: "pointer",
    //hoveropacity: "0.7"
        
}

export const inputStyle = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '94%',
    
};

export const inputStyle2 = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '100%',
};


export const Wrap = styled.div `

`;


export const WrapButton = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 5%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapButtonModal = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 5px;
margin-right: 1px;
margin-top: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapInput = styled.div `

`;

export const BoxShadow = styled.input`
width: 100%;
height: 25px;
background-color: ${props => props.theme.colors.surface};
border: 1px ${props => props.theme.borderInputColor} solid;
border-radius: 120px;
outline: 0;
padding-left: 15px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
`; 

export const MyInput = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 40%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const InputG = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 35%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 1px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;


export const WrapDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 45%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 1px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`

// `
// display: flex;
// justify-content: space-around;
// align-items: flex-start;
// flex-direction: row;
// flex-wrap: wrap;
// width: 100%;
// padding-top: 20px;
// `
;

export const LabelInputG = styled.div`
width: 100%;
color: gray;
padding-bottom: 10px;
padding-left: 35px;
`;

export const Items = styled.div `
display: flex;
justify-content:right;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: right;
}
`;

export const IconeSearch = styled(FaSearch)
`
display: flex;
justify-content: flex-start;
align-items: flex-start;
position:relative;
top:-10px;
&:hover{
    opacity: 0.7;
}
`;

// Tabela
export const WrapList = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const HeaderList = styled.div `
color: ${props => props.theme.textHeading};
background-color: ${props => props.theme.bgHeading};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
/* color: ${props => props.theme.textHeading};
display: flex;
justify-content: flex-start;
align-items: center;

height: 40px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;

width: 94%; */
`;


export const BodyList = styled.div `
height: 45vh;
overflow-y:auto;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
padding-top: 10px;
background-color: ${props => props.theme.bgColor};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
`;

export const Row = styled.div `
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
/* background-color: ${props => props.bgColor}; */
padding-top: 10px;
padding-bottom: 10px;
`;

export const THEAD = styled.div `
color: gray;
position: fixed;
display: fixed;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
/* background-color: ${props => props.bgColor}; */
padding-top: 10px;
padding-bottom: 10px;
`;



export const Column = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 20%;
padding:0 25px;
color: ${props => props.theme.textTable};
text-align:center !important
`;


export const WrapButtons = styled.div `
//position:absolute;
bottom:0;
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
// background-color: ${props => props.bgColor};
padding-top: 20px;
padding-bottom: 20px;
`;

export const NextPage = styled(FaAngleRight)
`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;

export const PrevPage = styled(FaAngleLeft)
`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;

//Modal

export const ModalBox = styled.div `
/* background-color: ${props => props.theme.colors.surface};
display:flex */
display:flex;
justify-content: space-around;
@media (max-width: 800px){
    width: 100%;
}
`
export const modalStyle = {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: '70vw',
    left: '50%',
    top: '50%',
    borderRadius: '15px',
    padding: '15px',
    transform: `translate(-50%, -50%)`

}


export const cancelar = {
    backgroundColor: 'red'
};


