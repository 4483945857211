import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer
} from 'components/SharedComponents/table';
import { Backdrop, ModalAcoes, ItemModal, Button, MyInput, ModalAcoes2 } from './styles'
import { ThemeContext } from 'styled-components'
import { shade } from 'polished'
import { useUserResume, useShowModal } from 'contexts/SharedContext'
import { AiTwotoneDelete } from "react-icons/ai";
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { GetSessionUser, deleteCookie } from 'services/helpers'
import ObservacaoText from 'components/ObservacaoText'
import { FiEdit } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { modalStyle2 } from '../../components/Modal';
import Modal from "@material-ui/core/Modal";
import { WrapInput,WrapButtons } from "../../components/Wrap";
import { Items } from "../../components/Search";
import { TitleStyle2 } from "../../components/PageCadastro";
import { ConfirmarBtn, CancelarBtn } from "../../components/Button"
import MessageAlert from 'components/MessageAlert';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0,

    },
    head3: {
        width: '95%',
        backgroundColor: "#fff",
        position: "sticky",
        top: 0,
    },
    head2: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0,
        width: '5%'
    },
    contentCell: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,


        '&:hover': {
            //backgroundColor: shade(0.15, "#fff"),
            opacity: '0.9'
        },
    },
});


export default function TableRegistros() {
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const { userResume, setUserResume } = useUserResume()
    const { colors, textTableColor } = useContext(ThemeContext)
    const [rows, setRows] = useState([])
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar()
    const { setShowModalEditarRegistro } = useShowModal()
    const [showModal, setShowModal] = useState(false)
    const [open, setOpen] = useState('translateY(100%)')
    const [open2, setOpen2] = useState('translateX(100%)')
    const [itemId, setItemId] = useState(0)
    const [myText, setMyText] = useState('')
    const [sendText, setSendText] = useState(false)
    const [lastItem, setLastItem] = useState('')
    const [order, setOrder] = useState(
        {
            entrada: false,
            saida: false,
            jobName: false,
            serviceName: false,
            clientName: false,
            placeName: true,
            duracao: true,
            turno: false
        }
    )

    useEffect(() => {
        if (showModal) {
            setOpen('translateY(0)')
        }
        else {
            setOpen('translateY(100%)')
            setOpen2('translateX(100%)')
        }
    }, [showModal])

    useEffect(() => {
        if (userResume.itens && userResume.itens !== null && userResume.itens !== undefined && userResume.itens.length >= 0) {
            if (window.innerWidth > 800) {
                if (userResume.itens.slice(-1)[0])
                    setLastItem(userResume.itens.slice(-1)[0].id)
                setRows(userResume.itens)
            } else {
                const lastItem = userResume.itens.slice(-1)
                setRows(lastItem)
            }
        }
    }, [userResume.itens, userResume])

    const getInit = async (message) => {
        setShowModal(false)
        try {
            const { perfil } = GetSessionUser()
            const { data } = await api.get('/register/resumo/' + perfil.personId)
            if (data.success) {
                const { result } = data
                setUserResume(result)
            } else {
                enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
        }
        setSendText(false)
    }

    function validaBotoesExcluirEditar(id) {
        let user = userResume;
        let item = user.itens.filter(x => x.id == id && x.status == 5 && x.entrada == "15:00")
        if (item.length) {
            return true
        }
        return false
    }

    async function confirmDelete(id) {
        deleteCookie("exibiuModalAlmoco")
        deleteCookie("pararExibicaoModalAlmoco")
        setItemId(id)
        setDeleteDialog(true)
    }
    async function handleDelete(id) {
        try {
            if (id) {
                const { data } = await api.delete('/register/' + id)
                if (data.success) {
                    enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
                    getInit('Atualizando registros...')

                } else {
                    enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
                }
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
        }
        setDeleteDialog(false)
        setShowModal(false)
    }

    const editRegister = (value, id, observacao) => {

        deleteCookie("exibiuModalAlmoco")
        deleteCookie("pararExibicaoModalAlmoco")
        //setShowModal(value)
        localStorage.setItem('obs', observacao)
        const obj = { show: value, id }
        setShowModalEditarRegistro(obj)
    }

    const openModal = (id, observacao) => {
        setMyText(observacao)
        setItemId(id)
        setShowModal(true)
    }

    const handleObservacao = () => {
        setOpen('translateX(-100%)')
        setOpen2('translateX(0)')
    }


    const updateObservacao = async () => {
        if (!sendText) {
            setSendText(true)
            await setTimeout(async () => {
                try {
                    const { data } = await api.put('/register/nota/' + itemId, { noteRegister: myText })
                    if (data.success) {
                        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
                    } else {
                        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
                    }
                    getInit()
                } catch (err) {
                    console.log(err)
                    enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
                    setSendText(false)
                }
            }, 900)
        }
    }

    function Order(item) {
        /*
        if (!order[item]) {
            const newArray = rows.sort((a, b) => {
                if (a[item] > b[item]) {
                    return -1;
                }
                if (a[item] < b[item]) {
                    return 1;
                }
                return 0;
            })
            setRows(newArray)
        }
        if (order[item]) {
            const newArray = rows.sort((a, b) => {
                if (a[item] > b[item]) {
                    return 1;
                }
                if (a[item] < b[item]) {
                    return -1;
                }
                return 0;
            })
            setRows(newArray)
        }
        setOrder({
            ...order,
            //  [item]: !order[item],
            entrada: item === 'entrada' && !order[item],
            saida: item === 'saida' && !order[item],
            jobName: item === 'jobName' && !order[item],
            serviceName: item === 'serviceName' && !order[item],
            clientName: item === 'clientName' && !order[item],
            placeName: item === 'placeName' && !order[item],
            duracao: item === 'duracao' && !order[item],
            turno: item === 'turno' && !order[item]
            /*
              //  [item]: !order[item],
    entrada: item === 'entrada' ? !order[item] : !order['entrada'],
    saida: item === 'saida' ? !order[item] : !order['saida'],
    jobName: item === 'jobName' ? !order[item] : !order['jobName'],
    serviceName: item === 'serviceName' ? !order[item] : !order['serviceName'],
    clientName: item === 'clientName' ? !order[item] : !order['clientName'],
    placeName: item === 'placeName' ? !order[item] : !order['placeName'],
    duracao: item === 'duracao' ? !order[item] : !order['duracao'],
    turno: item === 'turno' ? !order[item] : !order['turno'

        }) */
    }



    const handleClose = () => {
        setDeleteDialog(false);
    };
    const modalDelete = (
        <div className={'disabeFocus alert-modal2'} style={modalStyle2}>
            <TitleStyle2>Remover Registro</TitleStyle2>
            <WrapInput className="alertText">
                <h3>Você tem certeza que deseja remover esse registro? Essa operação é permanente! </h3>
            </WrapInput>

            <WrapInput className="alertBtn">
                <Items style={{ justifyContent: 'center', width:'100%' }}>
                    <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                    <ConfirmarBtn type="button" onClick={() => handleDelete(itemId)}> Confirmar </ConfirmarBtn>
                </Items>
            </WrapInput>
        </div>
    );
    const styles = {
        cell: {
            color: textTableColor,
            fontWeight: 'bold',
            border: 'none',
            fontSize: '11px'
        },
    }
    return (
        <TableContainer>
            <Modal open={deleteDialog}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{width:'80% !important'}}
            >
                {modalDelete}
            </Modal>
            <Table style={{ backgroundColor: colors.surface }} className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell onClick={() => Order('turno')} className={classes.head2} style={styles.cell} align="center">
                            <div className={classes.contentCell}>TURNO&nbsp;</div>
                        </TableCell>
                        <TableCell onClick={() => Order('entrada')} className={classes.head2} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                ENTRADA&nbsp;
                            {
                                    // order['entrada'] ?
                                    //     <IoMdArrowDropdown size={20} /> :
                                    //    <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        <TableCell onClick={() => Order('saida')} className={classes.head2} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                SAÍDA&nbsp;
                            {
                                    //  order['saida'] ?
                                    //    <IoMdArrowDropdown size={20} /> :
                                    //   <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        <TableCell onClick={() => Order('duracao')} className={classes.head2} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                DURAÇÃO&nbsp;
                            {
                                    // order['duracao'] ?
                                    //    <IoMdArrowDropdown size={20} /> :
                                    //    <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        <TableCell onClick={() => Order('placeName')} className={classes.head} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                LOCAL&nbsp;
                            {
                                    //  order['placeName'] ?
                                    //    <IoMdArrowDropdown size={20} /> :
                                    //    <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        <TableCell onClick={() => Order('clientName')} className={classes.head2} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                CLIENTE&nbsp;
                            {
                                    //  order['clientName'] ?
                                    //    <IoMdArrowDropdown size={20} /> :
                                    //   <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        <TableCell onClick={() => Order('serviceName')} className={classes.head3} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                SERVIÇO&nbsp;
                            {
                                    //  order['serviceName'] ?
                                    //     <IoMdArrowDropdown size={20} /> :
                                    //    <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        <TableCell onClick={() => Order('jobName')} className={classes.head3} style={styles.cell} align="center">
                            <div className={classes.contentCell}>
                                ATIVIDADE&nbsp;
                            {
                                    // order['jobName'] ?
                                    //    <IoMdArrowDropdown size={20} /> :
                                    //    <IoMdArrowDropup size={20} />
                                }
                            </div>
                        </TableCell>
                        {
                            window.innerWidth > 800 &&
                            <TableCell style={{
                                color: textTableColor,
                                fontWeight: 'bold', border: 'none',
                                fontSize: '11px'

                            }} align="center">OBSERVAÇÃO</TableCell>
                        }
                        <TableCell style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">{window.innerWidth > 800 ? 'AÇÕES' : ''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows && rows.map((row, index) => {
                            return (
                                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row">
                                        <div style={{ minWidth: '30px', width: '100%' }}> {row.periodName}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }}>
                                        <div style={{ width: '20px' }}> {row.entrada}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} >
                                        <div style={{ width: '20px' }}> {row.saida}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} >
                                        <div style={{ width: '20px' }}> {row.duracao}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px', }} align="center">
                                        <div style={{ width: '69px' }}> {row.placeName}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">
                                        <div style={{ minWidth: '100px', width: '100%' }}> {row.clientName}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">
                                        <div style={{ minWidth: '150px', width: '100%' }}> {row.serviceName}
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">
                                        <div style={{ minWidth: '200px', width: '100%' }}> {row.jobName}
                                        </div>
                                    </TableCell>
                                    {
                                        window.innerWidth > 800 && <TableCell
                                            style={{
                                                color: textTableColor,
                                                border: 'none', fontSize: '12px',
                                                padding: 0, margin: 0, paddingTop: '5px'
                                            }}
                                            align="center">
                                            <div style={{ minWidth: '150px', width: '100%' }}>
                                                <ObservacaoText id={row.id} index={index} text={row.observacao} />
                                            </div>

                                        </TableCell>
                                    }
                                    <TableCell
                                        style={{ color: textTableColor, border: 'none', width: '80px', paddingTop: '14px' }}
                                        align="center">
                                        {
                                            window.innerWidth > 800 ?
                                                lastItem && row.id === lastItem
                                                &&
                                                <span style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>

                                                    <FiEdit
                                                        style={{ cursor: 'pointer' }}
                                                        color={colors.primary}
                                                        size="18"
                                                        title="Editar"
                                                        onClick={() => editRegister(true, row.id, row.observacao)}
                                                    />

                                                    <AiTwotoneDelete
                                                        onClick={() => confirmDelete(row.id)}
                                                        style={{ cursor: "pointer" }}
                                                        color={colors.error}
                                                        size="20"
                                                        title="Remover"
                                                    />
                                                </span>
                                                :
                                                <BsThreeDotsVertical
                                                    style={{ cursor: 'pointer' }}
                                                    color={colors.primary}
                                                    size="18"
                                                    title="Ações"
                                                    onClick={() => openModal(row.id, row.observacao)}
                                                />
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
            { showModal && <Backdrop onClick={() => setShowModal(false)} />}
            {
                showModal && <>
                    <ModalAcoes open={open}>
                        <ItemModal
                            onClick={() => editRegister(true, itemId)}
                            border={'1px #ddd solid'}>
                            Editar item
                    </ItemModal>
                        <ItemModal onClick={() => handleObservacao()}>
                            Adicionar observação
                    </ItemModal>
                        <ItemModal
                            onClick={() => confirmDelete(itemId)}
                            border={'1px #ddd solid'}>
                            Remover item
                    </ItemModal>
                    </ModalAcoes>
                    <ModalAcoes2 open={open2}>
                        <MyInput
                            // onBlur={() => handleNoteRegister()}
                            value={myText}
                            onChange={e => setMyText(e.target.value)}
                            placeholder="Opcional" maxLength="140" />
                        <Button onClick={() => updateObservacao()}>Atualizar</Button>
                    </ModalAcoes2>
                </>
            }
        </TableContainer >
    );
}
//<Input border={`0.5px ${shade(0.2, colors.surface)} solid`} />

/* <span style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>

                                                    <FiEdit
                                                        style={{ cursor: 'pointer' }}
                                                        color={colors.primary}
                                                        size="18"
                                                        title="Editar"
                                                        onClick={() => editRegister(true, row.id)}
                                                    />

                                                    <AiTwotoneDelete
                                                        onClick={() => handleDelete(row.id)}
                                                        style={{ cursor: "pointer" }}
                                                        color={colors.error}
                                                        size="20"
                                                        title="Remover"
                                                    />

                                                </span>*/