import styled from 'styled-components'


export const Icone = styled.div`
margin-left: 15px;
 margin-right: 15px;
`;

export const Button = styled.div`
color: #ddd;
text-decoration: none;
display: flex;
align-items: center;
justify-content: space-evenly;
height: 30px;
width: 97%;
background-color: ${props => props.active};
border-right: ${props => props.border};
border-bottom-right-radius: ${props => props.radius};
border-bottom-left-radius: ${props => props.radius};
cursor: pointer;
`;

export const Title2 = styled.div`
font-size: 12px;
width: 100%;
display:flex;
align-items: center;
justify-content: flex-start;
font-weight: 500;
color: ${props => props.theme.textColorButtonMenu};
`;

