import React, { useState } from 'react'
import {
    Container,
    Header,
    BodyHeader,
    Body,
    LabelInput,
    Input,
    WrapInput,
    Button,
    Form,
    WrapButtons,
    Column,
    WrapLogo,
    Logo,
    
} from './styles.js'
import logo from 'assets/logo-confluence.svg'
import { apiNoAuth } from 'services/axios'
import { useSnackbar } from 'notistack';
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'

import MessageAlert from 'components/MessageAlert'


export default function EsqueciMinhaSenha(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState()
    const [sending, setSending] = useState(false)

    async function sendEmail() {
        if (sending === false) {
            setSending(true)
            
            try {
                const { data } = await apiNoAuth.post('/forgotPassword', { email })
                if (data.success) {
                    let count = 3
                    enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
                    setInterval(() => {
                        enqueueSnackbar('Redirecionando você em ' + count-- + "...", INFOMESSAGE)
                    }, 1000)
                    setTimeout(() => {
                        window.location = '/'
                    }, 3000)
                }
                if (!data.success) {
                    setSending(false)
                    enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
                }
            } catch (err) {
                console.log(err)
                enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
            }
            
        }
    }

    return (
        <Container>
            <Header>
                <WrapLogo onClick={() => window.location = "/"}>
                    <Logo img={logo} />
                </WrapLogo>
            </Header>
            <Body>
                <BodyHeader>
                    SOLICITAÇÃO DE NOVA SENHA
                </BodyHeader>
                <Form>
                    <Column>
                        <WrapInput>
                            <LabelInput>
                                Por favor, informe seu e-mail abaixo para receber uma nova senha:
                        </LabelInput>
                            <Input type="email" disabled={sending} value={email} onChange={e => setEmail(e.target.value)} />
                        </WrapInput>
                    </Column>

                </Form>
                <WrapButtons>

                    <Button btnColor={''} >
                        {' '}
                    </Button>
                    <Button onClick={() => sendEmail()} btnColor={'#107CB8'} >
                        Enviar
                        </Button>
                </WrapButtons>
            </Body>
        </Container>
    )
}
