import styled from 'styled-components'
import Select from 'react-select';


export const Input2 = styled(Select)`
border: ${props => props.border};
width: 100%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;


export const InputText = styled.input`
border: ${props => props.border};
outline: 0;
height: 30px;
width: 94%;
border-radius: 8px;
padding-left: 10px;
`;





