import React, { useState, useEffect, useContext } from "react";
import { modalStyle } from '../../components/Modal'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { SearchBox, IconeSearch, ItemsSearch } from "components/Search";
import {
  Container,
  Items
} from 'components/SharedComponents/styles'
import { useShowModal } from 'contexts/SharedContext'
import { MySelect } from "components/InputSelect";
import { Wrap, WrapInput, WrapList } from "components/Wrap";
import { Button, ConfirmarBtn, CancelarBtn } from "components/Button";
import { Input, LabelInput, inputStyle, selectStyle } from "components/Input";
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, } from "utils/types-messages";
import { api } from "services/axios";
import { useSnackbar } from "notistack";
import "./style.css";
import { Modal, CircularProgress } from "@material-ui/core";
import TableCadastroContrato from 'components/TableCadastroContrato'
import MessageAlert from 'components/MessageAlert'
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal'
import { ThemeContext } from 'styled-components'
import { TitleStyle2 } from "../../pages/CadastroPessoas/styles";
import { ModalBox } from "../../pages/CadastroContrato/styles";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { GetSessionUser } from 'services/helpers'


export default function CadastroContrato() {
  const { colors, textTableColor } = useContext(ThemeContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [agreement, setOptions] = useState([]);
  const [compId, setCompId] = useState(1);
  const [clients, setClients] = useState([]);
  const [agreementType, setAgreementType] = useState([]);
  const [agreementType2, setAgreementType2] = useState([]);
  const [values, setValues] = useState({
    "clientId": '', "ClienteName": "", "aggrementTypeId": "", "AggrementType": ""
  });
  const [valuesAlt, setValuesAlt] = useState([]);
  const { setShowModalContrato, showModalContrato } = useShowModal();
  const [loadDialog, setLoadDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedAggrementType, setAggrementType] = useState();
  const { perfil } = GetSessionUser()

  //Iniciar
  const getInit = async () => {
    setCompId(perfil.companyId)
    fetchAgreementAll(perfil.companyId);
    fetchClients()
    fetchTypeAgreement2()
    setValues({ ...values, 'companyId': perfil.companyId })
  };

  useEffect(() => {
    getInit();
  }, []);

  // fetch clients
  async function fetchClients() {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/client?companyId=1')
      if (data.success) {
        const { result } = data;
        let servicesRet = result.map((item) => {
          return { id: item.id, name: item.fantasyName };
        })
        setClients(servicesRet);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  useEffect(() => {
    if (values.clientId)
      fetchTypeAgreement(values.clientId)
  }, [values.clientId])


  async function fetchTypeAgreement(clientId) {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/client/getAggrementType/' + clientId)
      if (data.success) {
        const { result } = data;
        let servicesRet = result.map((item) => {
          return { id: item.AggrementTypeId, name: item.AggrementTypeName };
        })
        setAgreementType(servicesRet);
        setLoadDialog(false);
      } else {
        setAgreementType([]);
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  async function fetchTypeAgreement2() {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/aggrementType?companyId='+perfil.companyId)
      if (data.success) {
        const { result } = data;
         let servicesRet = result.map((item) => {
           return { id: item.id, name: item.name };
         })
        setAgreementType2(servicesRet);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
      setLoadDialog(false);
    }

  }
  // Criar Agreement
  async function sendAgreement() {
    try {
      setLoadDialog(true);
      const { data } = await api.post("/aggrement?companyId=" + compId, valuesAlt);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalContrato({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    fetchAgreementAll(1);
  }

  // Apagar Agreement
  async function deleteAgreement(id) {
    const key = enqueueSnackbar('Apagando contratos...', INFOMESSAGE);
    try {
      setLoadDialog(true);
      const { data } = await api.delete("/aggrement/" + id);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    setShowModalContrato({ show: false });
    fetchAgreementAll(1);
    closeSnackbar(key);
  }

  //Atualizar Agreement
  async function updateAgreement(id) {
    try {
      setLoadDialog(true);
      let comp_id = '&companyId=' + compId
      const { data } = await api.put("/aggrement/" + id + comp_id, valuesAlt);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalContrato({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    fetchAgreementAll(1);
  }

  //Pesquisar Agreement
  async function fetchAgreement(paginaVal) {
    setOptions([]);
    const key = enqueueSnackbar('Pesquisando contratos...', INFOMESSAGE)
    try {
      let comp_id
      let query_filter = ''
      if (values.name) {
        query_filter += `&name=${values.name}`
      }
      if (values.aggrementTypeId) {
        query_filter += `&aggrementTypeId=${values.aggrementTypeId}`
      }
      if (values.externalCode) {
        query_filter += `&externalCode=${values.externalCode}`
      }
      if (values.clientId) {
        query_filter += `&clientId=${values.clientId}`
      }
      setLoadDialog(true);
      comp_id = '?companyId=' + compId
      const { data } = await api.get("aggrement" + comp_id + query_filter);
      if (data.success) {
        const { result } = data;
        setOptions(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
      setLoadDialog(false);
    }
    closeSnackbar(key)
  }

  async function fetchAgreementAll(paginaVal) {
    try {
      setLoadDialog(true);
      let comp_id = '/0?companyId=' + 1
      let query_filter = ''
      query_filter += `&page=${paginaVal}`
      const { data } = await api.get("aggrement" + comp_id + query_filter);
      if (data.success) {
        const { result } = data;
        setOptions(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  //Handles
  const handleChange = (item, handleName) => {
    setValues({ ...values, [handleName]: item ? item.value : '' });
  }

  const handleClient = (item) => {
    setValues({ ...values, clientId: item ? item.value : '', ClienteName: item ? item.label : '' ,aggrementTypeId:'',AggrementType:''});
    setSelectedClient(item);
    if (item) {
      fetchTypeAgreement(item.value)
    } else {
      setAgreementType([]);
      setAggrementType('');
    }
  }

  const handleAggrementType = (item) => {
    setValues({ ...values, aggrementTypeId: item ? item.value : '', AggrementType: item ? item.label : '' });
    setAggrementType(item);
  }


  let handleChangeAlt = (itemAlt) => {
    if (itemAlt.label) {
      let { label, value, handleName } = itemAlt
      if (handleName === "clientId") {
        setValuesAlt(
          { ...valuesAlt, clientId: value, ClienteName: label }
        );
      } else {
        setValuesAlt(
          { ...valuesAlt, aggrementTypeId: value, AggrementType: label }
        );
      }
    } else {
      let { name, value } = itemAlt.target;
      setValuesAlt({ ...valuesAlt, [name]: value });
    }
  }

  const handleClientEdit = (itemAlt) => {
    setValuesAlt({ ...valuesAlt, clientId: itemAlt.value, ClienteName: itemAlt.label });
  }

  const handleAggrementTypeEdit = (itemAlt) => { 
    setValuesAlt({ ...valuesAlt, aggrementTypeId: itemAlt.value, AggrementType: itemAlt.label });
  }
  
  // Modal
  useEffect(() => {
    if (showModalContrato.action === 'editar') {
      setValuesAlt({
        "companyId": compId,
        "clientId": showModalContrato.item.clientId,
        "ClienteName": showModalContrato.item.ClienteName,
        "aggrementTypeId": showModalContrato.item.aggrementTypeId,
        "AggrementType": showModalContrato.item.AggrementType,
        "externalCode": showModalContrato.item.externalCode,
        "name": showModalContrato.item.name,
        "logChange": showModalContrato.item.logChange
      })
    }
    if (showModalContrato.action === 'inserir') {
      setValuesAlt({ "companyId": compId })
    }
  }, [showModalContrato.action])

  const handleClose = () => {
    setShowModalContrato({ show: false });
  };

  function clearSearch() {
    setSelectedClient('');
    setAgreementType([]);
    setAggrementType('');
    setValues({ externalCode: "", name: "", companyId: 1, clientId:  '', ClienteName: '' ,aggrementTypeId:'',AggrementType:'', logChange: 0 });
    setOptions([]);
  }


  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const modalUpdate = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        {showModalContrato.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>

      {showModalContrato.action === 'editar' || showModalContrato.action === 'inserir' ?
        <ModalBox>
          <Wrap>
            <WrapInput>
              <LabelInput>Cliente <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <MySelect
                value={{ value: valuesAlt.clientId, label: valuesAlt.ClienteName }}
                onChange={handleClientEdit}
                options={clients.map((item) => { return { value: item.id, label: item.name, handleName: 'clientId' }; })}
                styles={selectStyle}
              />
            </WrapInput>
            <WrapInput>
              <LabelInput>Tipo de Contrato/Serviço <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <MySelect
                styles={selectStyle}
                value={{ value: valuesAlt.aggrementTypeId, label: valuesAlt.AggrementType }}
                onChange={handleAggrementTypeEdit}
                options={agreementType2.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              />
            </WrapInput>

          </Wrap>
          <Wrap>
            <WrapInput>
              <LabelInput>Código Externo</LabelInput>
              <Input
                style={inputStyle}
                type="text"
                name="externalCode"
                onChange={handleChangeAlt}
                value={valuesAlt.externalCode}
              />
            </WrapInput>
            <WrapInput>
              <LabelInput>Nome Contrato <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <Input
                style={inputStyle}
                maxLength="50"
                type="text"
                name="name"
                onChange={handleChangeAlt}
                value={valuesAlt.name}

              />
            </WrapInput>

          </Wrap>
          <Wrap>
            <WrapInput className="wrapInputModal">
              <Items>
                <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                <Button type="button" onClick={() => showModalContrato.action === 'editar' ? updateAgreement(showModalContrato.item.id) : sendAgreement()}> Salvar </Button>
              </Items>
            </WrapInput>

          </Wrap>
        </ModalBox> :
        ''
      }
      {showModalContrato && showModalContrato.action === 'remover' ?
        <div>
          <WrapInput className="alertText">
            <h3>Você tem certeza que deseja remover {showModalContrato ? showModalContrato.item.name : ''}? Essa operação é permanente! </h3>
          </WrapInput>
          <WrapInput className="alertBtn">
            <Items>
              <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
              <ConfirmarBtn type="button" onClick={() => deleteAgreement(showModalContrato.item.id)}> Confirmar </ConfirmarBtn>
            </Items>
          </WrapInput>
        </div>

        : ''
      }


    </div>
  );


  return (
    <Container>

      <Modal open={showModalContrato.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalUpdate}
      </Modal>
      <SearchBox>
        <TitleStyle2>Cadastro de Contratos</TitleStyle2>
        <form>
          <Wrap className="Wrap" >
            <WrapInput style={{ width: '10%', paddingRight: '0px' }}>
              <LabelInput>Cliente</LabelInput>
              <MySelect
                styles={selectStyle}
                placeholder="Opções"
                isClearable={true}
                value={selectedClient}
                onChange={(item) => handleClient(item)}
                //onChange={(item) => handleChange(item, 'clientId')}
                options={clients.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              />
            </WrapInput>
            <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
              <LabelInput>Tipo de Contrato/Serviço</LabelInput>
              <MySelect
                styles={selectStyle}
                placeholder="Opções"
                isClearable={true}
                value={selectedAggrementType}
                onChange={(item) => handleAggrementType(item)}
                //onChange={(item) => handleChange(item, 'aggrementTypeId')}
                options={agreementType.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', paddingRight: '12px' }}>
              <LabelInput>Código Externo</LabelInput>
              <Input
                style={inputStyle}
                type="text"
                name="externalCode"
                onChange={e => setValues({ ...values, 'externalCode': e.target.value })}
                value={values.externalCode}
              />
            </WrapInput>
            <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
              <LabelInput>Nome Contrato</LabelInput>
              <Input 
                style={inputStyle}
                maxLength="50"
                type="text"
                name="name"
                value={values.name}
                onChange={e => setValues({ ...values, 'name': e.target.value })}
              />
            </WrapInput>
            <WrapInput style={{ width: '20%', marginTop: '10px' }}>
              <ItemsSearch>
                <IconeSearch size={25} title="Pesquisar" onClick={() => fetchAgreement(1)} />
                <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => setShowModalContrato({ show: true, title: 'CADASTRAR CONTRATO', action: 'inserir' })}>&nbsp;Novo<AddIcon></AddIcon></Button>
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>
        </form>
      </SearchBox>

      <WrapList>
        <HeaderList>

          <span style={{ marginLeft: '20px' }}>CONTRATOS  </span>
        </HeaderList>
        {
          <BodyList>
            <TableCadastroContrato contrato={agreement} clients={clients} agreementType={agreementType} />
          </BodyList>

        }
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  );
}
