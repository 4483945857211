import React, { useState, useContext, useEffect } from 'react';
import { TableRow, TableCell, Tooltip, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  ListItems,
  WrapButtons,
  ButtonW,
  WrapTable,
  Title,
  modalClose
} from './styles.js'
import { ThemeContext } from 'styled-components'
import { api } from 'services/axios'
import { shade } from 'polished'

import { BsExclamationCircle } from 'react-icons/bs'

import TableRegistrosFechamento from 'components/TableRegistrosFechamento'
import { useUserResume, useShowModal } from 'contexts/SharedContext'
import { GetSessionUser } from 'services/helpers'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { useSnackbar } from 'notistack';
import { FiEye, FiEdit, FiUpload, FiFile } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'
import MessageAlert from 'components/MessageAlert'
import CancelIcon from '@material-ui/icons/Cancel';

export default function RowTable({ row, index, getInit, esconderEditar, handleUpload }) {
  const { colors, textTableColor, buttonPrimary } = useContext(ThemeContext)
  const [checked, setChecked] = useState(false);
  const { userResumeFechamento } = useUserResume()
  const { showModalIncluirRegistro, setShowModalIncluirRegistro } = useShowModal()
  const { enqueueSnackbar } = useSnackbar()
  const [dia, setDia] = useState()
  const [del, setDel] = useState(false);

  useEffect(() => {
    let temp = row.regData.split('-')
    setDia(temp[2] + '/' + temp[1] + '/' + temp[0])
  }, [row])

  const handleChange = () => {
    let existeNovo = false;
    row.itens.forEach(reg => {
      if (reg.novo) {
        existeNovo = true;
      }
    });
    if (existeNovo || del) {
      enqueueSnackbar('Você não salvou as alterações dos registros, verifique!', WARNINGMESSAGE)
    } else {
      setChecked((prev) => {
        return !prev
      });
    }
  }

  const setExclusao = (item) => {
    setDel(item);
  }

  const openNewRegister = () => {
    setShowModalIncluirRegistro({ show: true, regData: row.regData, lineId: row.itens.length + '' })
  }


  const sendNewsRegisters = async () => {
    try {
      const { perfil } = GetSessionUser()
      const newArray = userResumeFechamento
      const temp = newArray.filter(item => { return item.regData + '' === row.regData + '' })
      const itens = temp[0].itens
      const obj = {
        itens,
        personId: perfil.personId,
        regData: row.regData,
      }
      const { data } = await api.post('/registerItem/aturegdia', obj)
      if (data.success) {
        setChecked(false);
        setDel(false);
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        getInit()
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  
  return (
    <>
      {
        checked && <Backdrop onClick={() => handleChange()} />
      }
      <TableRow style={{ width: '100%', backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={row.data}>
        <Tooltip title={row.holidayType === 0 ? '' : row.holiday + ' - ' + row.holidayName} placement="right" arrow>
          <TableCell style={{ cursor: 'pointer', fontSize: '13px', color: row.diaSemana === "sábado" || row.diaSemana === 'domingo' || row.holidayType === 1 || row.holidayType === 2 || row.holidayType === 3 ? colors.error : textTableColor, border: 'none' }} align="center" component="th" scope="row">{row.diaSemana}</TableCell>
        </Tooltip>
        <TableCell style={{ fontSize: '12px', color: textTableColor, border: 'none' }} align="center">{row.data}</TableCell>
        <TableCell style={{ fontSize: '12px', color: textTableColor, border: 'none' }} align="center">{row.Entrada ? row.Entrada : '-'}</TableCell>
        <TableCell style={{ fontSize: '12px', color: textTableColor, border: 'none' }} align="center">{row.saida ? row.saida : '-'}</TableCell>
        <TableCell style={{ fontSize: '12px', color: textTableColor, border: 'none' }} align="center">{row.duracao}</TableCell>
        <TableCell
          style={{ fontSize: '12px', color: textTableColor, border: 'none' }}
          align="center">

          {
            row.manual && 'Sim'
          }

        </TableCell>
        <TableCell
          style={{ fontSize: '12px', color: textTableColor, border: 'none' }}
          align="center">
          {
            row.inconsistencia && <BsExclamationCircle color={colors.error} size='20' />
          }
        </TableCell>

        <TableCell
          style={{ fontSize: '12px', color: textTableColor, border: 'none' }}
          align="center">
          {
            row.diaFechado ? 'Fechado' : 'Pendente'
          }
        </TableCell>
        <TableCell style={{ fontSize: '12px', color: textTableColor, border: 'none' }} align="center">
          {
            esconderEditar && <FiEye
              style={{ cursor: 'pointer' }}
              color={colors.primary}
              size="18"
              title="Visualizar"
              onClick={() => handleChange()}
            />
          }
          {
            !row.diaFechado && !esconderEditar && <FiEdit
              style={{ cursor: 'pointer' }}
              color={colors.primary}
              size="18"
              title="Editar"
              onClick={() => handleChange()}
            />
          }
          </TableCell>
          <TableCell style={{ fontSize: '12px', color: textTableColor, border: 'none' }} align="center">
          {
            !row.existeFile && <FiUpload 
            style={{ cursor: 'pointer' }}
              color= {row.existeFile ? colors.primary : colors.textBg }                                   
              size="18"
              title="UpLoad"
              onClick={() => handleUpload(true, dia, row.regData, true)}
            />
          }
          {
            row.existeFile && <FiUpload 
            style={{ cursor: 'pointer' }}
              color= {colors.primary}                                   
              size="18"
              title="UpLoad"
              onClick={() => handleUpload(true, dia, row.regData, false)}
            />
          }
        </TableCell>
      </TableRow>
      {
        checked && <ListItems>
          <WrapTable>
            <Title>&nbsp;&nbsp;&nbsp;REGISTROS DO DIA - {dia}<div>
              <CancelIcon
                style={modalClose}
                color={colors.error}
                size="20"
                title="Fechar"
                onClick={() => handleChange()}
              />
            &nbsp;&nbsp;&nbsp;</div>
            </Title>
            <TableRegistrosFechamento closeModal={handleChange} getInitFechamento={getInit} items={row.itens} regData={row.regData} esconderEditar={esconderEditar} setExclusao={setExclusao} />
          </WrapTable>
          {
            !esconderEditar && <WrapButtons>
              <ButtonW onClick={() => openNewRegister()} bg={buttonPrimary}>
                Incluir Registro
              </ButtonW>
              <ButtonW onClick={() => sendNewsRegisters()} bg={"#3FC248"}>
                Salvar
              </ButtonW>
            </WrapButtons>
          }
          {
            esconderEditar && <WrapButtons></WrapButtons>
          }
        </ListItems>
      }
    </>
  );
}
