import React, {  useContext } from 'react'
import {
    Container,
    Wrap,
    Title,
  
    Button,
    Items
} from './styles'





import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import { ThemeContext } from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip';



export default function RelatorioMensal() {
   
    const {bgHeading } = useContext(ThemeContext)
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: bgHeading,
                },
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    //backgroundColor: bgHeading,
                    //color: bgHeading,
                },
            },
            MuiPickersDay: {
                day: {
                    // color: bgHeading,
                },
                daySelected: {
                    backgroundColor: bgHeading,
                    '&:hover': {
                        backgroundColor: bgHeading,
                    },

                },
                dayDisabled: {
                    //  color: lightBlue["100"],
                },
                current: {
                    color: bgHeading,
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    color: bgHeading,
                },
            },
        },
    });



    return (
        <Container>
            <Wrap>
                <Title>RELATÓRIO FECHAMENTO MENSAL</Title>
                <Items>

                    <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                minDateMessage="Não é possível buscar por datas futuras."
                                views={["year", "month"]}
                                helperText={''}
                                clearLabel="Limpar"
                                cancelLabel="Cancelar"
                                clearable
                                // disableFuture
                                animateYearScrolling
                                margin="normal"
                                placeholder="junho"
                                id="date-picker-dialog"
                                label="Mês de referência"
                                //format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDate={new Date(new Date("01-01-2018"))}
                                maxDate={new Date(new Date())}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>

                    <Tooltip title="Faça o uplaod do arquivo" placement="bottom-end" arrow>
                        <Button type="file">
                            Upload
                            </Button>

                    </Tooltip>
                </Items>
            </Wrap>
        </Container>
    )
}