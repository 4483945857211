import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow-y: auto;
padding-left: 70px;
padding-right: 70px;
@media( max-height: 659px){
    padding-bottom: 100px;
}

`;