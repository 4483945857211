import React, { useContext, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer
  } from 'components/SharedComponents/table'; 
import { ThemeContext } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { shade } from 'polished'
import { useColaboradoresResume } from 'contexts/SharedContext'

export default function TabelaConsolidado() {
  const {  colabsInconsistentes } = useColaboradoresResume();
    const { colors, textTableColor } = useContext(ThemeContext);
    const useStyles = makeStyles({
        table: {           
            backgroundColor: colors.surface, 
            width: '100%', 
            fontSize: '14px'
        },     
        headCol: {
            backgroundColor: colors.background,
            position: "sticky",
            top: 0,
            color: textTableColor, 
            fontWeight: 500, 
            border: 'none', 
          
            textTransform: 'uppercase',
            textAlign: 'center'
        },
        bodyCol: {
            color: textTableColor, 
            border: 'none', 
            fontSize: '18px',
            
        },
        wrapBtnModal:{
            alignItems:'flex-start'
        },
        btnModal:{
            marginLeft:'0',marginTop:'-5px'
        },
        headColModal: {
            backgroundColor: colors.surface,
            textTransform: 'uppercase',
            borderRadius: '0',
            fontSize: '14px'
        },
        containerModal:{
            boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
            borderRadius: '0',
            width: '100%'
        },
        head: {
          backgroundColor: "#fff",
          position: "sticky",
          top: 0
      }
    });
    const classes = useStyles();   
    useEffect(() =>{
      //  alert(JSON.stringify(colabsInconsistentes))
    },[colabsInconsistentes])
    return (
      <TableContainer className={classes.containerModal}>
        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">SERVIÇO</TableCell>  
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">ATIVIDADE</TableCell>  
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">EQUIPE</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">COORDENADOR</TableCell>  
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">GERENTE</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">REGISTRADO EM</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">INCONSISTÊNCIA</TableCell>
 
            </TableRow>
          </TableHead>
          <TableBody style={{ width: '100%' }}>
            {
              colabsInconsistentes && colabsInconsistentes.map((row, index) => {
                return (
                  <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.name}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.service}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.job}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.team}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.coordinator}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.manager}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.registerDate}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.inconsistencia}</TableCell>
                   
             
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
}
