import styled from 'styled-components'

export const Input = styled.input`
height: 30px;
outline: 0;
border-radius: 5px;
padding-left: 10px;
width: 100%;
border: 1px;
color: ${props => props.theme.textTable};
background-color: ${props => props.theme.colors.background};
margin: 0;
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ddd;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ddd;
}
`;