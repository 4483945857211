import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'


export const ContainerClose = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
width: 55px;
background-color: ${props => props.theme.bgMenu};
height: 92.5vh;
@media (max-width: 1080px){
display: none;
}
`;

export const Items = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
`;

export const Space = styled.div`
display: flex;
justify-content: center;
align-items:center;
flex-direction: column;
width: 100%;
margin-bottom: 20px;
`;


export const Faq = styled(Link)`
display:flex;
width: 30px;
height: 30px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
cursor: pointer;
margin-bottom: 20px;
`





export const Ibrati = styled.div`
display:flex;
width: 27px;
height: 27px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
cursor: pointer;
margin-bottom: 20px;
`

export const Email = styled.div`
display:flex;
width: 25px;
height: 25px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
cursor: pointer;
margin-bottom: 20px;
`

export const HandleMenu = styled.div`
color: #ddd;
text-decoration: none;
display: flex;
align-items: center;
justify-content: space-evenly;
height: 60px;
width: 100%;

`;

export const IconeMenu = styled(FaBars)`

&:hover{
    opacity: 0.6;
}
`;
