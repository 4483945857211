import styled from 'styled-components'
import Select from 'react-select';

export const MySelect = styled(Select)
`
border: ${props => props.border};
width: 100%;
border-radius: 10px;
background-color: ${props => props.theme.colors.surface};
`;
export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,
        padding: 0,
        margin:0,
        height: '30px',
        minHeight: '30px',
        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: `${props => props.theme.colors.background}`,
        borderColor: 'gray',
        height: '30px',
        minHeight: '30px',
        fontSize: 14,
        padding: 0,
        margin:0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 5,        
       
    }),
    clearIndicator: base => ({
        ...base,
        padding: 5,
       
    }),
    indicatorSeparator: base => ({
        ...base,

        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: '0',      
        overflow: "hidden",   
        textOverflow: "ellipsis",     
        height: '30px',
        minHeight: '30px',   
    }),
    multiValue: (base, state) => ({
        ...base,
           
    }),
   
};