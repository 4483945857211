import React, { useState, useEffect, useContext } from 'react'
import {
  Container,
  ButtonLogout,
  Header,
  BodyHeader,
  Body,
  LabelInput,
  WrapInput,
  Button,
  Form,
  WrapButtons,
  Column,
  WrapLogo,
  Logo,
  MySelect
} from './styles.js'
import logo from 'assets/logo-confluence.svg'
import { GetUserAccess, GetSessionUser } from 'services/helpers'
import { shade } from 'polished'
import { ThemeContext } from 'styled-components'
import { keyUserAccess } from 'services/axios'
import Tooltip from '@material-ui/core/Tooltip';
import { FaPowerOff } from 'react-icons/fa'
import { Logout } from 'services/helpers'

export default function SelecionarPerfil(props) {
  const { colors, } = useContext(ThemeContext)
  const [perfilSelected, setPerfilSelected] = useState()
  const [perfis, setPerfis] = useState([])


  const getOptions = async () => {
    const { menu } = GetSessionUser()
    if (menu) {
      const temp = []
      await menu.map(async item => {
        await temp.push(item.profile)
      })
      setPerfis(temp)
    }
  }

  const handleLogout = () => {
    Logout()
  }

  useEffect(() => {
    getOptions()
  }, [])

  const handlePerfil = async (item) => {
    const { menu } = GetSessionUser()
    if (item) {
      if (menu) {
        const temp = menu.filter(obj => obj.profile === item.label)
        await localStorage.setItem(keyUserAccess, JSON.stringify(...temp))
      }
    }
  }

  const handleRedirect = () => {
    const access = GetUserAccess()
    window.location = '/' + access.profile.toLowerCase()
  }
  const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 35
  });

  return (
    <Container>
      <Header>
        <WrapLogo onClick={() => window.location = "/"}>
          <Logo img={logo} />
        </WrapLogo>
        <Tooltip title="Sair da aplicação" placement="bottom-end" arrow>
          <ButtonLogout
            onClick={() => handleLogout()}
          >
            <FaPowerOff size={25} color="#ddd" />
          </ButtonLogout>
        </Tooltip>
      </Header>
      <Body>
        <BodyHeader>
          Bem vindo ao Worklog
                </BodyHeader>
        <Form>
          <Column>
            <WrapInput>
              <LabelInput>
                Por favor selecione o perfil que deseja logar.
                        </LabelInput>
              <MySelect
                styles={{ control: customControlStyles }}
                placeholder="ex.(Administrador)"
                isClearable

                value={perfilSelected}
                onChange={handlePerfil}
                options={perfis.map(item => { return { value: item, label: item } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
          </Column>
        </Form>
        <WrapButtons>
          <Button onClick={() => handleRedirect()} btnColor={'#107CB8'} >
            Entrar
                        </Button>
          <Button btnColor={''} >
            {' '}
          </Button>

        </WrapButtons>
      </Body>
    </Container>
  )
}
