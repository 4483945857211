import styled from 'styled-components';
import {  FaCaretRight } from 'react-icons/fa';
import { Collapse as Collap } from '@material-ui/core';


export const Icone2 = styled(FaCaretRight)`
transform: ${props => props.rotate};
transition: transform 0.4s;
opacity: 0.6;
`;

export const Collapse = styled(Collap)`
 width: 100%;
 background-color: ${props => props.bg};
 border-bottom-right-radius: 10px;
`;

export const Button2 = styled.div`
color: #ddd;
display: flex;
align-items: center;
justify-content: center;
height: 30px;
width: 100%;
text-decoration: none;
background-color: ${props => props.bg};
cursor: pointer;
&:hover{
    background-color: ${props => props.bg};
}
`;

export const Title = styled.div`
font-size: 13px;
width: 60%;
display:flex;
align-items: center;
justify-content: flex-start;
font-weight: 500;
color: ${props => props.theme.textColorButtonMenu};
`;



export const Icone = styled.div`
margin-left: 15px;
 margin-right: 15px;
`;

export const Button = styled.div`
color: #ddd;
text-decoration: none;
display: flex;
align-items: flex-start;
justify-content: space-evenly;
height: 30px;
width: 97%;
background-color: ${props => props.active};
border-right: ${props => props.border};
cursor: pointer;

`;

export const Title2 = styled.div`
width: 100%;
display:flex;
align-items: center;
justify-content: flex-start;
font-weight: 500;
color: ${props => props.theme.textColorButtonMenu};
`;

