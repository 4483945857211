
import React, { useState, createContext, useContext } from 'react'

const MenuContext = createContext()


export default function MenuProvider({ children }) {

    const [showMenu, handleMenu] = useState(false)

    return <MenuContext.Provider value={{ showMenu, handleMenu }}>
        {children}
    </MenuContext.Provider>


}

export function useMenu() {
    const context = useContext(MenuContext)
    const { showMenu, handleMenu } = context

    return { showMenu, handleMenu }

}