import styled from 'styled-components'
import { FaExclamationTriangle } from 'react-icons/fa'


export const Header = styled.div`
width: 98%;
display:flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
padding-top: 10px;
@media (max-width: 800px){
    flex-direction: column;
    align-items: center;
}
`;

export const WrapMessages = styled.div`
width: 60%;
@media (max-width: 1230px){
   width: 50%;
}
@media (max-width: 800px){
   display: none;
}
`;

export const WrapTotalHoras = styled.div`
width: 19%;
height: 100px;
@media (max-width: 1230px){
   width: 23%;
}
@media (max-width: 800px){
   display: none;
}
`;

export const WrapTotalHoras2 = styled.div`
width: 150px;
@media(max-width: 1245px){
 margin-top: 10px;
}
`;
export const TotalHoras = styled.div`
background-color: ${props => props.theme.colors.surface};
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
display: flex;
justify-content: space-between;
align-items: center;
height: 98px;
`;

export const TotalHoras2 = styled.div`
background-color: ${props => props.theme.colors.surface};
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
display: flex;
justify-content: space-between;
align-items: center;
height: 80px;
`;

export const Messages = styled.div`
min-height:100px;
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
`;

export const Line2 = styled.div`
width: 100px;
height: 5px;
background-color: ${props => props.theme.colors.secondary};
margin-top: 8px;
`;

export const ListMessages = styled.div`
font-size: 15px;
overflow:auto; 
width: 90%;
color: gray;
`;

export const IconeMessages = styled.div`
display: flex;
width: 15%;
justify-content:center;
align-items: center;
height: 70px;
`;

export const Title2 = styled.span`
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: ${props => props.theme.textHeading};
font-weight: bold;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;

export const Title = styled.div`
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: ${props => props.theme.textHeading};
font-weight: bold;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;

export const Icone = styled(FaExclamationTriangle)`

`;

export const Line = styled.div`
border: 1px gray solid;
height: 60px;
margin-top: 20px;
margin-bottom: 20px;
`;

export const Previstas = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
`;

export const Horas = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
`;


export const Hora = styled.div`
font-size: 15px;
color: gray;
font-weight: 500;
`;

export const TitleHora = styled.div`
font-size: 16px;
color: gray;
`;

export const WrapInfos2Mobile = styled.div`
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
display: none;
background-color: ${props => props.theme.colors.surface};
padding-bottom: 5px;
width: 98%;
flex-direction: column;
justify-content: space-around;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
@media(max-width: 800px){
    margin-top: 10px;
    display: flex;
    width: 98%;
}
`;