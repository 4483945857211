import React, { useState, useEffect } from 'react'
import {
  ButtonLogout,
  WrapMessages,
  Item,
  WrapInfos,
  IconeMenu,
  Container,
  WrapLogo,
  UserInfo,
  Messages,
  Backdrop,
  Logo,
  Lockscreen,
} from './styles.js'
import {
  Title,
  TitleSpan3,
  WrapButtons,
  AlertLaunch,
  BodyLaunch,
  ButtonLaunch
} from 'pages/RegistroHoras/styles.js'
import { ERRORMESSAGE, WARNINGMESSAGE, SUCCESSMESSAGE } from 'utils/types-messages'
import { Logout, GetSessionUser, TrocarPerfil, GetUserAccess, SomaMinutos, getCookie, setCookie, setCookieHora, getDevice } from 'services/helpers'
import { useUserResume } from 'contexts/SharedContext'
import logo from 'assets/logo-confluence.svg'
import { FaPowerOff } from 'react-icons/fa'
import Tooltip from '@material-ui/core/Tooltip';
import { useMenu } from 'contexts/MenuContext'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { handleAlmoco } from 'pages/RegistroHoras'
import MessageAlert from 'components/MessageAlert'
import { useShowModal } from 'contexts/SharedContext'

export default function NavBar(prop) {
  const { handleMenu } = useMenu()
  const { enqueueSnackbar } = useSnackbar()
  const [show, setShow] = useState(false)
  const { setUserResume } = useUserResume()
  const [user, setUser] = useState('')
  let [almoco, setAlmoco] = useState(true)
  const [showChangeProfile, setShowChangeProfile] = useState(false)
  const [showModalAlertLaunch, setShowModalAlertLaunch] = useState(false)
  const [title, setTitle] = useState('')
  const [device, setDevice] = useState(true)

  // let {showModalAlertLaunch} = useShowModal()

  const access = GetUserAccess();
  const site = '/' + access.profile.toLowerCase()
  let free = true
  let executaIntervalAlmoco = true
  let intervalAlmoco = null
  let horaServer = ""
  let lunch = false;

  async function getUser() {
    const { perfil, menu } = await GetSessionUser()
    if (perfil) {
      const { name, familyName } = perfil
      setUser(familyName)
    }
    if (menu.length > 1)
      setShowChangeProfile(true)
  }




  const getUserResume = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/register/resumo/' + perfil.personId)
      if (data.success) {
        free = true
        const { result } = data

        horaServer = result.currentTime
        lunch = result.Lunch

        setUserResume(result)

      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function verificaAlmoco() {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/register/mensagemAlmoco/' + perfil.personId)
      if (data.success) {

        const { result } = data
        return result.messageLunch
      } else {
        return false
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
    return null
  }

  const update = () => {
    if (site === '/colaborador') {
      setInterval(async () => {
        if (free) {
          free = false
          await getUserResume()
          await validaAlmoco()
        }
      }, 30000)
    }
  }

  async function stop() {
    let total = ''
    const newDate = new Date();
    let day = newDate.getDate();
    let dayStart = newDate.getDate() + 1;
    let mes = newDate.getMonth() + 1;
    let ano = newDate.getFullYear();

    let Fim = ano + '/' + mes + '/' + day + ' ' + horaServer;
    let Start = ano + '/' + mes + '/' + dayStart + ' ' + '10:00';

    let diff = Math.abs(new Date(Fim) - new Date(Start));
    let minutes = Math.floor((diff / 1000) / 60);
    total = minutes;

    return total;
  }

  async function validainterval() {
    const { startLunch, limitLunch } = GetSessionUser()
    let arr = new Array();
    let interval = true;
    let cont = 0;
    let tmp = startLunch;
    while (interval) {
      arr.push(tmp)
      tmp = SomaMinutos(tmp, 15);
      if (tmp > limitLunch) {
        interval = false;
      }
      cont += 1;
    }
    if (cont > 0) {
      for (let i = 0; i < cont; i++) {
        let hr = horaServer;
        if (hr >= arr[i] && hr <= arr[i + 1]) {
          return arr[i + 1];
        }
      }
    }

    return null;
  }

  async function validaAlmoco() {
    const { startLunch, limitLunch } = GetSessionUser()
    if (getCookie('exibiuModalAlmoco') && horaServer < limitLunch) {
      return
    }
    if (site !== '/colaborador') return
    if (!getCookie('pararExibicaoModalAlmoco')) {
      if (await verificaAlmoco()) {

        if (horaServer >= startLunch && site == '/colaborador') {

          Text(horaServer)
          if (horaServer >= limitLunch) {
            setCookie("pararExibicaoModalAlmoco", true, stop())
            setShowModalAlertLaunch(true)
            await getUserResume()
            return
          }

          if (!getCookie('exibiuModalAlmoco')) {
            setCookieHora("exibiuModalAlmoco", true, await validainterval())
            setShowModalAlertLaunch(true)
          }
        }
      }
    }

  }

  async function Text(params) {
    setTitle('Você ainda não registrou o seu intervalo hoje. Não esqueça de realizar o registro no sistema!')
  }

  useEffect(async () => {
    setDevice(getDevice());
    update()
    await getUserResume()
    await getUser()
    //await validainterval()
    await validaAlmoco()
    
  }, [])

  const myHandleMenu = () => {
    handleMenu(true)
  };

  const handleLogout = () => {
    if (showChangeProfile && !device) {
      setShow(true)
    } else {
      Logout()
    }
  }
  const Sair = () => {
    Logout()
  }
  const Trocar = () => {
    TrocarPerfil()
  }

  async function alertLaunch() {
    handleLaunch(false)
    //enqueueSnackbar(<MessageAlert message={'O tempo limite excedeu, a partir de agora seu intervalo será registrado automaticamente.'} />, WARNINGMESSAGE)
  }

  const handleLaunch = (value) => {
    setShowModalAlertLaunch(value)
  }

  //<HandleTheme toggleTheme={toggleTheme} />
  return (
    <Container>
      {
        show && <Backdrop onClick={() => setShow(false)} />
      }

      <WrapLogo href="www.globo.">
        <IconeMenu onClick={() => myHandleMenu()} color="white" size={20} />
        <a href={site} >
          <Logo img={logo} />
        </a>
      </WrapLogo>

      <WrapInfos>

        <UserInfo>Olá, {user}</UserInfo>
        {
          /*<Tooltip title="Alterar tema entre Light ou Dark" placement="bottom-end" arrow>
               <div>
                   <HandleTheme toggleTheme={toggleTheme} />
               </div>
           </Tooltip>*/
        }

        <Tooltip title="Sair da aplicação" placement="bottom-end" arrow>
          <ButtonLogout
            onClick={() => handleLogout()}
          >
            <FaPowerOff size={25} color="#ddd" />

          </ButtonLogout>
        </Tooltip>
        {
          showChangeProfile && show && <WrapMessages>
            <Messages>
              <Item onClick={() => Trocar()} >Trocar Perfil</Item>
              <Item onClick={() => Sair()}>Sair</Item>
            </Messages>
          </WrapMessages>
        }
        {
          showModalAlertLaunch &&
          <Lockscreen>
            <AlertLaunch>
              <Title>&nbsp;&nbsp;&nbsp;&nbsp;Atenção</Title>
              <BodyLaunch>
                <TitleSpan3> {title} </TitleSpan3>
                <WrapButtons>
                  <ButtonLaunch onClick={() => alertLaunch()} bg="#D12020" >Fechar</ButtonLaunch>
                </WrapButtons>
              </BodyLaunch>
            </AlertLaunch>
          </Lockscreen>
        }
      </WrapInfos>
    </Container>
  )
}
