import React, { useState, useContext, useEffect } from 'react'
import {
    Title2,
    Button,
    Icone
} from './styles.js'

import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components'
import { useMenu } from 'contexts/MenuContext'


export default function ButtonMenu({ path, title, hideBg, isMenuClose, icone, radius }) {
    const [showBorder, setShowBorder] = useState(false)
    const {
        borderButtonMenu,
        bgButtonMenu,
    } = useContext(ThemeContext)
    let history = useHistory();
    const { handleMenu } = useMenu()
    const handleClick = () => {
        let myPath = history.location.pathname.split('/')[1]
        history.replace(path)
        if (myPath === path) {
            setShowBorder(true)
        }
        handleMenu(false)
    }
    useEffect(() => {
        let myPath = history.location.pathname.split('/')[1]
        if (myPath === path) {
            setShowBorder(true)
        } else {
            setShowBorder(false)
        }
    }, [history.location.pathname])
    return (
        <Button
            radius={radius}
            border={showBorder && `5px ${borderButtonMenu}  solid`}
           onClick={() => handleClick()}
            active={showBorder ? (hideBg ? 'none' : bgButtonMenu) : 'none'}>
            <Icone>{icone}</Icone>
            {
                !isMenuClose && <Title2>{title}</Title2>
            }
        </Button>
    )
}
