import React, { useContext, useState, useEffect } from 'react'
import {
  Container,
  Button2,
  List,
  Container2,
  Title,
  ListBody,
  WrapFooter,
  Button,
  WrapSearch,
  Wrap,
  Header,
  WrapHora,
  Title2,
  Backdrop,
  ModalFechamento,
  BodyTrocarProjeto,
  WrapButtons
} from './styles.js'
import {
  WrapTotalHoras2,
  Horas,
  TitleHora,
  Hora,
  TotalHoras2,
} from '../../components/CommonInfos/styles'

import { Input2 } from '../../components/TableCadastroServico/styles'
import { LabelInput2 } from '../../pages/RegistroHoras/styles'
import { shade } from 'polished'
import MenuItem from '@material-ui/core/MenuItem';
import { WrapInput } from "../../components/Wrap"
import { LabelInput, Select, Input } from 'components/Input'
import TableFechamento from 'components/TableFechamento'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, Modal, CircularProgress } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useUserResume, useShowModal } from 'contexts/SharedContext'
import { ThemeContext } from 'styled-components'
import { ptBR } from 'date-fns/locale'
import Tooltip from '@material-ui/core/Tooltip';
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { GetSessionUser } from 'services/helpers'
import ModalIncluirRegistro from 'components/ModalIncluirRegistro'
import MessageAlert from 'components/MessageAlert'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Button as ButtonLimpar } from "../../components/Button";
import { IconeSearch, ItemsSearch } from "../../components/Search";
import UpLoad from 'components/Upload';

export default function AberturaHoras(props) {
  const { colors, bgHeading } = useContext(ThemeContext)
  const { enqueueSnackbar } = useSnackbar()
  const { setUserResumeFechamento } = useUserResume()
  const { showModalIncluirRegistro } = useShowModal()
  const [team, setTeams] = useState([]);
  const [teamSelected, setTeamSelected] = useState()
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradorSelected, setColaboradorSelected] = useState(null)
  const [showModalAbrirPeriodo, setshowModalAbrirPeriodo] = useState(false)
  const [hora, setHora] = useState('');
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFechamentoInit, setSelectedDateFechamentoInit] = React.useState(null);
  const [selectedDateFechamentoFim, setSelectedDateFechamentoFim] = React.useState(null);
  const [dataSelected, setDataSelected] = useState(new Date())
  const [dataInitSelected, setDataInitSelected] = useState(null)
  const [dataFimSelected, setDataFimSelected] = useState(null)
  const [buttonDisable, setButtonDisable] = useState(true);
  const [controle, setControle] = useState(false)
  const [loadDialog, setLoadDialog] = useState(false);
  const [dtInicio, setDtInicio] = useState()
  const [dtFim, setDtFim] = useState()
  const [regDateUpload, setRegDateUpload] = useState('');
  const [diaUpload, setDiaUpload] = useState('');
  const [personId, SetPersonId] = useState();
  const [modalArquivos, setModalArquivos] = useState(false);

  const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 35
  });
  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue["100"],
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });

  async function getColaboradores(teamId) {
    try {
      setColaboradores([]);
      const { data } = await api.get('/team/getPerson/' + teamId + '/0')
      if (data.success)
        setColaboradores(data.result)
    } catch (error) {
      enqueueSnackbar('Erro ao buscar colaboradores', ERRORMESSAGE)
    }
  }

  async function getTeams() {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await await api.get('/team?companyId=' + perfil.companyId)
      if (data.success)
        setTeams(data.result)
    } catch (error) {
      enqueueSnackbar('Erro ao buscar equipes', ERRORMESSAGE)
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    const newDate = new Date(date)
    let mes = newDate.getMonth() + 1
    const ano = newDate.getFullYear()
    if (parseInt(mes) < 10) {
      mes = '0' + mes.toString()
    }
    setDataSelected(ano + '-' + mes)
    setUserResumeFechamento([]);
    setHora('');
    setButtonDisable(true);
  }

  const handleDateFim = (date) => {
    setSelectedDateFechamentoFim(date)
    const newDate = new Date(date)
    let mes = newDate.getMonth() + 1
    let ano = newDate.getFullYear()
    let dia = newDate.getDate()
    if (parseInt(mes) < 10) {
      mes = '0' + mes.toString()
    }
    if (parseInt(dia) < 10) {
      dia = '0' + dia.toString()
    }
    setDataFimSelected(`${ano}-${mes}-${dia}`)
  }

  const handleDateInit = (date) => {
    setSelectedDateFechamentoInit(date)
    const newDate = new Date(date)
    let mes = newDate.getMonth() + 1
    let ano = newDate.getFullYear()
    let dia = newDate.getDate()
    if (parseInt(mes) < 10) {
      mes = '0' + mes.toString()
    }
    if (parseInt(dia) < 10) {
      dia = '0' + dia.toString()
    }
    setDataInitSelected(`${ano}-${mes}-${dia}`)
  }

  async function handleTeams(item) {
    setTeamSelected(item)
    setColaboradorSelected(null);
    setUserResumeFechamento([]);
    setHora('');
    setButtonDisable(true);
    var teamId = 0;
    if (item !== null) teamId = item.value;
    getColaboradores(teamId)
  }

  async function handleColaboradores(items) {
    setColaboradorSelected(items)
    setUserResumeFechamento([]);
    setHora('');
    setButtonDisable(true);
  }

  const fetchData = () => {
    if (colaboradorSelected === null) {
      enqueueSnackbar(<MessageAlert message='Selecione o colaborador' />, WARNINGMESSAGE)
      return
    }
    if (selectedDate === null) {
      enqueueSnackbar(<MessageAlert message='Selecione mês e ano' />, WARNINGMESSAGE)
      return
    }
    getInit()
  };

  useEffect(() => {
    handleDateChange(new Date())
    getTeams()
    setColaboradorSelected(null);
    getColaboradores(0);
    setUserResumeFechamento(null);
  }, [])

  useEffect(() => {
  }, [dataSelected])

  const getInit = async () => {
    try {
      setLoadDialog(true);
      SetPersonId(colaboradorSelected.value);
      const { data } = await api.get('/registeritem/resumo/' + colaboradorSelected.value + '/' + dataSelected.toString())
      if (data.success) {
        const { result } = data
        setHora(result.totalMes)
        setUserResumeFechamento(result.dias)
        setDtInicio(result.dias[0].dtIniAux)
        setDtFim(result.dias[0].dtFimAux)
        setButtonDisable(false);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  function exibeModal() {
    if (!colaboradorSelected) {
      enqueueSnackbar(<MessageAlert message='Selecione o colaborador' />, WARNINGMESSAGE)
      return
    }
    setshowModalAbrirPeriodo(true)
    setSelectedDateFechamentoInit(`${dtInicio}`);
    setSelectedDateFechamentoFim(`${dtFim}`);
    handleDateInit(new Date(`${dtInicio}`))
    handleDateFim(new Date(`${dtFim}`))
  }

  async function fechamento() {
    if (dataInitSelected === null || dataFimSelected === null) {
      enqueueSnackbar(<MessageAlert message='Informe o período.' />, WARNINGMESSAGE)
    } else {
      if (!controle) {
        setControle(true);
        await sendFormFechamento();
        setControle(false);
      }
    }
  }

  async function sendFormFechamento() {
    try {
      setLoadDialog(true);
      const obj = {
        fechar: false,
        personId: colaboradorSelected.value,
        dtInicial: dataInitSelected,
        dtFinal: dataFimSelected,
        enviarPdf: false
      }
      const { data } = await api.put('/registeritem/fecharPeriodo', obj)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        setLoadDialog(false);
        getInit()
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
      setshowModalAbrirPeriodo(false)
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  async function handleUpload(event, dia, regDate, update) {
    setModalArquivos(event)

    if (event) {
      setRegDateUpload(regDate);
      setDiaUpload(dia);
    } else {
      if (update) {
        getInit();
      }
    }
  }

  useEffect(() => {

  }, [])

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput style={{ alignItems: 'center' }} className="alertText">
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Aguarde!</h3>
      </WrapInput>
    </div>
  );

  function clearSearch() {
    setTeamSelected('');
    setColaboradorSelected(null);
    getColaboradores(0);
    const newDate = new Date()
    setSelectedDate(newDate);
    let mes = newDate.getMonth() + 1
    const ano = newDate.getFullYear()
    if (parseInt(mes) < 10) {
      mes = '0' + mes.toString()
    }
    setDataSelected(ano + '-' + mes);
    setUserResumeFechamento([]);
    setHora('');
    setButtonDisable(true);
  }

  return (
    <>
      <Container2>
        <big color={colors.textPrimary}>Página Disponível apenas na versão Desktop.</big>
      </Container2>
      <Container>
        <Header>
          <ThemeProvider theme={materialTheme} style={{ width: '100%' }}>
            <Wrap style={{ width: '72%' }}>
              <Title2>&nbsp;&nbsp;&nbsp; FILTROS</Title2>
              <WrapSearch>
                <WrapInput style={{ marginRight: "10px" }}>
                  <LabelInput2>Equipe</LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Selecione"
                    label="Teste"
                    isClearable
                    maxMenuHeight={190}
                    isSearchable
                    value={teamSelected}
                    onChange={handleTeams}
                    options={team.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput style={{ marginRight: "10px", width: '30%' }}>
                  <LabelInput2>Colaborador</LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Selecione"
                    label="Teste"
                    isClearable
                    maxMenuHeight={190}
                    isSearchable
                    value={colaboradorSelected}
                    onChange={handleColaboradores}
                    options={colaboradores.map(item => { return { value: item.PersonId, label: item.personName } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils} >
                  <div style={{ display: 'flex', width: '15%', backgroundColor: '' }} >
                    <KeyboardDatePicker
                      minDateMessage="Não é possível buscar por datas futuras."
                      views={["month", "year"]}
                      clearable
                      clearLabel="Limpar"
                      cancelLabel="Cancelar"
                      animateYearScrolling
                      margin="normal"
                      placeholder="mês/ano"
                      id="date-picker-dialog"
                      label="Mês Referência"
                      format="MM/yyyy"
                      helperText={''}
                      value={selectedDate}
                      onChange={handleDateChange}
                      minDate={new Date(new Date("01-01-2018"))}
                      maxDate={new Date(new Date())}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <WrapInput style={{ width: '10%', marginTop: '10px' }}>
                  <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchData()} />
                    <ButtonLimpar className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></ButtonLimpar>
                  </ItemsSearch>
                </WrapInput>
              </WrapSearch>
            </Wrap>
          </ThemeProvider>

          <WrapTotalHoras2>
            <Title2>&nbsp;&nbsp;&nbsp;TOTAL HORAS MÊS</Title2>
            <TotalHoras2>
              <Horas>
                <Hora> {hora ? hora + 'hs' : '--'}</Hora>
                <TitleHora>trabalhadas</TitleHora>
              </Horas>
            </TotalHoras2>
          </WrapTotalHoras2>
        </Header>

        <List>
          <Title>
            &nbsp;&nbsp;&nbsp;REGISTROS DE HORAS {/*&nbsp; - &nbsp;{hora} */}
          </Title>
          <ListBody>
            <TableFechamento getInit={getInit} esconderEditar={true} handleUpload={handleUpload} />
          </ListBody>
        </List>
        <WrapFooter>
          {!buttonDisable &&
            <Button onClick={buttonDisable !== true ? () => exibeModal() : ''} color="#ddd" marginR="">Abrir Período</Button>
          }
        </WrapFooter>
        {
          showModalIncluirRegistro && showModalIncluirRegistro.show && <ModalIncluirRegistro regData={showModalIncluirRegistro.regData} />
        }
        {
          showModalAbrirPeriodo && <Backdrop onClick={() => setshowModalAbrirPeriodo(false)} />
        }
        {
          showModalAbrirPeriodo &&
          <ModalFechamento>
            <Title>&nbsp;&nbsp;&nbsp;&nbsp;ABRIR PERÍODO COMPREENDIDO ENTRE</Title>
            <BodyTrocarProjeto>
              <WrapHora>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      minDateMessage="Não é possível buscar por datas futuras."
                      helperText={''}
                      cancelLabel="cancelar"
                      margin="normal"
                      clearLabel="Limpar"
                      clearable
                      id="dateInitial"
                      label="Data Início"
                      format="dd/MM/yyyy"
                      value={selectedDateFechamentoInit}
                      onChange={handleDateInit}
                      minDate={new Date(new Date("01-01-2018"))}
                      maxDate={new Date(new Date())}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      minDateMessage="Não é possível buscar por datas futuras."
                      helperText={''}
                      cancelLabel="cancelar"
                      margin="normal"
                      clearLabel="Limpar"
                      clearable
                      id="dateFinal"
                      label="Data Fim"
                      format="dd/MM/yyyy"
                      value={selectedDateFechamentoFim}
                      onChange={handleDateFim}
                      minDate={new Date(new Date("01-01-2018"))}
                      maxDate={new Date(new Date())}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </WrapHora>
              <WrapButtons>
                <Button2 onClick={() => setshowModalAbrirPeriodo(false)} bg="#D12020" >Cancelar</Button2>
                <Button2 onClick={() => fechamento()} className={controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button2>
              </WrapButtons>
            </BodyTrocarProjeto>
          </ModalFechamento>
        }
        <Modal open={modalArquivos}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <UpLoad personId={personId} dia={diaUpload} regDate={regDateUpload} handleCloseUpload={handleUpload} device={false} btEnviar={false} />
        </Modal>
        <Modal open={loadDialog}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalLoad}
        </Modal>
      </Container >
    </>
  )
}
