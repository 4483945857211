import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.bgNavbar};
height: 49px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin: 0;
padding: 0;
`;


export const NumberAlert = styled.div`
border-radius: 100%;
background-color: #00BAC6;
color: white;
font-weight: 500;
padding: 7px;
display: flex;
justify-content: center;
align-items: center;
height: 5px;
width: 4px;
transform: ${props => props.myScale};
transition: transform 1s;
z-index: 200;
position: absolute;
margin-left: 10px;
top: 15px;
`;


export const WrapEmail = styled.div`
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 100%;
&:hover{  
    background-color: #03324c;
}

`;

export const Backdrop = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 200;
@media (max-width: 800px){
   background-color: rgba(0, 0, 0, 0.2);
}
`;

export const Messages = styled.div`
color: gray;
height: 100%;
width: 100%;
background-color: ${props => props.theme.bgHeading};
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border-radius: 5px;
`;

export const Item = styled.div`
cursor: pointer;
color: white;
font-weight: 600;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 25px;
border: 1px rgba(0, 0, 0, 0.2) solid;
height: 100%;
`;

export const WrapMessages = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 200px;
height: 75px;
z-index: 400;
position: absolute;
top: 61px;
right: 40px;
border-radius: 5px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
@media (max-width: 800px){
   top: 50%;
   bottom: 0;
   right: 50%;
   left: 32%;
   width: 300px;
   position: fixed;
   height: 100px;
}
@media (max-width: 720px){
   right: 50%;
   left: 28%;
}
@media (max-width: 630px){
   right: 50%;
   left: 24%;
}
@media (max-width: 520px){
   right: 50%;
   left: 20%;
}
@media (max-width: 420px){
   right: 50%;
   left: 14%;
}
@media (max-width: 390px){
   right: 50%;
   left: 9%;
}
@media (max-width: 350px){
   right: 50%;
   left: 5%;
}
`;

export const Logo = styled.div`
display:flex;
width: 180px;
height: 33.7px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
@media (max-width: 1080px){
display: none;
}
@media (max-width: 800px){
display: flex;
width: 150px;
height: 28px;
}
`;

export const WrapLogo = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: 20%;
padding-left: 15px;
@media (max-width: 750px){
    width: 50%;
}
`;

export const UserInfo = styled.div`
height: 100%;
color: white;
font-weight: 500;
@media (max-width: 510px){
display:none;
}
`;

export const IconeMenu = styled(FaBars)`
display: none;
&:hover{
    opacity: 0.6;
}
@media (max-width: 1080px){
display: flex;
}
@media (max-width: 800px){
display: none;
}
`;



export const Lockscreen = styled.div`
position:fixed;
z-index:999;
cursor:forbidden;
width:100%;
height:200%;
left: 0;
background-color:rgba(0,0,0,0.9);
color:white;

`;

export const WrapInfos = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
width: 250px;
@media (max-width: 1080px){
width: 230px;
}
@media (max-width: 620px){
width: 230px;
}

@media (max-width: 513px){
width: 40%;
}
@media (max-width: 490px){
width: 25%;
}
`;


export const ButtonLogout = styled.div`
width: 40px;
cursor:pointer;
&:hover{
opacity: 0.8;
}
`;
