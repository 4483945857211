import React, { useState, useEffect, useContext } from 'react'
import {
  ContainerMobile,
  Title,
  WrapInput,
  LabelInput,
  LabelInput2,
  Input,
  InputText,
  WrapButtons,
  WrapHora,
  Button2,
  RegistroManual,
  BodyRegistroManual,
  Input2,
  WrapChecks,
  WrapInput2,
  WrapInput3,
} from './styles.js'
import { shade } from 'polished'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { IoMdClock } from 'react-icons/io'
import { ThemeContext } from 'styled-components'
import { useUserResume } from 'contexts/SharedContext'
import { GetSessionUser } from 'services/helpers'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import MessageAlert from 'components/MessageAlert'
import { FaRegAddressCard } from 'react-icons/fa';
import { isRegularExpressionLiteral } from 'typescript';

export default function RegistroHoras({ operacoesRegistro, device, setRegManual }) {
  const [hourSelected, setHourSelected] = useState(null)
  const [hourToSend, setHourToSend] = useState('')
  const [locais, setLocais] = useState([])
  const [servicos, setServicos] = useState([])
  const [atividades, setAtividades] = useState([])
  const { setUserResume, setShowModalRegistroManual } = useUserResume()
  const [locaisSelected, setLocaisSelected] = useState()
  const [servicoSelected, setServicoSelected] = useState()
  const [justificativas, setJustificativas] = useState([])
  const [observacaoText, setObservacaoText] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [atividadesSelected, setAtividadesSelected] = useState(null)
  const [justificativasSelected, setJustificativasSelected] = useState(null)
  const [operacaoRegistroSelected, setOperecaoRegistroSelected] = useState()
  const { colors, bgHeading } = useContext(ThemeContext)
  const [controle, setControle] = useState(false)
  const handleRegistroManual = (value) => {
    setShowModalRegistroManual(value)
    setRegManual(value)
  }



  const getAtividades = async () => {
    
    try {
      const { data } = await api.get('/job/getByService/' + servicoSelected.value)
      if (data.success) {
        const { result } = data
        let temp = []
        await result.map(item => {
          temp.push({
            id: item.Job.id,
            name: item.Job.name,
          })
        })
        setAtividades(temp)
      } else {
        setAtividades([]);
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }

    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }

  }

  useEffect(() => {
    if (servicoSelected) {
      getAtividades()
    }
  }, [servicoSelected])


  const getLocais = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/place/getByCompany/' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setLocais(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getJustificativas = async () => {
    try {
      const { data } = await api.get('/justification/0/?companyId=1')
      if (data.success) {
        const { result } = data
        setJustificativas(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getServicos = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/service/getByPerson/' + perfil.personId)
      if (data.success) {
        const { result } = data
        let temp = []
        await result.map(item => {
          temp.push({
            id: item.id,
            name: item.clientName + " - " + item.name
          })
        })
        setServicos(temp)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getInit = async (message) => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/register/resumo/' + perfil.personId)
      if (data.success) {
        const { result } = data
        setUserResume(result)
        if (result.serviceId === 0) {
          setServicoSelected();
          setLocaisSelected();
          setAtividadesSelected();
        } else {
          setServicoSelected({ value: result.serviceId, label: result.serviceName })
          setLocaisSelected({ value: result.placeId, label: result.placeName })
          setAtividadesSelected({ value: result.jobId, label: result.jobName })
        }
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  useEffect(() => {
    getServicos();
    getLocais()
    getJustificativas()
    getInit()
  }, [])

  async function handlerManualPreventDblClick() {
    if (!controle) {
      setControle(true);
      await sendFormRegistroManual();
      setControle(false);
    }
  }

  const handleServico = (item) => {
    setServicoSelected(item)
    setAtividadesSelected(null)
  }

  const handleAtividades = (item) => {
    setAtividadesSelected(item)
  }

  const handleJustificativas = (item) => {
    setJustificativasSelected(item)
  }

  const handleLocais = (item) => {
    setLocaisSelected(item)
  }


  const handleOperacaoRegistro = (item) => {
    setOperecaoRegistroSelected(item)
  }


  const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 35
  });


  const handleHourSelected = (e) => {
    setHourSelected(e)
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setHourToSend(hora + ':' + minutos)
  }

  async function sendFormRegistroManual() {
    if (!hourSelected) {
      enqueueSnackbar('Campo Hora obrigatorio.', WARNINGMESSAGE)
    }
    else if (!operacaoRegistroSelected) {
      enqueueSnackbar('Selecione o tipo de operação que deseja realizar.', WARNINGMESSAGE)
    }
    else if (hourSelected + '' === 'Invalid Date') {
      enqueueSnackbar('Hora com formato inválido.', WARNINGMESSAGE)
    }
    else if (!servicoSelected && (operacaoRegistroSelected.value === 'entrar' || operacaoRegistroSelected.value === 'trocar')) {
      enqueueSnackbar('Serviço obrigatório.', WARNINGMESSAGE)
    }
    else if (!atividadesSelected && (operacaoRegistroSelected.value === 'entrar' ||
      operacaoRegistroSelected.value === 'trocar' ||
      operacaoRegistroSelected.value === 'intervalo')) {
      enqueueSnackbar('Atividade obrigatório.', WARNINGMESSAGE)
    }
    else if (!locaisSelected && (operacaoRegistroSelected.value === 'entrar' || operacaoRegistroSelected.value === 'trocar')) {
      enqueueSnackbar('Local obrigatório.', WARNINGMESSAGE)
    }
    else if (!justificativasSelected) {
      enqueueSnackbar('Campo Justificativa obrigatorio', WARNINGMESSAGE)
    } else {
      const { perfil } = GetSessionUser()
      try {
        const obj = {
          personId: perfil.personId,
          justificationId: justificativasSelected.value,
          noteRegister: observacaoText,
          hour: hourToSend
        }
        if (operacaoRegistroSelected.value === 'entrar') {
          obj.serviceId = servicoSelected.value
          obj.placeId = locaisSelected.value
          obj.jobId = atividadesSelected.value
          var { data } = await api.post("/register/entrar", obj)
        }
        if (operacaoRegistroSelected.value === 'sair') {
          var { data } = await api.post("/register/sair", obj)
        }
        if (operacaoRegistroSelected.value === 'trocar') {
          obj.operacao = 'trocar'
          obj.serviceId = servicoSelected.value
          obj.placeId = locaisSelected.value
          obj.jobId = atividadesSelected.value
          var { data } = await api.post("/register/trocar", obj)
        }
        if (operacaoRegistroSelected.value === 'intervalo') {
          obj.operacao = 'intervalo'
          var { data } = await api.post("/register/trocar", obj)
        }
        handleRegistroManual(false)
        if (data.success) {
          enqueueSnackbar(<MessageAlert message={'Registro efetuado com sucesso.'} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')
          //handleRegistroManual(false)
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
          handleRegistroManual(false)
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
        handleRegistroManual(false)
      }

    }
  }


  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue["100"],
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });
  return (
    <>
      {!device &&
        <RegistroManual>

          <Title> &nbsp;&nbsp;&nbsp;&nbsp;REGISTRO MANUAL</Title>

          <BodyRegistroManual>
            <WrapChecks>
              <WrapInput2>

                <WrapHora>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        autoFocus={true}

                        margin="normal"
                        id="time-picker"
                        helperText={''}
                        label={'Hora'}
                        // error={false}
                        value={hourSelected}
                        onChange={e => handleHourSelected(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </WrapHora>
              </WrapInput2>
              <WrapInput3>
                <LabelInput2>
                  Operação
                        </LabelInput2>
                <Input2
                  styles={{ control: customControlStyles }}
                  placeholder="Qual operação deseja realizar?"
                  isClearable

                  value={operacaoRegistroSelected}
                  onChange={handleOperacaoRegistro}
                  options={operacoesRegistro.map(item => { return { value: item.value, label: item.label } })}
                  border={`0.5px ${shade(0.2, colors.surface)} solid`} />
              </WrapInput3>

            </WrapChecks>


            {

              operacaoRegistroSelected && operacaoRegistroSelected.value !== 'sair' && operacaoRegistroSelected.value !== 'intervalo' &&
              <>
                <WrapInput>
                  <LabelInput2>
                    Serviço
                         </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Serviços"
                    isClearable
                    value={servicoSelected}
                    onChange={handleServico}
                    options={servicos.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>

                <WrapInput>
                  <LabelInput2>
                    Atividade
                                </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Atividades"
                    isClearable

                    value={atividadesSelected}
                    onChange={handleAtividades}
                    options={atividades.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>


                <WrapInput>
                  <LabelInput>
                    Local
                                </LabelInput>
                  <Input
                    styles={{ control: customControlStyles }}
                    placeholder="Locais"
                    isClearable

                    value={locaisSelected}
                    onChange={handleLocais}
                    options={locais.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />

                </WrapInput>
              </>

            }



            {
              operacaoRegistroSelected && <>

                <WrapInput>
                  <LabelInput2>
                    Justificativa
                            </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Justificativas"
                    isClearable

                    value={justificativasSelected}
                    onChange={handleJustificativas}
                    options={justificativas.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>
                    Observação
                                </LabelInput>
                  <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
                </WrapInput>
              </>
            }


            <WrapButtons>
              <Button2 onClick={() => handleRegistroManual(false)} bg="#D12020" >Cancelar</Button2>
              <Button2 onClick={handlerManualPreventDblClick} className={controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button2>
            </WrapButtons>

          </BodyRegistroManual>
        </RegistroManual>
      }
      { device &&
        <ContainerMobile>

          <Title> &nbsp;&nbsp;&nbsp;&nbsp;REGISTRO MANUAL</Title>

          <BodyRegistroManual>
            <WrapChecks>
              <WrapInput2>

                <WrapHora>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        autoFocus={true}

                        margin="normal"
                        id="time-picker"
                        helperText={''}
                        label={'Hora'}
                        // error={false}
                        value={hourSelected}
                        onChange={e => handleHourSelected(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </WrapHora>
              </WrapInput2>
              <WrapInput3>
                <LabelInput2>
                  Operação
                        </LabelInput2>
                <Input2
                  styles={{ control: customControlStyles }}
                  placeholder="Qual operação deseja realizar?"
                  isClearable

                  value={operacaoRegistroSelected}
                  onChange={handleOperacaoRegistro}
                  options={operacoesRegistro.map(item => { return { value: item.value, label: item.label } })}
                  border={`0.5px ${shade(0.2, colors.surface)} solid`} />
              </WrapInput3>

            </WrapChecks>


            {

              operacaoRegistroSelected && operacaoRegistroSelected.value !== 'sair' && operacaoRegistroSelected.value !== 'intervalo' &&
              <>
                <WrapInput>
                  <LabelInput2>
                    Serviço
                         </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Serviços"
                    isClearable

                    value={servicoSelected}
                    onChange={handleServico}
                    options={servicos.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>

                <WrapInput>
                  <LabelInput2>
                    Atividade
                                </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Atividades"
                    isClearable

                    value={atividadesSelected}
                    onChange={handleAtividades}
                    options={atividades.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>


                <WrapInput>
                  <LabelInput>
                    Local
                                </LabelInput>
                  <Input
                    styles={{ control: customControlStyles }}
                    placeholder="Locais"
                    isClearable

                    value={locaisSelected}
                    onChange={handleLocais}
                    options={locais.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />

                </WrapInput>
              </>

            }



            {
              operacaoRegistroSelected && <>

                <WrapInput>
                  <LabelInput2>
                    Justificativa
                            </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Justificativas"
                    isClearable

                    value={justificativasSelected}
                    onChange={handleJustificativas}
                    options={justificativas.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>
                    Observação
                                </LabelInput>
                  <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
                </WrapInput>
              </>
            }


            <WrapButtons>
              <Button2 onClick={() => handleRegistroManual(false)} bg="#D12020" >Cancelar</Button2>
              <Button2 onClick={handlerManualPreventDblClick} className={controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button2>
            </WrapButtons>

          </BodyRegistroManual>

        </ContainerMobile>
      }
    </>
  )
}
