import { apiNoAuth, keySession, keyUserAccess } from './axios'
import { format, getHours, getMinutes, getSeconds, startOfMonth, endOfMonth, subMonths, getDate } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import {isSafari} from 'react-device-detect';

export async function LoginUser(password, email) {

  if (!email || !password)
    return { message: 'Preencha todos os campos.', success: false }
  else
    try {
      let { data } = await apiNoAuth.post('/login', { email, password })
      if (data.success) {
        deleteCookie("exibiuModalAlmoco");
        deleteCookie("pararExibicaoModalAlmoco")
        const { result } = data
        const { token, usuario, menu, perfil, changePassword, startLunch, limitLunch, diaIniMes } = result
        await localStorage.setItem(keySession, JSON.stringify({ token, usuario, menu, perfil, changePassword, startLunch, limitLunch, diaIniMes }))
        if (changePassword) {
          window.location = '/nova-senha'
        } else
          return { message: data.message, success: true }
      } else
        return { message: data.message, success: false }
    } catch (error) {
      // alert(JSON.stringify(error))
      console.log(error)
      return error
    }
}

export const MyData = (date) => {
  let dia = format(date, "iiii", { locale: ptBR })
  if (dia === 'segunda' || dia === 'terça' || dia === 'quarta' || dia === 'quinta' || dia === 'sexta') {
    dia = dia + '-' + 'feira'
  }
  let data = format(date, 'dd/MM/yyyy', { locale: ptBR }) + ' ';
  let regDate = format(date, 'yyyy-MM-dd', { locale: ptBR }) + ' ';
  let hora = getHours(date)
  if (parseInt(hora) < 10) {
    hora = '0' + hora
  }
  let minutos = getMinutes(date)
  if (parseInt(minutos) < 10) {
    minutos = '0' + minutos
  }

  let segundos = getSeconds(date)
  if (parseInt(segundos) < 10) {
    segundos = '0' + segundos
  }

  let horas = hora + ":" + minutos
  let horas2 = hora + ":" + minutos + ":" + segundos
  return { dia: dia.toUpperCase(), data: data, horas: horas, minutos, segundos, horas2, regDate }
}

export const GetSessionUser = () => {
  try {
    return JSON.parse(localStorage.getItem(keySession))
  } catch (error) {
    console.log(error)
    return { errorMessage: 'Não foi possivel ler a sessão' }
  }
}

export const setCookieHora = (cname, cvalue, hora) => {
  let dataTmp = new Date();
  let horaMinuto = hora.split(':')
  let data = new Date(dataTmp.getFullYear(), dataTmp.getMonth(), dataTmp.getDate(), horaMinuto[0], horaMinuto[1], 0);
  //date.setMinutes(date.getMinutes() + hora);
  let expires = "expires=" + data.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const setCookie = (cname, cvalue, minutos) => {
  let date = new Date();
  date.setMinutes(date.getMinutes() + minutos);
  let expires = "expires=" + date.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const deleteCookie = (cname) => {
  let date = new Date();
  let expires = "expires=" + date.toUTCString();
  document.cookie = cname + "=" + "" + ";" + expires + ";path=/"
}

export function Logout() {
  try {
    localStorage.removeItem(keySession)
    localStorage.removeItem(keyUserAccess)
    window.location = "/login"
    return true
  } catch (error) {
    window.location = "/login"
    console.log(error)
    return true
  }
}


export function TrocarPerfil() {
  try {
    localStorage.removeItem(keyUserAccess)
    window.location = "/selecionar-perfil"
    return true
  } catch (error) {
    window.location = "/selecionar-perfil"
    console.log(error)
    return true
  }
}

export const isAuthenticated = () => {
  if (localStorage.getItem(keySession)) {
    return true
  } else {
    return false
  }

}

export const GetUserAccess = () => {
  if (localStorage.getItem(keyUserAccess)) {
    return JSON.parse(localStorage.getItem(keyUserAccess))
  }
  return false
}

export const SomaMinutos = (horaString, valor) => {
  let dataTmp = new Date();
  let horaMinuto = horaString.split(':')
  let data = new Date(dataTmp.getFullYear(), dataTmp.getMonth(), dataTmp.getDate(), horaMinuto[0], horaMinuto[1], 0);
  data.setMinutes(data.getMinutes() + valor)
  if (data.getMinutes() == 0) {
    return data.getHours() + ":" + data.getMinutes() + "0";
  }
  return data.getHours() + ":" + data.getMinutes()
}

export const getDevice = () => {
  
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    || isSafari
  ) {
    return true; // está utilizando celular
  }
  else {
    return false; // não é celular
  }
}

/** Mês/Ano dtIncial dtFinal */
export const getMonthYear = () => {
  const { diaIniMes } = GetSessionUser()
  var months = [];
  var dt = new Date();
  var dia = getDate(dt);
  var diaParam = parseInt(diaIniMes, 10) + 1;
  var ind = 0;
  if (dia < diaParam) ind = 1;
  for (var i = 0; i < 12; i++) {
    var mesAno = subMonths(dt, i);
    months.push(
      {
        "id": i,
        "ind": ind,
        "monthYear": format(mesAno, "MMM-yyyy", { locale: ptBR }),
        "dtInicial": startOfMonth(mesAno),
        "dtFinal": i === 0 ? dt : endOfMonth(mesAno)
      }
    )
  }
  return months;
}

/*
const result = {
    success: true,
    "token": "d516dd8ae3bfbf56303fab5511a3bc28b7e2971f",
    "usuario": "cristianogb182@gmail.com",
    "acesso": [{
            "modulo": "Cadastro",
            "access": [
                { "name": "Client", "url": "/client" },
                { "name": "Carro", "url": "/carro" },
                { "name": "Contrato", "url": "/contrato" },
                { "name": "Company", "url": "/company" },
                { "name": "Module", "url": "/module" },
                { "name": "Person", "url": "/person" },
                { "name": "Team", "url": "/team" },
                { "name": "User", "url": "/user" },
                { "name": "Consolidado", "url": "/consolidado" },
            ]
        },
        {
            "modulo": "Colaborador",
            "access": [
                { "name": "Fechamento de Horas", "url": "/fechamento-de-horas" }, { "name": "Registro de Horas", "url": "/registro-de-horas" }
            ]
        },
        {
            "modulo": "Relatórios",
            "access": [{ "name": "Relatório Mensal", "url": "/relatorio-mensal" }, ]
        },
    ],
    "perfil": { "userId": 51, "personId": 1, "name": "João", "familyName": "Oliveira", "sex": "M", "urlAvatar": { "type": "Buffer", "data": [] } },
    "changePassword": false
}*/