import styled from 'styled-components'
import { FaSearch, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Select from 'react-select'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
display:flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const Card = styled.div`
width: 200px;
background-color: ${props => props.theme.colors.surface} ;
height: 200px;
`;


export const MySelect = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;


export const SearchBox = styled.div `
background-color: ${props => props.theme.colors.surface};
margin:20px 15px;
padding:0;
border-radius:7px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width:93.7%;
`;

export const TitleStyle2 = styled.h2 `
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;
`;



export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 16,
        margin: 0,
        //backgroundColor: state.isSelected ? "#eee" : "",
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,

        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderColor: 'gray',
        height: 30,
        minHeight: 30,
        fontSize: 16,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};

export const inputStyle = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '94%',
    fontSize: 14
    
};

export const inputStyle2 = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '100%',
};


export const Wrap = styled.div `
display: flex;
justify-content: space-around;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
width: 98%;
margin: 0 auto;
padding-top: 20px;
@media (max-width: 800px){
    flex-direction: column;
    align-items: center;

}
`;

export const WrapDiv = styled.div `
display: flex;
justify-content: space-around;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
padding-top: 20px;
`;

export const WrapButton = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 47px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapButtonModal = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 5px;
margin-right: 1px;
margin-top: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapInput = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 15%;
margin-left: 1px;
flex-grow: 1;
margin-right: 1px;
margin-bottom: 10px;
box-sizing:border-box;
padding: 0 5px;

@media (max-width: 800px){
    width: 60%;
    margin:0;
    padding:0
}
`;

export const BoxShadow = styled.input`
width: 100%;
height: 25px;
background-color: ${props => props.theme.colors.surface};
border: 1px ${props => props.theme.borderInputColor} solid;
border-radius: 120px;
outline: 0;
padding-left: 15px;
box-sizing:border-box;

/* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2); */
`; 

export const MyInput = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 40%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const InputG = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 51%;
margin-left: 1px;
margin-right: 220px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const LabelInputG = styled.div`
width: 100%;
color: gray;
padding-bottom: 10px;
padding-left: 45px;
`;

export const Items = styled.div `
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;

export const IconeSearch = styled(FaSearch)
`
display: flex;
justify-content: flex-start;
align-items: flex-start;
position:relative;
top:-10px;
&:hover{
    opacity: 0.7;
}
`;

// Tabela
export const WrapList = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const HeaderList = styled.div `
background-color: ${props => props.theme.bgBorderButtonMenu};
color: ${props => props.theme.textHeading} !important;
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 14px;
font-weight:bold;
text-transform:uppercase;
height: 25px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 94%;
`;
export const BodyList = styled.div `
height: 44vh;
overflow-y:auto;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
padding-top: 10px;
background-color: ${props => props.theme.bgColor};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
border-bottom-left-radius:10px;
border-bottom-right-radius:10px;
`;

export const Row = styled.div `
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
/* background-color: ${props => props.bgColor}; */
padding-top: 10px;
padding-bottom: 10px;
`;

export const THEAD = styled.div `
color: gray;
position: fixed;
display: fixed;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
/* background-color: ${props => props.bgColor}; */
padding-top: 10px;
padding-bottom: 10px;
`;


export const Column = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 20%;
padding:0 25px;
color: ${props => props.theme.textTable};
text-align:center !important
`;

export const WrapButtons = styled.div `
//position:absolute;
bottom:0;
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
// background-color: ${props => props.bgColor};
padding-top: 20px;
padding-bottom: 20px;
`;

export const NextPage = styled(FaAngleRight)
`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;

export const PrevPage = styled(FaAngleLeft)
`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;

//Modal


export const modalStyle = {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: '70vw',
    left: '50%',
    top: '50%',
    borderRadius: '15px',

    transform: `translate(-50%, -50%)`

}


export const cancelar = {
    backgroundColor: 'red'
};


export const WrapHora = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
color: gray;
border-radius: 5px;
width: 100%;
height: 25px;
padding: 2px;
margin-left: 20px;
@media( max-width: 565px){
    margin-top: 10px; 
}
`;

export const ModalBox = styled.div `
    flex-flow: column nowrap;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border: 0px;
    margin:0;
    min-height: 220px;
    @media (max-width: 800px){
        max-height: 80vh;
    } 
    `