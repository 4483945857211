import React, { } from 'react'
import {
    Header,
    WrapMessages,
    WrapTotalHoras,
    Title,
    WrapTotalHoras2,
    Title2,
    Messages,
    TotalHoras,
    IconeMessages,
    ListMessages,
    Icone,
    Horas,
    TitleHora,
    Hora,
    Line2,
    TotalHoras2,
} from './styles.js'
import { useUserResume } from 'contexts/SharedContext'

export function MyTotalHoras() {
    const { userResume } = useUserResume()
    return (
        <WrapTotalHoras2>
            <Title2>&nbsp;&nbsp;&nbsp;TOTAL HORAS MÊS</Title2>
            <TotalHoras2>
                <Horas>
                    <Hora> {userResume.totHorasMes ? userResume.totHorasMes + 'hs' : '--'}</Hora>
                    <TitleHora>trabalhadas</TitleHora>

                </Horas>
            </TotalHoras2>
        </WrapTotalHoras2>
    )
}

export default function CommonInfos(props) {
    const { userResume } = useUserResume()
    return (
        <Header>
            <WrapMessages>
                <Title>&nbsp;&nbsp; &nbsp;ATENÇÃO<div>{" "}</div></Title>
                <Messages>
                    <IconeMessages>
                        <Icone color="#FC2806" size={40} />
                    </IconeMessages>
                    <ListMessages>
                        <br />
                        A partir de agora esse será seu novo sistema para registro de horas. Seja bem-vindo(a)
                        <br />
                        {
                            userResume.message && userResume.message.map((item, index) => {
                                return <><br /> <span>{item}</span>  <br />  </>
                            })
                        }
                        <br />
                    </ListMessages>
                </Messages>
            </WrapMessages>
            <WrapTotalHoras>
                <Title>&nbsp;&nbsp;&nbsp;TOTAL HORAS MÊS</Title>
                <TotalHoras>
                    <Horas>
                        <Hora> {userResume.totHorasMes ? userResume.totHorasMes + 'hs' : '--'}</Hora>
                        <TitleHora>trabalhadas</TitleHora>
                    </Horas>
                </TotalHoras>
            </WrapTotalHoras>

            <WrapTotalHoras>
                <Title>&nbsp;&nbsp;&nbsp;MÉDIA DE HORAS/DIA</Title>
                <TotalHoras>
                    <Horas>
                        <Hora>{userResume.mediaHorasMes ? userResume.mediaHorasMes + '/dia' : '--'}</Hora>
                        <TitleHora>trabalhadas</TitleHora>
                        <Line2> </Line2>
                    </Horas>
                </TotalHoras>
            </WrapTotalHoras>
        </Header>
    )
}

/* <Previstas>
                        <Prevista>176hs</Prevista>
                        <TitlePrevista> previstas</TitlePrevista>
                    </Previstas>

                       <Line>
                    </Line>
                    */


