import React, { useState, useEffect, useContext } from 'react'
import { Container, Items,WrapHora,ModalBox } from './styles.js'
import { Button, ConfirmarBtn, CancelarBtn } from "../../components/Button"
import { api } from 'services/axios'
import TableCadastroIntervalo from 'components/TableCadastroIntervalo'
import { useSnackbar } from 'notistack';
import { GetSessionUser } from 'services/helpers'
import { useShowModal } from 'contexts/SharedContext'
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Wrap, WrapInput, WrapList } from "../../components/Wrap";
import { modalStyle,  } from '../../components/Modal'
import { HeaderList, BodyList } from '../../components/TabelaCadastro'
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { Input, LabelInput, inputStyle,multSelect } from "components/Input";
import { Modal, CircularProgress } from "@material-ui/core";
import MessageAlert from 'components/MessageAlert'
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal'
import { ThemeContext } from 'styled-components'
import { TitleStyle2 } from "../../pages/CadastroPessoas/styles";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { MySelect as MySelect2 } from "../../components/InputSelect";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from "@material-ui/core";
import { IoMdClock } from 'react-icons/io'

export default function CadastroIntervalo() { 
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { colors, textTableColor,bgHeading } = useContext(ThemeContext)
  const [interval, setInterval] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [name, setName] = useState('');
  const [initialLunch, setInitialLunch] = useState(null);
  const [initialLunchSlct, setInitialLunchSlct] = useState(null);
  const [startLunch, setStartLunch] = useState(null);
  const [startLunchSlct, setStartLunchSlct] = useState(null);
  const [limitLunch, setLimitLunch] = useState(null);
  const [limitLunchSlct, setLimitLunchSlct] = useState(null);
  const [durationLunch, setDurationLunch] = useState('');
  const [defaultLunch, setDefaultLunch] = useState(null);
  const cmbDefaultLunch = [
    { value: 0, label: "Não" },
    { value: 1, label: "Sim" }
  ]
  // const [valuesSearch, setValuesSearch] = useState({ name: "", initialLunch: "", startLunch: "", limitLunch: "",durationLunch:0, defaultLunch:0, companyId: 1 });
  // const [values, setValues] = useState([]);


   
  const { showModalInterval, setShowModalInterval } = useShowModal();
  const [loadDialog, setLoadDialog] = useState(false);
  const { perfil } = GetSessionUser()
  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue["100"],
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });
  //Iniciar 
  const getInit = async () => { 
    setLoadDialog(true);
    setNameSearch('');
    setInterval([]);
    try {
      const { data } = await api.get('/interval/?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setInterval(result)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }

  }
  useEffect(() => {
    getInit();
  }, []);
  
  useEffect(() => { 
    if (showModalInterval.action === 'editar') {
      setName(showModalInterval.item.name);
      setInitialLunchSlct(new Date('2021-01-01' + ' ' + showModalInterval.item.initialLunch));
      setStartLunchSlct(new Date('2021-01-01' + ' ' + showModalInterval.item.startLunch));
      setLimitLunchSlct(new Date('2021-01-01' + ' ' + showModalInterval.item.limitLunch));
      setInitialLunch(showModalInterval.item.initialLunch);
      setStartLunch(showModalInterval.item.startLunch);
      setLimitLunch(showModalInterval.item.limitLunch);
      setDurationLunch(showModalInterval.item.durationLunch);
      if (showModalInterval.item.default===false)
        setDefaultLunch({ value: 0, label: "Não" })
      else 
        setDefaultLunch({ value: 1, label: "Sim" })
    }
    if (showModalInterval.action === 'inserir') {
      setDefaultLunch({ value: 0, label: "Não" })
      setName('');
      setInitialLunchSlct(null);
      setStartLunchSlct(null);
      setLimitLunchSlct(null);
      setInitialLunch(null);
      setStartLunch(null);
      setLimitLunch(null);
      setDurationLunch(0);
    }
  }, [showModalInterval.action])

  //Criar Intervalo
  async function sendInterval() {
    try { 
      setLoadDialog(true);
      var obj = { name: name, initialLunch: initialLunch, startLunch: startLunch, limitLunch: limitLunch, durationLunch:durationLunch, defaultLunch:defaultLunch.value, companyId: perfil.companyId }
      const { data } = await api.post("/interval?companyId=" + perfil.companyId, obj);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalInterval({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    fetchCreate();
  }

  async function fetchCreate() {
    try {
      setLoadDialog(true);
      const { data } = await api.get('/interval/?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data;
        setInterval(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar clientes", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  // Apagar Client
  async function deleteClient(id) {
    try {
      setLoadDialog(true);
      const { data } = await api.delete("/client/" + id);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalInterval({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    //setDeleteDialog(false);
    fetchCreate(1);
  }

  //Atualizar Interval
  async function updateInterval(id) { 
    try {
      setLoadDialog(true);
      var obj = { name: name, initialLunch: initialLunch, startLunch: startLunch, limitLunch: limitLunch, durationLunch:durationLunch, defaultLunch:defaultLunch.value, companyId: perfil.companyId }
      const { data } = await api.put("/interval/" + id, obj);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalInterval({ show: false });
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }

    fetchCreate(1);
  }

  //Pesquisar Intervalo
  async function fetchIntervalo() {
    const key = enqueueSnackbar('Pesquisando Intervalos...', INFOMESSAGE)
    try {
      setInterval([])
      setLoadDialog(true);
      const { data } = await api.get("interval?companyId=" + perfil.companyId + "&name=" + nameSearch);
      if (data.success) {
        const { result } = data;
        setInterval(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
    closeSnackbar(key)
    setLoadDialog(false);
  }

  let handleName = (item) => {
    setName(item.target.value)
    //setValues({ ...values, name: item.target.value })
  }
  // let handleInitialLunch = (item) => { 
  //   setInitialLunch(item.target.value)
  //   setValues({ ...values, initialLunch: item.target.value })
  // }
  const handleInitialLunch = (e) => { 
    setInitialLunchSlct(e);
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setInitialLunch(hora + ':' + minutos)
  }
  const handleStartLunch = (e) => {  
    setStartLunchSlct(e);
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setStartLunch(hora + ':' + minutos)
  }
  const handleLimitLunch = (e) => { 
    setLimitLunchSlct(e);
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setLimitLunch(hora + ':' + minutos)
  }
  let handleDurationLunch = (item) => {
    setDurationLunch(item.target.value)
    //setValues({ ...values, durationLunch: item.target.value })
  }
  let handleDefaultLunch = (item) => { 
    setDefaultLunch(item);
    //setValues({ ...values, defaultLunch: item.value })
  }
  const handleClose = () => {
    setShowModalInterval({ show: false });
  };

  function clearSearch() {
    setNameSearch('');
    setInterval([]);
  }

  const handleNameSearch = (item) => {
    setNameSearch(item.target.value);
  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const modalContent = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        {showModalInterval.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      {showModalInterval.action === 'editar' || showModalInterval.action === 'inserir' ?
        <ModalBox>
          <Wrap>
            <WrapInput classname="Wrap">
              <LabelInput>Nome <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <Input
                style={inputStyle}
                maxLength="30"
                type="text"
                onChange={handleName}
                value={name}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '30%' }}> 
            <LabelInput>Definir como padrão <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <MySelect2 
                styles={multSelect}
                value={defaultLunch}
                onChange={handleDefaultLunch}
                options={cmbDefaultLunch}
              />
            </WrapInput>
            <WrapInput classname="Wrap">
              <LabelInput>Duração Minutos <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <Input
                style={inputStyle}
                type="number"
                min={0}
                max={120}
                onChange={handleDurationLunch}
                value={durationLunch}
              />
            </WrapInput>
          </Wrap>
          <Wrap>
            <WrapInput classname="Wrap">
              <WrapHora>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        helperText={''}
                        label={'Inicio Intervalo *'}
                        // error={false}
                        value={initialLunchSlct}
                        onChange={e => handleInitialLunch(e)}
                    
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </WrapHora>
            </WrapInput>
            <WrapInput classname="Wrap">
              <WrapHora>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        helperText={''}
                        label={'Inicio Alerta *'}
                        // error={false}
                        value={startLunchSlct}
                        onChange={e => handleStartLunch(e)}
                    
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </WrapHora>
            </WrapInput>
            <WrapInput classname="Wrap">
              <WrapHora>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        helperText={''}
                        label={'Fim Intervalo *'}
                        // error={false}
                        value={limitLunchSlct}
                        onChange={e => handleLimitLunch(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </WrapHora>
            </WrapInput>
          </Wrap>
          <Wrap>
            <WrapInput className="wrapInputModal">
              <Items>
                <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                <Button type="button" onClick={() => showModalInterval.action === 'editar' ? updateInterval(showModalInterval.item.id) : sendInterval()}> Salvar </Button>
              </Items>
            </WrapInput>
          </Wrap>
        </ModalBox>
        : ''}

      {showModalInterval && showModalInterval.action === 'remover' ?
        <div>
          <WrapInput className="alertText">
            <h3>Você tem certeza que deseja remover {showModalInterval ? showModalInterval.item.name : ''}? Essa operação é permanente! </h3>
          </WrapInput>
          <WrapInput className="alertBtn">
            <Items>
              <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
              <ConfirmarBtn type="button" onClick={() => deleteClient(showModalInterval.item.id)}> Confirmar </ConfirmarBtn>
            </Items>
          </WrapInput>
        </div>

        : ''
      }
    </div>
  );



  return (
    <Container>
      <Modal open={showModalInterval.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalContent}
      </Modal>
      <SearchBox>
        <TitleStyle2>Cadastro de Intervalos</TitleStyle2>
        <Wrap className="Wrap" >
          <WrapInput>
            <LabelInput>Nome </LabelInput>
            <Input
              style={inputStyle}
              maxLength="30"
              type="text"
              onChange={handleNameSearch}
              value={nameSearch}
            />
          </WrapInput>
          
          <WrapInput style={{ marginTop: '10px' }}>
            <ItemsSearch>
              <IconeSearch size={25} title="Pesquisar" onClick={() => fetchIntervalo()} />
              <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => setShowModalInterval({ show: true, title: 'CADASTRAR INTERVALO', action: 'inserir' })}>&nbsp;Novo<AddIcon></AddIcon></Button>
              <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>

            </ItemsSearch>
          </WrapInput>
        </Wrap>
      </SearchBox>
      <WrapList>
        <HeaderList className="HeaderList">
          <span> Intervalo </span>
        </HeaderList>

        <BodyList>
          {/* {interval.length>0 &&       */}
            <TableCadastroIntervalo intervalo={interval} getIntervalo={fetchIntervalo} />
          {/* }    */}
        </BodyList>
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}