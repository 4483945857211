import styled from 'styled-components'
import { TableCell } from 'components/SharedComponents/table';



export const OrderCell = styled(TableCell)`
color: ${props => props.color};
font-weight: 'bold';
border: 'none';
font-size: '11px';
background-color: 'red';
display: 'flex';
justify-content: 'center';
align-items: 'center';
padding-left: '39px';
&:hover{
    opacity: 0.8;
}
`;

export const Input = styled.select`
border: ${props => props.border};
width: 90%;
height: 25px;
border-radius: 10px;
outline: 0;
background-color: ${props => props.theme.colors.background};
`;


export const Dots = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
transform: rotate(270deg);
border-radius: 10px;
outline: 0;
color: ${props => props.theme.colors.primary};
cursor: pointer;
&:hover{
    opacity: 0.8;
}
`;

/*

@media (min-width: 800px){
   display: none;
}*/

export const Button = styled.div`
font-weight: 600;
border-radius: 10px;
color: white;
width: 50%;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
background-color: #256FC9;
cursor: pointer;
&:hover{
  background-color: #256FF9;
}
`;


export const ModalAcoes2 = styled.div`
padding: 0;
margin-right: 10px;
width: 100%;
height: 120px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
z-index: 400; 
position: fixed;
bottom: 0;
background-color: ${props => props.theme.colors.surface};
transform:  ${props => props.open};
transition: transform 0.4s ease-in-out;
`;

export const ModalAcoes = styled.div`
padding: 0;
margin: 0;
width: 100%;
height: 25%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: 400; 
position: fixed;
bottom: 0;
background-color: ${props => props.theme.colors.surface};
transform:  ${props => props.open};
transition: transform 0.4s ease-in-out;
`;


export const ItemModal = styled.div`
margin: 0;
padding: 0;
color: #CF6679;
font-weight: 700;
left: 0;
right: 0;
border: ${props => props.border};
width: 100%;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
&:hover{
  opacity: 0.9;
}
`;

export const Backdrop = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 300;
opacity: ${props => props.op};
transition: opacity 0.5s;
background-color: rgba(0, 0, 0, 0.2);
`;



export const MyInput = styled.input`
height: 40px;
outline: 0;
border-radius: 5px;
padding-left: 10px;
width: 99%;
border: 1px;
color: ${props => props.theme.textTable};
background-color: ${props => props.theme.colors.background};
margin: 0;
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ddd;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ddd;
}
`;