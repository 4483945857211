import styled from 'styled-components'
import { FaSearch  } from 'react-icons/fa'
import Select from 'react-select';


export const Container = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;



export const Wrap = styled.div `
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    margin: 0 auto;
    padding-top: 20px;
    @media (max-width: 800px){
        flex-direction: column;
        align-items: center;
    }
`;

export const WrapInput = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 15%;
    margin-left: 1px;
    flex-grow: 1;
    margin-right: 1px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 5px;        
    @media (max-width: 800px){
        width: 60%;
        margin: 0;
        padding: 0
    }
`;

export const WrapInputSwitch = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 286px;
    margin-left: 1px;
    flex-grow: 1;
    margin-right: 1px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 5px;        
    @media (max-width: 800px){
        width: 224px;
        margin: 0;
        padding: 0
    }
`;

export const Items = styled.div `
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;

export const ItemsV2 = styled.div `
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;




/*Buttons*/ 
export const Button = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 100px;
height: 30px;
background-color: ${props => props.theme.buttonPrimary};
border-radius: 10px;
margin-left: 20px;
color: white;
position:relative;
top:-3px;
cursor: pointer;
&:hover{
    opacity: 0.7;
}
`;
export const CancelarBtn = styled(Button)`
    background-color: ${props => props.theme.colors.error}
`;
export const ConfirmarBtn = styled(Button)`
    background-color: ${props => props.theme.colors.success}
`;

/* Icons */
export const IconeSearch = styled(FaSearch)
`
display: flex;
justify-content: flex-start;
align-items: flex-start;
position:relative;
top:-10px;
&:hover{
    opacity: 0.7;
}
`;





/* Table */






export const WrapList = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;



/* Form */
export const MySelect = styled(Select)
`
width: 100%;
@media (max-width: 800px){
    width: 100%;
}
`;

export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        margin: 0,
        //backgroundColor: state.isSelected ? "#eee" : "",
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,
        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderColor: 'gray',
        height: 30,
        minHeight: 30,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};







//  28072020
export const SearchBox = styled.div `
background-color: ${props => props.theme.colors.surface};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
margin: 20px 15px 20px 0;
padding: 0;
border-radius: 7px;
width: 93.7%;
@media (max-width: 800px){
    margin: 20px 15px;
    padding-bottom:20px
}
`;

export const TitleStyle2 = styled.h2 `
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius: 10px;
    height: 25px;
    border-top-right-radius: 10px;
    font-weight: bold;
    text-transform: uppercase;
`

export const SearchButton = {
    background: 'red',
}
export const SearchContent = styled.div `
display: flex;
justify-content: space-between;
align-items: stretch;
width: 94%;
/* padding: 15px 0; */
@media (max-width: 800px){
    width: 100%;
    flex-direction:column
    
}
`;
export const SearchRow = styled.div `
width:98%;
display:flex;
justify-content: flex-start;
flex-direction: row;
flex-wrap: wrap;
position:relative;
@media (max-width: 800px){
    flex-direction: column;
    justify-content: center;
    align-items:center
}
`;