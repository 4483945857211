import React, { useState, useEffect } from 'react'
import { Input } from './styles.js'

import { api } from 'services/axios'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { useSnackbar } from 'notistack';
import { GetSessionUser } from 'services/helpers'
import { useUserResume } from 'contexts/SharedContext'
import MessageAlert from 'components/MessageAlert'


export default function ObservacaoText({ text, index, id, getInitFechamento, esconderEditar }) {
  const { setUserResume } = useUserResume()

  const [myText, setMyText] = useState()
  const [free, setFree] = useState(true)
  const { enqueueSnackbar } = useSnackbar()


  useEffect(() => {
    setMyText(text)
  }, [text])
  const getInit = async (message) => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/register/resumo/' + perfil.personId)
      if (data.success) {
        const { result } = data
        setUserResume(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
    setFree(true)
  }

  async function handleNoteRegister() {
    if (free && !esconderEditar) {
      setFree(false)
      await setTimeout(async () => {
        try {
          const { data } = await api.put('/register/nota/' + id, { noteRegister: myText })
          if (data.success) {
            enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
          } else {
            enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
          }
          getInit()
          // getInitFechamento()
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
          setFree(true)
        }
      }, 900)

    }
  }


  return (
    <Input
      onBlur={() => handleNoteRegister()}
      value={myText}
      onChange={e => setMyText(e.target.value)}
      placeholder="Opcional" maxLength="140" />
  )
}