import styled from 'styled-components'
import Select from 'react-select'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
display:flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
`;

export const TitleStyle2 = styled.h2 `
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;

`;

export const MySelect = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;

export const overrideStrings ={    
    "selectSomeItems": "Opções...",
    "allItemsAreSelected": "Todos itens Selecionados",
    "selectAll": "Selecionar Todos",
    "search": "Pesquisar"
}
