import styled from 'styled-components'
import { FaSearch, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Select from 'react-select';
import TableContainer from '@material-ui/core/TableContainer';

export const Container = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;

export const SearchBox = styled.div`
background-color: ${props => props.theme.colors.surface};
margin:20px 15px;
padding:0;
border-radius:7px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width:93.7%;
`;
export const ModalBox = styled.div `
    flex-flow: column nowrap;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border: 0px;
    margin:0;
    min-height: 220px;
    @media (max-width: 800px){
        max-height: 80vh;
    } 
    `
export const TitleStyle2 = styled.h2`    
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;`

export const titleStyle = {
    margin: '0',
    borderBottom: '1px solid gray',
    // borderButtonMenu
    color: `${props => props.theme.borderButtonMenu}`,
    fontWeight: '100'
};
export const cancelar = {
    backgroundColor: 'red'
};
export const modalStyle = {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100%',
    maxWidth: '50vw',
    left: '50%',
    top: '50%',
    borderRadius: '10px',
    padding: '0',
    transform: `translate(-50%, -50%)`
}
export const inputStyle = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '94%',
    fontSize: 14
};

export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        margin: 0,
        //backgroundColor: state.isSelected ? "#eee" : "",
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,

        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderColor: 'gray',
        height: 30,
        minHeight: 30,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};







export const WrapInput = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const Items = styled.div`
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;

export const Wrap = styled.div`
display: flex;
justify-content: space-around;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
padding-top: 20px;
`;


export const IconeSearch = styled(FaSearch)
    `
display: flex;
justify-content: flex-start;
align-items: flex-start;
position:relative;
top:-10px;
&:hover{
    opacity: 0.7;
}
`;


export const MySelect = styled(Select)
    `
width: 95%;
`;

// Tabela
export const WrapList = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const HeaderList = styled.div`
background-color: ${props => props.theme.bgBorderButtonMenu};
color: ${props => props.theme.textHeading} !important;
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 14px;
font-weight:bold;
text-transform:uppercase;
height: 25px;

border-top-left-radius: 10px;
border-top-right-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 94%;
`;
export const BodyList = styled.div`
// height: 40vh;
overflow-y:auto;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
padding-top: 10px;
margin-bottom: 30px;
background-color: ${props => props.theme.bgColor};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
border-bottom-left-radius:10px;
border-bottom-right-radius:10px;
`;
export const Row = styled.div`
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
/* background-color: ${props => props.bgColor}; */
padding-top: 10px;
padding-bottom: 10px;
`;

export const Column = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 20%;
padding:0 25px;
color: ${props => props.theme.textTable};
text-align:center !important
`;

export const WrapButtons = styled.div`
position:absolute;
bottom:0;
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
// background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;
export const NextPage = styled(FaAngleRight)
    `
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;
export const PrevPage = styled(FaAngleLeft)
    `
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;