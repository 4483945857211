import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import { FaUserEdit } from 'react-icons/fa'
import {
    Options,
    Editar,
    ReenviarSenha
} from './styles'

export default function ButtonEditUser({ titleEditar, titleSenha }) {
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <>
            <FaUserEdit size={20} onClick={() => handleChange()} />
            <Zoom in={checked}>
                <Options>
                    <Editar>
                        {checked && titleEditar}
                    </Editar>
                    <ReenviarSenha>

                        {checked && titleSenha}
                    </ReenviarSenha>
                </Options>
            </Zoom>
        </>
    )
}