import styled from 'styled-components'
import { FaExclamationTriangle } from 'react-icons/fa'


export const Header = styled.div `
width: 100%;
display:flex;
justify-content: space-between;
align-items: stretch;
/* justify-content: space-between;
align-items: stretch;
background-color:red; */

@media (max-width: 800px){
    flex-direction: column;
    align-items: center;
}
`;

export const WrapMessages = styled.div `
width: 60%;
@media (max-width: 1230px){
   width: 50%;
}
@media (max-width: 800px){
   display: none;
}
`;

export const WrapTotalHoras = styled.div `
width: 47%;
display:flex;
flex-direction:column;
align-items:stretch;

@media (max-width: 800px){
   display: none;
}
`;

export const WrapTotalAtivos = styled.div `
width: 47%;

/* @media (max-width: 1230px){
   width: 23%;
} */
@media (max-width: 800px){
   display: none;
}
`;
export const TotalHoras = styled.div `
background-color: ${props => props.theme.colors.surface};
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
height: 177px;
display:flex;
flex-direction: column;
justify-content: flex-start !important
`;

export const Messages = styled.div `
background-color: ${props => props.theme.colors.surface};
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
height: 100px;
`;



export const ListMessages = styled.div `
font-size: 14px;
width: 90%;
color: gray;
`;

export const IconeMessages = styled.div `
display: flex;
width: 15%;
justify-content:center;
align-items: center;
height: 70px;
`;

export const Title2 = styled.span `
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: ${props => props.theme.textHeading};
font-weight: bold;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;

export const Title = styled.div `
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: ${props => props.theme.textHeading};
font-weight: bold;
display: flex;
text-transform:uppercase ;
text-align:center;
justify-content: center;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;

export const Icone = styled(FaExclamationTriangle)
`

`;

export const Line = styled.div `
width: 100px;
height: 5px;
background-color: ${props => props.theme.colors.primary};
margin-top: 8px;
`;
export const Line2 = styled.div `
width: 100px;
height: 5px;
background-color: ${props => props.theme.colors.secondary};
margin-top: 8px;
`;
export const Previstas = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
`;

export const Horas = styled.div `
display: flex;
justify-content: space-around;
align-items: center;
align-content: space-between;
width: 100%;
flex-direction: column;
height:100%;

`;


export const Hora = styled.div `
font-size: 25px;
color: gray;
font-weight: 500;
`;

export const TitleHora = styled.div `
font-size: 16px;
color: gray;
text-align:center;
padding: 0 10px;
`;