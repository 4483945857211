import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
height: 100vh;
margin: 0;
padding: 0%;
background-color: ${props => props.theme.colors.background};
display: flex;
justify-content: flex-start;
align-items: center;
background-image: url(${props => props.bg});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
flex-direction: column;
`;

export const Form = styled.div`
width: 65%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
padding-top: 20px;
@media(max-width: 900px){
    flex-direction: column;
    width: 100%;
}
`;

export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 40%;
height: 40px;
background-color: ${props => props.btnColor};
border-radius: 10px;
margin-left: 20px;
color: white;
opacity: ${props => props.op};
cursor: pointer;
&:hover{
}
`;

export const Column = styled.div`
width: 100%;
display: flex;
padding-top: 30px;
justify-content: flex-start;
align-items: center;
flex-direction: column;
height: 200px;
@media(max-width: 900px){
    height: 100%;
}
`


export const WrapButtons = styled.div`
margin-top: 60px;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 40%;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
@media(max-width: 900px){
    width: 75%;
}
@media(max-width: 400px){
    width: 100%;
}
`;

export const Logo = styled.div`
display:flex;
width: 240px;
height: 45px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;

`;

export const TextLogo = styled.div`
color: #ddd;
width: 100%;
display: flex;
justify-content: flex-end;
align-items: flex-start;
padding: 0;
margin: 0;
font-weight: 600;

`;

export const WrapLogo = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 20%;
padding-left: 15px;
flex-direction: column;
margin: 0;
cursor: pointer;
`;


export const Input = styled.input`
width: 80%;
height: 30px;
background-color: ${props => props.theme.colors.surface};
border: 1px ${props => props.theme.borderInputColor} solid;
border-radius: 10px;
box-shadow: none;
outline: 0;
padding-left: 15px;
@media (max-width: 800px){
    width: 80%;
}
`;


export const WrapInput = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
flex-direction: row;
width: 300px;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 90%;
}
`;


export const LabelInput = styled.div`
width: 100% !important;
padding-bottom: 10px;
/* padding-left: 10px; */
`;



export const Body = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;


export const BodyHeader = styled.div`
color:  ${props => props.theme.colorTextAlterarSenha};
width: 50%;
height: 150px;
font-size: 14px;
display: flex;
justify-content: flex-start;
align-items: flex-end;
border-bottom: 1px ${props => props.theme.bgHeading} solid;
padding-bottom: 10px;
@media (max-width: 800px){
    justify-content: center;
    width: 90%;
}
`;

export const Header = styled.div`
width: 100%;
height: 10vh;
margin: 0;
padding: 0;
display: flex;
justify-content: flex-start;
align-items: center;
background-color: ${props => props.theme.bgHeading};
`;