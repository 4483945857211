import React, { useContext, useState, useEffect } from 'react';
import { AiTwotoneDelete } from "react-icons/ai";
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from 'components/SharedComponents/table';
import { ThemeContext } from 'styled-components'
import { shade } from 'polished'
import { useShowModal } from 'contexts/SharedContext'
import { FaClipboardList } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsFillPersonPlusFill, } from "react-icons/bs";
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { Wrap, WrapInput, } from '../../components/Wrap'
import { modalStyle, ModalBox, modalClose } from '../../components/Modal'
import { Button, } from "../../components/Button";
import { TitleStyle2 } from "../../pages/CadastroPessoas/styles";
import Modal from "@material-ui/core/Modal";
import { tint } from "polished";
import { Input2, InputText } from './styles.js'
import { AiOutlineClose } from 'react-icons/ai'
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from 'components/MessageAlert';
import Historico from '../Historico.js';
import { BiHistory } from "react-icons/bi";
import { ConfirmarBtn, CancelarBtn } from "../../components/Button";
import { Items } from './styles.js'


const customControlStyles = base => ({
  ...base,
  height: 35,
  minHeight: 35
});

export default function TableCadastroServico({ servico, getServico, setChangeModal }) {
  const { colors, textTableColor } = useContext(ThemeContext)
  const [employeeDialog, setEmployeeDialog] = useState(false);
  const [taskDialog, setTaskDialog] = useState(false);
  const [serviceObj, setServiceObj] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const [dateIni, setDateIni] = useState()
  const [dateFim, setDateFim] = useState()
  const [servicePerson, setServicePerson] = useState()
  const [serviceId, setServiceId] = useState('');
  const [serviceName, setServiceName] = useState('')
  const [jobs, setJobs] = useState([])
  const [persons, setPersons] = useState([])
  const [pessoas, setPessoas] = useState([])
  const [atividades, setAtividades] = useState([])
  const [atividadesSelected, setAtividadesSelected] = useState(null)
  const [pessoaSelected, setPessoaSelected] = useState(null)
  const [novaTarefa, setNovaTarefa] = useState('')
  const [nameService, setNameService] = useState([]);
  const { setShowModalServico } = useShowModal();
  const [modalChange, setModalChange] = useState(false);
  const [openHist, setOpenHist] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [name, setName] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleAtividades = (item) => {
    setAtividadesSelected(item)
  }

  const handlePessoas = (item) => {
    setPessoaSelected(item)
  }

  useEffect(() => {
    if (servico) {
      const temp = servico.filter(item => item.id === serviceId)
      if (temp.length > 0)
        setJobs(temp[0].job)
    }
  }, [servico])

  useEffect(() => {
    if (servico) {
      const temp = servico.filter(item => item.id === serviceId)
      if (temp.length > 0)
        setPersons(temp[0].person)
    }
  }, [servico])

  useEffect(() => {
    //getComboTarefas()
  }, [])

  const handleClose = () => {
    setEmployeeDialog(false);
    setTaskDialog(false);
    setPersons([]);
    if (modalChange)
      getServico();
    setModalChange(false);
    setDeleteDialog(false);
  };

  const useStyles = makeStyles({
    table: {
      // minWidth: 650,
      backgroundColor: colors.surface,
      width: '100%',
      fontSize: '14px'
    },
    head: {
      backgroundColor: "#fff",
      position: "sticky",
      top: 0
    },
    headCol: {
      backgroundColor: colors.background,
      position: "sticky",
      top: 0,
      color: textTableColor,
      fontWeight: 500,
      border: 'none',

      textTransform: 'uppercase',
      textAlign: 'center'
    },
    bodyCol: {
      color: textTableColor,
      border: 'none',
      fontSize: '18px',

    },
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    btnModal: {
      marginLeft: '0', marginTop: '-5px'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    }
  });
  const classes = useStyles();

  const cadastrarTarefa = async () => {
    try {
      const obj = {
        name: novaTarefa,
        companyId: 1,
        type: 1,
        minimumDuration: 0
      }
      const { data } = await api.post('/job', obj)
      if (data.success) {
        getComboTarefas(serviceId)
        setNovaTarefa('');
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function getHistorico(companyId, serviceId, tableChangeId) {
    try {
      const { data } = await api.get("historico" + '?companyId=' + companyId + '&keyTable=' + serviceId + '&tableChangeId=' + tableChangeId);;
      if (data.success) {
        const { result } = data;
        setHistorico(result)
        setOpenHist(true)
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  function openModalHist(item) {
    setHistorico([]);
    setName(item.name);
    getHistorico(item.companyId, item.id, 4);
  }

  const handleCloseHist = () => {
    setOpenHist(false);
    setHistorico([]);
  };

  const getComboTarefas = async (id) => {
    setAtividades([]);
    try {
      const { data } = await api.get('/job/notService/1/' + id)
      if (data.success) {
        const { result } = data
        setAtividades(result.map(item => { return { value: item.id, label: item.name, isDisabled: false } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  const getTableTarefas = async (id) => {
    try {
      const { data } = await api.get('/service/getJob/' + id)
      if (data.success) {
        const { result } = data
        setJobs(result)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const compara = async (atividades) => {
    setAtividades([])
    for (let x of atividades) {
      let tmp = jobs.filter(y => y.jobName == x.label)

      if (tmp.length) {
        x.isDisabled = true;
        setAtividades(atividades)
      }

    }
  }

  const getPerson = async (id) => {
    try {
      const { data } = await api.get('/service/personByService/' + id)
      if (data.success) {
        const { result } = data
        setPersons(result)
        setServicePerson(result.map(item => { return [item.servicePersonId] }))
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  const getPersonSelect = async (id) => {
    try {
      const { data } = await api.get('/service/personNotInService/' + id)
      if (data.success) {
        const { result } = data
        setPessoas(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  function openModal(item, action, items) {
    setServiceId(item.id)
    setServiceObj(item)
    if (action === 'employee') {
      setEmployeeDialog(true)
      getPersonSelect(item.id)
      setNameService(item)
      getPerson(item.id)
      // setDateIni(item.initalDate)
      // setDateFim(item.finalyDate)
    } else {
      setJobs([]);
      getComboTarefas(item.id)
      setNameService(item)
      setTaskDialog(true)
      getTableTarefas(item.id)
    }
  }

  async function deleteService() {
    try {
      const { data } = await api.delete('/service/' + serviceId)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        //const temp = persons.filter(item => item.servicePersonId !== id)
        setDeleteDialog(false);
        getServico();
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function deletePerson(id) {
    try {
      setChangeModal(true);
      const { data } = await api.delete('/servicePerson/' + id)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        const temp = persons.filter(item => item.servicePersonId !== id)
        setPersons(temp)
        getPersonSelect(serviceId)
        setChangeModal(false);
        setModalChange(true)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setChangeModal(false);
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setChangeModal(false);
    }
  }

  async function deleteJob(id) {
    try {
      setChangeModal(true);
      const { data } = await api.delete('/serviceJob/' + id)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        const temp = jobs.filter(item => item.serviceJobId !== id)
        setJobs(temp)
        getComboTarefas(serviceId)
        setChangeModal(false)
        setModalChange(true)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setChangeModal(false);
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setChangeModal(false);
    }
  }

  async function inserirTarefa() {
    try {
      setChangeModal(true);
      const obj = {
        serviceId: serviceId,
        jobId: atividadesSelected === null ? null : atividadesSelected.value
      }
      //alert(JSON.stringify(obj))
      const { data } = await api.post('/serviceJob/', obj)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        // getInit('Atualizando registros...')
        getTableTarefas(serviceId)
        getComboTarefas(serviceId)
        setAtividadesSelected(null)
        setChangeModal(false);
        setModalChange(true)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setChangeModal(false);
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setChangeModal(false);
    }
  }

  async function inserirPessoa() {
    try {
      setChangeModal(true);
      const obj = {
        serviceId: serviceId,
        personId: pessoaSelected === null ? null : pessoaSelected.value
      }
      const { data } = await api.post('/servicePerson/', obj)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        getPersonSelect(serviceId)
        getPerson(serviceId)
        setPessoaSelected(null)
        setChangeModal(false);
        setModalChange(true)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setChangeModal(false);
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setChangeModal(false);
    }
  }

  function openModalDelete(item) {
    setServiceId(item.id);
    setServiceName(item.name);
    setDeleteDialog(true);
  }

  const modalDelete = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">Remover Serviço {serviceName}</TitleStyle2>
      <WrapInput className="alertText">
        <h3>Você tem certeza que deseja remover {serviceName}? Essa operação é permanente! </h3>
      </WrapInput>
      <WrapInput style={{width:'100%', alignItems:'center'}}>
        <Items>
          <CancelarBtn type="button" onClick={() => handleClose()}> Cancelar </CancelarBtn>
          <ConfirmarBtn type="button" onClick={() => deleteService()}> Confirmar </ConfirmarBtn> 
        </Items>
      </WrapInput>
    </div>
  );

  const modalEmployee = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Pessoas associadas ao serviço {nameService.name}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'hidden' }} >
        <Wrap style={{}}>
          <WrapInput>
            <Input2
              styles={{ control: customControlStyles }}
              placeholder="Pessoas"
              isClearable
              maxMenuHeight={190}
              isSearchable
              value={pessoaSelected}
              onChange={handlePessoas}
              options={pessoas.map(item => { return { value: item.personID, label: item.personName } })}
              border={`0.5px ${shade(0.2, colors.surface)} solid`} />
          </WrapInput>
          <WrapInput className={classes.wrapBtnModal}>
            <Button className={serviceObj.status === 0 ? 'disable' : 'enable'} style={{ marginTop: '10px' }}
              onClick={serviceObj.status === 1 ? () => inserirPessoa(dateIni, dateFim) : ''}>
              Associar
            </Button>
          </WrapInput>
        </Wrap>
        <TableContainer style={{ height: '300px' }} className={classes.containerModal}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headColModal} align="center">NOME</TableCell>
                <TableCell className={classes.headColModal} align="center">AÇÕES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%', backgroundColor: 'white' }}>
              {
                persons && persons.map((row, index) => {
                  return (
                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                      <TableCell align="center">
                        {row.PersonName}
                      </TableCell>
                      <TableCell align="center">
                        {serviceObj.status === 1 &&
                          <AiTwotoneDelete
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            color={tint(0.1, colors.error)}
                            size="20"
                            title="Remover"
                            onClick={() => deletePerson(row.servicePersonId)}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </ModalBox>
    </div>
  );

  const modalTask = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Tarefas associadas ao serviço {nameService.name}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox>
        <Wrap>
          <WrapInput>
            <Input2
              styles={{ control: customControlStyles }}
              placeholder="Tarefas"
              maxMenuHeight={190}
              isClearable
              isSearchable
              value={atividadesSelected}
              onChange={handleAtividades}
              options={atividades}
              border={`0.5px ${shade(0.2, colors.surface)} solid`} />
          </WrapInput>
          <WrapInput className={classes.wrapBtnModal}>
            <Button className={serviceObj.status === 0 ? 'disable' : 'enable'} style={{ marginTop: '8px' }}
              onClick={serviceObj.status === 1 ? () => inserirTarefa() : ''}>
              Associar</Button>
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput>
            <InputText
              placeholder="Cadastrar nova tarefa"
              value={novaTarefa}
              onChange={e => setNovaTarefa(e.target.value)}
              border={`0.4px ${shade(0.2, colors.surface)} solid`} />
          </WrapInput>
          <WrapInput className={classes.wrapBtnModal}>
            <Button className={serviceObj.status === 0 ? 'disable' : 'enable'} style={{ marginTop: '8px' }}
              onClick={serviceObj.status === 1 ? () => cadastrarTarefa() : ''}>
              Cadastrar</Button>
          </WrapInput>
        </Wrap>
        <TableContainer className={classes.containerModal}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headColModal} align="center">Tarefa</TableCell>
                <TableCell className={classes.headColModal} align="center">AÇÕES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%', backgroundColor: 'white' }}>
              {
                jobs && jobs.map((row, index) => {
                  return (
                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                      <TableCell align="center">
                        {row.jobName}
                      </TableCell>
                      <TableCell align="center">
                        {serviceObj.status === 1 &&
                          <AiTwotoneDelete
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            color={tint(0.1, colors.error)}
                            size="20"
                            title="Remover"
                            onClick={() => deleteJob(row.serviceJobId)}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  )
                })
              }

            </TableBody>
          </Table>
        </TableContainer>
      </ModalBox>
    </div>
  );

  return (
    <TableContainer className={classes.containerModal}>
      <Modal open={deleteDialog}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalDelete}
      </Modal>
      <Modal open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Historico historico={historico} name={name} handleCloseHist={handleCloseHist} />
      </Modal>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >CLIENTE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >CONTRATO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >TIPO DE CONTRATO/SERVIÇO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >CÓDIGO EXTERNO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >NOME DO SERVIÇO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >DATA INÍCIO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >DATA FIM</TableCell>
            {/* <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >RESPONSAVEL CONFLUENCE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >CÓDIGO CLIENTE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >RESPONSÁVEL CLIENTE</TableCell> */}
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center" >AÇÕES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>

          {
            servico && servico.map((row, index) => {
              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.fantasyName}
                  </TableCell>

                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.aggrementName}
                  </TableCell>

                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.AggrementType}
                  </TableCell>


                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.externalCode}
                  </TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.name}
                  </TableCell>


                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.initalDate}
                  </TableCell>

                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.finalyDate}
                  </TableCell>

                  {/* <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row['Responsavel Confluence'] ? row['Responsavel Confluence'] : ' - '}
                  </TableCell>

                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.clientServiceCode ? row.clientServiceCode : ' - '}
                  </TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.clientResponsible ? row.clientResponsible : ' - '}
                  </TableCell> */}
                  <TableCell
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'none',
                      marginTop: '6px'
                    }}
                    align="center">
                    <BsFillPersonPlusFill
                      onClick={() => openModal(row, 'employee', row.person)}
                      style={{ cursor: "pointer", marginLeft: '5px' }}
                      color={colors.primary}
                      size="18"
                      title="Pessoas"
                    />
                    <FaClipboardList
                      onClick={() => openModal(row, 'task', row.job)}
                      style={{ cursor: "pointer" }}
                      color={colors.primary}
                      size="18"
                      title="Tarefas"
                    />
                    <FiEdit
                      onClick={() => { setShowModalServico({ show: true, action: 'editar', title: 'EDITAR SERVIÇO', item: row }); }}
                      style={{ cursor: "pointer", marginLeft: '5px' }}
                      color={colors.primary}
                      size="18"
                      title="Editar"
                    />
                    {row.logChange > 0 ?
                      <BiHistory
                        onClick={() => openModalHist(row)}
                        style={{ cursor: "pointer" }}
                        color={colors.primary}
                        size="20"
                        title="Atualizações"
                      /> : <BiHistory
                        style={{ cursor: "pointer" }}
                        color={"#808080"}
                        size="20"
                        title="Atualizaçõeso"
                      />
                    }
                    {row.flagDelete === 1 && <AiTwotoneDelete
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      color={tint(0.1, colors.error)}
                      size="20"
                      title="Remover"
                      onClick={() => openModalDelete(row)}
                    />}
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>


      <Modal open={employeeDialog}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalEmployee}
      </Modal>
      <Modal open={taskDialog}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalTask}
      </Modal>
    </TableContainer >
  )
}