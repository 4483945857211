import React, { } from 'react'
import { Container, TitleStyle2 } from "../../components/PageCadastro";
import { Items, SearchBox } from "../../components/Search";
import { Wrap, WrapInput } from "../../components/Wrap";
import { MySelect, selectStyle } from "../../components/InputSelect";
import { Button } from "../../components/Button";
import { LabelInput } from "components/Input";


export default function CadastroPerfil() {

    return (
        <Container>
            <SearchBox>
                <TitleStyle2>Cadastro de Serviço</TitleStyle2>
                <Wrap>



                    <WrapInput>
                        <LabelInput>Responsável Confluence</LabelInput>
                        <MySelect
                            styles={selectStyle}
                            placeholder="Responsável"
                            //  onChange={item => setValues({ ...values, ["personId"]: item === null ? '' : item.value })}
                            // value={selectedAgreements}
                            isClearable={true}
                            isSearchable={true}
                        // options={pessoas.map((item) => { return { value: item.id, label: item.name + ' ' + item.familyName }; })}
                        />
                    </WrapInput>






                    <WrapInput>
                        <Items>
                            {
                                // <Button onClick={() => sendService()}>Inserir</Button>
                                <Button
                                //  onClick={() => setInserirDialog(true)}
                                >
                                    Inserir
                                </Button>
                            }
                        </Items>
                    </WrapInput>
                </Wrap>
            </SearchBox>
        </Container>
    )
}