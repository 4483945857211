import React, { useState, useEffect, useContext } from 'react'
import {
  Container,
  ContainerMobile,
  ListDesktop,
  ListMobile,
  ModalFechamento,
  ModalSaida,
  Title,
  TitleSpan,
  TitleSpan2,
  TitleSpan3,
  TitleSpanMobile,
  TextInfo,
  TextInfoMobile,
  ItemInfoMobile,
  WrapOptionsMobile,
  ItemInfo3,
  WrapInfos3,
  WrapInfos2Mobile,
  WrapInfosService,
  InputTextService,
  WrapHora,
  WrapHoraSaida,
  WrapOptions,
  WrapAlmocoMobile,
  Options,
  Infos,
  WrapInfos2,
  ButtonOptions,
  ButtonOptionsMobile,
  ItemInfo,
  EnterIcon,
  ExitIcon,
  ListBody,
  Backdrop,
  TrocarProjeto,
  BodyTrocarProjeto,
  BodyInsertSaida,
  StopIcon,
  WrapInput,
  LabelInput,
  LabelInput2,
  WrapAlmoco,
  AlmocoIcon,
  Input,
  InputService,
  InputText,
  WrapButtons,
  Button,
  Input2,
  IconSync,
  WrapInfos,
  Data,
  Hour,
  PlayIcon,
  AlertLaunch,
  BodyLaunch,
  ButtonLaunch,
  Lockscreen
} from './styles.js'
import { shade } from 'polished'
import exit from 'assets/exit.png'
import lunch from 'assets/lunch.png'
import { api } from 'services/axios'
import enter from 'assets/enter.png'
import { useSnackbar } from 'notistack';
import { IoMdClock } from 'react-icons/io'
import { FaRegCalendar, FaCalendarDay, FaEdit } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi'
import CommonInfos from 'components/CommonInfos'
import CommonInfosMobile from 'components/CommonInfosMobile'
import { ThemeContext } from 'styled-components'
import TableRegistros from 'components/TableRegistros'
import { useUserResume, useShowModal } from 'contexts/SharedContext'
import { GetSessionUser, MyData } from 'services/helpers'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, INFOALMOCOMESSAGE } from 'utils/types-messages'
import Tooltip from '@material-ui/core/Tooltip';
import ModalRegistroManual from 'components/ModalRegistroManual'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, Modal } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ptBR } from 'date-fns/locale'
import MessageAlert from 'components/MessageAlert';
import UpLoad from 'components/Upload';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import NavBar from 'components/NavBar'
import observacao from 'components/TableRegistros'
import { GetUserAccess, getDevice } from 'services/helpers'

export default function RegistroHoras(props) {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [dataSelected, setDataSelected] = useState(new Date())
  const [dateModal, setDateModal] = useState()
  const [hourSelected, setHourSelected] = useState(null)
  const [hourToSend, setHourToSend] = useState('')
  const [Op, setOp] = useState(0.1)
  const [rotate, setRotate] = useState(2)
  const [locais, setLocais] = useState([])
  const [servicos, setServicos] = useState([])
  const [atividades, setAtividades] = useState([])
  const [locaisSelected, setLocaisSelected] = useState()
  const [servicoSelected, setServicoSelected] = useState()
  const [myData] = useState(MyData(new Date()))
  const [observacaoText, setObservacaoText] = useState('')
  const [observacaoText2, setObservacaoText2] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [atividadesSelected, setAtividadesSelected] = useState()
  const [showTrocarProjeto, setShowTrocarProjeto] = useState(false)
  const { showModalEditarRegistro, setShowModalEditarRegistro } = useShowModal()
  const { setUserResume, userResume, showModalRegistroManual, setShowModalRegistroManual } = useUserResume()
  const [showModalUpload, setShowModalUpload] = useState(false)
  const { buttonPrimary, hoverButtonPrimary, colors, bgHeading, textTable } = useContext(ThemeContext)
  const [showModalFechamento, setShowModalFechamento] = useState(false)
  const [operacoesRegistro, setOperacoesRegistro] = useState([])
  const [showModal1Minuto, setShowModal1Minuto] = useState({ show: false, id: 0 })
  const [primeiraEntrada, setPrimeiraEntrada] = useState(false)
  const [justificativas, setJustificativas] = useState([])
  const [justificativasSelected, setJustificativasSelected] = useState(null)
  const [showModalAlertLaunch, setShowModalAlertLaunch] = useState(false)
  const [Controle, setControle] = useState(false)
  const [device, setDevice] = useState(false)
  const [regManual, setRegManual] = useState(false)
  const [titulo, setTitulo] = useState('')
  const [personId, SetPersonId] = useState();

  const access = GetUserAccess();
  const site = '/' + access.profile.toLowerCase()

  let horaServer = " "
  //let lunch = false;

  const exibeAlmoco = () => {
    let almoco = true;
    if (userResume.itens.length) {
      for (let x of userResume.itens) {
        if (x.status === 5) {
          almoco = false;
          break;
        }
      }
    } else {
      almoco = false
    }
    return almoco;
  }

  useEffect(() => {
    setDevice(getDevice());
    if (userResume.buttons) {
      const temp = []
      if (userResume.buttons.entrada) {
        setPrimeiraEntrada(true)
        temp.push({
          value: 'entrar',
          label: 'Entrada'
        })
      }

      if (userResume.buttons.saida)
        temp.push({
          value: 'sair',
          label: 'Saída'
        })

      if (userResume.buttons.troca)
        temp.push({
          value: 'trocar',
          label: 'Trocar Serviço ou Atividade'
        })
      if (exibeAlmoco()) {
        temp.push({
          value: 'intervalo',
          label: 'Intervalo'
        })
      }
      setOperacoesRegistro(temp)
    }
  }, [userResume])

  async function verificaHoraServer() {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/register/resumo/' + perfil.personId)

      if (data.success) {
        horaServer = data.result.currentTime
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function verificaAlmoco() {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/register/mensagemAlmoco/' + perfil.personId)
      if (data.success) {
        const { result } = data
        return result.messageLunch
      } else {
        return false
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
    return null
  }

  const handleHourSelected = (e) => {
    setHourSelected(e)
    const newHour = new Date(e)
    let hora = newHour.getHours()
    let minutos = newHour.getMinutes()
    if (parseInt(hora) < 10) {
      hora = '0' + hora.toString()
    }
    if (parseInt(minutos) < 10) {
      minutos = '0' + minutos.toString()
    }
    setHourToSend(hora + ':' + minutos)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    const newDate = new Date(date)
    let mes = newDate.getMonth() + 1
    let ano = newDate.getFullYear()
    let dia = newDate.getDate()
    if (parseInt(mes) < 10) {
      mes = '0' + mes.toString()
    }
    if (parseInt(dia) < 10) {
      dia = '0' + dia.toString()
    }
    setDataSelected(`${ano}-${mes}-${dia}`)
  }

  const moveDateModal = (date) => {
    const newDate = new Date(date)
    let mes = newDate.getMonth() + 1
    let ano = newDate.getFullYear()
    let dia = newDate.getDate()
    if (parseInt(mes) < 10) {
      mes = '0' + mes.toString()
    }
    if (parseInt(dia) < 10) {
      dia = '0' + dia.toString()
    }
    setDateModal(`${dia}/${mes}/${ano}`)
  }

  async function validaAlmoco() {

    const { startLunch, limitLunch } = GetSessionUser()
    await verificaHoraServer()
    //horaServer = "14:00"
    if (horaServer >= startLunch && horaServer <= limitLunch && site == '/colaborador') {
      if (await verificaAlmoco()) {
        setShowModalAlertLaunch(true)
        return false
      } else {
        setShowModalAlertLaunch(false)
        return true
      }
    } else {
      return true;
    }

  }

  const handleFechamentoAnterior = async (value) => {
    setOp(0.1)
    setShowModalFechamento(value)
    setRegManual(value)
  }

  const handleTrocarProjeto = async (value) => {
    let dataAnterior = verData(userResume.data)
    setOp(0.1)
    getServicos()
    var entrada = true;
    // if (userResume.itens.length > 0) {
    //   if (userResume.itens[userResume.itens.length - 1].saida === "") entrada = false;
    // }
    if (dataAnterior) {
      if (userResume.buttons.entrada === false) entrada = false;
    }
    setTitulo(!userResume.serviceName ? 'MEU PRIMEIRO ACESSO' : (entrada ? 'ENTRADA' : 'TROCAR SERVIÇO OU ATIVIDADE'));
    setPrimeiraEntrada(entrada)
    setShowTrocarProjeto(value)
    setRegManual(value)
  }

  const verData = async (date) => {
    const dt = new Date(date)
    const today = new Date()
    if (dt < today) {
      return () => true
    } else {
      return () => false
    }
  }

  const handleUpload = async (value, dia, regDate, update) => {
    setOp(0.1);
    setShowModalUpload(value);
    setRegManual(value)
  }

  const handleRegistroManual = async (value) => {
    setOp(0.1)
    setShowModalRegistroManual(value)
    setRegManual(value)
  }

  useEffect(() => {
    setOp(1)
  }, [showModalRegistroManual, showTrocarProjeto])


  useEffect(() => {
    if (showTrocarProjeto) {
      const interval = setInterval(() => {
        setRotate(props => props + 40)
      }, 100);
      return () => clearInterval(interval);
    }
  }, [showTrocarProjeto])



  const getAtividades = async () => {

    try {
      const { data } = await api.get('/job/getByService/' + servicoSelected.value)
      if (data.success) {
        const { result } = data
        let temp = []
        await result.map(item => {
          temp.push({
            id: item.Job.id,
            name: item.Job.name,
          })
        })
        setAtividades(temp)
      } else {
        setAtividades([]);
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }

    } catch (err) {

      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }

  }

  useEffect(() => {
    if (servicoSelected) {
      getAtividades()
    }
  }, [servicoSelected])


  const getLocais = async () => {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/place/getByCompany/' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setLocais(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)

    }
  }

  const getServicos = async () => {
    try {
      setServicoSelected('');
      setAtividadesSelected('');
      setLocaisSelected('');
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/service/getByPerson/' + perfil.personId)
      if (data.success) {
        const { result } = data
        let temp = []
        await result.map(item => {
          temp.push({
            id: item.id,
            name: item.clientName + " - " + item.name
          })
          if (item.id === userResume.serviceId) {
            setLocaisSelected({ value: userResume.placeId, label: userResume.placeName })
            setAtividadesSelected({ value: userResume.jobId, label: userResume.jobName })
            setServicoSelected({ value: userResume.serviceId, label: userResume.serviceName })
          }
        })
        setServicos(temp)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {

      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getJustificativas = async () => {
    try {
      const { data } = await api.get('/justification/0/?companyId=1')
      if (data.success) {
        const { result } = data
        setJustificativas(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getInit = async (message) => {
    try {
      const { perfil } = GetSessionUser()
      SetPersonId(perfil.personId);
      const { data } = await api.get('/register/resumo/' + perfil.personId)
      if (data.success) {
        const { result } = data
        setUserResume(result)
        // alert(JSON.stringify(result))
        //enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {

      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  useEffect(() => {
    getInit('Buscando histórico de registros...')
    getLocais()
    getServicos()
    getJustificativas()
  }, [])

  const handleServico = (item) => {
    setServicoSelected(item)
    setAtividadesSelected(null)
  }


  const handleAtividades = (item) => {
    setAtividadesSelected(item)
  }

  const handleLocais = (item) => {
    setLocaisSelected(item)
  }

  const handleJustificativas = (item) => {
    setJustificativasSelected(item)
  }

  async function handlerEntradaPreventDblClick() {
    if (!Controle) {
      setControle(true);
      if (!primeiraEntrada) {
        await sendFormTrocarServicoAtividade();
      } else {
        await handleEntrada();
      }
      setControle(false);
    }
  }

  async function handlerFechamentoAnterior() {
    if (!Controle) {
      setControle(true);
      await sendFormFechamentoAnterior();
      setControle(false);
    }
  }
  async function handlerSaidaPreventDblClick() {
    if (!Controle) {
      setControle(true);
      await handleSaida();
      setControle(false);
    }
  }
  async function handlerEditarPreventDblClick() {
    if (!Controle) {
      setControle(true);
      await sendFormEditarRegistro();
      setControle(false);
    }
  }
  async function handlerAlmocoPlayPreventDblClick() {
    if (!Controle) {
      setControle(true);
      await handleAlmoco('Iniciando Intervalo...');
      setControle(false);
    }
  }

  async function handlerAlmocoStopPreventDblClick() {
    if (!Controle) {
      setControle(true);
      await handleAlmoco('Finalizando Intervalo...');
      setControle(false);
    }
  }

  async function handleEntrada() {
    try {
      if (!servicoSelected) {
        enqueueSnackbar(<MessageAlert message={'Serviço obrigatório.'} />, WARNINGMESSAGE)
      }
      else if (!atividadesSelected) {
        enqueueSnackbar(<MessageAlert message={'Atividade obrigatória.'} />, WARNINGMESSAGE)
      }
      else if (!locaisSelected) {
        enqueueSnackbar(<MessageAlert message={'Local obrigatório.'} />, WARNINGMESSAGE)
      } else {
        const { personId } = userResume
        const obj = {
          hour: "",
          personId,
          serviceId: servicoSelected.value,
          placeId: locaisSelected.value,
          jobId: atividadesSelected.value,
          justificationId: null,
          noteRegister: observacaoText,
          latitude: "",
          longitude: ""
        }
        const { data } = await api.post("/register/entrar", obj)
        if (data.success) {
          setObservacaoText('');
          enqueueSnackbar(<MessageAlert message={'Entrada realizada.'} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        }
        handleTrocarProjeto(false)
        setPrimeiraEntrada(false)
      }
    } catch (err) {
      handleTrocarProjeto(false)
      setPrimeiraEntrada(false)
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function handleSaida() {
    try {
      if (userResume.dayOpen) {
        handleFechamentoAnterior(true)
        handleDateChange(userResume.data)
        moveDateModal(userResume.data);
      } else {
        const { personId } = userResume
        const obj = { personId }
        const { data } = await api.post("/register/sair", obj)
        if (data.success) {
          setObservacaoText('');
          enqueueSnackbar(<MessageAlert message={'Saida registrada.'} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')
        } else {
          if (data.message === 'Fechar no mesmo horário de início não permitido. Deseja Excluir a Entrada?') {
            setShowModal1Minuto({ show: true, id: data.id })
          } else {
            enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
          }
        }
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const customControlStyles = base => ({
    ...base,
    height: 35,
    minHeight: 35
  });

  useEffect(() => {
    if (userResume.Lunch) {
      showAlmocoMessage()
    }
  }, [userResume.Lunch, userResume])

  const showAlmocoMessage = () => {
    return enqueueSnackbar(<MessageAlert message={'Você esta no intervalo.'} />, INFOALMOCOMESSAGE)
  }

  const sendFormFechamentoAnterior = async () => {
    if (!hourSelected) {
      enqueueSnackbar(<MessageAlert message={'Hora obrigatória.'} />, WARNINGMESSAGE)
    }
    else if (hourSelected + '' === 'Invalid Date') {
      enqueueSnackbar(<MessageAlert message={'Hora com formato inválido.'} />, WARNINGMESSAGE)
    }
    else if (!justificativasSelected) {
      enqueueSnackbar(<MessageAlert message={'Justificativa obrigatória.'} />, WARNINGMESSAGE)
    }
    else if (!selectedDate) {
      enqueueSnackbar(<MessageAlert message={'Data obrigatória.'} />, WARNINGMESSAGE)
    } else {
      const { perfil } = GetSessionUser()
      try {
        const obj = {
          personId: perfil.personId,
          hour: hourToSend,
          date: dataSelected,
          obs: observacaoText,
          just: justificativasSelected
        }
        const { data } = await api.post("/register/fecharAnterior", obj)

        if (data.success) {
          enqueueSnackbar(<MessageAlert message={'Registro efetuado.'} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')
          handleFechamentoAnterior(false)
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
        handleFechamentoAnterior(false)
      }
    }
    setObservacaoText('');
  }

  const handleAlmoco = async (MESSAGE) => {
    const { perfil } = GetSessionUser()
    try {
      const { data } = await api.post("/register/almocar", { personId: perfil.personId })
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={'Registro efetuado.'} />, SUCCESSMESSAGE)
        getInit('Atualizando registros...')
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  useEffect(() => {
    getServicos()
  }, [showTrocarProjeto])

  useEffect(() => {
    if (showModalEditarRegistro.show)
      setObservacaoText2(localStorage.getItem('obs'))
    setServicoSelected('');
    setAtividadesSelected('');
    setLocaisSelected('');
    if (userResume.serviceId !== 0) {
      var existe = false;
      servicos.forEach(item => {
        if (item.id === userResume.serviceId) existe = true;
      });
      if (existe) {
        setLocaisSelected({ value: userResume.placeId, label: userResume.placeName })
        setAtividadesSelected({ value: userResume.jobId, label: userResume.jobName })
        setServicoSelected({ value: userResume.serviceId, label: userResume.serviceName })
      }
    }
  }, [showModalEditarRegistro.show])

  async function sendFormTrocarServicoAtividade() {
    const { perfil } = GetSessionUser()
    try {
      if (!servicoSelected) {
        enqueueSnackbar(<MessageAlert message={'Serviço obrigatório.'} />, WARNINGMESSAGE)
      }
      else if (!atividadesSelected) {
        enqueueSnackbar(<MessageAlert message={'Atividade obrigatória.'} />, WARNINGMESSAGE)
      }
      else if (!locaisSelected) {
        enqueueSnackbar(<MessageAlert message={'Local obrigatório.'} />, WARNINGMESSAGE)
      } else {
        const obj = {
          hour: "",
          personId: perfil.personId,
          serviceId: servicoSelected.value,
          placeId: locaisSelected.value,
          jobId: atividadesSelected.value,
          justificationId: null,
          noteRegister: observacaoText,
          latitude: "",
          longitude: ""
        }
        const { data } = await api.post("/register/trocar", obj)
        if (data.success) {
          setObservacaoText('');
          enqueueSnackbar(<MessageAlert message={'Alteração efetuada com sucesso.'} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')
          handleTrocarProjeto(false)
        } else {
          if (data.message === 'Fechar no mesmo horário de início não permitido. Deseja Excluir a Entrada?') {
            setShowModal1Minuto({ show: true, id: data.id })
          } else {
            enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
          }
        }
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue["100"],
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });

  const handleEditarRegistro = (value) => {
    setObservacaoText2('')
    setShowModalEditarRegistro(value)
  }

  async function alertLaunch() {
    handleLaunch(false)
    //enqueueSnackbar(<MessageAlert message={'O tempo limite excedeu, a partir de agora seu intervalo será registrado automaticamente.'} />, WARNINGMESSAGE)
  }

  const handleLaunch = (value) => {
    setShowModalAlertLaunch(value)
  }

  async function sendFormEditarRegistro() {
    try {
      if (!servicoSelected) {
        enqueueSnackbar(<MessageAlert message={'Serviço obrigatório.'} />, WARNINGMESSAGE)
      }
      else if (!atividadesSelected) {
        enqueueSnackbar(<MessageAlert message={'Atividade obrigatória.'} />, WARNINGMESSAGE)
      }

      else if (!locaisSelected) {
        enqueueSnackbar(<MessageAlert message={'Local obrigatório.'} />, WARNINGMESSAGE)
      } else {
        const obj = {
          serviceId: servicoSelected.value,
          placeId: locaisSelected.value,
          jobId: atividadesSelected.value,
          noteRegister: observacaoText2
        }
        const id = showModalEditarRegistro.id
        const { data } = await api.put("/register/" + id, obj)
        if (data.success) {
          enqueueSnackbar(<MessageAlert message={'Registro atualizado.'} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')
          handleEditarRegistro(false)
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        }
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function excluirRegistro() {
    try {
      if (showModal1Minuto.id) {
        const id = showModal1Minuto.id
        const { data } = await api.delete('/register/' + id)
        if (data.success) {
          enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
          getInit('Atualizando registros...')

        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        }
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
    setShowModal1Minuto({ show: false })
  }


  return (
    <>
      { !device &&
        <Container>
          <WrapInfos>
            <Data>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                &nbsp;&nbsp;<FaRegCalendar color={colors.primary} size={15} />&nbsp;&nbsp;<span>{myData.data}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                &nbsp;&nbsp;<FaCalendarDay color={colors.primary} size={15} />&nbsp;&nbsp;<span>{myData.dia}</span>
              </div>
            </Data>
            <WrapAlmoco>
              <WrapAlmocoMobile>
                <AlmocoIcon img={lunch} />
                {
                  userResume.Lunch ?
                    <Tooltip title="Quero Finalizar meu intervalo!" placement="bottom-start" arrow>
                      <div>
                        <StopIcon onClick={handlerAlmocoStopPreventDblClick} className={Controle ? 'disable' : 'enable'} color={colors.primary} size={25} />
                      </div>
                    </Tooltip>
                    :
                    <Tooltip title="Quero Iniciar meu intervalo!" placement="bottom-start" arrow>
                      <div>
                        <PlayIcon onClick={handlerAlmocoPlayPreventDblClick} className={Controle ? 'disable' : 'enable'} color={colors.primary} size={25} />
                      </div>
                    </Tooltip>
                }
              </WrapAlmocoMobile>
            </WrapAlmoco>
          </WrapInfos>
          <WrapInfos2>
            <Title style={{ height: '30px' }}>&nbsp;&nbsp;&nbsp;&nbsp;VOCÊ ESTÁ ATUANDO EM: </Title>
            <Infos>
              <ItemInfo3 color={textTable}>
                Cliente:<br /><TextInfo>{userResume.clientName ? userResume.clientName + " " : ' -- '}</TextInfo>
              </ItemInfo3>
              <ItemInfo color={textTable}>
                Serviço:<br /><TextInfo>{userResume.serviceName ? (userResume.serviceName.length > 20 ? userResume.serviceName.split(0, 20) + '...' : userResume.serviceName) : ' -- '}</TextInfo>
              </ItemInfo>
              <ItemInfo3 color={textTable}>
                Local:<br /><TextInfo>{userResume.placeName ? userResume.placeName + " " : ' -- '}</TextInfo>
              </ItemInfo3>
              <ItemInfo color={textTable}>
                Atividade: <br /> <TextInfo>{userResume.jobName ? userResume.jobName : ' -- '} </TextInfo>
              </ItemInfo>
            </Infos>
          </WrapInfos2>
          <ListMobile>
            <Title>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;HORAS TRABALHADAS HOJE  &nbsp; - &nbsp;{userResume.totHorasDia}</span>
              {
                userResume && !userResume.dayOpen && <Tooltip title="Realize seu registro manualmente" placement="bottom-end" arrow>
                  <span><FaEdit style={{ cursor: 'pointer' }} onClick={() => handleRegistroManual(!showModalRegistroManual)} size={22} />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Tooltip>
              }
            </Title>

            <ListBody>
              <TableRegistros />
            </ListBody>
          </ListMobile>
          <WrapOptions>
            <WrapInfos3>
              <Title style={{ height: '30px' }}>&nbsp;&nbsp;&nbsp;&nbsp;VOCÊ ESTÁ ATUANDO EM: </Title>
              <Infos>
                <ItemInfo3 color={textTable}>
                  Cliente:<br /><TextInfo>{userResume.clientName ? userResume.clientName + " " : ' -- '}</TextInfo>
                </ItemInfo3>
                <ItemInfo color={textTable}>
                  Serviço:<br /><TextInfo>{userResume.serviceName ? userResume.serviceName : ' -- '}</TextInfo>
                </ItemInfo>
                <ItemInfo3 color={textTable}>
                  Local:<br /><TextInfo>{userResume.placeName ? userResume.placeName + " " : ' -- '}</TextInfo>
                </ItemInfo3>
                <ItemInfo color={textTable}>
                  Atividade:<br /><TextInfo>{userResume.jobName ? userResume.jobName : ' -- '} </TextInfo>
                </ItemInfo>
              </Infos>
            </WrapInfos3>

            <Options>
              {
                userResume.buttons && userResume.buttons.entrada &&
                <ButtonOptions onClick={() => handleTrocarProjeto(!showTrocarProjeto)} color="white" font={'15px'} bg='#3FC248' hover="#0a9b14" >
                  <EnterIcon bg={enter} />
                  <span>Entrada</span>
                </ButtonOptions>
              }
              {
                userResume.buttons && userResume.buttons.troca &&
                <ButtonOptions color="#ddd" onClick={() => handleTrocarProjeto(!showTrocarProjeto)} bg={buttonPrimary} hover={hoverButtonPrimary} >
                  <IconSync rotate={`rotate(${rotate}deg)`} size={20} />
                  <span>Trocar Serviço <br /> ou Atividade</span>
                </ButtonOptions>
              }
              {
                userResume.buttons && userResume.buttons.saida &&
                <ButtonOptions onClick={handlerSaidaPreventDblClick} className={Controle ? 'disable' : 'enable'} color="white" font={'15px'} bg='#d3504e' hover="#C9302C" >
                  <ExitIcon bg={exit} />
                  <span>Saída</span>
                </ButtonOptions>
              }
            </Options >
          </WrapOptions >
          {
            showTrocarProjeto && <Backdrop op={Op} onClick={() => handleTrocarProjeto(!showTrocarProjeto)} />
          }
          {
            showTrocarProjeto && <TrocarProjeto>
              <Title>&nbsp;&nbsp;&nbsp;&nbsp;{titulo}</Title>
              <BodyTrocarProjeto>
                <WrapInput>
                  <LabelInput2>Serviço</LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Serviços"
                    isClearable
                    value={servicoSelected}
                    onChange={handleServico}
                    options={servicos.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput2>Atividade</LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Atividades"
                    isClearable
                    value={atividadesSelected}
                    onChange={handleAtividades}
                    options={atividades.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>Local</LabelInput>
                  <Input
                    styles={{ control: customControlStyles }}
                    placeholder="Locais"
                    isClearable
                    value={locaisSelected}
                    onChange={handleLocais}
                    options={locais.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>Observação</LabelInput>
                  <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
                </WrapInput>
                <WrapButtons>
                  <Button onClick={() => handleTrocarProjeto(false)} bg="#D12020" >Cancelar</Button>
                  <Button onClick={handlerEntradaPreventDblClick} className={Controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button>
                </WrapButtons>
              </BodyTrocarProjeto>
            </TrocarProjeto>
          }
          <ListDesktop>
            <Title>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;HORAS TRABALHADAS HOJE&nbsp; - &nbsp;{userResume.totHorasDia}&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div placement="bottom-end">
                <Tooltip title="Upload">
                  <span><FiUpload style={{ cursor: 'pointer' }} onClick={() => handleUpload(!showModalRegistroManual)} size={20} />&nbsp;&nbsp;&nbsp;</span>
                </Tooltip>
                <Tooltip title="Ponto Manual" arrow>
                  <span><TouchAppIcon style={{ cursor: 'pointer' }} onClick={() => handleRegistroManual(!showModalRegistroManual)} size={22} />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Tooltip>
              </div>
            </Title>
            <ListBody>
              <TableRegistros />
            </ListBody>
          </ListDesktop>
          {
            showModalRegistroManual && <Backdrop op={Op} onClick={() => handleRegistroManual(!showModalRegistroManual)} />
          }
          {
            showModalRegistroManual && <ModalRegistroManual operacoesRegistro={operacoesRegistro} device={false} setRegManual={setRegManual} />
          }
          {
            showModalFechamento && <Backdrop op={Op} onClick={() => setShowModalFechamento(false)} />
          }
          {
            showModalFechamento &&
            <TrocarProjeto>
              <Title>&nbsp;&nbsp;&nbsp;&nbsp;REGISTRO DE SAÍDA</Title>
              <BodyInsertSaida>
                <WrapInfosService>
                  <TitleSpan> Olá, você não registrou a saída no dia <TitleSpan2>{dateModal}</TitleSpan2> </TitleSpan>
                </WrapInfosService>
                <WrapInfosService style={{ border: '1px solid #ccc', borderRadius: '10px' }}>
                  <Title style={{ height: '30px' }}>&nbsp;&nbsp;&nbsp;&nbsp;VOCÊ ESTAVA ATUANDO EM: </Title>
                  <Infos>
                    <ItemInfo3 color={textTable}>
                      Cliente:
                                    <br />
                      <TextInfo>{userResume.clientName ? userResume.clientName + " " : ' -- '}</TextInfo>
                    </ItemInfo3>

                    <ItemInfo color={textTable}>
                      Serviço:
                                    <br />
                      <TextInfo>{userResume.serviceName ? userResume.serviceName : ' -- '}</TextInfo>
                    </ItemInfo>

                    <ItemInfo3 color={textTable}>
                      Local:
                                    <br />
                      <TextInfo>{userResume.placeName ? userResume.placeName + " " : ' -- '}</TextInfo>
                    </ItemInfo3>
                    <ItemInfo color={textTable}>
                      Atividade: <br /> <TextInfo>{userResume.jobName ? userResume.jobName : ' -- '} </TextInfo>
                    </ItemInfo>
                  </Infos>
                </WrapInfosService>
                <WrapHoraSaida>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        minDateMessage="Não é possível buscar por datas futuras."
                        //views={["year", "month", "day"]}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        autoFocus={true}
                        // disableFuture
                        helperText={''}
                        animateYearScrolling
                        margin="normal"
                        placeholder="dia, mês e ano"
                        id="date-picker-dialog"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date(new Date("01-01-2018"))}
                        maxDate={new Date(new Date())}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                        clearLabel="Limpar"
                        cancelLabel="Cancelar"
                        clearable
                        ampm={false}
                        invalidDateMessage="Hora inválida"
                        margin="normal"
                        id="time-picker"
                        label={'Hora'}
                        value={hourSelected}
                        onChange={e => handleHourSelected(e)}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </WrapHoraSaida>
                <WrapInfosService>
                  <LabelInput>
                    Justificativa
                            </LabelInput>
                  <InputService
                    styles={{ control: customControlStyles }}
                    placeholder="Justificativas"
                    isClearable
                    value={justificativasSelected}
                    onChange={handleJustificativas}
                    options={justificativas.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInfosService>
                <WrapInfosService>
                  <LabelInput>
                    Observação
                            </LabelInput>
                  <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
                </WrapInfosService>
                <WrapButtons>
                  <Button onClick={() => setShowModalFechamento(false)} bg="#D12020" >Cancelar</Button>
                  <Button onClick={handlerFechamentoAnterior} className={Controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button>
                </WrapButtons>
              </BodyInsertSaida>
            </TrocarProjeto>
          }
          {
            showModal1Minuto.show && <Backdrop op={Op} onClick={() => setShowModal1Minuto({ show: false })} />
          }
          {
            showModal1Minuto.show && <ModalFechamento>

              <Title>&nbsp;&nbsp;&nbsp;&nbsp;ATENÇÃO</Title>
              <BodyTrocarProjeto>
                <WrapHora>
                  Fechar no mesmo horário de início não permitido. Deseja Excluir a Entrada?
                        </WrapHora>

                <WrapButtons>
                  <Button onClick={() => excluirRegistro()} bg="#107CB8" >SIM</Button>
                  <Button onClick={() => setShowModal1Minuto({ show: false })} bg="#D12020" >NÃO</Button>
                </WrapButtons>
              </BodyTrocarProjeto>
            </ModalFechamento>
          }

          {
            showModalEditarRegistro.show && <Backdrop op={Op} onClick={() => handleEditarRegistro(false)} />
          }
          {
            showModalEditarRegistro.show && <TrocarProjeto>
              <Title>&nbsp;&nbsp;&nbsp;&nbsp;EDITAR REGISTRO</Title>
              <BodyTrocarProjeto>
                <WrapInput>
                  <LabelInput2>
                    Serviço
                                </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Serviços"
                    isClearable

                    value={servicoSelected}
                    onChange={handleServico}
                    options={servicos.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>

                <WrapInput>

                  <LabelInput2>
                    Atividade
              </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Atividades"
                    isClearable

                    value={atividadesSelected}
                    onChange={handleAtividades}
                    options={atividades.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>
                    Local
              </LabelInput>
                  <Input
                    styles={{ control: customControlStyles }}
                    placeholder="Locais"
                    isClearable
                    value={locaisSelected}
                    onChange={handleLocais}
                    options={locais.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>
                    Observação
                            </LabelInput>
                  <InputText onChange={e => setObservacaoText2(e.target.value)} value={observacaoText2} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
                  {/* <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength="1000" /> */}
                </WrapInput>
                <WrapButtons>
                  <Button onClick={() => handleEditarRegistro(false)} bg="#D12020" >Cancelar</Button>
                  <Button onClick={handlerEditarPreventDblClick} className={Controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button>
                </WrapButtons>
              </BodyTrocarProjeto>
            </TrocarProjeto>
          }
          {
            showModalAlertLaunch &&
            <Lockscreen>
              <AlertLaunch>
                <Title>&nbsp;&nbsp;&nbsp;&nbsp;Atenção</Title>
                <BodyLaunch>
                  <TitleSpan3> Você não registrou seu intervalo hoje! Não esqueça de realizar o registro! </TitleSpan3>
                  <WrapButtons>
                    <ButtonLaunch onClick={() => alertLaunch()} bg="#D12020" >Fechar</ButtonLaunch>
                  </WrapButtons>
                </BodyLaunch>
              </AlertLaunch>
            </Lockscreen>
          }
          <Modal open={showModalUpload}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <UpLoad personId={personId} dia={myData.data} regDate={myData.regDate} handleCloseUpload={handleUpload} device={device} btEnviar={true} />
          </Modal>
          <CommonInfos />
          <br />
        </Container >
      }
      {device && !regManual &&
        <ContainerMobile>
          <WrapInfos>
            <Data style={{ margin: '0px', padding: '0px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px', padding: '0px !important' }}>
                &nbsp;&nbsp;<FaRegCalendar color={colors.primary} size={15} />&nbsp;&nbsp;{myData.data}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px', padding: '0px !important' }}>
                &nbsp;&nbsp;<FaCalendarDay color={colors.primary} size={15} />&nbsp;&nbsp;{myData.dia}
              </div>
            </Data>
            <WrapAlmoco>
              <WrapAlmocoMobile>
                <AlmocoIcon img={lunch} />
                {
                  userResume.Lunch ?
                    <StopIcon onClick={handlerAlmocoStopPreventDblClick} className={Controle ? 'disable' : 'enable'} color={colors.primary} size={25} />
                    :
                    <PlayIcon onClick={handlerAlmocoPlayPreventDblClick} className={Controle ? 'disable' : 'enable'} color={colors.primary} size={25} />
                }
              </WrapAlmocoMobile>
            </WrapAlmoco>
          </WrapInfos>
          <WrapInfos2Mobile>
            <Title style={{ height: '30px' }}><span style={{ fontSize: '12px', textAlign: 'start' }}>&nbsp;&nbsp;&nbsp;&nbsp;VOCÊ ESTÁ ATUANDO EM: </span></Title>
            <ItemInfoMobile color={textTable}>
              &nbsp;&nbsp;Cliente:&nbsp;<TextInfoMobile>{userResume.clientName ? userResume.clientName : ' -- '}</TextInfoMobile>
            </ItemInfoMobile>
            <ItemInfoMobile color={textTable}>
              &nbsp;&nbsp;Serviço:&nbsp;<TextInfoMobile>{userResume.serviceName ? userResume.serviceName : ' -- '}</TextInfoMobile>
            </ItemInfoMobile>
            <ItemInfoMobile color={textTable}>
              &nbsp;&nbsp;Local:&nbsp;<TextInfoMobile>{userResume.placeName ? userResume.placeName : ' -- '}</TextInfoMobile>
            </ItemInfoMobile>
            <ItemInfoMobile color={textTable}>
              &nbsp;&nbsp;Atividade:&nbsp;<TextInfoMobile>{userResume.jobName ? userResume.jobName : ' -- '} </TextInfoMobile>
            </ItemInfoMobile>
          </WrapInfos2Mobile>
          {
            showTrocarProjeto && <Backdrop op={Op} onClick={() => handleTrocarProjeto(!showTrocarProjeto)} />
          }

          <WrapInfos2Mobile>
            <Title>
              <span style={{ fontSize: '12px', textAlign: 'start' }}>&nbsp;&nbsp;&nbsp;&nbsp;HORAS TRABALHADAS HOJE  &nbsp; - &nbsp;{userResume.totHorasDia}</span>
              {
                userResume && !userResume.dayOpen && <div placement="bottom-end">
                  <Tooltip title="Upload">
                    <span><FiUpload style={{ cursor: 'pointer' }} onClick={() => handleUpload(!showModalRegistroManual,'','', false)} size={20} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </Tooltip>
                  <Tooltip title="Ponto Manual" arrow>
                    <span><TouchAppIcon style={{ cursor: 'pointer' }} onClick={() => handleRegistroManual(!showModalRegistroManual)} size={20} />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </Tooltip>
                </div>
              }
            </Title>
            <ListBody style={{ overflowY: 'visible' }}>
              <TableRegistros />
            </ListBody>
          </WrapInfos2Mobile>
          <WrapOptionsMobile>
            <WrapButtons>
              {
                userResume.buttons && userResume.buttons.entrada &&
                <ButtonOptionsMobile onClick={() => handleTrocarProjeto(!showTrocarProjeto)} color="white" font={'15px'} bg='#3FC248' hover="#0a9b14" >
                  <EnterIcon bg={enter} />
                  <span>Entrada</span>
                </ButtonOptionsMobile>
              }
              {
                userResume.buttons && userResume.buttons.troca &&
                <ButtonOptionsMobile color="#ddd" onClick={() => handleTrocarProjeto(!showTrocarProjeto)} bg={buttonPrimary} hover={hoverButtonPrimary} >
                  <IconSync rotate={`rotate(${rotate}deg)`} size={20} />
                  <span>Trocar Serviço <br /> ou Atividade</span>
                </ButtonOptionsMobile>
              }
              {
                userResume.buttons && userResume.buttons.saida &&
                <ButtonOptionsMobile onClick={handlerSaidaPreventDblClick} className={Controle ? 'disable' : 'enable'} color="white" font={'15px'} bg='#d3504e' hover="#C9302C" >
                  <ExitIcon bg={exit} />
                  <span>Saída</span>
                </ButtonOptionsMobile>
              }
            </WrapButtons>
          </WrapOptionsMobile>
          <br />
          {
            showModalRegistroManual && <Backdrop op={Op} onClick={() => handleRegistroManual(!showModalRegistroManual)} />
          }
          {
            showModalFechamento && <Backdrop op={Op} onClick={() => handleFechamentoAnterior(false)} />
          }

          {
            showModal1Minuto.show && <Backdrop op={Op} onClick={() => setShowModal1Minuto({ show: false })} />
          }
          {
            showModal1Minuto.show && <ModalFechamento>
              <Title>&nbsp;&nbsp;&nbsp;&nbsp;ATENÇÃO</Title>
              <BodyTrocarProjeto>
                <WrapHora>
                  Fechar no mesmo horário de início não permitido. Deseja Excluir a Entrada?
                        </WrapHora>

                <WrapButtons>
                  <Button onClick={() => excluirRegistro()} bg="#107CB8" >SIM</Button>
                  <Button onClick={() => setShowModal1Minuto({ show: false })} bg="#D12020" >NÃO</Button>
                </WrapButtons>
              </BodyTrocarProjeto>
            </ModalFechamento>
          }

          {
            showModalEditarRegistro.show && <Backdrop op={Op} onClick={() => handleEditarRegistro(false)} />
          }
          {
            showModalEditarRegistro.show && <TrocarProjeto>
              <Title>&nbsp;&nbsp;&nbsp;&nbsp;EDITAR REGISTRO</Title>
              <BodyTrocarProjeto>
                <WrapInput>
                  <LabelInput2>
                    Serviço
                                </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Serviços"
                    isClearable

                    value={servicoSelected}
                    onChange={handleServico}
                    options={servicos.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>

                <WrapInput>

                  <LabelInput2>
                    Atividade
              </LabelInput2>
                  <Input2
                    styles={{ control: customControlStyles }}
                    placeholder="Atividades"
                    isClearable

                    value={atividadesSelected}
                    onChange={handleAtividades}
                    options={atividades.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>
                    Local
              </LabelInput>
                  <Input
                    styles={{ control: customControlStyles }}
                    placeholder="Locais"
                    isClearable
                    value={locaisSelected}
                    onChange={handleLocais}
                    options={locais.map(item => { return { value: item.id, label: item.name } })}
                    border={`0.5px ${shade(0.2, colors.surface)} solid`} />
                </WrapInput>
                <WrapInput>
                  <LabelInput>
                    Observação
                            </LabelInput>
                  <InputText onChange={e => setObservacaoText2(e.target.value)} value={observacaoText2} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
                  {/* <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength="1000" /> */}
                </WrapInput>
                <WrapButtons>
                  <Button onClick={() => handleEditarRegistro(false)} bg="#D12020" >Cancelar</Button>
                  <Button onClick={handlerEditarPreventDblClick} className={Controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button>
                </WrapButtons>
              </BodyTrocarProjeto>
            </TrocarProjeto>
          }
          {
            showModalAlertLaunch &&
            <Lockscreen>
              <AlertLaunch>
                <Title>&nbsp;&nbsp;&nbsp;&nbsp;Atenção</Title>
                <BodyLaunch>
                  <TitleSpan3> Você não registrou seu intervalo hoje! Não esqueça de realizar o registro! </TitleSpan3>
                  <WrapButtons>
                    <ButtonLaunch onClick={() => alertLaunch()} bg="#D12020" >Fechar</ButtonLaunch>
                  </WrapButtons>
                </BodyLaunch>
              </AlertLaunch>
            </Lockscreen>
          }
        </ContainerMobile>
      }
      {
        device && regManual && showModalUpload && <UpLoad personId={personId} dia={myData.data} regDate={myData.regDate} handleCloseUpload={handleUpload} device={device} btEnviar={true} />
      }
      {
        device && regManual && showModalRegistroManual && <ModalRegistroManual operacoesRegistro={operacoesRegistro} device={device} setRegManual={setRegManual} />
      }
      {device && regManual && showTrocarProjeto &&
        <ContainerMobile>
          <Title>&nbsp;&nbsp;&nbsp;&nbsp;{titulo}</Title>
          <BodyTrocarProjeto>
            <WrapInput>
              <LabelInput2>Serviço</LabelInput2>
              <Input2
                styles={{ control: customControlStyles }}
                placeholder="Serviços"
                isClearable
                value={servicoSelected}
                onChange={handleServico}
                options={servicos.map(item => { return { value: item.id, label: item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
            <WrapInput>
              <LabelInput2>Atividade</LabelInput2>
              <Input2
                styles={{ control: customControlStyles }}
                placeholder="Atividades"
                isClearable
                value={atividadesSelected}
                onChange={handleAtividades}
                options={atividades.map(item => { return { value: item.id, label: item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
            <WrapInput>
              <LabelInput>Local</LabelInput>
              <Input
                styles={{ control: customControlStyles }}
                placeholder="Locais"
                isClearable
                value={locaisSelected}
                onChange={handleLocais}
                options={locais.map(item => { return { value: item.id, label: item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
            <WrapInput>
              <LabelInput>Observação</LabelInput>
              <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
            </WrapInput>
            <WrapButtons>
              <Button onClick={() => handleTrocarProjeto(false)} bg="#D12020" >Cancelar</Button>
              <Button onClick={handlerEntradaPreventDblClick} className={Controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button>
            </WrapButtons>
          </BodyTrocarProjeto>
        </ContainerMobile>
      }
      {device && regManual && showModalFechamento &&
        <ContainerMobile>
          <Title>&nbsp;&nbsp;&nbsp;&nbsp;REGISTRO DE SAÍDA</Title>
          <BodyTrocarProjeto>
            <WrapInfosService>
              <TitleSpanMobile> Olá, você não registrou a saída no dia <TitleSpan2>{dateModal}</TitleSpan2></TitleSpanMobile>
            </WrapInfosService>
            <WrapInfos2Mobile style={{ paddingLeft: '3px' }} >
              <Title style={{ height: '30px' }}><span style={{ fontSize: '12px', textAlign: 'start' }}>&nbsp;&nbsp;&nbsp;&nbsp;VOCÊ ESTAVA ATUANDO EM: </span></Title>
              <ItemInfoMobile color={textTable}>
                &nbsp;&nbsp;Cliente:&nbsp;<TextInfoMobile>{userResume.clientName ? userResume.clientName : ' -- '}</TextInfoMobile>
              </ItemInfoMobile>
              <ItemInfoMobile color={textTable}>
                &nbsp;&nbsp;Serviço:&nbsp;<TextInfoMobile>{userResume.serviceName ? userResume.serviceName : ' -- '}</TextInfoMobile>
              </ItemInfoMobile>
              <ItemInfoMobile color={textTable}>
                &nbsp;&nbsp;Local:&nbsp;<TextInfoMobile>{userResume.placeName ? userResume.placeName : ' -- '}</TextInfoMobile>
              </ItemInfoMobile>
              <ItemInfoMobile color={textTable}>
                &nbsp;&nbsp;Atividade &nbsp;<TextInfoMobile>{userResume.jobName ? userResume.jobName : ' -- '} </TextInfoMobile>
              </ItemInfoMobile>
            </WrapInfos2Mobile>
            <WrapHoraSaida>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  {/* <KeyboardDatePicker style={{ paddingRight: '10px' }}
                    minDateMessage="Não é possível buscar por datas futuras."
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    ampm={false}
                    autoFocus={true}
                    helperText={''}
                    animateYearScrolling
                    margin="normal"
                    placeholder="dia, mês e ano"
                    id="date-picker-dialog"
                    label="Data"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  /> */}


                  <KeyboardDatePicker
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    minDateMessage="Não é possível buscar por datas futuras."
                    margin="normal"
                    helperText={""}
                    id="date-picker-dialog"
                    label="Data Início"
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    // keyboardIcon={<IoMdClock color={colors.primary} size={15} />}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />


                </MuiPickersUtilsProvider>
              </ThemeProvider>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    keyboardIcon={<IoMdClock color={colors.primary} size={19} />}
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    ampm={false}
                    invalidDateMessage="Hora inválida"
                    margin="normal"
                    id="time-picker"
                    label={'Hora'}
                    value={hourSelected}
                    onChange={e => handleHourSelected(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </WrapHoraSaida>
            <WrapInput>
              <LabelInput>Justificativa</LabelInput>
              <Input
                styles={{ control: customControlStyles }}
                placeholder="Justificativas"
                isClearable
                value={justificativasSelected}
                onChange={handleJustificativas}
                options={justificativas.map(item => { return { value: item.id, label: item.name } })}
                border={`0.5px ${shade(0.2, colors.surface)} solid`} />
            </WrapInput>
            <WrapInput>
              <LabelInput>Observação</LabelInput>
              <InputText onChange={e => setObservacaoText(e.target.value)} border={`0.5px ${shade(0.2, colors.surface)} solid`} maxLength={250} />
            </WrapInput>
            <WrapButtons>
              <Button onClick={() => handleFechamentoAnterior(false)} bg="#D12020" >Cancelar</Button>
              <Button onClick={handlerFechamentoAnterior} className={Controle ? 'disable' : 'enable'} bg="#107CB8" >Confirmar</Button>
            </WrapButtons>
          </BodyTrocarProjeto>
        </ContainerMobile>
      }
    </>
  )
}

