import styled from 'styled-components'
import { FaSearch, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Select from 'react-select';

export const Container = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;

export const WrapButtons = styled.div `
position:absolute;
bottom:0;
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;

// background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;

`;

export const NextPage = styled(FaAngleRight)
`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;
export const PrevPage = styled(FaAngleLeft)
`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;

export const Wrap = styled.div `
display: flex;
justify-content: space-around;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
background-color: ${props => props.theme.colors.surface};
width: 65%;

border-radius:10px;
padding-bottom:15px;
@media (max-width: 800px){
    width: 95%;
    
}
`;
export const TitleStyle2 = styled.h2 `
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    width:100%;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;

`
export const ColaboradoresContainer = styled.div `
display: flex;
width: 35%;
padding: 20px 0 0;
margin:0;
@media (max-width: 800px){
    display:none
}
`;




export const ColaboradoresCard = styled.div `
    background-color: ${props => props.bgColor};
`;
export const SearchRow = styled.div `
width:98%;
display:flex;
justify-content: flex-start;
flex-direction: row;
flex-wrap: wrap;
position:relative;
@media (max-width: 800px){
    /* flex-direction: column; */
    justify-content: center;
}
`;

export const WrapInput = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 23%;
padding: 0 10px;
margin:0;
box-sizing:border-box;
@media (max-width: 800px){
    padding-top:15px;
    width: 48%;
}
`;



export const Items = styled.div `
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 70px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;




export const IconeSearch = styled(FaSearch)
`

&:hover{
    opacity: 0.7;
}

`;

export const Button = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 40%;
height: 40px;
background-color: #107CB8;
border-radius: 10px;
margin-left: 20px;
color: white;
cursor: pointer;
&:hover{
    opacity: 0.7;
}
`;



export const WrapList = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const BodyList = styled.div `
background-color: ${props => props.theme.bgColor};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
/* overflow-y:auto;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
padding-top: 10px;
margin-bottom: 30px;
border-bottom-left-radius:10px;
border-bottom-right-radius:10px; */
`;


export const HeaderList = styled.div `
background-color: ${props => props.theme.bgBorderButtonMenu};
color: ${props => props.theme.textHeading} !important;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
/* display: flex;
justify-content: flex-start;
align-items: center;
font-size: 14px;
font-weight:bold;
text-transform:uppercase;
height: 25px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
width: 94%; */
`;



export const Row = styled.div `
background-color: ${props => props.bgColor};
color: gray;
/* display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;

padding-top: 10px;
padding-bottom: 10px; */
`;

export const Column = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 20%;
padding:0 25px;
color: ${props => props.theme.textTable};
text-align:center !important
`;

export const MySelect = styled(Select)
`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.surface};
`;

export const inputStyle = {
    borderRadius: '5px',
    borderColor: 'gray',
    padding: '0',
    margin: '0',

    width: '100%'
};

export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,
        padding: 0,
        margin:0,
        height: '30px',
        minHeight: '30px',
        width: "100%"
    }),
    control: base => ({
        ...base,
        backgroundColor: `${props => props.theme.colors.background}`,
        borderColor: 'gray',
        height: '30px',
        minHeight: '30px',
        fontSize: 14,
        padding: 0,
        margin:0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 5,        
       
    }),
    clearIndicator: base => ({
        ...base,
        padding: 5,
       
    }),
    indicatorSeparator: base => ({
        ...base,

        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: '0',      
        overflow: "hidden",   
        textOverflow: "ellipsis",     
        height: '30px',
        minHeight: '30px',   
    }),
    multiValue: (base, state) => ({
        ...base,
           
    }),
   
};
// export const selectStyle = {
//     container: (base, state) => ({
//         ...base,
//         height: '30px',
//         width: '100%',
//         minHeight: '30px'

//     }),
//     control: (base, state) => ({
//         ...base,
//         backgroundColor: 'transparent',
//         borderRadius: 5,
//         height: '30px',
//         minHeight: '30px'
//     }),
//     valueContainer: (base, state) => ({
//         ...base,
//         // background: "pink",
//         height: '30px',
//         minHeight: '30px',
//         padding: '0 6px'
//     }),
//     input: (base, state) => ({
//         ...base,
//         margin: '0px',
//     }),
//     multiValue: (base, state) => ({
//         ...base,

//         whiteSpace: "nowrap",
//         overflow: "hidden",
//         textOverflow: "ellipsis",
//         maxWidth: "100px",
//         height: '20px',
//         minHeight: '20px'
//     }),
//     dropdownIndicator: (base, state) => ({
//         ...base,
//         color: `${props => props.theme.colors.primary}`,
//         // background: "blue",
//         height: '30px',
//         minHeight: '30px',
//         position: 'relative',
//         top: '-5px'

//     }),
//     indicatorSeparator: (styles) => ({ display: 'none' }),
//     indicatorsContainer: (base, state) => ({
//         ...base,
//         height: '30px',
//     }),
// };

export const Data = styled.div `
font-size: 13px;
margin-left: 20px;
display: flex;
justify-content: space-between;
align-items: center;    
width: 320px;
color: ${props => props.theme.textTable};
font-weight: 700;
@media(max-width: 1740px){
    width: 310px;
}
@media(max-width: 535px){
font-size: 12px;
margin: 0;
height: 100%;
flex-direction: column;
align-items: flex-start;
justify-content: space-around;
}

`;