import styled from 'styled-components'

export const HeaderList = styled.div `
background-color: ${props => props.theme.bgBorderButtonMenu};
color: ${props => props.theme.textHeading} !important;
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 14px;
font-weight:bold;
text-transform:uppercase;
height: 25px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 94%;

`;
export const BodyList = styled.div `
height: 50vh;
overflow-y:auto;
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
margin-bottom: 30px;
background-color: ${props => props.theme.bgColor};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
border-bottom-left-radius:10px;
border-bottom-right-radius:10px;
@media (max-width: 800px){
    height: 80vh
}
`;
export const Row = styled.div `
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;
export const RowHeader = styled.div `
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
background-color: ${props => props.theme.colors.background};

padding-top: 10px;
padding-bottom: 10px;
font-weight: 500;
text-transform: uppercase;
position: -webkit-sticky;
position: sticky;
top: 0;
`;
export const Column = styled.div `
display: flex;
justify-content: center;
align-items: center;
width: 20%;
padding:0 25px;
color: ${props => props.theme.textTable};
text-align:center !important
`;


/* export const TableHeaderBG = {
    
}
 */
