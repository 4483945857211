import styled from 'styled-components'

export const LabelInput = styled.div`
width: 100%;
color: gray;
padding-bottom: 10px;
padding-left: 10px;
`;


export const LabelStatus = styled.div`
color: gray;
`;


export const Select = styled.select`
width: 100%;
height: 30px;
background-color: ${props => props.theme.colors.surface};
border: 1px ${props => props.theme.borderInputColor} solid;
border-radius: 10px;
box-shadow: none;
outline: 0;
padding-left: 15px;
color: ${props => props.theme.colors.textBg}
`;


export const Input = styled.input`
width: 100%;
height: 25px;
background-color: ${props => props.theme.colors.surface};
border: 1px ${props => props.theme.borderInputColor} solid;
border-radius: 120px;
box-shadow: none;
outline: 0;
padding-left: 15px;
color: ${props => props.theme.colors.textBg}
`;


export const WrapSmall = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 10%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapMedium = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 30%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const inputStyle = {
    borderRadius: 5,
    backgroundColor: 'white',
    borderColor: 'gray',
    width: '96%',
    fontSize: 14,
    height: 31,
    minHeight: 31,
};

export const multSelect = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        margin: 0,
        textAlign: "left",
        cursor: "pointer"
    }),
    container: base => ({
        ...base,
        width: "100%",
        cursor: "pointer"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'white',
        borderColor: 'gray',
        height: 35,
        minHeight: 35,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "101%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 0,
    }),
    indicatorSeparator: base => ({
        ...base,
        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: 0,
        paddingLeft: 2
    })
};

export const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        fontSize: 14,
        textAlign: "left",
        cursor: "pointer"

    }),
    container: base => ({
        ...base,
        width: "100%",
        cursor: "pointer"
    }),
    control: base => ({
        ...base,
        backgroundColor: 'white',
        borderColor: 'gray',
        height: 35,
        minHeight: 35,
        fontSize: 14,
        padding: 0,
        margin: 0,
        borderRadius: 5,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 5,        
       
    }),
    clearIndicator: base => ({
        ...base,
        padding: 5,
       
    }),
    indicatorSeparator: base => ({
        ...base,

        display: "none"
    }),
    valueContainer: base => ({
        ...base,
        padding: '0',      
        overflow: "hidden",   
        textOverflow: "ellipsis",     
        height: '30px',
        minHeight: '30px',   
    }),
    multiValue: (base, state) => ({
        ...base,
           
    }),
};