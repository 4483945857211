import styled from 'styled-components'
import { FaSearch, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Select from '@material-ui/core/Select';


export const Container = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100vh;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;

export const NextPage = styled(FaAngleRight)`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;
export const PrevPage = styled(FaAngleLeft)`
cursor: pointer;
&:hover{
opacity: 0.7;
}
`;

export const WrapList = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const WrapSearch = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
height: 25%;
background-color: ${props => props.theme.colors.background};
@media (max-width: 800px){
    flex-direction: column;
    justify-content:flex-start;
    min-height: 35%;
}
`;


export const MySelect = styled(Select)`
width: 100%;
height: 30px;

box-shadow: none;
outline: 0;
padding-left: 15px;
border-bottom: none;
`;


export const Items = styled.div`
display: flex;
justify-content:flex-start;
align-items: flex-end;
width: 100%;
height: 70px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;

export const IconeSearch = styled(FaSearch)`
display: flex;
justify-content: flex-start;
align-items: flex-start;
&:hover{
    opacity: 0.7;
}

`;




export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 40%;
height: 40px;
background-color: #107CB8;
border-radius: 20px;
margin-left: 20px;
color: white;
cursor: pointer;
&:hover{
    opacity: 0.7;
}
`;



export const WrapInput = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
height: 100%;
margin-left: 1px;
margin-right: 1px;
@media (max-width: 800px){
    width: 60%;
}
`;


export const HeaderList = styled.div`
color: ${props => props.theme.textHeading};
display: flex;
justify-content: flex-start;
align-items: center;
background-color: ${props => props.theme.bgHeading};
height: 40px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
width: 94%;
`;


export const BodyList = styled.div`
display: flex;
justify-content: flex-start;
flex-direction: column;
align-items: center;
width: 94%;
padding-top: 30px;
background-color: white;
`;

export const Row = styled.div`
color: gray;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: row;
width: 100%;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;

export const Column = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: 20%;
`;

export const ColumnEditItem = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: 20%;
z-index: 100;
`;


export const WrapButtons = styled.div`
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;