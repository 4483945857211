import styled from 'styled-components';



export const FooterTag = styled.div`
    width: 100%;
    font-size: 12px;
    display: flex;

    justify-content: center;
    font-weight: 500;
    color: #F5F5F5;

    height: auto;
    margin-top: auto;
    background-color: $BLUE;
    position: relative;
`;