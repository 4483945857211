import styled from 'styled-components'

export const Options = styled.span`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100px;
background-color: #F1F1F1;
`;

export const Editar = styled.span`

`;



export const ReenviarSenha = styled.span`

`;


