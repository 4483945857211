import styled from 'styled-components'

export const modalStyle = {
    backgroundColor: 'white',    
    top: '315px',
    width: '100%',
    overflow: 'unset'
}
    
export const StyledBox = styled.div`
  background-color: white;
  top: 315px;
  width: 100%;
  ​​​​​:focus {​​​​​
    outline: none !important;
  }​​​​​
`;

export const modalStyle2 = {
    backgroundColor: 'white',    
    top: '315px',
    width: '100%'
}

export const ModalBox = styled.div `
/* background-color: ${props => props.theme.colors.surface};
display:flex */
/* max-height: 50vh; */
overflow-y:scroll;
overflow-x:hidden;
flex-flow: column nowrap;
align-items: center;
justify-content: stretch;
padding: 5px;
margin:0;
min-height: 350px;
@media (max-width: 800px){
    max-height: 80vh;
} 

`;

export const ModalBoxUpload = styled.div`
    flex-flow: column nowrap;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border: 0px;
    margin:0;
    min-height: 350px;
    @media (max-width: 800px){
        max-height: 80vh;
    } 
    `;



export const modalStyle3 = {
    backgroundColor: 'white',  
    top: '315px',
    maxWidth: '75vw !important'
}

export const modalClose = {
    cursor: 'pointer', 
    fontWeight: 'bold',
    display: 'block',
    marginLeft: 'auto'
    
}



export const ModalBox2 = styled.div `
/* background-color: ${props => props.theme.colors.surface};
display:flex */
/* max-height: 50vh; */
overflow-y:auto;
overflow-x:hidden;
flex-flow: column nowrap;
align-items: center;
justify-content: stretch;
padding: 5px;
margin:0;
height: 450px;


@media (max-width: 800px){
    max-height: 80vh;
} 
`