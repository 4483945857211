import React, { useEffect} from 'react'
import { Container } from './styles.js'
import {Helmet} from "react-helmet";
import './styles.css'

export default function Faq() {



    return (
        <Container> 
        <Helmet>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css" />
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
       </Helmet>

          
       
                <h2 style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>FAQ</h2>
        
                <div className="col-md-6">
        
                    <div className="divisor">
                            <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo1">1)Primeira vez que acesso o Worklog, posso bater o ponto automático?</button>
                            <div id="demo1" className="collapse">
                            R: Sim, ao clicar no botão “entrada”, o sistema lhe apresentará um modal ao qual você escolherá o serviço, atividade e local de trabalho.
                            </div>
                    </div>
        
                    <div className="divisor">
                            <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo2">2)No meu primeiro acesso do dia, onde ver o serviço que estou logada(o) antes <br/> de bater o ponto?</button>
                            <div id="demo2" className="collapse">
                            R: O sistema entenderá que você irá continuar no mesmo serviço que finalizou o dia anterior, caso deseje ter certeza do serviço que está no momento, basta verificar a section “Você está atuando em:”.
                            <img src={require('./imagens/demo1.png')} width="100%" />
                            </div>
                    </div>
        
                    <div className="divisor">
                            <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo3">3)Onde posso ver o Serviço, Atividade e Local onde estou trabalhando?</button>
                            <div id="demo3" className="collapse">
                            R: É possível ver em na section “Você está atuando em:” e na section “Horas trabalhadas hoje”.
                            <img src={require('./imagens/demo2.png')} width="100%" />
                            </div>
                    </div>
        
                    <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo4">4)Quando trocar serviço/atividade, preciso bater a saída do serviço anterior?</button>
                        <div id="demo4" className="collapse">
                        R: Não precisa, o próprio sistema realizará a saída do serviço/atividade anterior.
                        </div>
                    </div>
        
                    <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo5">5)Preciso clicar em “saída” para me associar a outro projeto?</button>
                        <div id="demo5" className="collapse">
                        R: Não, basta realizar a troca do serviço/atividade, que a saída do serviço anterior será realizada automaticamente.
                        </div>
                    </div>
        
        
                    <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo6">6)Caso clicar em “saída” para realizar entrada em outro serviço acidentalmente,<br/> posso apertar direto o botão “entrada”?</button>
                        <div id="demo6" className="collapse">
                        R: Não, pois nesse caso você estará fazendo entrada automática no mesmo serviço ao qual você saiu. Você deverá clicar em “trocar serviço/atividade”, dessa forma você selecionará o novo serviço ao qual quer se associar e a entrada acontecerá normalmente.
                        </div>
                    </div>
        
                    <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo7">7)	Como faço para bater o ponto do intervalo?</button>
                        <div id="demo7" className="collapse">
                        R: A entrada e a saída do intervalo, acontece pelo botão “Intervalo” no canto direito superior da tela.
                        </div>
                    </div>
                
        
                    <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo8">8)O que fazer se registrar o ponto automaticamente errado?</button>
                        <div id="demo8" className="collapse">
                        R: Você deve editar o registro que está localizado na tabela “Horas Trabalhadas Hoje”, mas lembrando que só é possível editar o último registro.
                        </div>
                    </div>
                
                
        
                </div>
                <div className="col-md-6">
        
                    <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo9">9)Posso bater o ponto offline?</button>
                        <div id="demo9" className="collapse">
                            R: Não!
                        </div>
                    </div>
        
                <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo10">10)Posso bater o ponto pelo meu celular?</button>
                        <div id="demo10" className="collapse">
                            R: Sim, a aplicação oferece uma versão mobile, porém só é possível bater ponto automático.
                        </div>
                </div>
        
                <div className="divisor">
                        <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo11">11)Esqueci de bater meu ponto automático, o que faço?</button>
                        <div id="demo11" className="collapse">
                            R: A aplicação oferece o método de bater ponto manual, onde é possível editar horário, serviço, atividade e local. Para ter acesso, clique no botão editar, no canto direito da tela, conforme a figura abaixo:
                            <img src={require('./imagens/demo11.png')} width="100%" />
                        </div>
                </div>
        
                <div className="divisor">
                    <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo12">12)Quero editar a minha observação do registro anterior, como faço?</button>
                    <div id="demo12" className="collapse">
                    R: Caso queira editar a observação do registro anterior, basta clicar no campo observação e editar. Para salvar, basta clicar fora do campo, a aplicação salvará automaticamente.
                    <img src={require('./imagens/demo12.png')} width="100%" />
                    </div>
                </div>
        
                <div className="divisor">
                    <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo13">13)Como faço para acessar o site do Ibrati ?</button>
                    <div id="demo13" className="collapse">
                        R: No canto esquerdo da tela, tem um ícone que encaminhará o usuário diretamente para o site do ibrati, vide figura abaixo:
                       <p> <img src={require('./imagens/demo13.png')} width="25%" height="50%" /></p>
                    </div>
                </div>
        
        
                <div className="divisor">
                    <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo14">14)Como faço para acessar o meu Webmail?</button>
                    <div id="demo14" className="collapse">
                    R: No canto esquerdo da tela, tem um ícone que encaminhará o usuário diretamente para o webmail, vide figura abaixo:
                   <p> <img src={require('./imagens/demo14.png')} width="25%" height="50%" /></p>
        
                    </div>
                </div>
        
                <div className="divisor">
                    <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo15">15)	Como faço para editar meu registro anterior se precisar?</button>
                    <div id="demo15" className="collapse">
                        R: Para editar o registro anterior, você terá que excluir o último registro, para ter acesso ao registro que deseja editar.
        
                    </div>
                </div>
        
                <div className="divisor">
                    <button type="button" className="btn btn-info" data-toggle="collapse" data-target="#demo16">16)As minhas horas trabalhadas, como faço para encontrá-las?</button>
                    <div id="demo16" className="collapse">
                        R: No final da tela do colaborador, terá um campo onde apresenta as horas trabalhadas total do mês e dia.
                    <img src={require('./imagens/demo16.png')} width="100%" />
        
                    </div>
                </div>
            
        
                </div>
              
        
                
              
                
        </Container>
        
    )
}