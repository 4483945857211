import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa'



export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
display:flex;
flex-direction:column;
justify-content: flex-start;
align-items: center;
height: 92.5vh;
@media( max-width: 900px){
    display: none;
}
`;

export const WrapButtons = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
`;


export const WrapHora = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
color: gray;
border-radius: 5px;
width: 100%;
height: 25px;
padding: 2px;
margin-top: 20px; 
`;

export const BodyTrocarProjeto = styled.div`
background-color: ${props => props.theme.colors.surface};
width: 100%;
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);

`;

export const ModalFechamento = styled.div`
padding-top: 10px;
width: 450px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border-radius: 10px;
z-index: 400; 
position: fixed;
@media(max-width: 500px){
    width: 90%;
}
 `;

export const Button2 = styled.div`
font-size: 12px;
width: 100px;
height: 30px;
background-color: ${props => props.bg};
color: white;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
margin: 15px;
margin-top: 40px;
cursor: pointer;
&:hover{
    opacity: 0.9;
}
`;

export const Container2 = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
display:none;
flex-direction:column;
justify-content: center;
align-items: center;
height: 100vh;
@media( max-width: 900px){
    display: flex;
}
`;

export const Header = styled.div`
width: 98%;
display: flex;
justify-content: space-between;
align-items: center;
padding-top: 10px;
@media(max-width: 1245px){
    flex-direction: column;
}
`;

export const IconeSearch = styled(FaSearch)`
display: flex;
justify-content: flex-start;
align-items: flex-start;
margin-left: 60px;
padding-top: 20px;
padding-right: 50px;
cursor: pointer;
`;

export const TitlePeriodo = styled.div`
width: 100%;
display:flex;
justify-content: center;
align-items:center;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
font-weight: 500;
color: ${props => props.theme.textHeading};
background-color: ${props => props.theme.bgMenu};
`;

export const WrapSearch = styled.div`
width: 100%;
flex-direction:row;
display: flex;
justify-content: space-between;
align-items: center;
height: 80px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
`;

export const Title2 = styled.span`
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: ${props => props.theme.textHeading};
font-weight: bold;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;


export const Wrap = styled.div`
width: 400px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 10px;
background-color: ${props => props.theme.colors.surface};
`;


export const List = styled.div`
width: 98%;
background-color: ${props => props.theme.colors.surface};
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 10px;
padding: 0;
`;

export const ListBody = styled.div`
background-color: ${props => props.theme.colors.surface};
width: 100%;
display:flex;
flex-direction: column;
justify-content:center;
align-items: center;
padding: 0;
max-height: 70vh;
@media (max-height: 1060px){
    max-height: 69vh;
}
@media (max-height: 945px){
    max-height: 67vh;
}
@media (max-height: 877px){
    max-height: 66vh;
}
@media (max-height: 850px){
    max-height: 64vh;
}
@media (max-height: 800px){
    max-height: 62vh;
}
@media (max-height: 750px){
    max-height: 60vh;
}
@media (max-height: 700px){
    max-height: 58vh;
}
@media (max-height: 670px){
    max-height: 56vh;
}
@media (max-height: 650px){
    max-height: 54vh;
}
@media (max-height: 627px){
    max-height: 51vh;
}
@media (max-height: 600px){
    max-height: 51;
}
@media (max-height: 590px){
    max-height: 49vh;
}
`;
export const Title = styled.span`
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: #F5F5F5;
font-weight: bold;
display: flex;
justify-content: space-between;
flex-direction: row;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;





export const WrapFooter = styled.div`
margin-top: 16px;
width: 98%;
display: flex;
justify-content: flex-end;
align-items: center;
@media (max-width: 800px){
    justify-content: center;
}
`;

export const Button = styled.div`
font-size: 13px;
width: 140px;
height: 40px;
background-color: ${props => props.theme.buttonPrimary};
color: ${props => props.color};
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
margin-right: ${props => props.marginR};
font-weight: bold;
cursor: pointer;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
&:hover{
    background-color: ${props => props.theme.hoverButtonPrimary};
    transition: background-color 0.3s;
}
&:active {
 opacity: 0.7;
 transition: opacity 0.3s;
}
`;

export const Backdrop = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 300;
transition: opacity 0.5s;
background-color: rgba(0, 0, 0, 0.2);
`;