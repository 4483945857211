import React, { useState, useEffect } from 'react'
import {
  Container,
  TitleStyle2,
  Wrap,
  WrapInput,
  Items,
  WrapInputSwitch,
  selectStyle
} from 'components/SharedComponents/styles';
import { overrideStrings } from 'contexts/SharedContext'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, LOADMESSAGE } from 'utils/types-messages';
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { LabelInput, LabelStatus, multSelect } from 'components/Input';
import MultiSelect from "react-multi-select-component";
import MessageAlert from 'components/MessageAlert'
import Switch from '@material-ui/core/Switch';
import { Button } from "../../components/Button";
import { withStyles } from '@material-ui/core/styles';
import { MySelect as MySelect2 } from "../../components/InputSelect";
import { WrapInputDate } from "./styles"
import { Modal, CircularProgress } from "@material-ui/core";
import { getMonthYear } from 'services/helpers'
import { MySelect } from "../../components/InputSelect";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { SearchBox, ItemsSearch, IconPdf } from "../../components/Search";


export default function RelatorioHoraCliente() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [compId, setCompId] = useState(1);
  const [pdf, setPdf] = useState({ dataInicial: "", dataFinal: "", personId: [], clientId: [], aggrementId: [], serviceId: [], headerFlag: false });
  const [client, setClient] = useState([]);
  const [clientSelected, setClientSelected] = useState([]);

  const [aggrement, setAggrement] = useState([]);
  const [aggrementSelected, setAggrementSelected] = useState([]);

  const [service, setService] = useState([]);
  const [serviceSelected, setServiceSelected] = useState([]);

  const [person, setPerson] = useState([]);
  const [personSelected, setPersonSelected] = useState([]);

  const [team, setTeam] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);
  const [loadDialog, setLoadDialog] = useState(false);
  const [relStateToFilter, setRelStateToFilter] = useState(true);

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFim, setSelectedDateFim] = React.useState(null);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [op, setOp] = useState(null);
  const [statusSelected, setStatus] = useState(0);
  const [monthYear] = useState(getMonthYear());
  const [selectedMesAno, setSelectedMesAno] = useState([]);
  const opCabecalho = [
    { value: false, label: "Horas Registradas" },
    { value: true, label: "Filtros Selecionados" }
  ]

  const SwitchColor = withStyles({
    switchBase: {
      color: 'white',
      '&$checked': {
        color: '#107CB8',
      },
      '&$checked + $track': {
        backgroundColor: '#107CB8',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  //Combo Cliente
  const getClient = async (boolean) => {
    try {

      const { data } = await api.get('/client?companyId=' + compId)
      if (data.success) {
        const { result } = data
        setClient(result.map((item) => { return { label: item.fantasyName, value: item.id, disabled: boolean } }))

      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  useEffect(() => {
    getClient(false)
    setStatus({ value: 0, label: "Só Ativos" });
    setOp({ value: false, label: "Horas Registradas" })
  }, []);

  //Combo Contrato
  const getAggrement = async (idClient) => {
    try {
      const { data } = await api.get('client/getAggrement/' + idClient)
      if (data.success) {
        const { result } = data
        setAggrement(result.map((item) => { return { label: item.AggrementName, value: item.AggrementId } }))
      } else {
        enqueueSnackbar("Não há contratos para este cliente!", WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  //Combo Serviço
  const getService = async (idAgg, status) => {
    try {
      const { data } = await api.get('aggrement/getServiceStatus/' + idAgg + '/' + status)
      if (data.success) {
        const { result } = data
        setService(result.map((item) => { return { label: item.ServiceName, value: item.ServiceId } }))
      } else {
        enqueueSnackbar('Não há serviços para este contrato!', WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }

  }

  const getPersonPorData = async (idService) => {
    try {
      let dataInicial = pdf.dataInicial
      let dataFim = pdf.dataFinal
      const { data } = await api.get('service/getPerson/' + idService + "?dataInicial=" + dataInicial + "&dataFim=" + dataFim)
      if (data.success) {
        const { result } = data
        setPerson(result.map((item) => { return { label: item.PersonName, value: item.PersonId } }))
      } else {
        enqueueSnackbar('Não há pessoas para este serviço!', WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Person
  const getPerson = async (idService) => {
    try {
      const { data } = await api.get('service/getPerson/' + idService)
      if (data.success) {
        const { result } = data
        setPerson(result.map((item) => { return { label: item.PersonName, value: item.PersonId } }))
      } else {
        enqueueSnackbar('Não há pessoas para este serviço!', WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  //Combo Equipe
  const getTeam = async () => {
    try {
      const { data } = await api.get('/team?companyId=' + compId)
      if (data.success) {
        const { result } = data
        setTeam(result.map((item) => { return { label: item.name, value: item.id } }));
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  useEffect(() => {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setRelStateToFilter(true);
    setPdf({ ...pdf, headerFlag: true });
  }, []);


  let handleDateChange = (date) => {
    handleClient([])
    setSelectedDate(date);
    setButtonDisable(false);
    setClientSelected('');
    setAggrementSelected([]);
    setAggrement([]);
    setService([]);
    setServiceSelected([]);
    setPerson([]);
    setPersonSelected([]);
    setStatus({ value: 0, label: "Só Ativos" });


    if (date === null) {
      setPdf({ ...pdf, dataInicial: "" });
      setButtonDisable(true);

    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();

      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = day + '/' + mes + '/' + ano;
      pdf.dataInicial = data;
      setPdf({ ...pdf });
    }
  }

  const handleDateChangeFim = (date) => {
    handleClient([])
    setSelectedDateFim(date);
    setButtonDisable(false);
    setClientSelected('');
    setAggrement([]);
    setAggrementSelected([]);
    setAggrement([]);
    setService([]);
    setServiceSelected([]);
    setPerson([]);
    setPersonSelected([]);
    setStatus({ value: 0, label: "Só Ativos" });


    if (date === null) {
      setPdf({ ...pdf, dataFinal: "" });
      setButtonDisable(true);
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }

      let data = day + '/' + mes + '/' + ano;
      pdf.dataFinal = data;
      setPdf({ ...pdf });
    }
  }



  async function handleClient(items) {

    if (items.length >= 5) {
      for (let x of client) {
        let tmp = items.filter(y => y.value == x.value)

        if (!tmp.length) {
          x.disabled = true;
        }
      }
    } else {
      for (let x of client) {
        let tmp = items.filter(y => y.value == x.value)

        if (!tmp.length) {
          x.disabled = false;
        }
      }
    }

    if (items) {

      if (items.length === 0) {

        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)

        const idClient = obj.map(item => item.value);

        setPdf({ ...pdf, clientId: idClient, aggrementId: [], serviceId: [], personId: [] });
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
        setPerson([]);
        setPersonSelected([]);
        setStatus({ value: 0, label: "Só Ativos" });


      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        const idClient = obj.map(item => item.value);
        setPdf({ ...pdf, clientId: idClient });
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
        setPerson([]);
        setPersonSelected([]);
        getAggrement(idClient);
        setStatus({ value: 0, label: "Só Ativos" });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setClientSelected(obj)
      const idClient = obj.map(item => item.value);
      setPdf({ ...pdf, clientId: idClient });
      getAggrement([]);
      setAggrementSelected([]);
      setAggrement([]);
      setService([]);
      setServiceSelected([]);
      setPerson([]);
      setPersonSelected([]);
      setStatus({ value: 0, label: "Só Ativos" });
    }
  }

  async function handleContrato(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setAggrementSelected(obj)
        const idAgg = obj.map(item => item.value);
        setPdf({ ...pdf, aggrementId: idAgg, serviceId: [], personId: [] });
        setServiceSelected([]);
        setService([]);
        setPerson([]);
        setPersonSelected([]);
        setStatus({ value: 0, label: "Só Ativos" });

      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setAggrementSelected(obj)
        const idAgg = obj.map(item => item.value);
        setPdf({ ...pdf, aggrementId: idAgg });
        setServiceSelected([]);
        setService([]);
        setPerson([]);
        setPersonSelected([]);
        getService(idAgg, statusSelected.value);
        setStatus({ value: 0, label: "Só Ativos" });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setAggrementSelected(obj)
      const idAgg = obj.map(item => item.value);
      setPdf({ ...pdf, aggrementId: idAgg });
      getService([]);
      setServiceSelected([]);
      setService([]);
      setPerson([]);
      setPersonSelected([]);
      setStatus({ value: 0, label: "Só Ativos" });
    }
  }

  async function handleService(items) {

    if (items) {

      if (items.length === 0) {

        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)

        const idService = obj.map(item => item.value);

        setPdf({ ...pdf, serviceId: idService, personId: [] });
        setPerson([]);
        setPersonSelected([]);

      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)

        const idService = obj.map(item => item.value);
        setPdf({ ...pdf, serviceId: idService });
        setPerson([]);
        setPersonSelected([]);
        getPersonPorData(idService);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setServiceSelected(obj)
      const idService = obj.map(item => item.value);
      setPdf({ ...pdf, serviceId: idService });
      getPerson([]);
      setPerson([]);
      setPersonSelected([]);
    }
  }
  async function handleStatus(item) {
    setStatus(item);
    const idAgg = aggrementSelected.map(item => item.value);
    if (idAgg.length > 0) getService(idAgg, item.value)
  }

  async function handlePerson(items) {

    if (items) {

      if (items.length === 0) {

        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setPersonSelected(obj)

        const idPerson = obj.map(item => item.value);

        setPdf({ ...pdf, personId: idPerson });

      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setPersonSelected(obj)
        const idPerson = obj.map(item => item.value);
        setPdf({ ...pdf, personId: idPerson });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setPersonSelected(obj)
      const idPerson = obj.map(item => item.value);
      setPdf({ ...pdf, personId: idPerson });
    }
  }


  function open(params) {
    var link = document.createElement('a');
    link.href = params;
    window.open(link, '_blank');
  }

  const handleStateFilter = (item) => {
    setRelStateToFilter(item.target.checked);
    setPdf({ ...pdf, headerFlag: item.target.checked });
  };

  let handleOp = (item) => {
    if (item === null) {
      setOp(item);
    } else {
      setOp(item);
      setPdf({ ...pdf, headerFlag: item.value });
    }
  }

  async function sendPdf() {

    if (pdf.clientId.length == 0) {
      enqueueSnackbar(<MessageAlert message={'Informar ao menos um cliente para gerar PDF!'} />, WARNINGMESSAGE);
    } else {

      setButtonDisable(true);
      setLoadDialog(true);
      try {

        const temp = await api.put("/registeritem/extratoCliente", pdf);
        const { config } = temp
        const { data } = temp
        if (data.success) {
          const { result, path } = data;
          console.log(result, path)
          var url = config.baseURL + path;
          open(url);
          enqueueSnackbar('PDF Gerado com sucesso!', SUCCESSMESSAGE);
          setButtonDisable(false);
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
          setButtonDisable(false);
        }
      } catch (err) {
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        setButtonDisable(false);
      }
    }
    //setButtonDisable(true);
    setLoadDialog(false);
  }

  function clearSearch() {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setClientSelected('');
    setServiceSelected('');
    setAggrementSelected('');
    setOp({ value: false, label: "Horas Registradas" });
    setStatus({ value: 0, label: 'Só Ativos' })
  }

  const handleMesAno = (item) => {
    setSelectedMesAno(item);
    if (item === null) {
      handleDateChange(null);
      handleDateChangeFim(null);
    } else {
      handleDateChange(monthYear[item.value].dtInicial);
      handleDateChangeFim(monthYear[item.value].dtFinal);
    }
  }
  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Gerando o PDF, Aguarde!</h3>
      </WrapInput>
    </div>
  );


  return (
    <Container>
      <SearchBox>
        <TitleStyle2>Relatório Horas Cliente</TitleStyle2>
        <form>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <Wrap style={{ position: 'relative' }}>
              <WrapInputDate >
                <KeyboardDatePicker
                  helperText={''}
                  minDateMessage="Não é possível buscar por datas futuras."
                  margin="normal"
                  marginLeft="0"
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  clearable
                  id="dateInitial"
                  label="Data Início"
                  format="dd/MM/yyyy"
                  onChange={handleDateChange}
                  value={selectedDate}
                  minDate={new Date(new Date("01-01-2018"))}
                  maxDate={new Date(new Date())}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </WrapInputDate>
              <WrapInputDate >
                <KeyboardDatePicker
                  helperText={''}
                  minDateMessage="Não é possível buscar por datas futuras."

                  margin="normal"
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  clearable
                  id="dateFinal"
                  label="Data Fim"
                  format="dd/MM/yyyy"
                  onChange={handleDateChangeFim}
                  value={selectedDateFim}
                  minDate={new Date(new Date("01-01-2018"))}
                  maxDate={new Date(new Date())}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </WrapInputDate>
              <WrapInput style={{ position: 'relative', width: '7%' }}>
                <LabelInput>Mês</LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder="Opções.."
                  onChange={handleMesAno}
                  value={selectedMesAno}
                  isClearable={true}
                  isSearchable={true}
                  options={monthYear.map((item) => {
                    return { value: item.id, label: item.monthYear };
                  })}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '7%' }}>
                <LabelInput> Opção de cabeçalho </LabelInput>
                <MySelect2
                  styles={multSelect}
                  placeholder="Opções"
                  value={op}
                  //isClearable={true}
                  //isSearchable={true}
                  onChange={handleOp}
                  options={opCabecalho}
                />


              </WrapInput>
              <WrapInput>
                <LabelInput>Clientes </LabelInput>
                <MultiSelect
                  hasSelectAll={false}
                  overrideStrings={overrideStrings}
                  options={client}
                  value={clientSelected}
                  onChange={(item) => handleClient(item)}
                  labelledBy={"Select"}
                />

              </WrapInput>
              <WrapInput>
                <LabelInput>Contratos </LabelInput>
                <MultiSelect
                  overrideStrings={overrideStrings}
                  options={aggrement}
                  value={aggrementSelected}
                  onChange={(item) => handleContrato(item)}
                  labelledBy={"Select"}
                />
              </WrapInput>
            </Wrap>
            <Wrap style={{ position: 'relative', padding: '1px' }}>
              <WrapInput style={{ position: 'relative', width: '15%' }}>
                <LabelInput>Status Serviço</LabelInput>
                <MySelect2
                  styles={multSelect}
                  overrideStrings={overrideStrings}
                  options={[
                    { value: 0, label: 'Só Ativos' },
                    { value: 1, label: 'Ativos e Inativos' }
                  ]}
                  value={statusSelected}
                  onChange={(item) => handleStatus(item)}
                  placeholder={"Opções.."}
                />

                <WrapInput> </WrapInput>
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '22%' }}>
                <LabelInput>Serviços </LabelInput>
                <MultiSelect
                  overrideStrings={overrideStrings}
                  options={service}
                  value={serviceSelected}
                  onChange={(item) => handleService(item)}
                  labelledBy={"Select"}
                />
              </WrapInput>
              <WrapInput>
                <LabelInput>Colaboradores </LabelInput>
                <MultiSelect
                  overrideStrings={overrideStrings}
                  options={person}
                  value={personSelected}
                  onChange={(item) => handlePerson(item)}
                  labelledBy={"Select"}
                />
              </WrapInput>
              <WrapInput style={{ width: '10%', marginTop: '10px' }}>
                <ItemsSearch>
                  <IconPdf size={25} title="Gerar PDF" style={{ marginLeft: '0%' }} onClick={() => sendPdf()} />
                  <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                </ItemsSearch>
              </WrapInput>
            </Wrap>
          </MuiPickersUtilsProvider>
        </form>
      </SearchBox>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}