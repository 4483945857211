import React, { useContext, useState, useEffect } from 'react'
import { Container, TitleStyle2, MySelect, overrideStrings } from './styles';
import { Wrap, WrapInput, WrapList } from "../../components/Wrap";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { LabelInput } from 'components/Input';
import MultiSelect from "react-multi-select-component";
import { multSelect } from 'components/Input';
import MessageAlert from 'components/MessageAlert';
import { INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, LOADMESSAGE } from 'utils/types-messages';
import { api } from 'services/axios';
import { GetSessionUser, getMonthYear } from 'services/helpers';
import { Button } from "../../components/Button";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Modal, CircularProgress } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { SearchBox, IconeSearch, ItemsSearch, IconExcel } from "../../components/Search";
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from 'components/SharedComponents/table';
import { ThemeContext } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { shade } from 'polished'

export default function Historico() {
  const { colors, textTableColor } = useContext(ThemeContext);
  const [monthYear] = useState(getMonthYear());
  const [selectedMesAno, setSelectedMesAno] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [body, setBody] = useState({ empresaId: 0, dateIni: '', dateFin: '', table: [], transaction: [], person: [] });
  const [selectedDateFim, setSelectedDateFim] = useState(null);
  const [selectedTabela, setSelectedTabela] = useState([]);
  const [tabela, setTabela] = useState([]);
  const [pessoa, setPessoa] = useState([]);
  const [msgModal, setMsgModal] = useState('');
  const [transacao] = useState([
    { value: 1, label: 'Inclusão' },
    { value: 2, label: 'Alteração' },
    { value: 3, label: 'Exclusão' }
  ]);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [loadDialog, setLoadDialog] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [historico, setHistorico] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const useStyles = makeStyles({
    table: {
      // minWidth: 650,
      backgroundColor: colors.surface,
      width: '100%',
      fontSize: '14px'
    },
    head: {
      backgroundColor: "#fff",
      position: "sticky",
      top: 0
    },
    headCol: {
      backgroundColor: colors.background,
      position: "sticky",
      top: 0,
      color: textTableColor,
      fontWeight: 500,
      border: 'none',

      textTransform: 'uppercase',
      textAlign: 'center'
    },
    bodyCol: {
      color: textTableColor,
      border: 'none',
      fontSize: '18px',

    },
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    btnModal: {
      marginLeft: '0', marginTop: '-5px'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    }
  });
  const classes = useStyles();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date === null) {
      setBody({ ...body, dateIni: '' });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = ano + '-' + mes + '-' + day;
      body.dateIni = data;
      setBody({ ...body });
    }
  }

  const handleDateChangeFim = (date) => {
    setSelectedDateFim(date);
    if (date === null) {
      setBody({ ...body, dateFin: '' });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = ano + '-' + mes + '-' + day;
      body.dateFin = data;
      setBody({ ...body });
    }
  }

  const handleMesAno = (item) => {
    setSelectedMesAno(item);
    if (item === null) {
      handleDateChange(null);
      handleDateChangeFim(null);
    } else {
      handleDateChange(monthYear[item.value].dtInicial);
      handleDateChangeFim(monthYear[item.value].dtFinal);
    }
  }

  async function handleTabela(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setSelectedTabela(obj)
        const ids = obj.map(item => item.value);
        setBody({ ...body, table: ids, });
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setSelectedTabela(obj)
        const ids = obj.map(item => item.value);
        setBody({ ...body, table: ids });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setSelectedTabela(obj)
      const ids = obj.map(item => item.value);
      setBody({ ...body, table: ids });
    }
  }

  async function handleTransaction(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setSelectedTransaction(obj)
        const ids = obj.map(item => item.value);
        setBody({ ...body, transaction: ids, });
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setSelectedTransaction(obj)
        const ids = obj.map(item => item.value);
        setBody({ ...body, transaction: ids });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setSelectedTransaction(obj)
      const ids = obj.map(item => item.value);
      setBody({ ...body, transaction: ids });
    }
  }

  async function handlePerson(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setSelectedPerson(obj)
        const ids = obj.map(item => item.value);
        setBody({ ...body, person: ids, });
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setSelectedPerson(obj)
        const ids = obj.map(item => item.value);
        setBody({ ...body, person: ids });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setSelectedPerson(obj)
      const ids = obj.map(item => item.value);
      setBody({ ...body, person: ids });
    }
  }

  async function getInit() {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setSelectedPerson([]);
    setSelectedTabela([]);
    setHistorico([]);
    setSelectedTransaction([]);
    setBody({ ...body, table: [], transaction: [], person: [] });
  }

  //Combo Tabela
  const getTabela = async () => {
    try {
      const { data } = await api.get('/historico/tabela')
      if (data.success) {
        const { result } = data;
        setTabela(result.map((item) => { return { label: item.name, value: item.id } }));
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Pessoas
  const getPerson = async () => {
    try {
      const { data } = await api.get('/historico/person')
      if (data.success) {
        const { result } = data;
        setPessoa(result.map((item) => { return { label: item.changePerson, value: item.changePerson } }));
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function getConsulta() {
    setMsgModal('Pesquisando, Aguarde!')
    setLoadDialog(true);
    try {
      const { perfil } = GetSessionUser();
      body.empresaId = perfil.companyId;
      const { data } = await api.put("/historico/pesquisa", body);
      if (data.success) {
        const { result } = data;
        setHistorico(result);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
    setLoadDialog(false);
  }


  async function getExcel() {
    setMsgModal('Gerando a Planilha, Aguarde!')
    setLoadDialog(true);
    try {
      const { perfil } = GetSessionUser();
      body.empresaId = perfil.companyId;
      const temp = await api.put("/historico/excel", body);
      const { config } = temp
      const { data } = temp
      if (data.success) {
        const { path } = data;
        var url = config.baseURL + path;
        open(url);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
    setLoadDialog(false);
  }

  function open(params) {
    var link = document.createElement('a');
    link.href = params;
    link.click();
  }

  useEffect(() => {
    getTabela();
    getPerson();
    getInit();
  }, []);

  function clearSearch() {
    setBody({ ...body,  dateIni: '', dateFin: '' });
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setSelectedTabela([]);
    setSelectedTransaction([]);
    setSelectedPerson([]);
    setHistorico([]);
    setBody({ ...body,  table: [], transaction: [], person: []});
  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput style={{ alignItems: 'center' }} className="alertText">
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>{msgModal}</h3>
      </WrapInput>
    </div>
  );

  return (
    <Container>
      <SearchBox>
        <TitleStyle2>Histórico Atualização</TitleStyle2>
        <form>
          <Wrap className="Wrap" >
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <div style={{ display: 'flex', width: '24%', backgroundColor: '' }} >
                <WrapInput>
                  <KeyboardDatePicker
                    minDateMessage="Não é possível buscar por datas futuras."
                    helperText={''}
                    cancelLabel="cancelar"
                    margin="normal"
                    clearLabel="Limpar"
                    clearable
                    id="dateInitial"
                    label="Data Início"
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </WrapInput>
                <WrapInput>
                  <KeyboardDatePicker
                    minDateMessage="Não é possível buscar por datas futuras."
                    cancelLabel="cancelar"
                    helperText={''}
                    margin="normal"
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    id="dateFinal"
                    label="Data Fim"
                    format="dd/MM/yyyy"
                    onChange={handleDateChangeFim}
                    value={selectedDateFim}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </WrapInput>
              </div>
            </MuiPickersUtilsProvider>
            <WrapInput style={{ position: 'relative', width: '8%' }}>
              <LabelInput>Mês</LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Opções.."
                onChange={handleMesAno}
                value={selectedMesAno}
                isClearable={true}
                isSearchable={true}
                options={monthYear.map((item) => {
                  return { value: item.id, label: item.monthYear };
                })}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '11%' }}>
              <LabelInput>Tela</LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={tabela}
                value={selectedTabela}
                onChange={(item) => handleTabela(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '10%' }}>
              <LabelInput>Transação </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={transacao}
                value={selectedTransaction}
                onChange={(item) => handleTransaction(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '15%' }}>
              <LabelInput>Colaborador </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={pessoa}
                value={selectedPerson}
                onChange={(item) => handlePerson(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', marginTop: '10px' }}>
              <ItemsSearch>
                <IconeSearch size={25} title="Pesquisar" onClick={() => getConsulta()} />
                {historico.length > 0 &&
                  <IconExcel size={25} title="Gerar Excel" onClick={() => getExcel()} />
                }
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>

        </form>
      </SearchBox>
      <WrapList>
        <HeaderList className="HeaderList">
          <span> Histórico </span>
        </HeaderList>
        <BodyList>
            <TableContainer className={classes.containerModal}>
              <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head} style={{ width: '20%', color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TELA</TableCell>
                    <TableCell className={classes.head} style={{ width: '20%', color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TRANSAÇÃO</TableCell>
                    <TableCell className={classes.head} style={{ width: '20%', color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DATA - HORA</TableCell>
                    <TableCell className={classes.head} style={{ width: '20%', color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">COLABORADOR</TableCell>
                    <TableCell className={classes.head} style={{ width: '2%', color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center"></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            {
                historico.length > 0 && historico.map((row, index) => {
                  return (
                    <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)} style={{ paddingLeft: '0px', backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                      <AccordionSummary
                        style={{ paddingLeft: '0px' }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                          <TableBody>
                            <TableRow style={{ width: '100%', backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                              <TableCell style={{ width: '20%', color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center"><b>{row.tableName}</b></TableCell>
                              <TableCell style={{ width: '20%', color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.transactionType === 1 ? 'Inclusão' : row.transactionType === 2 ? 'Alteração' : 'Exclusão'}</TableCell>
                              <TableCell style={{ width: '20%', color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.dtAlter}</TableCell>
                              <TableCell style={{ width: '20%', color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.changePerson}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </AccordionSummary>
                      <AccordionDetails style={{ backgroundColor: "#DCDCDC" }}>
                        <Typography>
                          <p><b>Antes : </b>{row.beforeFields}</p>
                          <p><b>Depois: </b>{row.afterFields}</p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </TableContainer>
        </BodyList>
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container >
  )
}