import React, { useState, useEffect } from 'react'
import {
    Container,
    WrapSearch,
    WrapList,
    Button,
    Items,
    HeaderList,
    BodyList,
    Row,
    WrapButtons,
    Column,
    ColumnEditItem,
    IconeSearch,
    NextPage,
    PrevPage,
    WrapInput,
} from './styles'
import {
    Input,
    LabelInput,
    Select
} from 'components/Input'
import ButtonEditUser from 'components/ButtonEditUser'
import { api } from 'services/axios'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { useSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import MessageAlert from 'components/MessageAlert'



export default function CadastroUsuarios() {
    const { enqueueSnackbar } = useSnackbar();
    const [usuario, setUsuario] = useState('')
    const [codigoPerfil, setCodigoPerfil] = useState('')
    const [perfis, setPerfis] = useState([])
    const [usuarios, setUsuarios] = useState([])
    const [showUsuarios, setShowUsuarios] = useState(false)
    const [pagina, setPagina] = useState(1)
    const quantidadePorPagina = 12


    async function fetchPerfis() {

        try {
            const { data } = await api.get('/person/0')
            setPerfis(data.payload)
            if (!data.success)
                enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        } catch (error) {
            enqueueSnackbar('Erro ao buscar perfis', ERRORMESSAGE)
        }

    }
    async function fetchUsuarios(pagina, quantidadePorPagina) {

        try {
            const { data } = await api.post('/usuario/paginacao', { pagina, quantidadePorPagina })
            setUsuarios(data.payload)
            setShowUsuarios(true)
            if (!data.success)
                enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        } catch (error) {
            enqueueSnackbar('Erro ao buscar usuarios', ERRORMESSAGE)
        }

    }

    useEffect(() => {
        if (perfis.length === 0)
            fetchPerfis()
    }, [])

    const NextFetchUsuarios = () => {
        let nextPage = pagina + 1
        setPagina(nextPage);
        fetchUsuarios(nextPage, quantidadePorPagina)
    }
    const PrevFetchUsuarios = () => {
        if (pagina > 1) {
            let prevPage = pagina - 1
            setPagina(prevPage);
            fetchUsuarios(prevPage, quantidadePorPagina)
        }
    }


    async function cadastrarUsuario() {

        try {
            const { data } = await api.post('/usuario', { usuario, perfil: codigoPerfil })
            if (data.success) {
                enqueueSnackbar('Usuários cadastrado com sucesso!', SUCCESSMESSAGE)
            }
            if (!data.success)
                enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        } catch (error) {
            enqueueSnackbar('Erro ao cadastrar usuário', ERRORMESSAGE)
        }

    }

    return (
        <Container>
            <WrapSearch>
                <WrapInput >
                    <LabelInput> Usuário </LabelInput>
                    <Input
                        value={usuario}
                        onChange={p => setUsuario(p.target.value)} />
                </WrapInput>
                
                <WrapInput>
                    <LabelInput> Perfil </LabelInput>
                    <Select onChange={p => setCodigoPerfil(p.target.value)}> {
                        perfis && perfis.map(item => {
                            return (
                                <MenuItem
                                    value={item.codigo}>
                                    {item.descricao} </MenuItem>
                            )
                        })
                    } </Select>
                </WrapInput>
                <WrapInput>
                    <Items>
                        <IconeSearch onClick={() => fetchUsuarios()}
                            size={30}
                            color="#005A9C" />
                        <Button onClick={() => cadastrarUsuario()}>
                            Inserir
                                      </Button>
                    </Items>
                </WrapInput>
            </WrapSearch>
            <WrapList>
                <HeaderList>
                    <span style={{ marginLeft: '20px' }}>
                        USUÀRIOS </span>
                </HeaderList>
                {
                    showUsuarios ? <BodyList>
                        <Row>
                            <Column>
                                USUÁRIO
                                                                 </Column>
                            <Column >
                                PERFIL
                                                                     </Column>
                            <Column> {" "}
                            </Column> </Row>
                        {
                            usuarios && usuarios.slice(0, 12).map(item => {
                                return (
                                <Row
                                 bgColor={item.usuario_id % 2 !== 0 && '#F8F8F8'}
                                    key={item.usuario_id} >
                                    <Column> {item.usuario_email}
                                     </Column> <
                        Column > {item.perfil_descricao} <
                        /Column> <
                        ColumnEditItem >
                                                <
                                                    ButtonEditUser titleEditar="Editar senha"
                                                    titleSenha="Reenviar senha" />
                                                <
                        /ColumnEditItem> < /
                        Row >
                    )
                })

            } <
            WrapButtons >
                                                    <
                                                        PrevPage onClick={
                                                            () => PrevFetchUsuarios()
                                                        }
                                                        color="#015D7F"
                                                        size={35}
                                                    /> <
                                                        NextPage onClick={
                                                            () => NextFetchUsuarios()
                                                        }
                                                        color="#015D7F"
                                                        size={35}
                                                    /> < /
            WrapButtons > <
            /BodyList> : <
            Row >
                                                        <
                                                            IconeSearch onClick={
                                                                () => fetchUsuarios()
                                                            }
                                                            size={20}
                                                            color="#005A9C" />
                                                        <
            /Row>
        }

        <
        /WrapList> < /
        Container >
    )
}