import React, { useContext, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from 'components/SharedComponents/table';
import { ThemeContext } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { shade } from 'polished'
import BallotIcon from '@material-ui/icons/Ballot';
import { INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages';
import { api } from 'services/axios'
import MessageAlert from 'components/MessageAlert'
import { useSnackbar } from 'notistack';
import { Modal, CircularProgress } from "@material-ui/core";
import { WrapInput } from "../../components/Wrap"

export default function TabelaConsolidado(table) {
  const [loadDialog, setLoadDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { colors, textTableColor } = useContext(ThemeContext);
  const useStyles = makeStyles({
    table: {
      backgroundColor: colors.surface,
      width: '100%',
      fontSize: '14px'
    },
    headCol: {
      backgroundColor: colors.background,
      position: "sticky",
      top: 0,
      color: textTableColor,
      fontWeight: 500,
      border: 'none',

      textTransform: 'uppercase',
      textAlign: 'center'
    },
    bodyCol: {
      color: textTableColor,
      border: 'none',
      fontSize: '18px',

    },
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    btnModal: {
      marginLeft: '0', marginTop: '-5px'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    head: {
      backgroundColor: "#fff",
      position: "sticky",
      top: 0
    }
  });
  const classes = useStyles();

  function open(params) {
    var link = document.createElement('a');
    link.href = params;
    //link.click();
    window.open(link, '_blank');
  }

  async function sendExcel(id) {
    //enqueueSnackbar(<MessageAlert message={'Gerando PDF.'} />, INFOMESSAGE)
    try {
      setLoadDialog(true);

      let clientsId = table.clientIds.map(x => x.value);
      let aggrId = table.aggrementIds.map(x => x.value)
      let servicesId = table.servicesId.map(x => x.value)
      const temp = await api.put("/registeritem/extratoConsultor", { dataInicial: table.dataInicial, dataFinal: table.dataFinal, personId: [id], clientId: clientsId, aggrementId: aggrId, serviceId: servicesId });
      const { config } = temp
      const { data } = temp
      if (data.success) {
        const { result, path } = data;
        console.log(result, path)
        let url = config.baseURL + path;
        open(url);
        // enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
    setLoadDialog(false);
  }
  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput style={{ alignItems: 'center' }} className="alertText">
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Gerando PDF, Aguarde!</h3>
      </WrapInput>
    </div>
  );

  return (
    <TableContainer className={classes.containerModal}>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>

            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CÓDIGO EXTERNO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">COLABORADOR</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO CONTRATAÇÃO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">GERENTE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">COORDENADOR</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">HORAS ABERTAS</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">HORAS FECHADAS</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TOTAL DE HORAS</TableCell>
            {/* <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TOTAL HORAS TRABALHADAS</TableCell> */}
            {/* <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">STATUS DE HORAS</TableCell> */}
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">EXTRATO</TableCell>

          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {
            table.table && table.table.map((row, index) => {
              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.CodigoExterno}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.Colaborador}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.TipoContratacao}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row["Gerente da Equipe"]}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row["Coordenador da Equipe"]}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.totalHorasAberto}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.totalHorasFechado}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.totalHoras}</TableCell>
                  {/* <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.TotalHoras}</TableCell> */}
                  {/* <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.Status}</TableCell> */}
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">

                    <BallotIcon
                      onClick={() => sendExcel(row.personId)}
                      style={{ cursor: "pointer", marginLeft: '5px' }}
                      color={colors.primary}
                      size="18"
                      title="Serviços"
                    />

                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </TableContainer>
  );
}
