import React, { useState, useEffect, useContext } from 'react'
import {
  ContainerOpen,
  Items,
  Space,

  IconeMenu,
  Backdrop,
  Header,
  ButtonClose,
  WrapLogo,
  Logo
} from './styles.js'
import MenuItem from 'components/MenuItem'
import Footer from 'components/Footer'

import { GetUserAccess } from 'services/helpers'
import { useMenu } from 'contexts/MenuContext'
import { ThemeContext } from 'styled-components'
import { shade } from 'polished';
import logo from 'assets/logo-confluence.svg'
import Tooltip from '@material-ui/core/Tooltip';

export default function MenuOpen(props) {
  const { colors, title } = useContext(ThemeContext)
  const { showMenu, handleMenu } = useMenu()
  const [acesso, setAcesso] = useState([])
  const [show, setShow] = useState('translateX(-100%)')
  const [Op, setOp] = useState(0.1)

  const myHandleMenu = () => {
    handleMenu(false)
  };

  useEffect(() => {
    if (showMenu) {
      setShow('translateX(0)')
      setOp(1)
    } else {
      setShow('translateX(-100%)')
      setOp(0.1)
    }
  }, [showMenu])

  const getUser = () => {
    const access = GetUserAccess()
    setAcesso(access.module)
  }
  useEffect(() => {
    getUser()
  }, [])

  const handleBackdrop = () => {
    handleMenu(false)
  }
  const ordenar = (array, modulo) => {
    return array
  }

  return (
    <>
      {
        showMenu && <Backdrop op={Op} onClick={() => handleBackdrop()} />
      }
      <ContainerOpen open={show}>
        <Header>
          <ButtonClose>
            <Tooltip title="Fechar" placement="right" arrow>
              <div>
                <IconeMenu onClick={() => myHandleMenu()} color={title === 'dark' ? colors.primary : shade(0.2, '#33C8D1')} size={25} />
              </div>
            </Tooltip>
          </ButtonClose>
          <WrapLogo>
            <Logo img={logo} />
          </WrapLogo>
        </Header>
        {
          <div style={{ marginBottom: '30px' }}></div>
          // <hr style={{width: '80%', marginBottom: '50px'}} />
        }
        <Items>
          {
            acesso.map(item => {
              return <MenuItem
                moduleName={item.name}
                functionality={ordenar(item.functionality, item.name.toLowerCase())}
              />
            }
            )
          }
        </Items>
        <Space>
        </Space>
        <Footer />

      </ContainerOpen>
    </>
  )
}
//import { FaRegClock, FaRegFolder } from 'react-icons/fa'
/*//import {GiDarkSquad} from 'react-icons/gi'
   <ButtonItem isMenuClose={true} icone={<FaRegClock color="#F5F5F5" size={22} />} title={'Registro de Horas'} path="/registro-de-horas" />
                <ButtonItem isMenuClose={true} icone={<FaRegFolder color="#F5F5F5" size={22} />} title={'Fechamento de Horas'} path="/fechamento-de-horas" />*/

/*
     <ButtonItem icone={<FaRegClock  color="#F5F5F5" size={20} />} title={'Registro de Horas'} path="/registro-de-horas" />
<ButtonItem icone={<FaRegFolder color="#F5F5F5"  size={20} />} title={'Fechamento de Horas'} path="/fechamento-de-horas" />*/

/*

 <ButtonItem hideBg={true} title={'Usuários'} path="/cadastrar/usuarios" />
                            <ButtonItem hideBg={true} title={'Pessoas'} path="/cadastrar/pessoas" />
                            <ButtonItem hideBg={true} title={'Cargos'} path="/cadastrar/cargos" />
                            <ButtonItem hideBg={true} title={'Regime contratação'} path="/cadastrar/regimes-de-contratacao" />
                            <ButtonItem hideBg={true} title={'Perfil'} path="/cadastrar/perfis" />
                            <ButtonItem hideBg={true} title={'Cliente'} path="/cadastrar/clientes" />
                            <ButtonItem hideBg={true} title={'Tipo de Serviço'} path="/cadastrar/tipos-de-servicos" />
                            <ButtonItem hideBg={true} title={'Serviço'} path="/cadastrar/servicos" />
                            <ButtonItem hideBg={true} title={'Local'} path="/cadastrar/locais" />
                            <ButtonItem hideBg={true} title={'Projetos'} path="/cadastrar/projetos" />
                            <ButtonItem hideBg={true} title={'Turno'} path="/cadastrar/turnos" />
                            <ButtonItem hideBg={true} title={'Atividade'} path="/cadastrar/atividades" />*/