import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from 'components/SharedComponents/table';
import { ThemeContext } from 'styled-components'
import { shade } from 'polished'
import { useShowModal } from 'contexts/SharedContext'
import { FiEdit } from "react-icons/fi";
import { BiHistory } from "react-icons/bi";
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { WrapInput, Wrap } from '../../components/Wrap'
import { modalStyle, ModalBox } from '../../components/Modal'
import { ConfirmarBtn, CancelarBtn } from "../../components/Button";
import { TitleStyle2, Items } from "../../pages/CadastroPessoas/styles";
import Modal from "@material-ui/core/Modal";
import { Input2 } from './styles.js'
import { Button, } from "../../components/Button";
import MessageAlert from 'components/MessageAlert';
import { AiTwotoneDelete, AiFillProfile } from "react-icons/ai";
import { tint } from "polished";
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal'
import Historico from '../Historico.js'
let equipe = '';
let separa = '';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  head: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0
  },
});

const customControlStyles = base => ({
  ...base,
  height: 35,
  minHeight: 35
});


export default function TableCadastroPessoa({ pessoas, team, typeContract, fetchUpdate, ValueContainer }) {

  const { setShowModalPessoa } = useShowModal();
  const { colors, textTableColor } = useContext(ThemeContext)
  const [nameEdit, setNameEdit] = useState('');
  const [familyNameEdit, setFamilyNameEdit] = useState('');
  const [idToEdit, setIdToEdit] = useState();
  const [servico, setServicos] = useState([]);

  const useStyles = makeStyles({

    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    }
  });

  const [taskDialog, setTaskDialog] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [servicoSelected, setServicoSelected] = useState([]);
  const [getName, setGetName] = useState([]);
  const [person, setPerson] = useState();
  const [tableService, setTableService] = useState([]);
  const [openHist, setOpenHist] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [name, setName] = useState('');

  const handleServico = (item) => {
    setServicoSelected(item)
  }

  /** Ler serviços não associados */
  async function getService(personId) {
    //const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE)
    try {
      const { data } = await api.get("/servicePerson/serviceNotIn/" + personId);
      if (data.success) {
        const { result } = data;
        setServicos(result)
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  // Apagar Pessoa
  async function deletePerson(id) {
    enqueueSnackbar(<MessageAlert message={'Deletando...'} />, INFOMESSAGE)
    try {
      const { data } = await api.delete("/person/" + id);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        fetchUpdate(1);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
    setDeleteDialog(false);

  }

  async function getHistorico(companyId, personId, tableChangeId) {
    try {
      const { data } = await api.get("historico" + '?companyId=' + companyId + '&keyTable=' + personId + '&tableChangeId=' + tableChangeId);;
      if (data.success) {
        const { result } = data;
        setHistorico(result)
        setOpenHist(true)
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  ///modal 
  const [setOpen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  function openModal(item, action, items) {
    if (action === 'update') {
      setShowModalPessoa({ show: true, title: 'EDITAR PESSOA', action: 'editar', item: item });
    } else {
      setIdToEdit(item.id)
      setNameEdit(item.name);
      setFamilyNameEdit(item.familyName)
      setDeleteDialog(true);
    }
  }

  function openModal2(item, action, items) {
    setTableService([]);
    getService(item.id)
    setGetName(item);
    setPerson(items);
    inseriServico(items);
    setTaskDialog(true);
  }

  function openModalHist(item) {
    setHistorico([]);
    setName(item.name);
    getHistorico(item.companyId, item.id, 1);
  }

  const handleClose = () => {
    setOpen(false);
    setDeleteDialog(false);
  };

  const handleClose2 = () => {
    setTaskDialog(false);
    setServicoSelected([]);
    // fetchUpdate(1);
  };

  const handleCloseHist = () => {
    setOpenHist(false);
    setHistorico([]);
  };

  function getCpf(cpf) {
    //Remove tudo o que não é dígito
    if (cpf != null) {
      cpf = cpf.replace(/\D/g, "");

      if (cpf.length <= 14) {
        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");

        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
    }
    return cpf
  }

  async function inserirTarefa() {
    try {
      const obj = {
        serviceId: servicoSelected.value,
        personId: person
      }
      const { data } = await api.post('/servicePerson/', obj)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        inseriServico(person)
        getService(person)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function inseriServico(params) {
    try {
      const { data } = await api.get('/servicePerson/getService/' + params)
      if (data.success) {
        const { result } = data;
        setTableService(result)
        getService(params)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function deleteService(id) {
    try {
      const { data } = await api.delete('/servicePerson/' + id)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        inseriServico(person);
        getService(person)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }


  const modalDelete = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">Remover Pessoas</TitleStyle2>
      <WrapInput className="alertText">
        <h3>Você tem certeza que deseja remover {nameEdit ? nameEdit : ''}? Essa operação é permanente! </h3>
      </WrapInput>
      <WrapInput className="alertBtn">
        <Items>
          <ConfirmarBtn type="button" onClick={() => deletePerson(idToEdit)}> Confirmar </ConfirmarBtn>
          <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
        </Items>
      </WrapInput>
    </div>
  );

  const modalTask = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Associar Serviços a {getName.name}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose2()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'auto', height: '400px' }}>
        <Wrap>
          <WrapInput>
            <Input2
              styles={{ control: customControlStyles }}
              placeholder="Serviços"
              maxMenuHeight={190}
              isClearable
              isSearchable
              value={servicoSelected}
              onChange={handleServico}
              options={servico.map((item) => { return { value: item.serviceId, label: item.serviceName }; })}
              border={`0.5px ${shade(0.2, colors.surface)} solid`} />
          </WrapInput>
          <WrapInput className={classes.wrapBtnModal}>
            <Button style={{ marginTop: '8px' }} className={classes.btnModal} onClick={() => inserirTarefa()} >Associar</Button>
          </WrapInput>
        </Wrap>
        <Wrap>
          <TableContainer className={classes.containerModal}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headColModal} align="center">SERVIÇOS</TableCell>
                  <TableCell className={classes.headColModal} align="center">AÇÕES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: '100%', backgroundColor: 'white' }}>
                {
                  tableService && tableService.map((row, index) => {
                    return (
                      <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                        <TableCell align="center">
                          {row.serviceName}
                        </TableCell>
                        <TableCell align="center">
                          {/* {row.id} */}
                          <AiTwotoneDelete
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            color={tint(0.1, colors.error)}
                            size="20"
                            title="Remover"
                            onClick={() => deleteService(row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Wrap>
      </ModalBox>
    </div>
  );

  return (
    <TableContainer>
      <Modal open={deleteDialog}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalDelete}
      </Modal>
      <Modal open={taskDialog}
        onClose={handleClose2}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalTask}
      </Modal>
      <Modal open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Historico historico={historico} name={name} handleCloseHist={handleCloseHist} />
      </Modal>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">E-MAIL</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">EQUIPE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CÓDIGO EXTERNO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">MATRÍCULA</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">STATUS</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {
            pessoas && pessoas.map((row, index) => {
              equipe = "";
              separa = '';
              row.team && row.team.map((row2) => {
                equipe += separa + row2.teamName;
                separa = ' - ';
              })
              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.name}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.email}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{equipe}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.externalCode}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.enrollment}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.status}</TableCell>
                  <TableCell
                    style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                    align="center">
                    <AiFillProfile
                      onClick={() => openModal2(row, 'Service', row.id)}
                      style={{ cursor: "pointer", marginLeft: '5px' }}
                      color={colors.primary}
                      size="22"
                      title="Ass.Serviço"
                    />
                    <FiEdit
                      onClick={() => openModal(row, 'update')}
                      style={{ cursor: "pointer" }}
                      color={colors.primary}
                      size="20"
                      title="Editar"
                    />
                    {row.logChange > 0 ?
                      <BiHistory
                        onClick={() => openModalHist(row)}
                        style={{ cursor: "pointer" }}
                        color={colors.primary}
                        size="20"
                        title="Atualizações"
                      /> : <BiHistory
                        style={{ cursor: "pointer" }}
                        color={"#808080"}
                        size="20"
                        title="Atualizações"
                      />
                    }
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}