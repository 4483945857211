import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MyTableContainer, Input } from './styles'
import { ThemeContext } from 'styled-components'
import { shade } from 'polished'
import { useUserResume } from 'contexts/SharedContext'
import { FaCheck } from 'react-icons/fa'
import { AiTwotoneDelete } from 'react-icons/ai'
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import MessageAlert from 'components/MessageAlert'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0
  },
});


export default function TableRegistrosFechamento({ items, regData, closeModal, getInitFechamento, esconderEditar, setExclusao }) {
  const { userResumeFechamento, setUserResumeFechamento, userResume } = useUserResume()
  const { colors, textTableColor } = useContext(ThemeContext)
  const [rows, setRows] = useState([])
  const [myText, setMyText] = useState('')
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setRows(items)
  }, [items])


  const handleDelete = async (id) => {
    
    rows.forEach((reg => {
      if (reg.id === id && !reg.novo) setExclusao(true);
    }));
    const newArray = await rows.filter(item => item.id !== id)
    const newResume = userResumeFechamento
    const tempArray = newResume.map(item => {
      if (item.regData === regData) 
        item.itens = newArray
      return item
    })
    setUserResumeFechamento(tempArray)
  }

  const handleobservacao = async (ind, texto) => {
    items[ind].observacao = texto;
    rows[ind].observacao = texto;
    setMyText(texto);
  }

  async function handleNoteRegister(ind) {
    try {
      const { data } = await api.put('/register/nota/' + items[ind].id, { noteRegister: items[ind].observacao })
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const styleCell = { color: textTableColor, border: 'none', fontSize: '12px', padding: 0, margin: 0 }
  const styleCell2 = { color: textTableColor, border: 'none', fontSize: '12px', padding: 0, margin: 0, width: '10px', paddingRight: '15px' }
  const styleCell3 = { color: textTableColor, border: 'none', fontSize: '12px', padding: 0, margin: 0, width: '5px' }

  return (
      <MyTableContainer>
        <Table style={{ backgroundColor: colors.surface }} className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px', width: '10%' }} align="center">REGISTRO Nº</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">ENTRADA</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">SAÍDA</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">LOCAL</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">SERVIÇO</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">ATIVIDADE</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">JUSTIFICATIVA</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">OBSERVAÇÃO</TableCell>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px' }} align="center">MANUAL</TableCell>
              <TableCell className={classes.head} style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '11px'
              }} align="end" >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows && rows.map((row, index) => {
                return (
                  <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                    <TableCell style={styleCell3} align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell style={styleCell3} align="center">
                      {row.entrada}
                    </TableCell>
                    <TableCell style={styleCell2} align="center">
                      {row.saida}
                    </TableCell>
                    <TableCell style={styleCell} align="center">
                      {row.placeName}
                    </TableCell>
                    {row.jobName === 'Intervalo' ? <TableCell style={styleCell} align="center"> - </TableCell> :
                      <TableCell style={styleCell} align="center">
                       {row.clientName} - {row.serviceName}</TableCell>}
                    <TableCell style={styleCell} align="center">
                      {row.jobName}
                    </TableCell>
                    <TableCell style={styleCell} align="center">
                      {row.justificationName}
                    </TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px', padding: 0, margin: 0, paddingTop: '5px', height: '30px' }} align="center">
                      {esconderEditar && row.observacao}
                      {!esconderEditar && row.novo && row.observacao}
                      {!esconderEditar && !row.novo && <Input onBlur={() => handleNoteRegister(index)} value={row.observacao} onChange={e => handleobservacao(index, e.target.value)} placeholder="Opcional" maxLength={250} />}

                      {/*!esconderEditar && !row.novo && <ObservacaoText getInitFechamento={getInitFechamento} id={row.id} index={index} text={row.observacao} esconderEditar={esconderEditar} />*/}
                    </TableCell>
                    <TableCell style={styleCell3} align="center">
                      {row.manual && <FaCheck style={{ cursor: 'pointer' }} color={colors.success} size='20' />}
                    </TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', width: '15px' }} align="center">
                      {
                        rows.length && !esconderEditar && index === (rows.length - 1) ?
                          <AiTwotoneDelete
                            onClick={() => handleDelete(row.id)}
                            style={{ cursor: "pointer", marginLeft: "5px" }}
                            color={colors.error}
                            size="20"
                            title="Remover"
                          /> : ''
                      }
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </MyTableContainer>
  );
}
