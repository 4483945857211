import React, { useState, createContext, useContext } from 'react'

const SharedContext = createContext()

export default function SharedProvider({ children }) {
    const [userResume, setUserResume] = useState({ itens: [] })
    const [colabsInconsistentes, setColabsInconsistentes] = useState([])
    const [colaboradoresResume, setColaboradoresResume] = useState({ itens: [] })
    const [userResumeFechamento, setUserResumeFechamento] = useState([])
    const [userResumeAbertura, setUserResumeAbertura] = useState([])
    const [showModalRegistroManual, setShowModalRegistroManual] = useState(false)
    const [showModalIncluirRegistro, setShowModalIncluirRegistro] = useState({ show: false, regData: '', lineId: '' })
    const [showModalEditarRegistro, setShowModalEditarRegistro] = useState({ show: false, id: '' })
    const [showTableRegistroFechamento, setShowTableRegistroFechamento] = useState(false)
    const [showModalPessoa, setShowModalPessoa] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalContrato, setShowModalContrato] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalServico, setShowModalServico] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalCliente, setShowModalCliente] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalInterval, setShowModalInterval] = useState({ show: false, title: '', action: '', item: '' });
    const [showModalEquipe, setShowModalEquipe] = useState({ show: false, title: '', action: '', item: '' });


    return <SharedContext.Provider
        value={
            {
                showTableRegistroFechamento,
                showModalServico, setShowModalServico,
                setShowTableRegistroFechamento,
                setShowModalRegistroManual,
                showModalEditarRegistro,
                setShowModalEditarRegistro,
                showModalIncluirRegistro,
                setShowModalIncluirRegistro,
                setUserResumeFechamento,
                showModalRegistroManual,
                setColaboradoresResume,
                userResumeFechamento,
                colaboradoresResume,
                setUserResume,
                userResume,
                showModalContrato,
                setShowModalContrato,
                setShowModalPessoa,
                showModalPessoa,
                showModalCliente,
                setShowModalCliente,
                showModalInterval,
                setShowModalInterval,
                showModalEquipe, setShowModalEquipe,
                colabsInconsistentes, setColabsInconsistentes,
                setUserResumeAbertura
            }
        } > {children} </SharedContext.Provider>
}

export function useUserResume() {

    const {
        setShowModalRegistroManual,
        setUserResumeFechamento,
        showModalRegistroManual,
        userResumeFechamento,
        setUserResume,
        userResume,
        setUserResumeAbertura

    } = useContext(SharedContext)
    return {
        setShowModalRegistroManual,
        setUserResumeFechamento,
        showModalRegistroManual,
        userResumeFechamento,
        setUserResume,
        userResume,
        setUserResumeAbertura
    }
}

export function useShowModal() {
    const { showModalIncluirRegistro,
        setShowModalIncluirRegistro,
        showModalEditarRegistro,
        setShowModalEditarRegistro,
        showTableRegistroFechamento,
        setShowTableRegistroFechamento,
        showModalContrato,
        setShowModalContrato,
        setShowModalPessoa,
        showModalPessoa,
        showModalCliente,
        setShowModalCliente,
        showModalInterval,
        setShowModalInterval,
        showModalEquipe, setShowModalEquipe,
        showModalServico, setShowModalServico
    } = useContext(SharedContext)
    return {
        showModalIncluirRegistro,
        setShowModalIncluirRegistro,
        showModalEditarRegistro,
        setShowModalEditarRegistro,
        showTableRegistroFechamento,
        setShowTableRegistroFechamento,
        showModalContrato,
        setShowModalContrato,
        setShowModalPessoa,
        showModalPessoa,
        showModalCliente,
        setShowModalCliente,
        showModalInterval,
        setShowModalInterval,
        showModalEquipe,
        setShowModalEquipe,
        showModalServico, setShowModalServico
    }
}

export function useColaboradoresResume() {
    const { colaboradoresResume, setColaboradoresResume , colabsInconsistentes, setColabsInconsistentes } = useContext(SharedContext)
    return { colaboradoresResume, setColaboradoresResume , colabsInconsistentes, setColabsInconsistentes }
}

export const overrideStrings = {
    "selectSomeItems": "Opções...",
    "allItemsAreSelected": "Todos itens Selecionados",
    "selectAll": "Selecionar Todos",
    "search": "Pesquisar"
}

export const overrideStringsSearch = {
    "selectSomeItems": "Opções...",
    "allItemsAreSelected": "Todos itens Selecionados",
    "selectAll": "Selecionar Todos",
    "search": "Pesquisar"
}
