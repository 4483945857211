import styled from 'styled-components'

export const Container = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
background-color: ${props => props.theme.colors.background};
overflow: auto;
`;

export const TitleStyle2 = styled.h2 `
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;
`;