export default {
    title: 'light',
    fontTitle: '14px',
    textColorButtonMenu: '#F5F5F5',
    bgButtonMenu: '#0D4866',
    borderButtonMenu: '#33C8D1',
    bgBorderButtonMenu: '#043D5D',
    bgNavbar: '#043D5D',
    bgMenu: '#043D5D',
    textTable: '#003E5E',
    textHeading: '#F5F5F5',
    bgHeading: '#043D5D',
    buttonPrimary: '#107CB8',
    hoverButtonPrimary: '#07679b',
    textTableColor: '#003E5E',
    borderInputColor: '#015D7F',
    colorTextAlterarSenha: '#043D5D',
    shadow: 'red',
    colors: {
        primary: '#043D5D',
        secondary: '#33C8D1',
        background: '#F1F1F1',
        surface: '#FFFFFF',
        error: '#CF6679',
        success: '#26D855',
        textSurface: 'gray',
        textSecondary: '#F5F5F5',
        textPrimary: '#F5F5F5',
        textBg: 'gray',
    },
    fonts: {},
}