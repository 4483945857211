import React, { useState, useContext } from 'react'
import {
    Button2,
    Title,
    Collapse
} from './styles.js'
import { shade } from 'polished';
import ButtonMenu from 'components/ButtonMenu'
import { ThemeContext } from 'styled-components'

export default function MenuItem({ functionality, moduleName }) {
    const { bgMenu, colors, title } = useContext(ThemeContext)
    const [checked, setChecked] = useState(true);
    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    //<Icone2 size={15} rotate={checked ? 'rotate(-90deg)' : 'rotate(-270deg)'} />


    return (
        <>
            <Button2 bg={checked && (title === 'dark' ? colors.primary : shade(0.3, '#33C8D1'))} onClick={() => handleChange()} >
                <Title>{moduleName}</Title>

            </Button2>
            <Collapse bg={shade(0.1, bgMenu)} timeout={500} in={checked}>
                <ul style={{ margin: 0, marginBottom: '3px', marginTop: '3px' }}>
                    {
                    
                        functionality.map((item, index) => {
                                
                                const title = item.name
                                const functionalityName = item.name.replace(/\s/g, '-').toLowerCase()
                                return (
                                    <ButtonMenu
                                        // radius={(access.length - 1) === index && '15px'}
                                        path={functionalityName}
                                        title={title}
                                         hideBg={true}
                                        icone=""
                                        isMenuClose="" />
                                )
                            }
                        )
                    }
                </ul>

            </Collapse>
        </>
    )
}
