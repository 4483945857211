import React, { useState, createContext, useContext } from 'react'

const LoadingContext = createContext()

export default function LoadingProvider({ children }) {
    const [loading, handleLoading] = useState(false)
    return <LoadingContext.Provider value={{ loading, handleLoading }}>
        {children}
    </LoadingContext.Provider>
}

export function useLoading() {
    const context = useContext(LoadingContext)
    const { loading, handleLoading } = context
    return { loading, handleLoading }
}

