import React, { useContext, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from 'components/SharedComponents/table';
import { useShowModal } from 'contexts/SharedContext'
import { ThemeContext } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { FiEdit } from "react-icons/fi";
import { AiTwotoneDelete } from "react-icons/ai";
import { shade } from 'polished'
import { tint } from "polished";
import { api } from 'services/axios';
import Historico from '../Historico.js';
import { ERRORMESSAGE } from 'utils/types-messages'
import { useSnackbar } from 'notistack';
import Modal from "@material-ui/core/Modal";
import { BiHistory } from "react-icons/bi";

export default function TableCadastroCliente(clientes) {
  const { setShowModalCliente } = useShowModal();
  const { colors, textTableColor } = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openHist, setOpenHist] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [name, setName] = useState('');

  const useStyles = makeStyles({
    table: {
      // minWidth: 650,
      backgroundColor: colors.surface,
      width: '100%',
      fontSize: '14px'
    },
    head: {
      backgroundColor: "#fff",
      position: "sticky",
      top: 0
    },
    headCol: {
      backgroundColor: colors.background,
      position: "sticky",
      top: 0,
      color: textTableColor,
      fontWeight: 500,
      border: 'none',

      textTransform: 'uppercase',
      textAlign: 'center'
    },
    bodyCol: {
      color: textTableColor,
      border: 'none',
      fontSize: '18px',

    },
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    btnModal: {
      marginLeft: '0', marginTop: '-5px'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    }
  });
  const classes = useStyles();

  function openModal(item, action) {
    if (action === 'update') {
      setShowModalCliente({ show: true, title: 'EDITAR CLIENTE', action: 'editar', item: item });
    } else {
      setShowModalCliente({ show: true, title: 'REMOVER CLIENTE', action: 'remover', item: item });
    }
  }

  async function getHistorico(companyId, clienteId, tableChangeId) {
    try {
      const { data } = await api.get("historico" + '?companyId=' + companyId + '&keyTable=' + clienteId + '&tableChangeId=' + tableChangeId);;
      if (data.success) {
        const { result } = data;
        setHistorico(result)
        setOpenHist(true)
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  function openModalHist(item) {
    setHistorico([]);
    setName(item.clientName);
    getHistorico(item.companyId, item.id, 2);
  }

  const handleCloseHist = () => {
    setOpenHist(false);
    setHistorico([]);
  };

  function getCNPJ(cnpj) {
    //Remove tudo o que não é dígito
    cnpj = cnpj.replace(/\D/g, "")

    if (cnpj.length >= 14) {

      //Coloca ponto entre o segundo e o terceiro dígitos
      cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")

      //Coloca ponto entre o quinto e o sexto dígitos
      cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")

      //Coloca uma barra entre o oitavo e o nono dígitos
      cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")

      //Coloca um hífen depois do bloco de quatro dígitos
      cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return cnpj
  }

  return (
    <TableContainer className={classes.containerModal}>
      <Modal open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Historico historico={historico} name={name} handleCloseHist={handleCloseHist} />
      </Modal>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center" >CÓDIGO EXTERNO</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center" >NOME FANTASIA</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center" >NOME CLIENTE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center" >AÇÕES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {
            clientes.clientes && clientes.clientes.map((row, index) => {
              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.externalCode}
                  </TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.fantasyName}
                  </TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    {row.clientName}
                  </TableCell>
                  <TableCell
                    style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">
                    <FiEdit
                      style={{ cursor: 'pointer' }}
                      color={tint(0.1, colors.primary)}
                      size="20"
                      title="Editar"
                      onClick={() => openModal(row, 'update')}
                    />
                    {row.logChange > 0 ?
                      <BiHistory
                        onClick={() => openModalHist(row)}
                        style={{ cursor: "pointer" }}
                        color={colors.primary}
                        size="20"
                        title="Atualizações"
                      /> : <BiHistory
                        style={{ cursor: "pointer" }}
                        color={"#808080"}
                        size="20"
                        title="Atualizaçõeso"
                      />
                    }
                    <AiTwotoneDelete
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      color={tint(0.1, colors.error)}
                      size="20"
                      title="Remover"
                      onClick={() => openModal(row, 'delete')}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
