import React, { useState, useEffect } from 'react'
import {
 
    ContainerClose,
    Items,
    Space,
    HandleMenu,
    IconeMenu,
    Ibrati, Email,Faq
} from './styles.js'

import { GetSessionUser } from 'services/helpers'
import { useMenu } from 'contexts/MenuContext'

import logo from 'assets/ibrati.png'
import email from 'assets/outlook.png'
import faq from 'assets/faq.png'
import Tooltip from '@material-ui/core/Tooltip';



export default function MenuClose(props) {
    const { handleMenu } = useMenu()

    const [acesso, setAcesso] = useState([])

    const myHandleMenu = () => {
        handleMenu(true)
    };

    const getUser = () => {
        const { acesso } = GetSessionUser()
        setAcesso(acesso)
    }
    useEffect(() => {
        getUser()
    }, [])

    return (
        <ContainerClose>
            <Items>
                <HandleMenu>
                    <IconeMenu onClick={() => myHandleMenu()} color="white" size={20} />
                </HandleMenu>
            </Items>
            <Space>


            <Tooltip style={{ fontSize: '20px' }} title="Perguntas frequentes" placement="right" arrow>
                    <Faq img={faq}  to="/faq" />
                </Tooltip>



                <Tooltip style={{ fontSize: '20px' }} title="IBRATI" placement="right" arrow>
                    <Ibrati img={logo} onClick={() => window.open('https://ibrati.meuportalrh.com.br/', '_blank')} />
                </Tooltip>




                <Tooltip 
               
                style={{ fontSize: '20px' }} title="OUTLOOK" placement="right" arrow>
                    <Email
                        img={email}
                        onClick={() => window.open('https://outlook.office365.com/mail/inbox', '_blank')}
                        style={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            </Space>
        </ContainerClose>
    )
}
//import { FaRegClock, FaRegFolder } from 'react-icons/fa'
/*//import {GiDarkSquad} from 'react-icons/gi'
   <ButtonItem isMenuClose={true} icone={<FaRegClock color="#F5F5F5" size={22} />} title={'Registro de Horas'} path="/registro-de-horas" />
                <ButtonItem isMenuClose={true} icone={<FaRegFolder color="#F5F5F5" size={22} />} title={'Fechamento de Horas'} path="/fechamento-de-horas" />*/

/*
     <ButtonItem icone={<FaRegClock  color="#F5F5F5" size={20} />} title={'Registro de Horas'} path="/registro-de-horas" />
<ButtonItem icone={<FaRegFolder color="#F5F5F5"  size={20} />} title={'Fechamento de Horas'} path="/fechamento-de-horas" />*/

/*

 <ButtonItem hideBg={true} title={'Usuários'} path="/cadastrar/usuarios" />
                            <ButtonItem hideBg={true} title={'Pessoas'} path="/cadastrar/pessoas" />
                            <ButtonItem hideBg={true} title={'Cargos'} path="/cadastrar/cargos" />
                            <ButtonItem hideBg={true} title={'Regime contratação'} path="/cadastrar/regimes-de-contratacao" />
                            <ButtonItem hideBg={true} title={'Perfil'} path="/cadastrar/perfis" />
                            <ButtonItem hideBg={true} title={'Cliente'} path="/cadastrar/clientes" />
                            <ButtonItem hideBg={true} title={'Tipo de Serviço'} path="/cadastrar/tipos-de-servicos" />
                            <ButtonItem hideBg={true} title={'Serviço'} path="/cadastrar/servicos" />
                            <ButtonItem hideBg={true} title={'Local'} path="/cadastrar/locais" />
                            <ButtonItem hideBg={true} title={'Projetos'} path="/cadastrar/projetos" />
                            <ButtonItem hideBg={true} title={'Turno'} path="/cadastrar/turnos" />
                            <ButtonItem hideBg={true} title={'Atividade'} path="/cadastrar/atividades" />*/