import React, { useState, useEffect } from 'react'
import {
  Container,
  Header,
  BodyHeader,
  Body,
  LabelInput,
  Input,
  WrapInput,
  Button,
  Form,
  WrapButtons,
  Column,
  WrapLogo,
  Logo,

} from './styles.js'
import logo from 'assets/logo-confluence.svg'
import { apiNoAuth } from 'services/axios'
import { useSnackbar } from 'notistack';
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { GetSessionUser, Logout } from 'services/helpers'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import MessageAlert from 'components/MessageAlert'

export default function AlterarSenha(props) {
  const [senhaAtual, setSenhaAtual] = useState('')
  const [novaSenha, setNovaSenha] = useState('')
  const [confirmNovaSenha, setConfirmNovaSenha] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false)
  const [showSenhaAtual, setShowSenhaAtual] = useState(true)
  const [showNovaSenha, setShowNovaSenha] = useState(true)
  const [showconfirmNovaSenha, setConfirmShowNovaSenha] = useState(true)
  const [showEnviar, setShowEnviar] = useState(false)

  useEffect(() => {
    if (senhaAtual && novaSenha && confirmNovaSenha) {
      setShowEnviar(true)
    }
  }, [senhaAtual, novaSenha, confirmNovaSenha])


  async function sendForm() {
    if (sending === false) {
      setSending(true)
      const key = enqueueSnackbar('Enviando nova senha...', INFOMESSAGE)
      try {
        const { perfil } = GetSessionUser()
        const { data } = await apiNoAuth.put('/changePassword/' + perfil.userId, { oldPassword: senhaAtual, newPassword: novaSenha, reNewPassword: confirmNovaSenha })
        if (data.success) {
          let count = 3
          enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
          setInterval(() => {
            enqueueSnackbar('Redirecionando você em ' + count-- + "...", INFOMESSAGE)
          }, 1000)
          setTimeout(() => {
            Logout()
          }, 3000)
        }
        if (!data.success) {
          setSending(false)
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        }
      } catch (err) {
        setSending(false)
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      }
      closeSnackbar(key)
    }
  }

  return (
    <Container>
      <Header>
        <WrapLogo>
          <Logo img={logo} />{" "}
        </WrapLogo>{" "}
      </Header>{" "}
      <Body>
        <BodyHeader>ALTERAÇÃO DE SENHA </BodyHeader>{" "}
        <Form>


          <Column>
            <WrapInput>
              <LabelInput>Digite a sua senha atual </LabelInput>
              <Input
                type={showSenhaAtual ? "password" : "text"}
                disabled={sending}
                value={senhaAtual}
                onChange={(e) => setSenhaAtual(e.target.value)}
              />
              {showSenhaAtual ? (
                <AiFillEyeInvisible
                  onClick={() => setShowSenhaAtual(!showSenhaAtual)}
                  size={25}
                >
                  {" "}
                </AiFillEyeInvisible>
              ) : (
                  <AiFillEye
                    onClick={() => setShowSenhaAtual(!showSenhaAtual)}
                    size={25}
                  ></AiFillEye>
                )}{" "}
            </WrapInput>{" "}


        

          </Column>


          <Column>
            <WrapInput>
              <LabelInput>Qual será sua nova senha </LabelInput>{" "}
              <Input
                type={showNovaSenha ? "password" : "text"}
                disabled={sending}
                value={novaSenha}
                onChange={(e) => setNovaSenha(e.target.value)}
              />
              {showNovaSenha ? (
                <AiFillEyeInvisible
                  onClick={() => setShowNovaSenha(!showNovaSenha)}
                  size={25}
                >
                  {" "}
                </AiFillEyeInvisible>
              ) : (
                  <AiFillEye
                    onClick={() => setShowNovaSenha(!showNovaSenha)}
                    size={25}
                  ></AiFillEye>
                )}{" "}

              <span style={{fontSize: '11px'}} >A nova senha deverá conter no minimo 8 caracteres, uma letra maiuscula , numero e 1 caractere especial </span>
            </WrapInput>

            
            <WrapInput style={{margiTop: '40px'}}>
              <LabelInput>Confirme a nova senha </LabelInput>{" "}
              <Input
                type={showconfirmNovaSenha ? "password" : "text"}
                disabled={sending}
                value={confirmNovaSenha}
                onChange={(e) => setConfirmNovaSenha(e.target.value)}
              />
              {showconfirmNovaSenha ? (
                <AiFillEyeInvisible
                  onClick={() =>
                    setConfirmShowNovaSenha(!showconfirmNovaSenha)
                  }
                  size={25}
                >
                  {" "}
                </AiFillEyeInvisible>
              ) : (
                  <AiFillEye
                    onClick={() =>
                      setConfirmShowNovaSenha(!showconfirmNovaSenha)
                    }
                    size={25}
                  ></AiFillEye>
                )}
            </WrapInput>{" "}
          </Column>




        </Form>
        <WrapButtons>
          <Button
            op={showEnviar ? 1 : 0.4}
            onClick={() => showEnviar && sendForm()}
            btnColor={"#107CB8"}>
            Enviar{" "}
          </Button>{" "}
        </WrapButtons>{" "}
      </Body>{" "}
    </Container>
  );
}