import React, {} from 'react'
import {Container} from './styles'

export default function HomeAdmin(){

    return (
        <Container>

        </Container>
    )
}