import styled from 'styled-components'

import {
    TableContainer as MyTableContainer,
    Table as MyTable,
    TableBody as MyTableBody,
    TableCell as MyTableCell,
    TableHead as MyTableHead,
    TableRow as MyTableRow,
} from '@material-ui/core'

export const TableContainer = styled(MyTableContainer)`
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.1);
background-color: ${props => props.theme.colors.surface};
border: none;
height: 100%;
width: 100%;
`;

export const Table = styled(MyTable)`
`

export const TableBody = styled(MyTableBody)`
`

export const TableCell = styled(MyTableCell)`
`

export const TableHead = styled(MyTableHead)`
`

export const TableRow = styled(MyTableRow)`
`