import React, { useContext, useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from 'components/SharedComponents/table';
import { useShowModal } from 'contexts/SharedContext'
import { ThemeContext } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { FiEdit } from "react-icons/fi";
import { AiTwotoneDelete } from "react-icons/ai";
import { shade } from 'polished'
import { tint } from "polished";
import { BsFillPersonPlusFill, } from "react-icons/bs";
import Modal from "@material-ui/core/Modal";
import { modalStyle, ModalBox, modalClose } from '../../components/Modal'
import { Wrap, WrapInput } from '../../components/Wrap'
import { TitleStyle2 } from "../../pages/CadastroPessoas/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { Button, } from "../../components/Button";
import { SUCCESSMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import MessageAlert from 'components/MessageAlert'
import { Input2 } from '../../components/TableCadastroServico/styles'
import Historico from '../Historico.js';
import { BiHistory } from "react-icons/bi";

const customControlStyles = base => ({
  ...base,
  height: 35,
  minHeight: 35
});

export default function TableCadastroEquipe({ table, getEquipe }) {
  const { colors, textTableColor } = useContext(ThemeContext);
  const { setShowModalEquipe } = useShowModal();
  const [employeeDialog, setEmployeeDialog] = useState(false);
  const [nameService, setNameService] = useState([]);
  const [pessoas, setPessoas] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const [pessoaSelected, setPessoaSelected] = useState(null)
  const [persons, setPersons] = useState([])
  const [personTable, setPersonTable] = useState([])
  const [perfil, setPerfil] = useState()
  const [openHist, setOpenHist] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [name, setName] = useState('');

  const useStyles = makeStyles({
    table: {
      backgroundColor: colors.surface,
      width: '100%',
      fontSize: '14px'
    },
    headCol: {
      backgroundColor: colors.background,
      position: "sticky",
      top: 0,
      color: textTableColor,
      fontWeight: 500,
      border: 'none',

      textTransform: 'uppercase',
      textAlign: 'center'
    },
    bodyCol: {
      color: textTableColor,
      border: 'none',
      fontSize: '18px',

    },
    wrapBtnModal: {
      alignItems: 'flex-start'
    },
    btnModal: {
      marginLeft: '0', marginTop: '-5px'
    },
    headColModal: {
      backgroundColor: colors.surface,
      textTransform: 'uppercase',
      borderRadius: '0',
      fontSize: '14px'
    },
    containerModal: {
      boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
      borderRadius: '0'
    },
    head: {
      backgroundColor: "#fff",
      position: "sticky",
      top: 0
    }
  });

  const classes = useStyles();

  function openModal(item, action) {
    if (action === 'update') {
      setShowModalEquipe({ show: true, title: 'EDITAR EQUIPE', action: 'editar', item: item });
    } else {
      setShowModalEquipe({ show: true, title: 'REMOVER EQUIPE', action: 'remover', item: item });
    }
  }

  async function getHistorico(companyId, teamId, tableChangeId) {
    try {
      const { data } = await api.get("historico" + '?companyId=' + companyId + '&keyTable=' + teamId + '&tableChangeId=' + tableChangeId);;
      if (data.success) {
        const { result } = data;
        setHistorico(result)
        setOpenHist(true)
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  function openModalHist(item) {
    setHistorico([]);
    setName(item.name);
    getHistorico(item.companyId, item.id, 5);
  }

  const handleCloseHist = () => {
    setOpenHist(false);
    setHistorico([]);
  };

  const getPersonTable = async (id) => {
    try {
      const { data } = await api.get('/team/getPerson/' + id + '/0')
      if (data.success) {
        const { result } = data
        setPersonTable(result);
      } else {
        //enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getPerson = async () => {
    try {
      const { data } = await api.get('/team/personNotInTeam/')
      if (data.success) {
        const { result } = data
        setPessoas(result)
      } else {
        // enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function inserirPessoa() {
    try {
      if (pessoaSelected !== null) {
        const obj = {
          teamId: perfil,
          personId: pessoaSelected.value
        }
        const { data } = await api.post('/teamPerson/', obj)
        if (data.success) {
          const { result } = data;
          console.log(result);
          enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
          getPerson()
          getPersonTable(perfil)
          setPessoaSelected(null)
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        }
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function deletePerson(id) {
    try {
      const { data } = await api.delete('/teamPerson/' + id)
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE)
        const temp = persons.filter(item => item.teamPersonId !== id)
        setPersonTable(temp)
        getPerson()
        getPersonTable(perfil)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const handleClose = () => {
    setEmployeeDialog(false);
    setPersonTable([]);
    getEquipe();
  };

  const handlePessoas = (item) => {
    setPessoaSelected(item)
  }

  function openModal2(item, action) {
    if (action === 'employee') {
      getPerson()
      setPerfil(item.id)
      setEmployeeDialog(true)
      setNameService(item.name)
      getPersonTable(item.id)
      setPessoaSelected(null)
    }
  }

  const modalEmployee = (
    <div className={'disabeFocus alert-modal'} style={modalStyle}>
      <TitleStyle2 className="TitleStyle2">
        Pessoas associadas a Equipe {nameService}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox style={{ overflow: 'hidden' }} >
        <Wrap style={{}}>
          <WrapInput>
            <Input2
              styles={{ control: customControlStyles }}
              placeholder="Pessoas"
              isClearable
              maxMenuHeight={190}
              isSearchable
              value={pessoaSelected}
              onChange={handlePessoas}
              options={
                pessoas.map(item => { return { value: item.personID, label: item.personName } })}
              border={`0.5px ${shade(0.2, colors.surface)} solid`} />
          </WrapInput>
          <WrapInput className={classes.wrapBtnModal}>
            <Button style={{ marginTop: '10px' }} className={classes.btnModal} onClick={() => inserirPessoa()} > Associar </Button>
          </WrapInput>
        </Wrap>
        <TableContainer style={{ height: '300px' }} className={classes.containerModal}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headColModal} align="center">NOME</TableCell>
                <TableCell className={classes.headColModal} align="center">AÇÕES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%', backgroundColor: 'white' }}>
              {
                personTable && personTable.map((row, index) => {
                  return (
                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                      <TableCell align="center">
                        {row.personName}
                      </TableCell>
                      <TableCell align="center">
                        <AiTwotoneDelete
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          color={tint(0.1, colors.error)}
                          size="20"
                          title="Remover"
                          onClick={() => deletePerson(row.teamPersonId)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </ModalBox>
    </div>
  );


  return (
    <TableContainer className={classes.containerModal}>
      <Modal open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Historico historico={historico} name={name} handleCloseHist={handleCloseHist} />
      </Modal>
      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">NOME EQUIPE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">GERENTE EQUIPE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">COORDENADOR EQUIPE</TableCell>
            <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {
            table && table.map((row, index) => {
              return (
                <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.name}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.ManagerName}</TableCell>
                  <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.CoordinatorName}</TableCell>
                  <TableCell
                    style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                    align="center">
                    <BsFillPersonPlusFill
                      onClick={() => openModal2(row, 'employee')}
                      //onClick={() => handleDelete(row.id)}
                      style={{ cursor: "pointer" }}
                      color={tint(0.1, colors.primary)}
                      size="20"
                      title="Pessoas"
                    />
                    <FiEdit
                      style={{ cursor: 'pointer' }}
                      color={tint(0.1, colors.primary)}
                      size="20"
                      title="Editar"
                      onClick={() => openModal(row, 'update')}
                    />
                    {row.logChange > 0 ?
                      <BiHistory
                        onClick={() => openModalHist(row)}
                        style={{ cursor: "pointer" }}
                        color={colors.primary}
                        size="20"
                        title="Atualizações"
                      /> : <BiHistory
                        style={{ cursor: "pointer" }}
                        color={"#808080"}
                        size="20"
                        title="Atualizaçõeso"
                      />
                    }
                    <AiTwotoneDelete
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      color={tint(0.1, colors.error)}
                      size="20"
                      title="Remover"
                      onClick={() => openModal(row, 'delete')}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <Modal open={employeeDialog}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalEmployee}
      </Modal>
    </TableContainer>
  );
}
