import styled from 'styled-components'
import {FaCaretRight} from 'react-icons/fa'



export const Icone = styled(FaCaretRight)`
transform: ${props => props.rotate};
transition: transform 0.4s;
`;


export const Backdrop = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 200;
background-color: rgba(0, 0, 0, 0.4);
`;

export const Backdrop2 = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 500;
background-color: rgba(0, 0, 0, 0.4);
`;




export const WrapTable = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
height: 300px;
`;


export const ButtonW = styled.div`
text-align: center;
font-size: 13px;
width: 150px;
height: 40px;
background-color: ${props => props.bg};
color: #ddd;
display: flex;
justify-content: center;
align-items: center;
border-radius: 15px;
margin-right: 18px;
font-weight: bold;
cursor: pointer;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
&:hover{
    transition: background-color 0.3s;
}
&:active {
 opacity: 0.7;
 transition: opacity 0.3s;
}
`;

export const WrapButtons = styled.div`
display: flex;
justify-content: flex-end;
align-items:center;
width: 100%;
height: 200px;
`;

export const Title = styled.span`
font-size: ${props => props.theme.fontTitle};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
width: 100%;
color: #F5F5F5;
font-weight: bold;
display: flex;
justify-content: space-between;
flex-direction: row;
align-items: center;
background-color: ${props => props.theme.bgHeading};
border-top-left-radius: 10px;
border-top-right-radius: 10px;
height: 25px;
`;


export const ListItems = styled.div`
display: flex;
height: 350px;
border-radius: 10px;
justify-content: center;
align-items: center;
flex-direction: column;
width: 95.2%;
position: fixed;
top: 180px;
right: 150;
z-index: 300;
background-color: ${props => props.theme.colors.surface};  
@media(max-width: 1920px){
    width: 94.7%;
}  
@media(max-width: 1360px){
    width: 94%;
}  
@media(max-width: 1080px){
    min-width: 99%;
    width: 99%;
    left: 0;
    right: 0;
    margin-left: 5px;
}        
`;

export const modalClose = {
    cursor: 'pointer', 
    fontWeight: 'bold',
    marginLeft: 'auto'
    
}

/*

   "itens": [
                {
                    "id": 291,
                    "entrada": "09:00",
                    "saida": "18:13",
                    "placeId": 1,
                    "placeName": "Confluence",
                    "serviceId": 5,
                    "serviceName": "New Timesheet",
                    "jobId": 10,
                    "jobName": "Reunião",
                    "justificationId": 0,
                    "justificationName": "",
                    "observacao": "",
                    "manual": true,
                    "status": 1
                },*/