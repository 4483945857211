import styled from 'styled-components'
import TableContainer from '@material-ui/core/TableContainer';
import Select from 'react-select';


export const MySelect = styled(Select)`
border: ${props => props.border};
width: 95%;
border-radius: 10px;
background-color: ${props => props.theme.colors.background};
`;

export const MyTableContainer = styled(TableContainer)`
border-bottom-right-radius: 10px;
border-bottom-left-radius: 10px;
background-color: ${props => props.theme.colors.surface};
border: none;
height: 100%;
width: 100%;
`;

export const InputText = styled.input`
height: 30px;
min-height: 15px;
outline: 0;
border-radius: 5px;
padding-left: 10px;
width: 70%;
max-width: 400px;
min-width: 100px;
border: ${props => props.border};
color: ${props => props.theme.textTable};
background-color: ${props => props.theme.colors.background};
max-height: 200px;
margin: 0;
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ddd;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ddd;
}
`;

export const Input = styled.input`
height: 30px;
outline: 0;
border-radius: 5px;
padding-left: 10px;
width: 100%;
border: 1px;
color: ${props => props.theme.textTable};
background-color: ${props => props.theme.colors.background};
margin: 0;
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ddd;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ddd;
}
`;