import styled from 'styled-components'

export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100px;
height: 30px;
background-color: ${props => props.theme.buttonPrimary};
border-radius: 10px;
margin-left: 20px;
color: white;
position:relative;
top:-5px;
cursor: pointer;
&:hover{
    opacity: 0.7;
}
`

export const SalvarBtn = styled(Button)`
    background-color: #005A9C;
`
export const ConfirmarBtn = styled(Button)`
    background-color: ${props => props.theme.buttonPrimary};

`

export const CancelarBtn = styled(Button)`
    background-color: #d3504e;

`
export const ClearBtn = styled(Button)`
    color: #212529;
    background-color: #d9dbdc;
    border-color: #d9dbdc;
`


