import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
`;

export const inputStyle = {
    borderRadius: '5px',
    borderColor: 'gray',
    width: '94%',
    fontSize: 14,
    height: 30,
    minHeight: 30,
};

export const TitleStyle2 = styled.h2`    
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    padding: 0 15px;
    border-top-left-radius:10px;
    height:25px;
    border-top-right-radius:10px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;`

    export const fieldSetStyle = {
        margin: '0.5px',
        borderColor:'#aeaeae',
        borderRadius: '10px',
        paddingLeft: '0.1px'
    }

    export const ModalBox = styled.div `
    flex-flow: column nowrap;
    align-items: center;
    justify-content: stretch;
    padding: 5px;
    border: 0px;
    margin:0;
    min-height: 220px;
    @media (max-width: 800px){
        max-height: 80vh;
    } 
    `

export const ItemsBtn = styled.div`
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 48px;
@media (max-width: 800px){
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
`;