// Container,
//     Wrap,
//     Title,
//     WrapInput,
//     Button,
//     Items
import styled from 'styled-components'

export const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
display:flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
`;

export const SearchBox = styled.div `
background-color: ${props => props.theme.colors.surface};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
`;

export const TitleStyle2 = styled.h2 `
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;
`;
export const Wrap = styled.div `
`;
export const WrapInput = styled.div `
`;
export const Items = styled.div `
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;