import React, { useState, useEffect } from 'react';
import MenuClose from 'components/MenuClose'
import MenuOpen from 'components/MenuOpen'
import NavBar from 'components/NavBar'
import HomeAdmin from 'pages/HomeAdmin'
import User from 'pages/CadastroUsuarios'
import CadastroPerfil from 'pages/CadastroPerfil'
import CadastroPessoas from 'pages/CadastroPessoas'
import CadastroIntervalo from 'pages/CadastroIntervalo'
import Project from 'pages/CadastroProjetos'
import RegistroHoras from 'pages/RegistroHoras'
import CadastroCliente from 'pages/CadastroCliente'
import Relatorio from 'pages/Relatorio'
import FechamentoHoras from 'pages/FechamentoHoras'
import CadastroServico from 'pages/CadastroServico'
import AberturaHoras from 'pages/AberturaHoras'
import { GetUserAccess } from '../services/helpers';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CadastroContrato from 'pages/CadastroContrato'
import PainelAdmin from 'pages/PainelAdmin'
import Consolidado from 'pages/Consolidado'
import CadastroEquipe from 'pages/CadastroEquipe'
import RelatorioGeral from 'pages/RelatorioGeral'
import RelatorioHoraCliente from 'pages/RelatorioHoraCliente'
import Faq from 'pages/Faq'
import RelatorioHorasConsultor from 'pages/RelatorioHorasConsultor'
import Historico from 'pages/Historico'

const rotas = {
  "Relatório Mensal": Relatorio,
  "Geral": RelatorioGeral,
  "Horas Cliente": RelatorioHoraCliente,
  "Usuário": User,
  "Tíme": Project,
  "Horas": Project,
  "Pessoa": CadastroPessoas,
  "Intervalo": CadastroIntervalo,
  "Serviço": CadastroServico,
  "Modulo": Project,
  "Cliente": CadastroCliente,
  "Empresa": Project,
  "Projeto": Project,
  "Perfil": CadastroPerfil,
  "Administrativo": PainelAdmin,
  "Abertura de Horas": AberturaHoras,
  "Administrador": PainelAdmin,
  "Consolidado": Consolidado,
  "Registro de Horas": RegistroHoras,
  "Fechamento de Horas": FechamentoHoras,
  "Contrato": CadastroContrato,
  "Equipe": CadastroEquipe,
  "Relatório de Horas": RelatorioHorasConsultor,
  "Histórico Atualização": Historico
}


export default function Routes() {
  const [modulo, setModulo] = useState([])
  const [initialPage, setInitialPage] = useState()
  const [profileName, setProfileName] = useState('')

  const getUser = () => {
    const access = GetUserAccess()
    setProfileName(access.profile.toLowerCase())
    setModulo(access.module)
  }
  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (profileName === 'administrador') {
      setInitialPage(modulo[1].functionality[0].name)
    }
    else {
      if (modulo[0])
        setInitialPage(modulo[0].functionality[0].name)
    }
  }, [modulo])

  const routes2 = []
  modulo.map(item => {
    return item.functionality.map(functionality => {
      const name = functionality.name.replace(/\s/g, '-').toLowerCase()
      const pageName = functionality.name
      routes2.push({
        name: name,
        route: <Route exact path={'/' + name}
          component={rotas[pageName]}
        />
      })
    })
  })

  return (
    <BrowserRouter basename={`/${GetUserAccess().profile.toLowerCase()}`}>
      <NavBar />
      <MenuOpen />
      <div style={{ display: 'flex' }}>
        <MenuClose />
        <Switch >
          {routes2.map(item => item.route)}
          <Route exact path='/faq' component={Faq} />
          {
            <Route component={rotas[initialPage] ? rotas[initialPage] : HomeAdmin} />
          }
        </Switch>
      </div>
    </BrowserRouter>
  )

} 