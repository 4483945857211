import React, { useContext, useState, useEffect } from "react";
import { Items, SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Container } from "../../components/PageCadastro";
import { TitleStyle2, fieldSetStyle, ModalBox, ItemsBtn } from "../../pages/CadastroServico/styles";
import { useShowModal } from 'contexts/SharedContext'
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, } from "utils/types-messages";
import { HeaderList, BodyList } from '../../components/TabelaCadastro'
import { Wrap, WrapInput, WrapList, WrapInputClear } from "../../components/Wrap";
import { Input, LabelInput, inputStyle, multSelect } from 'components/Input';
import { MySelect, selectStyle } from "../../components/InputSelect";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, CircularProgress, ModalCircular } from "@material-ui/core";
import { ThemeContext } from 'styled-components'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from "notistack";
import { api } from "services/axios";
import { modalStyle2, modalStyle, modalClose, StyledBox } from '../../components/Modal'
import Modal from "@material-ui/core/Modal";
import { Button, ConfirmarBtn, CancelarBtn, ClearBtn } from "../../components/Button";
import TableCadastroServico from 'components/TableCadastroServico'
import { GetSessionUser, getMonthYear } from 'services/helpers'
import MessageAlert from 'components/MessageAlert'
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';

export default function CadastroServico() {
  const { bgHeading } = useContext(ThemeContext)
  const [values, setValues] = useState({
    "companyId": '',
    "aggrementId": '', "personId": '',
    "clientId": '', "name": "",
    "initalDate": "", "finalyDate": "",
    'clientResponsible': '', 'clientServiceCode': '',
    'externalCode': '',
    'aggrementTypeId': '',
    'logChange': 0
  });
  const [values2, setValues2] = useState({
    "companyId": '',
    "aggrementId": '', "personId": '',
    "clientId": '', "name": "",
    "initalDate": "", "finalyDate": "",
    'clientResponsible': '', 'clientServiceCode': '',
    'externalCode': '',
    'aggrementTypeId': '',
    'logChange': 0
  });

  const [agreements, setAgreements] = useState([]);
  const [agreements2, setAgreements2] = useState([]);
  const [typeAgreements, setTypeAgreements] = useState([]);
  const [typeAgreements2, setTypeAgreements2] = useState([]);
  const [clients, setClients] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [servico, setServicos] = useState([]);
  const [selectedAgreements, setSelectedAgreements] = useState();
  const [selectedAgreements2, setSelectedAgreements2] = useState();
  const [selectedPerson, setSelectedPerson] = useState();
  const [selectedPerson2, setSelectedPerson2] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedClient2, setSelectedClient2] = useState();
  const { setShowModalServico, showModalServico } = useShowModal();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFim, setSelectedDateFim] = React.useState(null);
  const [selectedDate2, setSelectedDate2] = React.useState(null);
  const [selectedDateFim2, setSelectedDateFim2] = React.useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [compId, setCompId] = useState(1);
  const [nameEdit, setNameEdit] = useState()
  const [codigoExterno, setCodigoExterno] = useState()
  const [codigoExterno2, setCodigoExterno2] = useState()
  const [clientServiceCode, setclientServiceCode] = useState('')
  const [clientResponsible, setclientResponsible] = useState('')
  const [clientServiceCode2, setclientServiceCode2] = useState('')
  const [tipoContrato, setTipoContrato] = useState('')
  const [tipoContrato2, setTipoContrato2] = useState('')
  const [clientResponsible2, setclientResponsible2] = useState('')
  const [nameEdit2, setNameEdit2] = useState()
  const [openUpdate, setOpenUpdate] = useState(false);
  const [inserirDialog, setInserirDialog] = useState(false);
  const { colors, textTableColor } = useContext(ThemeContext)
  const [loadDialog, setLoadDialog] = useState(false);
  const [monthYear] = useState(getMonthYear());
  const [selectedMesAno, setSelectedMesAno] = useState([]);

  //Iniciar
  const getInit = async () => {
    const { perfil } = GetSessionUser()
    setCompId(perfil.companyId)
    fetchClients()
    getPessoas()
    //fetchTypeAgreement()
    setValues({ ...values, companyId: perfil.companyId })
    setValues2({ ...values2, companyId: perfil.companyId })
    fetchService(1)
    fetchTypeAgreement();
  };

  useEffect(() => {
    getInit();
  }, []);


  useEffect(() => {
    if (values.aggrementId)
      fetchTypeAgreement2(values.aggrementId)
  }, [values.aggrementId])

  //  useEffect(() => {
  //    let date = new Date();
  //    handleDateChange(new Date(date.getFullYear(), date.getMonth(), 1));
  //    handleDateChangeFim(new Date(new Date()))
  //  }, []);


  const getPessoas = async () => {
    const { perfil } = GetSessionUser()
    try {
      const { data } = await api.get('/person?companyId=' + perfil.companyId + '&status=1')
      if (data.success) {
        const { result } = data
        setPessoas(result);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: bgHeading,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: bgHeading,
          //color: bgHeading,
        },
      },
      MuiPickersDay: {
        day: {
          // color: bgHeading,
        },
        daySelected: {
          backgroundColor: bgHeading,
          '&:hover': {
            backgroundColor: bgHeading,
          },

        },
        dayDisabled: {
          //  color: lightBlue100,
        },
        current: {
          color: bgHeading,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: bgHeading,
        },
      },
    },
  });

  let handleDateChange2 = (date) => {
    setSelectedDate2(date);
    if (date == null) {
      setValues2({ ...values2, initalDate: "" });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let params = ano + '-' + mes + '-' + day;
      values2.initalDate = params;
      setValues2({ ...values2, initalDate: params })
    }
  }

  let handleDateChange = (date) => {
    setSelectedDate(date);
    if (date == null) {
      setValues({ ...values, initalDate: "" });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let params = ano + '-' + mes + '-' + day;
      values.initalDate = params;
      setValues({ ...values, initalDate: params })
    }
  }

  let handleDateChangeFim2 = (date) => {
    setSelectedDateFim2(date);
    if (date == null) {
      setValues2({ ...values2, finalyDate: "" });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = ano + '-' + mes + '-' + day;
      setValues2({ ...values2, finalyDate: data })
    }
  }

  let handleDateChangeFim = (date) => {
    setSelectedDateFim(date);
    if (date == null) {
      setValues({ ...values, finalyDate: "" });
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = ano + '-' + mes + '-' + day;
      setValues({ ...values, finalyDate: data })
    }
  }

  const handleClientEdit = (item) => {
    if (item == null) {
      setSelectedAgreements(null)
      setAgreements([])
      setTipoContrato(null)
      setTypeAgreements2([])
      setSelectedClient(null)
    } else {
      setSelectedAgreements(null)
      setAgreements([])
      setTipoContrato(null)
      setTypeAgreements2([])
      fetchAgreement(item.value)
      setSelectedClient(item)
    }
    setValues({ ...values, clientId: item === null ? '' : item.value, aggrementId: '', aggrementTypeId: '', logChange: 0 })
  }

  const handleClientEdit2 = (item) => {
    if (item == null) {
      setTipoContrato2(null)
      setSelectedAgreements2(null)
      setAgreements2([])
      setSelectedClient2(item)
      setValues2({ ...values2, clientId: '', aggrementId: '', aggrementTypeId: '', logChange: 0 })
    } else {
      setTipoContrato2(null)
      setAgreements2([])
      setSelectedAgreements2(null)
      fetchAgreement2(item.value)
      setSelectedClient2(item)
      setValues2({ ...values2, clientId: item.value, aggrementId: '', aggrementTypeId: '' })
    }
  }

  const handleAgreementEdit = (item) => {
    if (item == null) {
      setTipoContrato(null)
      setTypeAgreements2([])
      setSelectedAgreements(item)
      setValues2({ ...values2, aggrementId: '', aggrementTypeId: '' })
    } else {
      setTipoContrato(null)
      setTypeAgreements2([])
      setSelectedAgreements(item)
      setValues({ ...values, aggrementId: item.value })
    }
  }

  const handleMesAno = (item) => {
    setSelectedMesAno(item);
    if (item == null) {
      handleDateChange(null);
      handleDateChangeFim(null);
      setValues({ ...values, finalyDate: '', initalDate: '' })
    } else {
      handleDateChange(monthYear[item.value].dtInicial);
      handleDateChangeFim(monthYear[item.value].dtFinal);
    }
  }


  async function fetchTypeAgreement2(aggrementId) {
    try {
      const { data } = await api.get('service/getServiceType/' + aggrementId)
      if (data.success) {
        const { result } = data;
        let servicesRet = result.map((item) => {
          return { id: item.AggrementType, name: item.AggrementTypeName };
        })
        setTypeAgreements2(servicesRet);
      } else {
        setTypeAgreements2([]);
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar tipos de contrato", ERRORMESSAGE);
    }
  }

  async function fetchTypeAgreement() {
    try {
      const { perfil } = GetSessionUser()
      const { data } = await api.get('/aggrementType?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data;
        let servicesRet = result.map((item) => {
          return { id: item.id, name: item.name };
        })
        setTypeAgreements(servicesRet);
      } else {
        setTypeAgreements([]);
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar tipos de contrato", ERRORMESSAGE);
    }
  }

  const handleAgreementEdit2 = (item) => {
    if (item == null) {
      setTipoContrato2('')
      setSelectedAgreements2(item)
    } else {
      setTipoContrato2('')
      setSelectedAgreements2(item)
    }

    setValues2({ ...values2, aggrementId: item === null ? '' : item.value })
  }

  let handleNameEdit2 = (item) => {
    setNameEdit2(item.target.value)
    // name
    setValues2({ ...values2, name: item.target.value })
  }

  let handleNameEdit = (item) => {
    setNameEdit(item.target.value)
    // name
    setValues({ ...values, name: item.target.value })
  }

  async function fetchClients() {
    try {
      const { data } = await api.get("/client/0/?companyId=" + compId);
      if (data.success) {
        const { result } = data;

        let temp = await result.map((item) => {
          return { id: item.id, name: item.fantasyName };
        })
        setClients(temp)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar clientes", ERRORMESSAGE);
    }
  }


  async function fetchAgreement(idClient) {
    try {
      const { data } = await api.get('client/getAggrement/' + idClient)
      if (data.success) {
        const { result } = data;
        //   alert(JSON.stringify(result))
        setAgreements(result)
      } else {
        setAgreements([])
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  async function fetchAgreement2(idClient) {
    try {
      const { data } = await api.get('client/getAggrement/' + idClient)
      if (data.success) {
        const { result } = data;
        //   alert(JSON.stringify(result))
        setAgreements2(result)
      } else {
        setAgreements2([])
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
  }

  async function fetchService(params) {
    const key = enqueueSnackbar('Pesquisando Serviços...', INFOMESSAGE)
    try {
      setServicos([])
      let query = '';
      if (!params) {
        query = `
        &name=${values.name}
        &initalDate=${""}
        &finalyDate=${""}
        &aggrementId=${values.aggrementId}
        &clientId=${values.clientId}
        &personId=${values.personId}
        &clientResponsible=${values.clientResponsible}
        &clientServiceCode=${values.clientServiceCode}
        &externalCode=${values.externalCode}
        &aggrementTypeId=${values.aggrementTypeId}`

      } else {

        query = `
        &name=${values.name}
        &initalDate=${values.initalDate}
        &finalyDate=${values.finalyDate}
        &aggrementId=${values.aggrementId}
        &clientId=${values.clientId}
        &personId=${values.personId}
        &clientResponsible=${values.clientResponsible}
        &clientServiceCode=${values.clientServiceCode}
        &externalCode=${values.externalCode}
        &aggrementTypeId=${values.aggrementTypeId}`
      }

      setLoadDialog(true);
      const { data } = await api.get("service?companyId=" + compId + query);
      if (data.success) {
        const { result } = data;
        //setOptions(result);
        //alert(JSON.stringify(result))
        setServicos(result)
        // console.log(result)
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
    }
    closeSnackbar(key)
    setLoadDialog(false);
  }

  async function sendService() {

    const key = enqueueSnackbar('Salvando Serviço...', INFOMESSAGE)
    try {
      //  name, initalDate, finalyDate, companyId, aggrementId ,clientId
      const { data } = await api.post("/service", values2);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setInserirDialog(false);
        fetchService(1)
        handleClose();
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      handleClose();
    }
    closeSnackbar(key)
  }

  async function EditService(idService) {
    try {
      const { data } = await api.put("/service/" + idService, values2);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setInserirDialog(false);
        fetchService()
        handleClose();
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      handleClose();
    }
  }

  //MOdal
  function openModal(client, clientName, action) {
    //fetchAgreementById(client,clientName)
    if (action === 'update') {
      setOpenUpdate(true);
    }
  }
  function clear() {
    setSelectedClient2("");
    setSelectedAgreements2("");
    setTipoContrato2("");
    setCodigoExterno2("");
    setNameEdit2("");
    setSelectedDate2(null);
    setSelectedDateFim2(null);
    setSelectedPerson2([]);
    setclientServiceCode2("");
    setclientResponsible2("");
    //    setTypeAgreements([]);
    setAgreements2([]);
    setValues2({
      ...values2,
      companyId: compId,
      aggrementId: '',
      aggrementTypeId: '',
      clientId: '',
      clientResponsible: '',
      clientServiceCode: '',
      externalCode: '',
      finalyDate: '',
      initalDate: '',
      name: '',
      personId: '',
      logChange: 0
    })
  }

  function clearSearch() {
    setSelectedClient("");
    setSelectedAgreements("");
    setTipoContrato("");
    setCodigoExterno("");
    setNameEdit("");
    handleMesAno(null);
    setSelectedPerson([]);
    setclientServiceCode("");
    setclientResponsible("");
    setAgreements([]);
    //    setTypeAgreements2([]);
    setValues({
      ...values, aggrementId: '',
      aggrementTypeId: '',
      clientId: '',
      clientResponsible: '',
      clientServiceCode: '',
      externalCode: '',
      finalyDate: '',
      initalDate: '',
      name: '',
      personId: '',
      logChange: 0
    })
    setServicos([]);

  }

  const handleClose = () => {
    clear();
    // setValuesAlt([])
    setShowModalServico({ ...showModalServico, show: false });
    setOpenUpdate(false);
    // fetchAgreementAll(1);
    setInserirDialog(false);
  };

  useEffect(() => {
    if (showModalServico.action === 'editar') {
      var finDt = showModalServico.item['finalyDate']
      if (finDt !== '') {
        var { clientId, aggrementId,
          AggrementType, AggrementTypeId, clientServiceCode,
          aggrementName, personId,
          clientResponsible, name,
          externalCode, initalDate, finalyDate
        } = showModalServico.item
      } else {
        var { clientId, aggrementId,
          AggrementType, AggrementTypeId, clientServiceCode,
          aggrementName, personId,
          clientResponsible, name,
          externalCode, initalDate
        } = showModalServico.item
      }
      const responsavelConfluence = showModalServico.item.personId;

      const _cliente = clients.filter(item => item.id === clientId)[0]
      const _tipoContrato = typeAgreements.filter(item => item.name === AggrementType)[0]
      if (responsavelConfluence) {
        const _responsavelConfluence = pessoas.filter(item => item.id === responsavelConfluence)[0]
        if (_responsavelConfluence) {
          setSelectedPerson2({ value: _responsavelConfluence.id, label: _responsavelConfluence.name });
          setValues2({ ...values2, personId: _responsavelConfluence.id })
        }
      } else {
        clear();
      }
      setSelectedClient2({ value: _cliente ? _cliente.id : '', label: _cliente ? _cliente.name : '' })
      setSelectedAgreements2({ value: aggrementId, label: aggrementName })
      setTipoContrato2({ value: AggrementTypeId ? AggrementTypeId : '', label: AggrementType ? AggrementType : '' })
      setclientServiceCode2(clientServiceCode)
      setclientResponsible2(clientResponsible)
      setNameEdit2(name)
      setCodigoExterno2(externalCode)
      fetchAgreement2(clientId)
      fetchTypeAgreement(aggrementId)
      let temp1 = ''
      let temp2 = ''
      if (finalyDate) {
        temp2 = finalyDate.split('/')
      }
      if (initalDate) {
        temp1 = initalDate.split('/')
      }

      const dataInicial = temp1[1] + '-' + temp1[0] + '-' + temp1[2]
      const dataFinal = temp2[1] + '-' + temp2[0] + '-' + temp2[2]
      const diIniAux = temp1[2] + '-' + temp1[1] + '-' + temp1[0]
      const dtFimAux = temp2[2] + '-' + temp2[1] + '-' + temp2[0]
      if (temp2 === '') {
        setSelectedDateFim2(null);
      } else {
        handleDateChangeFim2(new Date(dataFinal))
      }
      handleDateChange2(new Date(dataInicial))
      ////////////////////// values2
      setValues2({
        ...values2,
        clientId: _cliente && _cliente.id,
        clientServiceCode: clientServiceCode,
        aggrementId: aggrementId,
        aggrementTypeId: AggrementTypeId,
        initalDate: diIniAux,
        finalyDate: temp2 === '' ? '' : dtFimAux,
        clientResponsible: clientResponsible,
        name: name,
        externalCode: externalCode,
        personId: personId
      })
      //  alert(JSON.stringify(values2))
      //  handleDateChange2(new Date(dataInicial))
      //  handleDateChangeFim2(new Date(dataFinal))
    }
  }, [showModalServico.show])



  const setChangeModal = (parm) => {
    setLoadDialog(parm);
  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const modalInserir = (
    <StyledBox className={'disableFocus alert-modal'}>
      <TitleStyle2>
        {showModalServico.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <br></br>
      <ModalBox>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Cliente <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Cliente"
              onChange={handleClientEdit2}
              value={selectedClient2}
              isClearable={true}
              isSearchable={true}
              options={clients.map((item) => {
                return { value: item.id, label: item.name };
              })}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Contrato <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Contrato"
              onChange={handleAgreementEdit2}
              value={selectedAgreements2}
              isClearable={true}
              isSearchable={true}
              options={agreements2.map((item) => {
                return { value: item.AggrementId, label: item.AggrementName };
              })}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Tipo de Contrato/Serviço <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Tipo de Contrato/Serviço"
              onChange={item => {
                setTipoContrato2(item)
                setValues2({ ...values2, aggrementTypeId: item === null ? '' : item.value })
              }
              }
              value={tipoContrato2}
              isClearable={true}
              isSearchable={true}
              options={typeAgreements.map((item) => {
                return { value: item.id, label: item.name };
              })}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '15%' }}>
            <LabelInput>Código Externo</LabelInput>
            <Input
              style={inputStyle}
              className="inputModal"
              type="text"
              placeholder="Código Externo"
              name="name"
              onChange={item => {
                setCodigoExterno2(item.target.value)
                // name
                setValues2({ ...values2, externalCode: item.target.value })
              }
              }
              value={codigoExterno2}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '80%' }}>
            <LabelInput>Nome do Serviço <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              style={inputStyle}
              maxLength="50"
              className="inputModal"
              type="text"
              placeholder="Nome do Serviço"
              name="name"
              onChange={handleNameEdit2}
              value={nameEdit2}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <WrapInput style={{ position: 'relative', width: '20%' }} >
                <KeyboardDatePicker
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  clearable
                  minDateMessage="Não é possível buscar por datas futuras."
                  margin="normal"
                  helperText={""}
                  id="dateInitial"
                  label="Data Início *"
                  format="dd/MM/yyyy"
                  onChange={handleDateChange2}
                  value={selectedDate2}
                  minDate={new Date(new Date("01-01-2018"))}
                  maxDate={new Date(new Date())}
                  // keyboardIcon={<IoMdClock color={colors.primary} size={15} />}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '20%' }}>
                <KeyboardDatePicker
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  clearable
                  minDateMessage="Não é possível buscar por datas futuras."
                  margin="normal"
                  helperText={""}
                  id="dateFinal"
                  label="Data Fim"
                  format="dd/MM/yyyy"
                  onChange={handleDateChangeFim2}
                  value={selectedDateFim2}
                  minDate={new Date(new Date("01-01-2018"))}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </WrapInput>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput>
            <LabelInput>Responsável Confluence</LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Responsável Confluence"
              onChange={item => {
                setSelectedPerson2(item)
                setValues2({ ...values2, personId: item === null ? '' : item.value })
              }}
              value={selectedPerson2}
              isClearable={true}
              isSearchable={true}
              options={pessoas.map((item) => { return { value: item.id, label: item.name }; })}
            />
          </WrapInput>
          <WrapInput>
            <LabelInput>Código Cliente</LabelInput>
            <Input
              style={inputStyle}
              className="inputModal"
              type="text"
              placeholder="Código Cliente"
              name="name"
              onChange={item => {
                setclientServiceCode2(item.target.value)
                // name
                setValues2({ ...values2, clientServiceCode: item.target.value })
              }}
              value={clientServiceCode2}
            />
          </WrapInput>
          <WrapInput>
            <LabelInput>Responsável Cliente</LabelInput>
            <Input
              style={inputStyle}
              className="inputModal"
              type="text"
              placeholder="Responsável Cliente"
              name="name"
              onChange={item => {
                setclientResponsible2(item.target.value)
                // name
                setValues2({ ...values2, clientResponsible: item.target.value })
              }}
              value={clientResponsible2}
            />
          </WrapInput>
        </Wrap>
        <WrapInput className="wrapInputModal">
          <ItemsBtn>
            <CancelarBtn type="button" onClick={handleClose} > Cancelar </CancelarBtn>
            <Button type="button"
              onClick={() => showModalServico.action === 'editar' ? EditService(showModalServico.item.id) : sendService()}>
              Salvar
            </Button>
          </ItemsBtn>
        </WrapInput>
      </ModalBox>
    </StyledBox>
  );

  return (
    <Container>
      <Modal open={showModalServico.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalInserir}
      </Modal>
      <SearchBox>
        <TitleStyle2>Cadastro de Serviço</TitleStyle2>
        <Wrap>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <div style={{ display: 'flex', width: '34%', backgroundColor: '' }} >
                <WrapInput style={{ width: '15px' }} >
                  <KeyboardDatePicker
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    minDateMessage="Não é possível buscar por datas futuras."
                    margin="normal"
                    helperText={""}
                    id="dateInitial"
                    label="Data Início"
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    // keyboardIcon={<IoMdClock color={colors.primary} size={15} />}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </WrapInput>
                <WrapInput style={{ width: '15px' }} >
                  <KeyboardDatePicker
                    minDateMessage="Não é possível buscar por datas futuras."
                    margin="normal"
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    helperText={""}
                    id="dateFinal"
                    label="Data Fim"
                    format="dd/MM/yyyy"
                    onChange={handleDateChangeFim}
                    value={selectedDateFim}
                    minDate={new Date(new Date("01-01-2018"))}
                    // maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </WrapInput>
              </div>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
          <WrapInput style={{ width: '11%', paddingRight: '0px' }}>
            <LabelInput>Mês</LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Opções.."
              onChange={handleMesAno}
              value={selectedMesAno}
              isClearable={true}
              isSearchable={true}
              options={monthYear.map((item) => {
                return { value: item.id, label: item.monthYear };
              })}
            />
          </WrapInput>

          <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
            <LabelInput>Cliente</LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Opções.."
              onChange={handleClientEdit}
              value={selectedClient}
              isClearable={true}
              isSearchable={true}
              options={clients.map((item) => {
                return { value: item.id, label: item.name };
              })}
            />
          </WrapInput>
          <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
            <LabelInput>Contrato</LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Opções..."
              onChange={handleAgreementEdit}
              value={selectedAgreements}
              isClearable={true}
              isSearchable={true}
              options={agreements.map((item) => {
                return { value: item.AggrementId, label: item.AggrementName };
              })}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
            <LabelInput>Tipo de Contrato/Serviço</LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Opções..."
              onChange={item => {
                setTipoContrato(item)
                setValues({ ...values, aggrementTypeId: item === null ? '' : item.value })
              }
              }
              value={tipoContrato}
              isClearable={true}
              isSearchable={true}
              options={typeAgreements2.map((item) => {
                return { value: item.id, label: item.name };
              })}
            />
          </WrapInput>
          <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
            <LabelInput>Código Externo</LabelInput>
            <Input
              style={inputStyle}
              className="inputModal"
              type="text"
              //placeholder="Código Externo"
              name="name"
              onChange={item => {
                setCodigoExterno(item.target.value)
                // name
                setValues({ ...values, externalCode: item.target.value })
              }
              }
              value={codigoExterno}
            />
          </WrapInput>
          <WrapInput style={{ width: '20%', paddingRight: '0px' }}>
            <LabelInput>Nome do Serviço</LabelInput>
            <Input
              style={inputStyle}
              maxLength="50"
              className="inputModal"
              type="text"
              //placeholder="Inserir nome do Serviço"
              name="name"
              onChange={handleNameEdit}
              value={nameEdit}
            />
          </WrapInput>

          {/* <WrapInput>
            <LabelInput>Responsável Confluence</LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Opções..."
              onChange={item => {
                setSelectedPerson(item)
                setValues({ ...values, personId: item === null ? '' : item.value })
              }
              }
              value={selectedPerson}
              isClearable={true}
              isSearchable={true}
              options={pessoas.map((item) => { return { value: item.id, label: item.name }; })}
            />
          </WrapInput> */}
          {/* <WrapInput>
            <LabelInput>Código Cliente</LabelInput>
            <Input
              style={inputStyle}
              className="inputModal"
              type="text"
              name="name"
              onChange={item => {
                setclientServiceCode(item.target.value)
                // name
                setValues({ ...values, clientServiceCode: item.target.value })
              }}
              value={clientServiceCode}
            />
          </WrapInput> */}
          {/* <WrapInput>
            <LabelInput>Responsável Cliente</LabelInput>
            <Input
              style={inputStyle}
              className="inputModal"
              type="text"
              //placeholder="Inserir Responsável Cliente"
              name="name"
              onChange={item => {
                setclientResponsible(item.target.value)
                // name
                setValues({ ...values, clientResponsible: item.target.value })
              }}
              value={clientResponsible}
            />
          </WrapInput> */}
          <WrapInput style={{ width: '20%', marginTop: '10px' }}>
            <ItemsSearch>
              <IconeSearch size={25} title="Pesquisar" onClick={() => fetchService(1)} />
              <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => { clear(); setShowModalServico({ show: true, action: 'inserir', title: 'CADASTRAR SERVIÇO' }); }}>&nbsp;Novo<AddIcon></AddIcon></Button>
              <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              {/* <Button className='widthButton' onClick={() => { clear(); setShowModalServico({ show: true, action: 'inserir', title: 'CADASTRAR SERVIÇO' }); }}>Inserir</Button> */}
            </ItemsSearch>
          </WrapInput>
        </Wrap>
      </SearchBox>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
      <WrapList>
        <HeaderList>
          <span style={{ marginLeft: '20px' }}>SERVIÇOS  </span>
        </HeaderList>
        <BodyList>
          <TableCadastroServico servico={servico} getServico={fetchService} setChangeModal={setChangeModal} />
        </BodyList>
      </WrapList>
    </Container>
  );
}