import React, { useContext, useState, useEffect } from "react";
import { Button, ConfirmarBtn, CancelarBtn } from "../../components/Button";
import { Wrap, WrapInput, WrapList } from "../../components/Wrap";
import { modalClose, StyledBox } from '../../components/Modal'
import { HeaderList, BodyList } from '../../components/TabelaCadastro'
import { MySelect } from "../../components/InputSelect";
import { Input, LabelInput, inputStyle, selectStyle } from 'components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import TableCadastroEquipe from 'components/TableCadastroEquipe';
import { useShowModal } from 'contexts/SharedContext'
import { Container, TitleStyle2, Items, ModalBox } from "./styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, } from "utils/types-messages";
import { api } from "services/axios";
import { useSnackbar } from "notistack";
import "./style.css";
import { Modal, CircularProgress } from "@material-ui/core";
import { GetSessionUser } from 'services/helpers'
import MessageAlert from 'components/MessageAlert'
import { ThemeContext } from 'styled-components'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';

export default function CadastroEquipe() {
  const { enqueueSnackbar } = useSnackbar();
  const [compId, setCompId] = useState(1);
  const [options, setOptions] = useState([]);
  const [table, setTable] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState();
  const [coordenadorEdit, setCoordenadorEdit] = useState()
  const [gerenteEdit, setGerenteEdit] = useState()
  const [nameEdit, setNameEdit] = useState()
  const [idToEdit, setIdToEdit] = useState();
  const [quantidadePorPagina, setQuantidadePorPagina] = useState();
  const { colors, textTableColor } = useContext(ThemeContext);
  const [name, setName] = useState('');
  const [gerente, setGerente] = useState('');
  const [coordenador, setCoordenador] = useState('');
  const { setShowModalEquipe, showModalEquipe } = useShowModal();
  const [loadDialog, setLoadDialog] = useState(false);


  useEffect(() => {
    getInit()
  }, [])


  //Iniciar 
  const getInit = async () => {
    const { perfil } = GetSessionUser()
    try {
      setLoadDialog(true);
      const { data } = await api.get('/person?companyId=' + perfil.companyId + '&status=1')
      if (data.success) {
        const { result } = data
        setOptions(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  async function clear() {
    setName('');
    setGerente('');
    setCoordenador('');
  }

  const getTeam = async () => {
    try {
      const { perfil } = GetSessionUser()
      setLoadDialog(true);
      const { data } = await api.get('/team?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setTable(result)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  useEffect(() => {
    getTeam()
  }, [])

  //Criar Team
  async function sendTeam() {
    try {
      if (!gerenteEdit) {
        enqueueSnackbar('Campo Gerente Obrigatório.', WARNINGMESSAGE);
      } else {
        const values = {
          name: nameEdit.trim(),
          managerId: gerenteEdit.value,
          companyId: compId,
        }
        if (coordenadorEdit) {
          values.coordinatorId = coordenadorEdit.value
        }
        setLoadDialog(true);
        const { data } = await api.post("/team?companyId=" + compId, values)
        if (data.success) {
          enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
          setShowModalEquipe({ show: false });
          setLoadDialog(false);
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
          setLoadDialog(false);
        }
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    fetchCreate(1);
  }

  //Atualizar Team
  async function sendRegisterToUpdate(id) {
    try {
      debugger
      if (!gerenteEdit) {
        enqueueSnackbar('Campo Gerente Obrigatório.', WARNINGMESSAGE);
      } else {
        let comp_id = '?companyId=' + compId
        const values = {
          name: nameEdit.trim(),
          managerId: gerenteEdit.value,
          companyId: compId,
        }
        if (coordenadorEdit) {
          values.coordinatorId = coordenadorEdit.value
        }
        setLoadDialog(true);
        const { data } = await api.put("/team/" + id + comp_id, values);
        if (data.success) {
          enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
          setShowModalEquipe({ show: false });
          setLoadDialog(false);
        } else {
          enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
          setLoadDialog(false);
        }
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    fetchCreate(1);
  }

  //Atualizar tabela
  async function fetchCreate(paginaVal) {
    try {
      setLoadDialog(true);
      const { data } = await api.get("team" + '/0?companyId=' + 1 + `&page=${paginaVal}`);
      if (data.success) {
        const { result } = data;
        setTable(result);
        setTotalPaginas(data.pages);
        setQuantidadePorPagina(data.result.length);
        if (paginaVal === 1) {
          setPagina(1)
        }
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar clientes", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  // Apagar Team
  async function deleteTeam(id) {
    enqueueSnackbar(<MessageAlert message={'Apagando equipe.'} />, INFOMESSAGE)
    try {
      setLoadDialog(false);
      const { data } = await api.delete("/team/" + id);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
    setShowModalEquipe({ show: false });
    fetchCreate(1);
  }

  //Pesquisar Team
  async function fetchSearch(paginaVal) {

    try {
      let comp_id
      let query_filter = ''

      if (name) {
        query_filter += `&name=${name}`
      }
      if (gerente) {
        query_filter += `&ManagerId=${gerente.value}`
      }
      if (coordenador) {
        query_filter += `&CoordinatorId=${coordenador.value}`
      }

      query_filter += `&page=${paginaVal}`

      if (query_filter === "&name= &ManagerId= &CoordinatorId= &page=" + paginaVal) {
        comp_id = '/0?companyId=' + 1 + `&page=${paginaVal}`
      } else {
        comp_id = '?companyId=' + 1 + query_filter
      }
      setLoadDialog(true);
      const { data } = await api.get("team" + comp_id);
      if (data.success) {
        const { result } = data;
        setTable(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setTable([]);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar Eeuipes", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  // Modal
  const [setOpen] = React.useState(false);
  const [setDeleteDialog] = React.useState(false);

  function openModal(item, action) {
    if (action === 'update') {
      setCoordenadorEdit({ value: item.CoordinatorId, label: item.CoordinatorName })
      setGerenteEdit({ value: item.ManagerId, label: item.ManagerName })
      setNameEdit(item.name)
      setIdToEdit(item.id)
      setOpen(true);
    } else {
      setNameEdit(item.name)
      setDeleteDialog(true)
    }
  }

  //Handles  
  const handleGerente = (item) => {
    setGerente(item)
  }

  const handleCoordenador = (item) => {
    setCoordenador(item)
  }

  const handleClose = () => {
    setShowModalEquipe({ show: false });
  };

  let handleNameEdit = (item) => {
    setNameEdit(item.target.value)
  }

  const handleGerenteEdit = (item) => {
    setGerenteEdit(item)
  }

  const handleCoordenadorEdit = (item) => {
    setCoordenadorEdit(item)
  }
  useEffect(() => {
    if (showModalEquipe.action === 'editar') {
      setNameEdit(showModalEquipe.item.name)
      setGerenteEdit({ "value": showModalEquipe.item.ManagerId, "label": showModalEquipe.item.ManagerName })
      setCoordenadorEdit({ "value": showModalEquipe.item.CoordinatorId, "label": showModalEquipe.item.CoordinatorName })
    }
    if (showModalEquipe.action === 'inserir') {
      setNameEdit()
      setGerenteEdit()
      setCoordenadorEdit()
    }
  }, [showModalEquipe.action])

  function clearSearch() {
    setName('');
    setGerente('');
    setCoordenador('');
    setTable([]);

  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const body = (
    <StyledBox className={'disableFocus alert-modal'}>
      <TitleStyle2>
        {showModalEquipe.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      {(showModalEquipe.action === 'editar' || showModalEquipe.action === 'inserir') &&
        <ModalBox>
          <Wrap>
            <WrapInput>
              <LabelInput>Nome Equipe <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <Input
                placeholder="Inserir nome de equipe"
                style={inputStyle}
                maxLength="40"
                type="text"
                name="name"
                onChange={handleNameEdit}
                value={nameEdit}
              />
            </WrapInput>
            <WrapInput>
              <LabelInput>Gerente Equipe <span style={{color:'#FF0000'}}>*</span></LabelInput>
              <MySelect
                styles={selectStyle}
                placeholder="Selecionar Gerente"
                value={gerenteEdit}
                isClearable={true}
                isSearchable={true}
                onChange={handleGerenteEdit}
                options={options.map((item) => { return { value: item.id, label: item.name }; })}
              />
            </WrapInput>
          </Wrap>
          <Wrap>
            <WrapInput>
              <LabelInput>Coordenador Equipe </LabelInput>
              <MySelect
                styles={selectStyle}
                placeholder="Selecionar Coordenador"
                value={coordenadorEdit}
                isClearable={true}
                isSearchable={true}
                onChange={handleCoordenadorEdit}
                options={options.map((item) => { return { value: item.id, label: item.name }; })}
              />
            </WrapInput>
            <WrapInput></WrapInput>
          </Wrap>
          <Wrap>
            <WrapInput className="wrapInputModal">
              <Items>
                <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                <Button type="button" onClick={() => showModalEquipe.action === 'editar' ? sendRegisterToUpdate(showModalEquipe.item.id) : sendTeam()}> Salvar </Button>
              </Items>
            </WrapInput>
          </Wrap>
        </ModalBox>
      }
      {showModalEquipe && showModalEquipe.action === 'remover' &&
        <div>
          <WrapInput className="alertText">
            <h3>Você tem certeza que deseja remover {showModalEquipe ? showModalEquipe.item.name : ''}? Essa operação é permanente! </h3>
          </WrapInput>
          <WrapInput className="alertBtn">
            <Items>
              <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
              <ConfirmarBtn type="button" onClick={() => deleteTeam(showModalEquipe.item.id)}> Confirmar </ConfirmarBtn>
            </Items>
          </WrapInput>
        </div>
      }
    </StyledBox>
  );

  return (
    <Container>
      <Modal open={showModalEquipe.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <SearchBox>
        <TitleStyle2>Cadastro de Equipes</TitleStyle2>
        <form>
          <Wrap>
            <WrapInput>
              <LabelInput>Nome Equipe</LabelInput>
              <Input
                placeholder=""
                style={inputStyle}
                maxLength="40"
                type="text"
                name="name"
                onChange={e => setName(e.target.value)}
                value={name}
              />
            </WrapInput>
            <WrapInput>
              <LabelInput>Gerente Equipe </LabelInput>
              <MySelect
                styles={selectStyle}
                placeholder="Opções..."
                value={gerente}
                isClearable={true}
                isSearchable={true}
                onChange={handleGerente}
                options={options.map((item) => { return { value: item.id, label: item.name }; })}
              />
            </WrapInput>
            <WrapInput>
              <LabelInput>Coordenador Equipe </LabelInput>
              <MySelect
                styles={selectStyle}
                placeholder="Opções..."
                value={coordenador}
                isClearable={true}
                isSearchable={true}
                onChange={handleCoordenador}
                options={options.map((item) => { return { value: item.id, label: item.name }; })}
              />
            </WrapInput>
            <WrapInput style={{ marginTop: '10px' }}>
              <ItemsSearch>
                <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(1)} />
                <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => setShowModalEquipe({ show: true, title: 'CADASTRAR EQUIPE', action: 'inserir' })}>&nbsp;Novo<AddIcon></AddIcon></Button>
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>
        </form>
      </SearchBox>
      < WrapList>
        <HeaderList>
          <span style={{ marginLeft: '20px' }}>Equipes  </span>
        </HeaderList>
        {
          <BodyList>
            <TableCadastroEquipe table={table} getEquipe={fetchCreate} />
          </BodyList>
        }
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  );
}
