import React, { useContext, useState, useEffect } from 'react'
import { useShowModal, overrideStrings, overrideStringsSearch } from 'contexts/SharedContext'
import { Container, Items, TitleStyle2, fieldSetStyle, Mask2, Mask1, ModalBox2 } from './styles'
import { MySelect as MySelect2 } from "../../components/InputSelect";
import { selectStyle, MySelect } from "../../components/InputSelect";
import { Button, CancelarBtn, ClearBtn } from "components/Button";
import { Input, LabelInput, inputStyle, multSelect } from 'components/Input';
import { Wrap, WrapList, WrapInput } from '../../components/Wrap'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { GetSessionUser } from 'services/helpers'
import { SUCCESSMESSAGE, INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages'
import TableCadastroPessoa from 'components/TableCadastroPessoa'
import { Modal, CircularProgress } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { components } from "react-select";
import MultiSelect from "react-multi-select-component"
import MessageAlert from 'components/MessageAlert'
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose, modalStyle, ModalBox, StyledBox } from '../../components/Modal'
import { ThemeContext } from 'styled-components'
import { ImportantDevices, TramRounded } from '@material-ui/icons';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';

export default function CadastroPessoas() {
  const [pessoas, setPessoas] = useState([]);
  const [user, setUser] = useState('');
  const { setShowModalPessoa, showModalPessoa } = useShowModal();
  const [name, setName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [codExt, setCodExt] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [matricula, setMatricula] = useState('');
  const [team, setTeam] = useState([]);
  const [teamCreate, setTeamCreate] = useState();
  const [teamSearch, setTeamSearch] = useState([]);
  const [teamCreateSearch, setTeamCreateSearch] = useState();
  const [userStateToInclude, setUserStateToInclude] = useState(true);
  const [userStateToFilter, setUserStateToFilter] = useState(true);
  const [sexSend, setSexSend] = useState(null);
  const [perfilSelected, setPerfilSelected] = useState();
  const [intervalo, setIntervalo] = useState([]);
  const [intervaloSelected, setIntervaloSelected] = useState(null);
  const [statusSend, setStatusSend] = useState(null);
  const [userToInclude, setUserToInclude] = useState('');
  const [nameToInclude, setNameToInclude] = useState('');
  const [familyNameToInclude, setFamilyNameToInclude] = useState('');
  const [cpfToInclude, setCpfToInclude] = useState('');
  const [matriculaToInclude, setMatriculaToInclude] = useState('');
  const [maxToShow, setMaxToShow] = useState(2);
  const [servico, setServicos] = useState([]);
  const { colors, textTableColor } = useContext(ThemeContext)
  const [loadDialog, setLoadDialog] = useState(false);

  const sex = [
    { value: "F", label: "Feminino" },
    { value: "M", label: "Masculino" }
  ]
  const status = [
    { value: "1", label: "Ativo" },
    { value: "2", label: "Inativo" }
  ]
  const [typeContract, setTypeContract] = useState([]);
  const [typeContractSelected, setTypeContractSelected] = useState(null);
  const [idToModal, setIdToModal] = useState();
  const [compId, setCompId] = useState(1);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fullwidth, setFullwidth] = useState(false);
  const [values, setValues] = useState({
    "companyId": '',
    "aggrementId": '', "personId": '',
    "clientId": '', "name": "",
    "initalDate": "", "finalyDate": "",
    'clientResponsible': '', 'clientServiceCode': '',
    'externalCode': '',
    'aggrementTypeId': ''
  });


  const SwitchColor = withStyles({
    switchBase: {
      color: 'white',
      '&$checked': {
        color: '#107CB8',
      },
      '&$checked + $track': {
        backgroundColor: '#107CB8',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [timeId, setTimeIdSelected] = useState(null);
  const [equipeSelected, setequipeSelected] = useState([]);
  const ids = equipeSelected.map(item => item.value);

  const [equipeSelectedSearch, setequipeSelectedSearch] = useState([]);
  const idsSearch = equipeSelectedSearch.map(item => item.value);

  const ValueContainer = ({ children, getValue, ...props }) => {

    useEffect(() => {
      if (window.innerWidth < 800) {
        setMaxToShow(1);
      }
    }, []);

    var length = getValue().length;
    let displayChips = React.Children.toArray(children).slice(0, maxToShow);
    let shouldBadgeShow = length > maxToShow;
    let displayLength = length - maxToShow;

    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue && displayChips}
        <div className="root">
          {shouldBadgeShow &&
            `+ ${displayLength} Selecionado${displayLength !== 1 ? "s" : ""} `}
        </div>
      </components.ValueContainer>
    );
  };

  //Iniciar 
  const getInit = async () => {
    setValues({ ...values, companyId: compId })
    fetchService(1);
    const { perfil } = GetSessionUser()
    setUserStateToFilter({ value: 1, label: 'Ativo' })
    setCompId(1)
    try {
      setLoadDialog(true);
      const { data } = await api.get('/person?companyId=' + perfil.companyId + '&status=1')
      if (data.success) {
        const { result } = data
        setPessoas(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  useEffect(() => {
    getInit()
  }, [])


  function clearInclude() {
    setUserToInclude('');
    setNameToInclude('');
    setFamilyNameToInclude('');
    setCodExt('')
    setCpfToInclude('');
    setMatriculaToInclude('');
    setPhone('');
    setMobile('');
    setIntervaloSelected('');
    setSexSend([]);
    setStatusSend([]);
    setTypeContractSelected([]);
    setTeamCreate([]);
    setPerfilSelected('')
  }


  async function sendPerson() {
    const key = enqueueSnackbar('Cadastrando Colaborador ...', INFOMESSAGE);
    try {
      let checkCreate = "";
      if (userStateToInclude === true) {
        checkCreate = 1;
      } else {
        checkCreate = 2;
      }
      const values = {
        companyId: compId,
        typeContractId: typeContractSelected === null ? null : typeContractSelected.value,
        enrollment: matriculaToInclude,
        externalCode: codExt,
        cpf: cpfToInclude,
        name: nameToInclude,
        familyName: familyNameToInclude,
        sex: sexSend === null ? null : sexSend.value,
        phone: phone,
        mobile: mobile,
        email: userToInclude,
        urlAvatar: '',
        teamId: timeId,
        status: statusSend === null ? null : statusSend.value,
        perfil: perfilSelected,
        intervalId: intervaloSelected.value
      };
      setLoadDialog(true);
      const { data } = await api.post("/person?companyId=" + compId, values);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setShowModalPessoa({ show: false });
        setOpen(false);
        clearInclude();
        fetchUpdate(1);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  };

  async function editPerson(id) {
    const key = enqueueSnackbar('Alterando Colaborador ...', INFOMESSAGE)
    try {
      let checkUpdate = "";
      if (userStateToInclude === true) {
        checkUpdate = 1;
      } else {
        checkUpdate = 2;
      }

      const valuesEdit = {
        companyId: compId,
        typeContractId: typeContractSelected.value,
        enrollment: matriculaToInclude,
        externalCode: codExt,
        cpf: cpfToInclude,
        name: nameToInclude,
        familyName: familyNameToInclude,
        sex: sexSend.value,
        phone: phone,
        mobile: mobile,
        email: userToInclude,
        urlAvatar: '',
        teamId: ids,
        status: statusSend.value,
        perfil: perfilSelected,
        intervalId: intervaloSelected.value
      };
      setLoadDialog(true);
      const { data } = await api.put("/person/" + id, valuesEdit);
      if (data.success) {
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        const { result } = data;
        setShowModalPessoa({ show: false });
        clearInclude();
        setOpen(false);
        fetchUpdate(1);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }

    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  //Pesquisar Team
  async function fetchSearch(paginaVal) {
    const key = enqueueSnackbar('Pesquisando Colaborador ...', INFOMESSAGE);

    try {
      let cpfSearch = cpf.replace(/\D/g, "");
      let check = null;
      let comp_id
      let query_filter = ''
      if (userStateToFilter.value === 1) {
        check = 1;
      } else {
        check = 2;
      }

      if (matricula) {
        query_filter += `&enrollment=${matricula}`
      }

      if (codExt) {
        query_filter += `&externalCode=${codExt}`
      }

      if (cpf) {
        query_filter += `&cpf=${cpfSearch}`
      }

      if (name) {
        query_filter += `&name=${name}`
      }

      if (familyName) {
        query_filter += `&familyName=${familyName}`
      }

      if (user) {
        query_filter += `&email=${user}`
      }

      if (teamSearch) {
        query_filter += `&teamId=${idsSearch}`
      }

      query_filter += `&status=${check}`

      query_filter += `&page=${paginaVal}`

      if (query_filter === "&name= &ManagerId= &CoordinatorId= &page=" + paginaVal) {
        comp_id = '/0?companyId=' + 1 + `&page=${paginaVal}`
      } else {
        comp_id = '?companyId=' + 1 + query_filter
      }
      setLoadDialog(true);
      const { data } = await api.get("person" + comp_id);
      if (data.success) {
        const { result } = data;
        setPessoas(result);
        enqueueSnackbar(<MessageAlert message={data.message} />, SUCCESSMESSAGE);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setPessoas([]);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar Equipes", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  const fetchIntervalo = async () => {
    const { perfil } = GetSessionUser()
    try {
      setLoadDialog(true);
      const { data } = await api.get('/interval/?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setIntervalo(result)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  useEffect(() => {
    fetchIntervalo()
  }, []);

  const fetchTypeAgreement = async () => {
    const { perfil } = GetSessionUser()
    try {
      setLoadDialog(true);
      const { data } = await api.get('/typeContract/0?companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setTypeContract(result)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }

  useEffect(() => {
    fetchTypeAgreement()
  }, []);


  const getTeam = async () => {
    try {
      const { perfil } = GetSessionUser()
      setLoadDialog(true);
      const { data } = await api.get('/team?sTime=1&companyId=' + perfil.companyId)
      if (data.success) {
        const { result } = data
        setTeam(result.map((item) => { return { label: item.name, value: item.id } }));
        setTeamSearch(result.map((item) => { return { label: item.name, value: item.id } }));
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
        setLoadDialog(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
      setLoadDialog(false);
    }
  }
  useEffect(() => {
    getTeam()
  }, []);

  //Atualizar tabela
  async function fetchUpdate(paginaVal) {
    try {
      setLoadDialog(true);
      const { data } = await api.get("person" + '?companyId=' + 1 + `&page=${paginaVal}` + '&status=1');
      if (data.success) {
        const { result } = data;
        setPessoas(result);
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar clientes", ERRORMESSAGE);
      setLoadDialog(false);
    }
  }

  //Modal
  const [open, setOpen] = React.useState(false);

  const handleCloseCreate = () => {
    setShowModalPessoa({ show: false });
    setUserStateToInclude(true);
    setOpen(false);
    clearInclude();
  }

  const handleClose = () => {
    clearInclude();
    setOpen(false);
    setShowModalPessoa({ show: false });
    setUserStateToInclude(true);
  };

  function openModal(action) {
    setTimeIdSelected(null)
    setShowModalPessoa({ show: true, title: 'CADASTRO PESSOA', action: 'inserir' });
  }

  const handleStateCadastro = (item) => {
    setUserStateToInclude(item.target.checked);
  };

  const handleStateFilter = (item) => {
    setUserStateToFilter(item);
  };

  const handleStatusSend = (item) => {
    setStatusSend(item);
  };

  const handleTypeContract = (item) => {
    setTypeContractSelected(item);
  };

  let handleSexSend = (item) => {
    setSexSend(item);
  }

  async function handleEquipes(items) {
    if (!items) {
      items = []
      setTimeIdSelected(null)
    } else {
      setTimeIdSelected(items.value)
    }
    let obj = [];
    obj.push(items);
    setequipeSelected(obj)
    const ids = obj.map(item => item.value);
    setTeamCreate(obj);
  }

  async function handleEquipesSearch(items) {
    if (!items) {
      items = []
    }
    let obj = await items.map(item => { return { value: item.value, label: item.label } })
    let encontrou = items.filter(x => x.value == -1)[0]

    if (encontrou) {
      let i = items.indexOf(encontrou)
      if (i == items.length - 1) {
        obj = [{ value: encontrou.value, label: encontrou.label }]
      } else if (i == 0) {
        obj.splice(i, 1)
      }
    }
    setequipeSelectedSearch(obj)
    const ids = obj.map(item => item.value);
    setTeamCreateSearch(obj);
  }

  async function idTeam(params) {
    let obj = await params.map(item => { return { value: item.teamId, label: item.teamName } });
    setequipeSelected(obj);
    setTeamCreate(obj);
  }

  async function clear() {
    setUser("");
    setName("");
    setFamilyName("");
    setCodExt("");
    setCpf("");
    setMatricula("");
  }

  useEffect(() => {
    if (showModalPessoa.action === 'editar') {
      if (showModalPessoa.item.status === "Ativo") {
        setUserStateToInclude(true);
      } else {
        setUserStateToInclude(false);
      }
      idTeam(showModalPessoa.item.team)
      setTimeIdSelected(showModalPessoa.item.team)
      setIdToModal(showModalPessoa.item.id);
      setNameToInclude(showModalPessoa.item.name);
      setFamilyNameToInclude(showModalPessoa.item.familyName);
      setCpfToInclude(showModalPessoa.item.cpf);
      if (showModalPessoa.item.sex === "M") {
        setSexSend({ value: "M", label: "Masculino" });
      } else {
        setSexSend({ value: "F", label: "Feminino" });
      }
      if (showModalPessoa.item.status === "Ativo") {
        setStatusSend({ value: "1", label: "Ativo" });
      } else {
        setStatusSend({ value: "2", label: "Inativo" });
      }
      setTypeContractSelected({ value: showModalPessoa.item.typeContractId, label: showModalPessoa.item.typeContractName });
      setCodExt(showModalPessoa.item.externalCode);
      setMatriculaToInclude(showModalPessoa.item.enrollment);
      setUserToInclude(showModalPessoa.item.email);
      setPhone(showModalPessoa.item.phone);
      setMobile(showModalPessoa.item.mobile);
      setIntervaloSelected({ value: showModalPessoa.item.intervalId, label: showModalPessoa.item.intervalName })
      const temp = showModalPessoa.item.profile.map(item => {
        return { value: item.profileId, label: item.profileName.toUpperCase() }
      })
      setPerfilSelected(temp)
    } else {
      if (showModalPessoa.action === 'inserir') {
        intervalo.map((reg) => {
          if (reg.default) setIntervaloSelected({ value: reg.id, label: reg.name })
        })
      }
    }

  }, [showModalPessoa.action])



  useEffect(() => {
    if (window.innerWidth < 800) {
      setFullwidth(true)
    } else {
      setFullwidth(false)
    }
  }, []);

  async function fetchService() {
    const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE)
    try {
      setLoadDialog(true);
      const { data } = await api.get("/service/getServiceName/0");
      if (data.success) {
        const { result } = data;
        setServicos(result)
        setLoadDialog(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setLoadDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Erro ao buscar usuarios", ERRORMESSAGE);
      setLoadDialog(false);
    }
    closeSnackbar(key)
  }

  function clearSearch() {
    setTeamCreateSearch('');
    setUserStateToFilter({ value: 1, label: 'Ativo' });
    setUser('');
    setFamilyName('');
    setName('');
    setPessoas([]);
    handleEquipesSearch(null);
    setTimeIdSelected(null)
  }

  function componentDidMount() {
    if (setShowModalPessoa.show) {
      document.body.div.style.overflow = 'hidden';
    }
  };

  function componentWillUnmount() {
    document.body.div.style.overflow = 'unset';
  };

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput className="alertText" style={{ alignItems: 'center' }}>
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
      </WrapInput>
    </div>
  );

  const body = (
    <StyledBox className={'disableFocus alert-modal'}>
      <TitleStyle2>
        {showModalPessoa.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox2>

        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '25%' }}>
            <LabelInput>Equipe <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              hasSelectAll={false}
              overrideStrings={overrideStrings}
              options={team}
              value={teamCreate}
              onChange={(item) => handleEquipes(item)}
              placeholder={"Opções..."}
              styles={multSelect}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '28%' }}>
            <LabelInput>Perfil <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={[
                {
                  value: 1,
                  label: 'ADMINISTRADOR'
                },
                {
                  value: 2,
                  label: 'COLABORADOR'
                }
              ]}
              value={perfilSelected}
              onChange={(item) => {
                setPerfilSelected(item)

              }}
              styles={multSelect}
            />

          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '14px' }}>
            <LabelInput>Status <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              options={status}
              value={statusSend}
              onChange={handleStatusSend}
              placeholder={'Opções...'}
              styles={multSelect}
            />

          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '60%', marginLeft: '0px' }}>
            <LabelInput>Nome Completo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Nome Completo"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="name"
              onChange={e => setNameToInclude(e.target.value)}
              value={nameToInclude}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Apelido <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Apelido"
              style={inputStyle}
              maxLength="40"
              type="text"
              name="apelido"
              onChange={e => setFamilyNameToInclude(e.target.value)}
              value={familyNameToInclude}
              maxLength={20}
            />
          </WrapInput>

        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '60%', marginLeft: '0px' }}>
            <LabelInput>E-mail <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir E-mail"
              style={inputStyle}
              type="text"
              name="email"
              onChange={e => setUserToInclude(e.target.value)}
              value={userToInclude}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Sexo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={sexSend}
              isClearable={true}
              isSearchable={true}
              onChange={handleSexSend}
              options={sex}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '25%' }}>
            <LabelInput>Telefone</LabelInput>
            <Mask1
              style={inputStyle}
              type="text"
              name="name"
              onChange={e => setPhone(e.target.value)}
              value={phone}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '28%' }}>
            <LabelInput>Celular</LabelInput>
            <Mask2
              style={inputStyle}
              type="text"
              name="name"
              onChange={e => setMobile(e.target.value)}
              value={mobile}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%', marginLeft: '14px' }}>
            {/*<LabelInput> CPF </LabelInput>
               <Mask
                onChange={e => setCpfToInclude(e.target.value)}
                value={cpfToInclude}
              /> */}
            <LabelInput>Código Externo</LabelInput>
            <Input
              placeholder="Inserir Código "
              style={inputStyle}
              type="text"
              name="name"
              onChange={e => setCodExt(e.target.value)}
              value={codExt}
            />
          </WrapInput>
        </Wrap>
        <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '25%' }}>
            <LabelInput>Matrícula <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <Input
              placeholder="Inserir Matrícula"
              style={inputStyle}
              type="text"
              name="name"
              onChange={e => setMatriculaToInclude(e.target.value)}
              value={matriculaToInclude}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '28%' }}>
            <LabelInput>Tipo Contratação <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder="Opções..."
              value={typeContractSelected}
              isClearable={true}
              isSearchable={true}
              onChange={handleTypeContract}
              shouldToggleOnHover={true}
              options={typeContract.map((item) => { return { value: item.id, label: item.name }; })}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '30%' }}>
            <LabelInput>Intervalo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect2
              styles={multSelect}
              placeholder={'Opções...'}
              value={intervaloSelected}
              isClearable={true}
              isSearchable={true}
              onChange={(item) => { setIntervaloSelected(item) }}
              options={intervalo.map((item) => { return { value: item.id, label: item.name }; })}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput className="wrapInputModal">
            <Items>
              <CancelarBtn type="button" onClick={handleCloseCreate}> Cancelar </CancelarBtn>
              <Button type="button" onClick={() => showModalPessoa.action === 'inserir' ? sendPerson() : editPerson(idToModal)} > Salvar </Button>
            </Items>
          </WrapInput>
        </Wrap>
      </ModalBox2>
    </StyledBox>
  );


  return (
    <Container>
      <Modal open={showModalPessoa.show}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <SearchBox>
        <TitleStyle2>Cadastro de Pessoas</TitleStyle2>
        <Wrap>
          <WrapInput style={{ width: '15%', paddingRight: '5px' }}> <LabelInput> Nome Completo </LabelInput>
            <Input
              //placeholder="Pesquisar Nome Completo"
              style={inputStyle}
              maxLength="50"
              type="text"
              name="name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </WrapInput>
          <WrapInput style={{ width: '10%', paddingRight: '5px' }}> <LabelInput> Apelido </LabelInput>
            <Input
              //placeholder="Pesquisar Apelido"
              style={inputStyle}
              maxLength="40"
              type="text"
              name="name"
              onChange={e => setFamilyName(e.target.value)}
              value={familyName}
            />
          </WrapInput>
          <WrapInput style={{ width: '10%', paddingRight: '5px' }}>
            <LabelInput> E-mail </LabelInput>
            <Input
              //placeholder="Pesquisar E-mail"
              style={inputStyle}
              type="text"
              name="email"
              onChange={e => setUser(e.target.value)}
              value={user}
            />
          </WrapInput>
          <WrapInput style={{ width: '5%', paddingRight: '0px' }}>
            <LabelInput> Status </LabelInput>
            <MySelect
              styles={multSelect}
              placeholder="Opções"
              onChange={handleStateFilter}
              options={[{ value: 1, label: 'Ativo' }, { value: 2, label: 'Inativo' }]}
              value={userStateToFilter}
            />
          </WrapInput>
          <WrapInput style={{ width: '10%', paddingLeft: '0px' }}> <LabelInput>Equipes </LabelInput>
            <MultiSelect
              hasSelectAll={false}
              overrideStrings={overrideStringsSearch}
              options={teamSearch}
              value={teamCreateSearch}
              onChange={(item) => handleEquipesSearch(item)}
            />
          </WrapInput>
          <WrapInput style={{ marginTop: '10px' }}>
            <ItemsSearch>
              <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(1)} />
              <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('save')}>&nbsp;Novo<AddIcon></AddIcon></Button>
              <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
            </ItemsSearch>
          </WrapInput>
        </Wrap>
      </SearchBox>
      <WrapList>
        <HeaderList>
          <span style={{ marginLeft: '20px' }}>PESSOAS  </span>
        </HeaderList>
        {
          <BodyList>
            <TableCadastroPessoa
              pessoas={pessoas}
              team={team}
              typeContract={typeContract}
              fetchUpdate={fetchUpdate}
              equipeSelected={equipeSelected}
              ValueContainer={ValueContainer}
              handleEquipes={handleEquipes}
            />
          </BodyList>
        }
      </WrapList>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}
