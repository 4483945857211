import styled from 'styled-components'

export const WrapMedium = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 30%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapSmall = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 10%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapCpf = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 115px;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapEmail = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 300px;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapMultSelect = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 500px;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;


export const WrapStatus = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 160px;
margin-left: 660px;
margin-right: 1px;
margin-bottom: 10px;
margin-top: -50px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapCode = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 250px;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;


export const WrapModal = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 135px;
    margin-left: 1px;
    flex-grow: 1;
    margin-right: 1px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 5px;        
    @media (max-width: 800px){
        width: 60%;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
`;




export const Wrap = styled.div `
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 98%;
    margin: 0 auto;
    padding-top: 20px;
    @media (max-width: 800px){
        flex-direction: column;
        align-items: center;
    }
`;

export const WrapInput = styled.div `
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    width: 15%;
    margin-left: 5px;
    flex-grow: 1;
    margin-right: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 5px;        
    @media (max-width: 800px){
        width: 60%;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
`;



export const WrapInputClear = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 12%;
    margin-left: 5px;
    flex-grow: 1;
    margin-right: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 5px;        
    @media (max-width: 800px){
        width: 60%;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }
`;


export const WrapInputSmall = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 20px;
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapInputAlt = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 1px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapList = styled.div `
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const WrapButtons = styled.div `
position:absolute;
bottom:0;
color: gray;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
width: 100%;
// background-color: ${props => props.bgColor};
padding-top: 10px;
padding-bottom: 10px;
`;

export const WrapButton = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 47px;
margin-right: 1px;
margin-bottom: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapButtonModal = styled.div `
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
margin-left: 5px;
margin-right: 1px;
margin-top: 10px;
/* background-color:red; */
@media (max-width: 800px){
    width: 60%;
}
`;

export const WrapDiv = styled.div `
display: flex;
justify-content: space-around;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
padding-top: 20px;
`;


