import React, { useState, useEffect } from 'react'
import {

  TitleStyle2,
  Items,

} from '../Consolidado/styles.js';
import { Button } from "../../components/Button";
import { Wrap, WrapInput } from "../../components/Wrap"
import { SearchBox, ItemsSearch, IconPdf } from "../../components/Search";
import { Container, overrideStrings, overrideStringsClientes, WrapInputItensCenter } from './styles.js'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import { INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE } from 'utils/types-messages';
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { LabelInput } from "components/Input";
import { GetSessionUser } from 'services/helpers'
import MessageAlert from 'components/MessageAlert'
import MultiSelect from "react-multi-select-component"
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


export default function RelatorioHorasConsultor() {


  const { enqueueSnackbar } = useSnackbar();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFim, setSelectedDateFim] = React.useState(null);

  const [client, setClient] = useState([]);
  const [clientSelected, setClientSelected] = useState([]);

  const [dataFinal, setDataFinal] = useState('')
  const [dataInicial, setDataInicial] = useState('')

  const [service, setService] = useState([]);
  const [serviceSelected, setServiceSelected] = useState([]);
  const [loadDialog, setLoadDialog] = useState(false);

  const [aggrement, setAggrement] = useState([]);
  const [aggrementSelected, setAggrementSelected] = useState([]);

  const [compId, setCompId] = useState(1);

  const [valuesAlt, setValuesAlt] = useState({ serviceId: [], clientId: [], aggrementId: [] });

  useEffect(() => {
    let date = new Date();
    handleDateChange(new Date(date.getFullYear(), date.getMonth(), 1));
    handleDateChangeFim(new Date(new Date()));
    getClient();
  }, []);


  let handleDateChange = (date) => {
    setClientSelected([])
    setClient([])
    getClient()
    setService([]);
    setServiceSelected([]);
    setSelectedDate(date);
    if (date === null || !date) {
      setDataInicial('')
    } else {
      const newDate = date;
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();

      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }

      let data = day + '/' + mes + '/' + ano;
      setDataInicial(data)
    }
  }

  const handleDateChangeFim = (date) => {
    setClientSelected([])
    setClient([])
    getClient()
    setService([]);
    setServiceSelected([]);
    setSelectedDateFim(date);
    if (date === null || !date) {

      setDataFinal('');
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = day + '/' + mes + '/' + ano;
      setDataFinal(data);
    }
  }

  async function handleClient(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        //const idClient = obj.map(item => item.value);
        setValuesAlt({ ...valuesAlt, clientId: [], serviceId: [], aggrementId: [] });
        // setAggrementSelected([]);
        // setAggrement([]);
        setService([]);
        setServiceSelected([]);
        //getService([]);
        //getAggrement([]);


      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        const idClient = obj.map(item => item.value);
        setValuesAlt({ ...valuesAlt, clientId: idClient, serviceId: [], aggrementId: [] });
        // setAggrementSelected([]);
        // setAggrement([]);
        setService([]);
        setServiceSelected([]);
        getService(idClient);
        //getAggrement(idClient);
        // alert(JSON.stringify(idClient))
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setClientSelected(obj)
      const idClient = obj.map(item => item.value);
      setValuesAlt({ ...valuesAlt, clientId: idClient, serviceId: [], aggrementId: [] });
      getService([]);
      //getAggrement([]);
      // setAggrementSelected([]);
      // setAggrement([]);
      setService([]);
      setServiceSelected([]);
    }
  }

  // async function handleContrato(items) {
  //   if (items) {
  //     if (items.length === 0) {
  //       let obj = await items.map(item => { return { value: item.value, label: item.label } })
  //       setAggrementSelected(obj)
  //       const idAgg = obj.map(item => item.value);
  //       setValuesAlt({ ...valuesAlt, aggrementId: idAgg, serviceId: [] });
  //       setServiceSelected([]);
  //       setService([]);
  //       //getService([]);

  //     } else {
  //       let obj = await items.map(item => { return { value: item.value, label: item.label } })
  //       setAggrementSelected(obj)
  //       const idAgg = obj.map(item => item.value);
  //       setValuesAlt({ ...valuesAlt, aggrementId: idAgg });
  //       setServiceSelected([]);
  //       setService([]);
  //       getService(idAgg);
  //     }
  //   } else {
  //     items = [];
  //     let obj = await items.map(item => { return { value: item.value, label: item.label } })
  //     setAggrementSelected(obj)
  //     const idAgg = obj.map(item => item.value);
  //     setValuesAlt({ ...valuesAlt, aggrementId: idAgg });
  //     getService([]);
  //     setServiceSelected([]);
  //     setService([]);
  //   }
  // }

  async function handleService(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)
        const idService = obj.map(item => item.value);
        setValuesAlt({ ...valuesAlt, serviceId: idService });


      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)
        const idService = obj.map(item => item.value);
        setValuesAlt({ ...valuesAlt, serviceId: idService });

      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setServiceSelected(obj)
      const idService = obj.map(item => item.value);
      setValuesAlt({ ...valuesAlt, serviceId: idService });

    }
  }


  function open(params) {
    var link = document.createElement('a');
    link.href = params;
    //link.click();
    window.open(link, '_blank');
  }

  let unico = [];

  function clearSearch() {
    let date = new Date();
    handleDateChange(new Date(date.getFullYear(), date.getMonth(), 1));
    handleDateChangeFim(new Date(new Date()));
    setClientSelected([]);
    setService([]);
    setServiceSelected([]);
  }

  //Combo Cliente
  const getClient = async () => {
    try {
      const { perfil } = GetSessionUser()

      const { data } = await api.get('/client/getByPerson/' + perfil.personId)
      if (data.success) {
        const { result } = data
        setClient(result.map((item) => { return { label: item.clientName, value: item.id } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }
  //Combo Contrato
  // const getAggrement = async (idClient) => {
  //   try {
  //     const { perfil } = GetSessionUser() 
  //     const { data } = await api.get('client/getAggrementByPerson/' + idClient + '/'+ perfil.personId)
  //     if (data.success) {
  //       const { result } = data
  //       setAggrement(result.map((item) => { return { label: item.AggrementName, value: item.AggrementId } }))
  //     } else {
  //       enqueueSnackbar("Não há contratos para este cliente!", WARNINGMESSAGE)
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
  //   }
  // }

  //Combo Serviço
  const getService = async (idCLient) => {
    try {
      const { perfil } = GetSessionUser()

      const { data } = await api.get('/client/getServiceByPerson/' + idCLient + '/' + perfil.personId + '?dataInicio=' + dataInicial + '&dataFim=' + dataFinal)
      if (data.success) {
        const { result } = data
        setService(result.map((item) => { return { label: item.serviceName, value: item.serviceId } }))
      } else {
        enqueueSnackbar(data.message, WARNINGMESSAGE)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function sendExcel() {
    setLoadDialog(true);
    try {
      const { perfil } = GetSessionUser()
      const temp = await api.put("/registeritem/extratoConsultor", { dataInicial, dataFinal, personId: [perfil.personId], clientId: valuesAlt.clientId, aggrementId: valuesAlt.aggrementId, serviceId: valuesAlt.serviceId });
      const { config } = temp
      const { data } = temp
      if (data.success) {
        const { result, path } = data;
        console.log(result, path)
        var url = config.baseURL + path;
        open(url);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
    }
    setLoadDialog(false);
  }

  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInputItensCenter className="alertText">
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Gerando o PDF, Aguarde!</h3>
      </WrapInputItensCenter>
    </div>
  );

  return (
    <Container>
      <SearchBox>
        <TitleStyle2>Relatório Horas Consultor</TitleStyle2>
        <form>
          <Wrap className="Wrap" >
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <div style={{ display: 'flex', width: '25%', backgroundColor: '' }} >
                <WrapInput>
                  <KeyboardDatePicker
                    minDateMessage="Não é possível buscar por datas futuras."
                    helperText={''}
                    cancelLabel="cancelar"
                    margin="normal"
                    clearLabel="Limpar"

                    clearable
                    id="dateInitial"
                    label="Data Início"
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </WrapInput>
                <WrapInput>
                  <KeyboardDatePicker
                    helperText={''}
                    minDateMessage="Não é possível buscar por datas futuras."
                    cancelLabel="cancelar"
                    margin="normal"
                    clearLabel="Limpar"

                    clearable
                    id="dateFinal"
                    label="Data Fim"
                    format="dd/MM/yyyy"
                    onChange={handleDateChangeFim}
                    value={selectedDateFim}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </WrapInput>
              </div>
            </MuiPickersUtilsProvider>
            <WrapInput style={{ width: '25%' }} >
              <LabelInput>Clientes </LabelInput>
              <MultiSelect
                overrideStrings={overrideStringsClientes}
                options={client}
                isClearable={true}
                isSearchable={true}
                value={clientSelected}
                onChange={(item) => handleClient(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ width: '25%' }} >
              <LabelInput>Serviços </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={service}
                value={serviceSelected}
                onChange={(item) => handleService(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', marginTop: '10px' }}>
              <ItemsSearch>
                <IconPdf size={25} title="Gerar PDF" style={{ marginLeft: '0%' }} onClick={() => sendExcel()} />
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
          </Wrap>
        </form>
      </SearchBox>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}