import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa'
import { AiOutlineClear } from "react-icons/ai"
import { SiMicrosoftexcel } from "react-icons/si";
import { ImFilePdf } from "react-icons/im";

export const SearchButton = {
    background: 'red',
}
export const IconeSearch = styled(FaSearch)
`
display: flex;
color: #005A9C;
cursor: pointer;
justify-content: flex-start;
align-items: flex-start;
position:relative;
&:hover{
    opacity: 0.7;
}
`;

export const IconExcel = styled(SiMicrosoftexcel)
`
display: flex;
color: #005A9C;
cursor: pointer;
justify-content: flex-start;
align-items: flex-start;
position:relative;
margin-left: 5%;
&:hover{
    opacity: 0.7;
}
`;

export const IconPdf = styled(ImFilePdf)
`
display: flex;
color: #005A9C;
cursor: pointer;
justify-content: flex-start;
align-items: flex-start;
position:relative;
margin-left: 5%;
&:hover{
    opacity: 0.7;
}
`;

export const IconClear = styled(AiOutlineClear)
`
display: flex;
color: #005A9C;
cursor: pointer;
justify-content: flex-start;
align-items: flex-start;
position:relative;
&:hover{
    opacity: 0.7;
}
`;


export const SearchContent = styled.div `
display: flex;
justify-content: space-between;
align-items: stretch;
width: 94%;
/* padding: 15px 0; */
@media (max-width: 800px){
    width: 100%;
    flex-direction:column
    
}
`;
export const SearchRow = styled.div `
width:98%;
display:flex;
justify-content: flex-start;
flex-direction: row;
flex-wrap: wrap;
position:relative;
@media (max-width: 800px){
    flex-direction: column;
    justify-content: center;
    align-items:center
}
`;
export const SearchBox = styled.div `
background-color: ${props => props.theme.colors.surface};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
margin: 20px 15px 20px 0;
padding: 0;
border-radius: 7px;
width: 93.7%;
@media (max-width: 800px){
    margin: 20px 15px;
    padding-bottom:20px
}
`;

export const Items = styled.div `
display: flex;
justify-content:flex-end;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;

export const ItemsSearch = styled.div`
display: flex;
justify-content:left;
align-items: flex-end;
width: 100%;
height: 48px;
@media (max-width: 800px){
    width: 100%;
    justify-content: left;
    align-items: left;
    margin-top: 10px;
}
`;