import React, { useState, useEffect } from 'react'
import {
  overrideStrings,
  overrideStringsC,
  TitleStyle2,
  Items,
  MySelect,
  selectStyle
} from '../Consolidado/styles.js';
import { Button } from "../../components/Button";
import { Wrap, WrapInput } from "../../components/Wrap"
import { SearchBox, IconeSearch, ItemsSearch, IconExcel } from "../../components/Search";
import { Container } from './styles.js'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import { INFOMESSAGE, ERRORMESSAGE, WARNINGMESSAGE, LOADMESSAGE } from 'utils/types-messages';
import { api } from 'services/axios'
import { useSnackbar } from 'notistack';
import { LabelInput } from 'components/Input';
import MultiSelect from "react-multi-select-component";
import MessageAlert from 'components/MessageAlert'
import { getDate } from 'date-fns';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { getMonthYear } from 'services/helpers'
import { multSelect } from 'components/Input';
import { overrideStringsClientes } from 'pages/RelatorioHorasConsultor/styles.js';
export default function RelatorioGeral() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateFim, setSelectedDateFim] = React.useState(null);
  const [compId, setCompId] = useState(1);
  const [excel, setExcel] = useState({ dec: 2, dataInicial: "", dataFinal: "", teamId: [], clientId: [], aggrementId: [], serviceId: [], personId: [], typeContractId: [], colunas: [], cabec: 1 });
  const [colaboradoresSelected, setColaboradoresSelected] = useState([]);
  const [tipoContratacao, setTipoContratacao] = useState([]);
  const [tipoContratacaoSelected, setTipoContratacaoSelected] = useState([]);
  const [team, setTeam] = useState([]);
  const [person, setPerson] = useState([]);
  const [teamSelected, setTeamSelected] = useState([]);
  const [decSelected, setDecSelected] = useState();
  const [client, setClient] = useState([]);
  const [clientSelected, setClientSelected] = useState([]);
  const idClient = clientSelected.map(item => item.value);
  const [aggrement, setAggrement] = useState([]);
  const [aggrementSelected, setAggrementSelected] = useState([]);
  const [loadDialog, setLoadDialog] = useState(false);
  const [service, setService] = useState([]);
  const [serviceSelected, setServiceSelected] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [monthYear] = useState(getMonthYear());
  const [selectedMesAno, setSelectedMesAno] = useState([]);
  const [cabecSelected, setCabecSelected] = useState([]);
  const [colunasSelected, setColunasSelected] = useState([]);
  const [colunas] = useState([
    { value: 1, label: 'Ano/Mês' },
    { value: 2, label: 'Dia' },
    { value: 3, label: 'Dia da Semana' },
    { value: 4, label: 'Dia Útil' },
    { value: 5, label: 'Colaborador - Código Externo' },
    { value: 6, label: 'Colaborador - Nome Completo' },
    { value: 7, label: 'Colaborador - Apelido' },
    { value: 8, label: 'Colaborador - Tipo de Contratação' },
    { value: 9, label: 'Equipe - Nome' },
    { value: 10, label: 'Equipe - Gerente' },
    { value: 11, label: 'Equipe - Coordenador' },
    { value: 12, label: 'Cliente - Código Externo' },
    { value: 13, label: 'Cliente - Nome Fantasia' },
    { value: 14, label: 'Contrato - Código Externo' },
    { value: 15, label: 'Contrato - Descrição' },
    { value: 16, label: 'Contrato - Tipo' },
    { value: 17, label: 'Serviço - Código Externo' },
    { value: 18, label: 'Serviço - Descrição' },
    { value: 19, label: 'Serviço - Código Cliente' },
    { value: 20, label: 'Serviço - Responsável Cliente' },
    { value: 21, label: 'Serviço - Responsável Confluence' },
    { value: 22, label: 'Registro - Período' },
    { value: 23, label: 'Registro - Entrada' },
    { value: 24, label: 'Registro - Saída' },
    { value: 25, label: 'Registro - Duração' },
    { value: 26, label: 'Registro - Duração Minutos' },
    { value: 27, label: 'Registro - Duração Decimal' },
    { value: 28, label: 'Registro - Atividade' },
    { value: 29, label: 'Registro - Observação' },
    { value: 30, label: 'Registro - Local' },
    { value: 31, label: 'Registro - Tipo' },
    { value: 32, label: 'Registro - Justificativa' },
    { value: 33, label: 'Registro - Status' },
    { value: 34, label: 'Hora noturnas – Min.' },
    { value: 35, label: 'Hora noturnas – Dec.' },
    { value: 36, label: 'Hora comercial – Min.' },
    { value: 37, label: 'Hora comercial – Dec.' },
    { value: 38, label: 'Hora não comercial – Min.' },
    { value: 39, label: 'Hora não comercial – Dec.' },
    { value: 40, label: 'Hora madrugada – Min.' },
    { value: 41, label: 'Hora madrugada – Dec.' }
  ]);


  //Combo Equipe
  const getTeam = async () => {
    try {
      const { data } = await api.get('/team?companyId=' + compId)
      if (data.success) {
        const { result } = data
        setTeam(result.map((item) => { return { label: item.name, value: item.id } }));
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }
  useEffect(() => {
    getTeam()
    getTypeAggrement()
    getClient();
    setColunasSelected(colunas);
  }, []);


  //Combo Cliente
  const getClient = async () => {
    try {
      const { data } = await api.get('/client?companyId=' + compId)
      if (data.success) {
        const { result } = data
        setClient(result.map((item) => { return { label: item.fantasyName, value: item.id } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Contrato
  const getAggrement = async (idClient) => {
    try {
      const { data } = await api.get('client/getAggrement/' + idClient)
      if (data.success) {
        const { result } = data
        setAggrement(result.map((item) => { return { label: item.AggrementName, value: item.AggrementId } }))
      } else {
        enqueueSnackbar("Não há contratos para este cliente!", WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  //Combo Serviço
  const getService = async (idAgg) => {
    try {
      const { data } = await api.get('aggrement/getService/' + idAgg)
      if (data.success) {
        const { result } = data
        setService(result.map((item) => { return { label: item.ServiceName, value: item.ServiceId } }))
      } else {
        enqueueSnackbar('Não há serviços para este contrato!', WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getTypeAggrement = async () => {
    try {
      const { data } = await api.get('/typeContract/0?companyId=1')
      if (data.success) {
        const { result } = data
        setTipoContratacao(result.map((item) => { return { label: item.name, value: item.id } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  const getPerson = async (idTeam, idTypeAggrement) => {
    try {
      const { data } = await api.get(`team/getPerson/${idTeam}/${idTypeAggrement}`)
      if (data.success) {
        const { result } = data
        setPerson(result.map((item) => { return { label: item.personName, value: item.PersonId } }))
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE)
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE)
    }
  }

  async function getDate() {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setDecSelected({ value: 2, label: '2' })
    setExcel({ ...excel, dec: 2 });
    setCabecSelected({ value: 1, label: 'Simples' })
    setExcel({ ...excel, cabec: 1 });
  }

  useEffect(() => {
    getDate();
  }, []);

  let handleDateChange = (date) => {
    setSelectedDate(date);
    setButtonDisable(false);
    if (date === null) {
      setExcel({ ...excel, dataInicial: "" });
      setButtonDisable(true);
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = day + '/' + mes + '/' + ano;
      excel.dataInicial = data;
      setExcel({ ...excel });
    }
  }

  const handleDateChangeFim = (date) => {
    setSelectedDateFim(date);
    setButtonDisable(false);
    if (date === null) {
      setExcel({ ...excel, dataFinal: "" });
      setButtonDisable(true);
    } else {
      const newDate = new Date(date);
      let day = newDate.getDate();
      let mes = newDate.getMonth() + 1;
      let ano = newDate.getFullYear();
      if (parseInt(day) < 10) {
        day = '0' + day.toString();
      }
      if (parseInt(mes) < 10) {
        mes = '0' + mes.toString();
      }
      let data = day + '/' + mes + '/' + ano;
      excel.dataFinal = data;
      setExcel({ ...excel });
    }
  }

  function open(params) {
    var link = document.createElement('a');
    link.href = params;
    link.click();
  }

  async function handleDec(item) {
    setDecSelected(item);
    setExcel({ ...excel, dec: item.value });
  }

  async function handleCabec(item) {
    setCabecSelected(item);
    setExcel({ ...excel, cabec: item.value });
  }

  async function handleClient(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        const idClient = obj.map(item => item.value);
        setExcel({ ...excel, clientId: idClient, aggrementId: [], serviceId: [] });
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setClientSelected(obj)
        const idClient = obj.map(item => item.value);
        setExcel({ ...excel, clientId: idClient });
        setAggrementSelected([]);
        setAggrement([]);
        setService([]);
        setServiceSelected([]);
        getAggrement(idClient);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setClientSelected(obj)
      const idClient = obj.map(item => item.value);
      setExcel({ ...excel, clientId: idClient });
      getAggrement([]);
      setAggrementSelected([]);
      setAggrement([]);
      setService([]);
      setServiceSelected([]);
    }
  }

  async function handleContrato(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setAggrementSelected(obj)
        const idAgg = obj.map(item => item.value);
        setExcel({ ...excel, aggrementId: idAgg, serviceId: [] });
        setServiceSelected([]);
        setService([]);
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setAggrementSelected(obj)
        const idAgg = obj.map(item => item.value);
        setExcel({ ...excel, aggrementId: idAgg });
        setServiceSelected([]);
        setService([]);
        getService(idAgg);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setAggrementSelected(obj)
      const idAgg = obj.map(item => item.value);
      setExcel({ ...excel, aggrementId: idAgg });
      getService([]);
      setServiceSelected([]);
      setService([]);
    }
  }

  async function handleService(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)
        const idService = obj.map(item => item.value);
        setExcel({ ...excel, serviceId: idService });
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setServiceSelected(obj)
        const idService = obj.map(item => item.value);
        setExcel({ ...excel, serviceId: idService });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setServiceSelected(obj)
      const idService = obj.map(item => item.value);
      setExcel({ ...excel, serviceId: idService });
    }
  }

  async function handleTipoContratacao(items) {
    if (items) {
      if (items.length === 0) {
        setTipoContratacaoSelected([])
        setExcel({ ...excel, typeContractId: [], teamId: [], personId: [] });
        setTeam([])
        setTeamSelected([]);
        setPerson([]);
        setColaboradoresSelected([]);
        getTeam()
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTipoContratacaoSelected(obj)
        const tipoContratacao = obj.map(item => item.value);
        setExcel({ ...excel, typeContractId: tipoContratacao, teamId: [], personId: [] });
        setTeam([])
        setTeamSelected([]);
        getTeam()
        getPerson(0, tipoContratacao);
      }
    } else {
      setTipoContratacaoSelected([])
      setExcel({ ...excel, typeContractId: [], teamId: [], personId: [] });
      setTeam([])
      setTeamSelected([]);
      setPerson([]);
      setColaboradoresSelected([]);
      getTeam()
    }
  }

  async function handleEquipe(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTeamSelected(obj)
        const idTeam = obj.map(item => item.value);
        setExcel({ ...excel, teamId: idTeam, clientId: idClient, personId: [], aggrementId: [], serviceId: [] });
        setPerson([]);
        setColaboradoresSelected([]);
        getPerson(0, excel.typeContractId.length === 0 ? 0 : excel.typeContractId);
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setTeamSelected(obj)
        const idTeam = obj.map(item => item.value);
        setExcel({ ...excel, teamId: idTeam, personId: [] });
        setPerson([]);
        setColaboradoresSelected([]);
        getPerson(idTeam, excel.typeContractId.length === 0 ? 0 : excel.typeContractId);
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setTeamSelected(obj)
      const idTeam = obj.map(item => item.value);
      setExcel({ ...excel, teamId: idTeam, personId: [] });
      setPerson([]);
      getPerson(idTeam, 0);
    }
  }

  async function handleColaboradores(items) {
    if (items) {
      if (items.length === 0) {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setColaboradoresSelected(obj)
        const ids = obj.map(item => item.value);
        setExcel({ ...excel, personId: ids, });
      } else {
        let obj = await items.map(item => { return { value: item.value, label: item.label } })
        setColaboradoresSelected(obj)
        const ids = obj.map(item => item.value);
        setExcel({ ...excel, personId: ids });
      }
    } else {
      items = [];
      let obj = await items.map(item => { return { value: item.value, label: item.label } })
      setColaboradoresSelected(obj)
      const ids = obj.map(item => item.value);
      setExcel({ ...excel, personId: ids });
    }
  }

  async function handleColunas(items) {
    if (items) {
      setColunasSelected(items);
      let ItensNot = colunas.filter(col => !items.includes(col));
      const ids = ItensNot.map(Item => Item.value);
      setExcel({ ...excel, colunas: ids });
    }
  }

  async function sendExcel() {
    setButtonDisable(true);
    setLoadDialog(true);
    try {
      const temp = await api.put("/register/relGeral", excel);
      const { config } = temp
      const { data } = temp
      if (data.success) {
        const { result, path } = data;
        var url = config.baseURL + path;
        open(url);
        setButtonDisable(false);
      } else {
        enqueueSnackbar(<MessageAlert message={data.message} />, WARNINGMESSAGE);
        setButtonDisable(false);
      }
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setButtonDisable(false);
    }
    setLoadDialog(false);
  }
  function clearSearch() {
    setSelectedMesAno({ value: monthYear[monthYear[0].ind].id, label: monthYear[monthYear[0].ind].monthYear })
    handleDateChange(monthYear[monthYear[0].ind].dtInicial);
    handleDateChangeFim(monthYear[monthYear[0].ind].dtFinal);
    setDecSelected({ value: 2, label: '2' })
    setCabecSelected({ value: 1, label: 'Simples' })
    setExcel({ ...excel, dec: 2, teamId: [], clientId: [], aggrementId: [], serviceId: [], personId: [], typeContractId: [], colunas: [], cabec: 1 });
    setButtonDisable(true);
    setTipoContratacaoSelected('');
    setTeamSelected('');
    setColaboradoresSelected('');
    setPerson([])
    setClientSelected([]);
    setAggrementSelected('');
    setAggrement([]);
    setServiceSelected('');
    setService([]);
    setButtonDisable(false);
    setColunasSelected(colunas);
  }
  const handleMesAno = (item) => {
    setSelectedMesAno(item);
    if (item === null) {
      handleDateChange(null);
      handleDateChangeFim(null);
    } else {
      handleDateChange(monthYear[item.value].dtInicial);
      handleDateChangeFim(monthYear[item.value].dtFinal);
    }
  }
  const modalLoad = (
    <div className={'disabeFocus alert-modal'}>
      <WrapInput style={{ alignItems: 'center' }} className="alertText">
        <CircularProgress style={{ color: '#043D5D' }} /> <h3>Gerando a Planilha, Aguarde!</h3>
      </WrapInput>
    </div>
  );

  return (
    <Container>
      <SearchBox>
        <TitleStyle2>Relatório Geral</TitleStyle2>
        <form>
          <Wrap className="Wrap" >
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <div style={{ display: 'flex', width: '26%', backgroundColor: '' }} >
                <WrapInput>
                  <KeyboardDatePicker
                    minDateMessage="Não é possível buscar por datas futuras."
                    helperText={''}
                    cancelLabel="cancelar"
                    margin="normal"
                    clearLabel="Limpar"

                    clearable
                    id="dateInitial"
                    label="Data Início"
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </WrapInput>
                <WrapInput>
                  <KeyboardDatePicker
                    minDateMessage="Não é possível buscar por datas futuras."
                    cancelLabel="cancelar"
                    helperText={''}
                    margin="normal"
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    id="dateFinal"
                    label="Data Fim"
                    format="dd/MM/yyyy"
                    onChange={handleDateChangeFim}
                    value={selectedDateFim}
                    minDate={new Date(new Date("01-01-2018"))}
                    maxDate={new Date(new Date())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </WrapInput>
              </div>
            </MuiPickersUtilsProvider>
            <WrapInput style={{ position: 'relative', width: '10%' }}>
              <LabelInput>Mês</LabelInput>
              <MySelect
                styles={multSelect}
                placeholder="Opções.."
                onChange={handleMesAno}
                value={selectedMesAno}
                isClearable={true}
                isSearchable={true}
                options={monthYear.map((item) => {
                  return { value: item.id, label: item.monthYear };
                })}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '15%' }}>
              <LabelInput>Tipo de Contratação</LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={tipoContratacao}
                value={tipoContratacaoSelected}
                onChange={(item) => handleTipoContratacao(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '15%' }}>
              <LabelInput>Equipes </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={team}
                value={teamSelected}
                onChange={(item) => handleEquipe(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '25%' }}>
              <LabelInput>Colaborador </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={person}
                value={colaboradoresSelected}
                onChange={(item) => handleColaboradores(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
          </Wrap>
          <Wrap style={{ position: 'relative', padding: '1px' }}>
            <WrapInput style={{ position: 'relative', width: '16%' }}>
              <LabelInput>Clientes </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={client}
                value={clientSelected}
                onChange={(item) => handleClient(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '20%' }}>
              <LabelInput>Contratos </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={aggrement}
                value={aggrementSelected}
                onChange={(item) => handleContrato(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '20%' }}>
              <LabelInput>Serviços </LabelInput>
              <MultiSelect
                overrideStrings={overrideStrings}
                options={service}
                value={serviceSelected}
                onChange={(item) => handleService(item)}
                labelledBy={"Select"}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '5%' }}>
              <LabelInput>Cabeçalho (Excel)</LabelInput>
              <MySelect
                styles={selectStyle}
                overrideStrings={overrideStrings}
                options={[
                  { value: 1, label: 'Simples' },
                  { value: 2, label: 'Completo' }
                ]}
                value={cabecSelected}
                onChange={(item) => handleCabec(item)}
                labelledBy={"Opções.."}
                isClearable={true}
              />
            </WrapInput>
          </Wrap>
          <Wrap style={{ position: 'relative', padding: '1px' }}>
          <WrapInput style={{ position: 'relative', width: '16%' }}>
              <LabelInput>Colunas (Excel) </LabelInput>
              <MultiSelect
                overrideStrings={overrideStringsC}
                options={colunas}
                value={colunasSelected}
                onChange={(item) => { handleColunas(item) }}
                styles={multSelect}
              />
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '10%' }}>
              <LabelInput>Nº Decimais (Excel)</LabelInput>
              <MySelect
                styles={selectStyle}
                overrideStrings={overrideStrings}
                options={[
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                  { value: 5, label: '5' },
                  { value: 6, label: '6' }
                ]}
                value={decSelected}
                onChange={(item) => handleDec(item)}
                labelledBy={"Opções.."}
                isClearable={true}
              />
            </WrapInput>
            <WrapInput style={{ width: '10%', marginTop: '10px' }}>
              <ItemsSearch>
                <IconExcel size={25} title="Gerar Excel" style={{ marginLeft: '0%' }} onClick={() => sendExcel()} />
                <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
              </ItemsSearch>
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '25%' }}></WrapInput>
          </Wrap>
        </form>
      </SearchBox>
      <Modal open={loadDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalLoad}
      </Modal>
    </Container>
  )
}