import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

export const ContainerOpen = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 240px;
position: absolute;
left: 0;
top: 0;
bottom: 0;
background-color: ${props => props.theme.bgMenu};
height: 100vh;
z-index: 400;
transform:  ${props => props.open};
transition: transform 0.5s ease-in-out;
@media( max-width: 900px){
width: 280px;
}
@media( max-width: 500px){
width: 250px;
}

@media( max-width: 370px){
width: 230px;
}
`;


export const Backdrop = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 300;
opacity: ${props => props.op};
transition: opacity 0.7s ease-out;
background-color: rgba(0, 0, 0, 0.2);
`;


export const WrapLogo = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
`;

export const Logo = styled.div`
display:flex;
width: 180px;
height: 33px;
align-items: center;
justify-content: center;
background-image: url(${props => props.img});
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 0;
margin: 0;
@media( max-width: 500px){
width: 200px;
height: 37px;
}
@media( max-width: 370px){
width: 162px;
height: 30px;
}
`;


export const Header = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
height: 15%;
`;

export const Items = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
`;

export const Space = styled.div`
width: 90%;
background-color: darkturquoise;
`;


export const ButtonClose = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
width: 100%;
padding-top: 10px;
padding-right: 10px;
`;

export const IconeMenu = styled(MdClose)`
cursor: pointer;
&:hover{
    opacity: 0.6;
}
`;
