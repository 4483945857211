import styled from 'styled-components'

export const Container = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 100%;
height: 100vh;
background-color: ${props => props.theme.colors.background};
overflow: auto;
padding-top: 30px;
`;


export const Wrap = styled.div`
background-color: ${props => props.theme.colors.surface};
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 60%;
height: 300px;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.div`
font-size: 16px;
display: flex;
justify-content: flex-start;
align-items: center;
width: 90%;
height: 40px;
font-weight: 500;
color: ${props => props.theme.textTable};
border-bottom: 0.5px ${props => props.theme.textTable} solid;
`;


export const Items = styled.div`
display: flex;
justify-content:space-between;
align-items: center;
width: 90%;
height: 50%;
@media(max-width: 900px){
    flex-direction: column;
    padding-top: 30px;
}
`;


export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 120px;
height: 40px;
background-color: #107CB8;
border-radius: 10px;
color: white;
cursor: pointer;
@media(max-width: 900px){
   width: 70%;
}
`;


export const WrapInput = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 20%;
height: 100%;
margin-left: 1px;
margin-right: 1px;
@media (max-width: 800px){
    width: 60%;
}
`;



