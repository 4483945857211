import React, {} from 'react'
import {Container} from './styles'

export default function CadastroProjetos(){

    return (
        <Container>
            Pagina cadastro de projetos
        </Container>
    )
}