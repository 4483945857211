import React, { useState, useEffect, useContext } from 'react'
import { Container, Form, FormInputs, Title, FormLogo, ButtonLogin, InputLogin, Logo, WrapEsqueciSenha, ButtonEsqueciSenha, InputLoginParent } from './styles.js'
import { LoginUser, getDevice } from 'services/helpers'
import Loader from 'react-spinners/ClipLoader';
import logo from 'assets/logo-confluence.svg'
import bg from 'assets/fundo_web.png'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { ThemeContext } from 'styled-components'
import { GetSessionUser, GetUserAccess } from 'services/helpers'
import { keyUserAccess } from 'services/axios'


export default function Login(props) {
  const { textColorButtonMenu } = useContext(ThemeContext)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [password, setPassword] = useState('')
  const [showForm, setShowForm] = useState('translateY(-100vh)')
  const [loading, setLoading] = useState(false)
  const [showSenhaAtual, setShowSenhaAtual] = useState(true)
  const [device, setDevice] = useState(true)
  const [controle, setControle] = useState(false);

  useEffect(() => {
    setShowForm('translateY(0)')
    setDevice(getDevice());
  }, [])

  async function onSubmit(e) {
    setControle(true);
    e.preventDefault();
    setError(false)
    setLoading(true)
    let retorno = await LoginUser(password, email)
    if (retorno.success) {
      const { menu } = GetSessionUser()
      if (menu) {
        if (menu.length === 1) {
          await localStorage.setItem(keyUserAccess, JSON.stringify(...menu))
          const access = GetUserAccess()
          window.location = '/' + access.profile.toLowerCase()
        }
        else {
          if (device) {
            const temp = menu.filter(obj => obj.profile === 'Colaborador')
            await localStorage.setItem(keyUserAccess, JSON.stringify(...temp))
            const access = GetUserAccess()
            window.location = '/' + access.profile.toLowerCase()
          } else {
            window.location = '/'
          }
        }
      }

    }

    else
      setError(retorno.message)
    setLoading(false)
    setControle(false);
  }

  // function togglePass(val){          
  //     setShowSenhaAtual(!showSenhaAtual)        
  // }

  return (
    <Container bg={bg}>
      <Form onSubmit={onSubmit} showForm={showForm}>

        {error && (
          <small
            style={{
              color: "#ddd",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >

            {error}
          </small>
        )}
        <FormLogo>

          {loading ? (
            <Loader sizeUnit={"px"} size={25} color={"#ddd"} loading={true} />
          ) : (
            <Logo href="https://confluence.com.br " img={logo} />
          )}
        </FormLogo>
        <FormInputs>
          <Title> Login </Title>
          <InputLoginParent>
            <InputLogin
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputLoginParent>
          <InputLoginParent>
            <InputLogin
              type={showSenhaAtual ? "password" : "text"}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showSenhaAtual ?
              (<AiFillEyeInvisible style={{ fill: textColorButtonMenu, position: "absolute", right: "-25px", top: "10px" }} onClick={() => setShowSenhaAtual(!showSenhaAtual)} size={25}></AiFillEyeInvisible>) : (<AiFillEye style={{ fill: textColorButtonMenu, position: "absolute", right: "-25px", top: "10px" }} onClick={() => setShowSenhaAtual(!showSenhaAtual)} size={25} ></AiFillEye>)}
          </InputLoginParent>
          <WrapEsqueciSenha>
            <ButtonEsqueciSenha to="/esqueci-minha-senha">

              Esqueci a senha
              </ButtonEsqueciSenha>
          </WrapEsqueciSenha>
          <ButtonLogin type="submit" className={controle ? 'disable' : 'enable'}> Entrar </ButtonLogin>
        </FormInputs>
      </Form>
    </Container>

  );
}
//<Text>New Timesheet</Text>