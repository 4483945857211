import React, { } from 'react';
import Login from 'pages/Login'
import SelecionarPerfil from 'pages/SelecionarPerfil'
import EsqueciMinhaSenha from 'pages/EsqueciMinhaSenha'
import AlterarSenha from 'pages/AlterarSenha'
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components'
import { isAuthenticated } from 'services/helpers'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { GetSessionUser, GetUserAccess } from 'services/helpers'
import { useTheme } from 'contexts/ThemeContext'
import Routes from 'routes/index'


export default function App() {
  const { theme } = useTheme()


  const Authenticated = () => {
    return isAuthenticated()
  }


  const NeedChangePass = () => {
    const { changePassword } = GetSessionUser()
    if (changePassword)
      return true
    else
      return false
  }

  const hasAccess = () => {
    return GetUserAccess()
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
    
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        maxSnack={1}>
        {
        }
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route
                exact path="/"
                component={Authenticated() ? (hasAccess() ? Routes : SelecionarPerfil) : Login}
              />

              <Route
                exact path="/login"
                component={Authenticated() ? (hasAccess() ? Routes : SelecionarPerfil) : Login}
              />

              <Route exact path="/nova-senha"
                component={Authenticated() ? (NeedChangePass() ? AlterarSenha : (hasAccess() ? Routes : SelecionarPerfil)) : Login}
              />

              <Route
                exact path="/esqueci-minha-senha" component={Authenticated() ? (hasAccess() ? Routes : SelecionarPerfil) : EsqueciMinhaSenha}
              />

              <Route
                exact path="/selecionar-perfil" component={Authenticated() ? (hasAccess() ? Routes : SelecionarPerfil) : Login}
              />

              <Route
                component={Authenticated() ?  (hasAccess() ? Routes : SelecionarPerfil) : Login}
              />

            </Switch>
          </div>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
/*


              */