import React, { useContext } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer
  } from 'components/SharedComponents/table'; 
import { ThemeContext } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { shade } from 'polished'

export default function TabelaPainelAdm(tabelas) {
    const { colors, textTableColor } = useContext(ThemeContext);
    const useStyles = makeStyles({
        table: {           
            backgroundColor: colors.surface, 
            width: '100%', 
            fontSize: '14px'
        },     
        headCol: {
            backgroundColor: colors.background,
            position: "sticky",
            top: 0,
            color: textTableColor, 
            fontWeight: 500, 
            border: 'none', 
          
            textTransform: 'uppercase',
            textAlign: 'center'
        },
        bodyCol: {
            color: textTableColor, 
            border: 'none', 
            fontSize: '18px',
            
        },
        wrapBtnModal:{
            alignItems:'flex-start'
        },
        btnModal:{
            marginLeft:'0',marginTop:'-5px'
        },
        headColModal: {
            backgroundColor: colors.surface,
            textTransform: 'uppercase',
            borderRadius: '0',
            fontSize: '14px'
        },
        containerModal:{
            boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
            borderRadius: '0'
        },
        head: {
          backgroundColor: "#fff",
          position: "sticky",
          top: 0
      }
    });
    const classes = useStyles();   

    return (
      <TableContainer className={classes.containerModal}>
        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">CLIENTE</TableCell>  
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO DE CONTRATO/SERVIÇO</TableCell>  
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">SERVIÇO</TableCell>  
              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TOTAL DE HORAS</TableCell>  
            </TableRow>
          </TableHead>
          <TableBody style={{ width: '100%' }}>
            {
              tabelas.tabelas && tabelas.tabelas.map((row, index) => {
                return (
                  <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.Cliente}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.Contrato}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.Serviço}</TableCell>
                    <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} align="center">{row.TotalHoras}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
}
