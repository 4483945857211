import styled from 'styled-components'

export const Container = styled.div`
background-color: ${props => props.theme.colors.background};
`;

export const SearchBox = styled.div `
background-color: ${props => props.theme.colors.surface};
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
`;

export const TitleStyle2 = styled.h2 `
    background-color: ${props => props.theme.bgBorderButtonMenu};
    color: ${props => props.theme.textHeading} !important;
`;
export const Wrap = styled.div `
`;
export const WrapInput = styled.div `
`;
export const Items = styled.div `
display: flex;
justify-content:center;
align-items: flex-end;
width: 100%;
height: 60px;
@media (max-width: 800px){
    width: 60%;
    justify-content: center;
}
`;

export const WrapInputDate = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 38px;
    margin-left: 1px;
    flex-grow: 1;
    margin-right: 1px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 0 5px;        
    @media (max-width: 800px){
        width: 60%;
        margin: 0;
        padding: 0
    }
`;