import React, { useState, useContext } from 'react'
import {
  Header,
  WrapTotalHoras,
  Title,
  TotalHoras,
  Horas,
  Hora,
  Line,
  TitleHora,
  Line2
} from './styles.js'
import { useColaboradoresResume } from 'contexts/SharedContext'
import TableColab from 'components/TableColabInconsistentes'
import { modalStyle, ModalBox2 } from '../../components/Modal'
import Modal from "@material-ui/core/Modal";
import CancelIcon from '@material-ui/icons/Cancel';
import { modalClose } from '../../components/Modal'
import { ThemeContext } from 'styled-components'
import { TitleStyle2 } from "../../pages/CadastroPessoas/styles";


export default function CommonInfos(props) {
  const { colors, textTableColor } = useContext(ThemeContext)
  const { colaboradoresResume } = useColaboradoresResume();
  const [showModalColaboradores, setShowModalColaboradores] = useState(false)


  async function showColaboradores() {
    try {
      setShowModalColaboradores(true)
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    setShowModalColaboradores(false)
  }

  const modal = (
    <div className={'disabeFocus alert-modal2'} style={modalStyle}>
      <TitleStyle2>
        COLABORADORES COM REGISTROS INCONSISTENTES
                <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>


      <ModalBox2>
        <TableColab />
      </ModalBox2>

    </div>
  );



  return (
    <Header>

      <Modal open={showModalColaboradores}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modal}
      </Modal>

      <span style={{ display: 'none' }}>{JSON.stringify(colaboradoresResume[0])}</span>
      <WrapTotalHoras>
        <Title>Colaboradores</Title>
        <TotalHoras>
          <Horas>
            <Hora> {colaboradoresResume[0] ? colaboradoresResume[0]["Colaboradores Ativos"] : ""}</Hora>
            <TitleHora>Total Ativos</TitleHora>
            <Line> </Line>
          </Horas>
        </TotalHoras>
      </WrapTotalHoras>

      <WrapTotalHoras onClick={() => showColaboradores()} style={{ cursor: 'pointer' }}>
        <Title>Colaboradores</Title>
        <TotalHoras>
          <Horas>
            <Hora> {colaboradoresResume[0] ? colaboradoresResume[0]["Com Registros Inconsistentes"] : ""}</Hora>
            <TitleHora>
              <small>Total com registro de horas inconsistente</small>
            </TitleHora>
            <Line2> </Line2>
          </Horas>
        </TotalHoras>
      </WrapTotalHoras>
    </Header>
  )
}




